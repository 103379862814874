import React from "react";
import {IAttachmentEntity} from "../interface/entity/IAttachmentEntity";

interface IProps {
  title?: string;
  attachments: IAttachmentEntity[];
}

class GateViewAttachmentsSection extends React.Component<IProps> {
  public render() {
    const {attachments} = this.props;
    return (
      <div className="form-control-wrap">
        {this.props.title && <div className="form-label">{this.props.title}</div>}
        {attachments && attachments.length > 0 && attachments.map((attachment: IAttachmentEntity) => (
          <div key={attachment.id} className="form-control-static text-primary text-iconed mt-2 mb-4 py-0">
            <a target={"_blank"} rel="noopener noreferrer" href={attachment.cdnUrl}>
              <i className="ico ico-doc "/>&nbsp;{attachment.fileName}
            </a>
          </div>
        ))}
        {(attachments && attachments.length === 0) && <div className="text-medium text-primary-light mt-2 mb-4">
          No files Attached Yet...
        </div>}
      </div>
    );
  }
}

export default GateViewAttachmentsSection;
