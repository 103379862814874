import { Field } from "formik";
import React from "react";
import AnotherFormDatepicker from "../../components/form/AnotherFormDatepicker";
import FormSelectMultiple from "../../components/form/FormSelectMultiple";
import { Col, Row } from "../../components/Grid";
import {
  InnovationStageKey,
  innovationStageKeyToLabel,
} from "../../interface/entity/IInnovationEntity";
import { IInnovationCollectionFilters } from "../../services/innovation";

export interface IFilterModalFormBody {
  initialValues: Partial<IInnovationCollectionFilters>;
}

export const innovationFilterModalForm: React.FunctionComponent<IFilterModalFormBody> = ({
  initialValues,
}) => {
  return (
    <div>
      <div className="form-group">
        <span className="form-label">Submission Date Range:</span>
        <Row>
          <Col val="6">
            <Field
              name="createDateFrom"
              placeholder="Select Date From"
              labelClass="text-primary-light"
              component={AnotherFormDatepicker}
              selectsStart={true}
              value={initialValues.createDateFrom}
              isClearable={true}
            />
          </Col>
          <Col val="6">
            <Field
              name="createDateTo"
              placeholder="Select Date To"
              labelClass="text-primary-light"
              component={AnotherFormDatepicker}
              selectsEnd={true}
              selected={initialValues.createDateTo}
              isClearable={true}
            />
          </Col>
        </Row>
      </div>
      <div className="form-group">
        <span className="form-label">Project Deadline Date Range:</span>
        <Row>
          <Col val="6">
            <Field
              name="deadlineDateFrom"
              placeholder="Select Date From"
              labelClass="text-primary-light"
              component={AnotherFormDatepicker}
              selectsStart={true}
              selected={initialValues.deadlineDateFrom}
              isClearable={true}
            />
          </Col>
          <Col val="6">
            <Field
              name="deadlineDateTo"
              placeholder="Select Date"
              labelClass="text-primary-light"
              component={AnotherFormDatepicker}
              selectsEnd={true}
              selected={initialValues.deadlineDateTo}
              isClearable={true}
            />
          </Col>
        </Row>
      </div>
      <div className="form-group">
        <Field
          name="stages"
          component={FormSelectMultiple}
          customProps={{
            label: "Select Innovation Current Stage(s):",
          }}
          selectProps={{
            options: Object.keys(innovationStageKeyToLabel).map(
              (stage: any) => ({
                value: +stage,
                label: innovationStageKeyToLabel[stage as InnovationStageKey],
              })
            ),
            defaultValue:
              initialValues.stages &&
              initialValues.stages.map((v: InnovationStageKey) => ({
                value: v,
                label: innovationStageKeyToLabel[v],
              })),
            placeholder: "Select Innovation Current Stage(s)…",
          }}
        />
      </div>
    </div>
  );
};

export const submissionFilterModalForm: React.FunctionComponent<IFilterModalFormBody> = ({
  initialValues,
}) => {
  return (
    <div>
      <div className="form-group">
        <span className="form-label">Submission Date Range:</span>
        <Row>
          <Col val="6">
            <Field
              name="createDateFrom"
              placeholder="Select Date From"
              labelClass="text-primary-light"
              component={AnotherFormDatepicker}
              selectsStart={true}
              value={initialValues.createDateFrom}
              isClearable={true}
            />
          </Col>
          <Col val="6">
            <Field
              name="createDateTo"
              placeholder="Select Date To"
              labelClass="text-primary-light"
              component={AnotherFormDatepicker}
              selectsEnd={true}
              selected={initialValues.createDateTo}
              isClearable={true}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};
