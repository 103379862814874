import React, {Fragment} from "react";
import GateViewAttachmentsSection from "../../../../../../components/GateViewAttachmentsSection";
import {Col, Row} from "../../../../../../components/Grid";
import {
  IConceptInformationGate,
  IfRequestedByASpecificCustomerPleaseIdentify,
  IsThereATargetedBusinessSegmentToLaunch,
  YesNo,
} from "../../../../../../interface/entity/IInnovationEntity";
import ConceptUtils from "../utils/ConceptUtils";
import {defaultCheckboxValue, defaultRadioValue} from "./utils";

interface IProps {
  data: IConceptInformationGate;
}

class Concept extends React.Component<IProps> {
  public render(): React.ReactNode {
    const data: IConceptInformationGate = this.props.data;

    return (
      <Fragment>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">{ConceptUtils.isThisAnItemThatDfffAlreadyGrowsContractGrows.label}</span>
          <p className="text-medium">
            {defaultRadioValue(
              data.isThisAnItemThatDfffAlreadyGrowsContractGrows,
              ConceptUtils.isThisAnItemThatDfffAlreadyGrowsContractGrows.options,
            )}
          </p>
        </div>
        <hr className="hr-dashed"/>
        <GateViewAttachmentsSection title={"Images or illustrations of your proposed innovation:"} attachments={data.proposedInnovationAttachments}/>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">Type of innovation you are proposing:</span>
          <div className="pl-6">
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.productType.label}</span>
              <p className="text-medium">{
                defaultCheckboxValue(
                  data.productType,
                  ConceptUtils.productType.options,
                  data.productTypeOther,
                )}
              </p>
            </div>
            <Row>
              <Col val="7">
                <div className="mb-4">
                  <span className="form-label text-regular text-primary-dark">
                    {ConceptUtils.pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation.label}
                  </span>
                  <p className="text-medium">{data.pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation || "Not Added"}</p>
                </div>
              </Col>
              <Col val="5">
                <div className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.whatIsThePreliminaryProposedPricingPerUnit.label}</span>
                  <p className="text-medium">{data.whatIsThePreliminaryProposedPricingPerUnit || "Not Added"}</p>
                </div>
              </Col>
            </Row>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.innerPackagingType.label}</span>
              <p className="text-medium">{
                defaultCheckboxValue(
                  data.innerPackagingType,
                  ConceptUtils.innerPackagingType.options,
                  data.innerPackagingTypeOther,
                )}
              </p>
            </div>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.outerPackagingType.label}</span>
              <p className="text-medium">{
                defaultCheckboxValue(
                  data.outerPackagingType,
                  ConceptUtils.outerPackagingType.options,
                  data.outerPackagingTypeOther,
                )}
              </p>
            </div>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.convenienceValueAdded.label}</span>
              <p className="text-medium">{
                defaultCheckboxValue(
                  data.convenienceValueAdded,
                  ConceptUtils.convenienceValueAdded.options,
                  data.convenienceValueAddedOther,
                )}
              </p>
            </div>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.cpgShelfStable.label}</span>
              <p className="text-medium">{
                defaultCheckboxValue(
                  data.cpgShelfStable,
                  ConceptUtils.cpgShelfStable.options,
                  data.cpgShelfStableOther,
                )}
              </p>
            </div>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.foodService.label}</span>
              <p className="text-medium">{
                defaultCheckboxValue(
                  data.foodService,
                  ConceptUtils.foodService.options,
                  data.foodServiceOther,
                )}
              </p>
            </div>
          </div>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">{ConceptUtils.isThisSolutionIntendedToBeOrganic.label}</span>
          <p className="text-medium">
            &nbsp;
            {defaultRadioValue(
              data.isThisSolutionIntendedToBeOrganic,
              ConceptUtils.isThisSolutionIntendedToBeOrganic.options,
            )}
          </p>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">{ConceptUtils.pleaseDescribeInDetailTheTargetConsumerForThisInnovation.label}</span>
          <p className="text-medium">{data.pleaseDescribeInDetailTheTargetConsumerForThisInnovation || "Not Added"}</p>
        </div>
        <GateViewAttachmentsSection title={"Consumer research that helps define the consumer demand or need:"} attachments={data.consumerResearchAttachments}/>
        {data.productSpecs === YesNo.Yes && (
          <Fragment>
            <hr className="hr-dashed"/>
            <div className="mb-4">
              <span className="form-label">Product specs already available:</span>
              <Row>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.packSize.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.packSize,
                      ConceptUtils.packSize.options,
                    )}
                  </p>
                  <div className="text-medium">{data.packSize === YesNo.Yes ? data.packSizeValue : ""}</div>
                </Col>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.cartonsPerPallet.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.cartonsPerPallet,
                      ConceptUtils.cartonsPerPallet.options,
                    )}
                  </p>
                  <div className="text-medium">{data.cartonsPerPallet === YesNo.Yes ? data.cartonsPerPalletValue : ""}</div>
                </Col>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.tieHigh.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.tieHigh,
                      ConceptUtils.tieHigh.options,
                    )}
                  </p>
                  <div className="text-medium">{data.tieHigh === YesNo.Yes ? data.tieHighValue : ""}</div>
                </Col>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.grossWeight.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.grossWeight,
                      ConceptUtils.grossWeight.options,
                    )}
                  </p>
                  <div className="text-medium">{data.grossWeight === YesNo.Yes ? data.grossWeightValue : ""}</div>
                </Col>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.cartonSize.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.cartonSize,
                      ConceptUtils.cartonSize.options,
                    )}
                  </p>
                  <div className="text-medium">{data.cartonSize === YesNo.Yes ? data.cartonSizeValue : ""}</div>
                </Col>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.cartonCube.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.cartonCube,
                      ConceptUtils.cartonCube.options,
                    )}
                  </p>
                  <div className="text-medium">{data.cartonCube === YesNo.Yes ? data.cartonCubeValue : ""}</div>
                </Col>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.upc.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.upc,
                      ConceptUtils.upc.options,
                    )}
                  </p>
                  <div className="text-medium">{data.upc === YesNo.Yes ? data.upcValue : ""}</div>
                </Col>
                <Col val="3" className="mb-4">
                  <span className="form-label text-regular text-primary-dark">{ConceptUtils.gtin.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.gtin,
                      ConceptUtils.gtin.options,
                    )}
                  </p>
                  <div className="text-medium">{data.gtin === YesNo.Yes ? data.gtinValue : ""}</div>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <Row>
            <Col val="6">
              <span className="form-label text-regular text-primary-dark">
                {ConceptUtils.isThereATargetedBusinessSegmentToLaunch.label}
              </span>
              <div className="text-medium">{defaultRadioValue(
                data.isThereATargetedBusinessSegmentToLaunch,
                ConceptUtils.isThereATargetedBusinessSegmentToLaunch.options,
              )}</div>
            </Col>
            <Col val="6">
              <span className="form-label text-regular text-primary-dark">
                {ConceptUtils.ifRequestedByASpecificCustomerPleaseIdentify.label}
              </span>
              <div className="text-medium">{data.ifRequestedByASpecificCustomerPleaseIdentify === IfRequestedByASpecificCustomerPleaseIdentify.Other ?
                data.pleaseIdentifyCustomerName || "Not Added"
                :
                defaultRadioValue(
                  data.ifRequestedByASpecificCustomerPleaseIdentify,
                  ConceptUtils.ifRequestedByASpecificCustomerPleaseIdentify.options,
                )}</div>
            </Col>
          </Row>
        </div>
        {data.isThereATargetedBusinessSegmentToLaunch !== IsThereATargetedBusinessSegmentToLaunch.No && <Fragment>
            <hr className="hr-dashed"/>
            <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.targetBusinessSegmentToLaunchDetails.label}</span>
                <p className="text-medium">{data.targetBusinessSegmentToLaunchDetails || "Not Added"}</p>
            </div>
        </Fragment>}
        <hr className="hr-dashed"/>
        <div className="pl-6">
          <Row>
            <Col val="3">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.managerName.label}</span>
                <p className="text-medium">{data.managerName || "Not Added"}</p>
              </div>
            </Col>
            <Col val="3">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.officeOrStore.label}</span>
                <p className="text-medium">{data.officeOrStore || "Not Added"}</p>
              </div>
            </Col>
            <Col val="3">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.willingToTest.label}</span>
                <p className="text-medium">
                  {defaultRadioValue(
                    data.willingToTest,
                    ConceptUtils.willingToTest.options,
                  )}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col val="3">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.volumeEstimate.label}</span>
                <p className="text-medium">{data.volumeEstimate || "Not Added"}</p>
              </div>
            </Col>
            <Col val="3">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.canWeFollowUpWithThisBuyer.label}</span>
                <p className="text-medium">
                  {defaultRadioValue(
                    data.canWeFollowUpWithThisBuyer,
                    ConceptUtils.canWeFollowUpWithThisBuyer.options,
                  )}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col val="3">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.buyersPhone.label}</span>
                <p className="text-medium">{data.buyersPhone || "Not Added"}</p>
              </div>
            </Col>
            <Col val="3">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{ConceptUtils.buyersEmail.label}</span>
                <p className="text-medium">{data.buyersEmail || "Not Added"}</p>
              </div>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <Row>
          <Col val="3">
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.doYouHaveAMarketingContact.label}</span>
              <p className="text-medium">
                {defaultRadioValue(
                  data.doYouHaveAMarketingContact,
                  ConceptUtils.doYouHaveAMarketingContact.options,
                )}</p>
              <div className="text-medium">{data.doYouHaveAMarketingContact === YesNo.Yes ? data.marketingContractDetails : ""}</div>
            </div>
          </Col>
          <Col val="3">
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.doYouHaveAnRDContact.label}</span>
              <p className="text-medium">
                {defaultRadioValue(
                  data.doYouHaveAnRDContact,
                  ConceptUtils.doYouHaveAnRDContact.options,
                )}</p>
              <div className="text-medium">{data.doYouHaveAnRDContact === YesNo.Yes ? data.rndContactDetails : ""}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col val="3">
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.doYouHaveAnInnovationContact.label}</span>
              <p className="text-medium">
                {defaultRadioValue(
                  data.doYouHaveAnInnovationContact,
                  ConceptUtils.doYouHaveAnInnovationContact.options,
                )}</p>
              <div className="text-medium">{data.doYouHaveAnInnovationContact === YesNo.Yes ? data.innovationContactDetails : ""}</div>
            </div>
          </Col>
          <Col val="3">
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{ConceptUtils.doYouHaveAFoodSafetyContact.label}</span>
              <p className="text-medium">
                {defaultRadioValue(
                  data.doYouHaveAFoodSafetyContact,
                  ConceptUtils.doYouHaveAFoodSafetyContact.options,
                )}</p>
              <div className="text-medium">{data.doYouHaveAFoodSafetyContact === YesNo.Yes ? data.foodSafetyContactDetails : ""}</div>
            </div>
          </Col>
        </Row>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <Row>
            <Col val="4">
              <div className="form-group mb-4">
                <label className="form-datepicker-wrap"><span className="form-label">Expected Launch Date:</span>
                  <div className="form-datepicker-wrap">
                    <div className="form-control-static text-primary text-iconed mt-2 mb-4 py-0"><i className="ico ico-calendar "/>&nbsp;
                      {data.isThereAnExpectedLaunchDate || "Not Added"}
                    </div>
                  </div>
                </label>
              </div>
            </Col>
            <Col val="8">
              <GateViewAttachmentsSection title={"General Documents:"} attachments={data.generalAttachments}/>
            </Col>
          </Row>
        </div>
        <div className="mb-4">
          <span className="form-label">
            {ConceptUtils.anyOtherInformationYouWouldLikeToInclude.label}
          </span>
          <p className="text-medium">{data.anyOtherInformationYouWouldLikeToInclude || "Not Added"}</p>
        </div>
      </Fragment>
    );
  }
}

export default Concept;
