import React from "react";
import {Link} from "react-router-dom";
import PureLayout from "../components/Layout/PureLayout";
import {INNOVATION_LIST} from "../components/routes";
import notFoundTitle from "../markup/images/not-found-title.svg";

class NotFound extends React.Component {
  public render(): React.ReactNode {
    return (
      <PureLayout>
        <div className="body-not-found">
          <div className="text-center py-16">
            <img className="img-responsive mx-auto mb-6" src={notFoundTitle} alt="404" />
            <p className="text-primary mb-5">We’re sorry, but we cannot find this page.</p>
            <Link className="btn btn-ghost px-4" to={INNOVATION_LIST}>Back to Innovations List</Link>
          </div>
        </div>
      </PureLayout>
    );
  }
}

export default NotFound;
