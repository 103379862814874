import React, {Fragment} from "react";
import Modal from "react-modal";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import {addErrorNotification, addSuccessNotification} from "../../../components/Notification";
import axiosInstance from "../../../instance/axios";
import {IUserEntity, userRoleKeyToLabel} from "../../../interface/entity/IUserEntity";
import {AppState} from "../../../store";
import {adminUserCollectionRemoveError, adminUserCollectionRemoveStart, adminUserCollectionRemoveSuccess} from "../../../store/admin/user/list/action";
import {IAdminUserListState} from "../../../store/admin/user/list/type";

interface IProps {
  user: IUserEntity;
  adminUserListState: IAdminUserListState;
  onSuccess: () => void;
  onAdminUserCollectionRemoveStart: () => void;
  onAdminUserCollectionRemoveSuccess: () => void;
  onAdminUserCollectionRemoveError: (error: unknown) => void;
}

interface IState {
  isModalOpened: boolean;
}

class UserTr extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      isModalOpened: false,
    };
  }

  public render(): React.ReactNode {
    const {isModalOpened} = this.state;
    const {user} = this.props;

    const modal = (
      <Modal
        isOpen={isModalOpened}
        onRequestClose={this.onCloseModal}
        contentLabel="Remove user"
        className="modal modal-sm"
      >
        <header className="modal-header clearfix">
          <h3 className="modal-title pull-left">Remove User</h3>
          <button onClick={this.onCloseModal} className="modal-close">
            <i className="ico ico-close-thin"/>
          </button>
        </header>
        <div className="mobal-body px-5 py-6">
          <p>
            Are you sure you want to remove this user?
          </p>
        </div>
        <footer className="modal-footer text-right">
          <button
            className="btn btn-shade ml-4"
            type="button"
            onClick={this.onCloseModal}
          >
            Close
          </button>
          <button
            className="btn btn-shade ml-4 btn-danger"
            type="button"
            onClick={async () => {
              await this.removeUser();
            }}
          >
            Remove User
          </button>
        </footer>
      </Modal>
    );

    return (
      <Fragment>
        {modal}
        <tr>
          <td>
            <div className="media">
              <div className="media-aside">
                <div className="user-avatar">
                  {user.firstName.charAt(0)}{user.lastName.charAt(0)}
                </div>
              </div>
              <div className="media-body pl-2">
                <p className="mb-2">
                  <span className={"text-medium text-primary-dark"}>
                    {user.fullName || "N/a"}
                  </span>
                  {user.isValidator ? <span className={"text-danger"}> (validator)</span> : ""}
                </p>
                <p className="mb-0">
                  {user.email}
                </p>
              </div>
            </div>
          </td>
          <td>
            {userRoleKeyToLabel[user.role]}
          </td>
          <td>
            {user.department ? user.department.name : "Not Added Yet"}
          </td>
          <td className="text-right">
            <button
              className="btn btn-ghost btn-sq ml-3"
              type="button"
              onClick={() => {
                this.setState({isModalOpened: true});
              }}
            >
              <i className="ico ico-delete"/>
            </button>
            <Link to={`/admin/user/${this.props.user.id}`} className="btn btn-ghost btn-sq ml-3" type="button">
              <i className="ico ico-edit"/>
            </Link>
          </td>
        </tr>
      </Fragment>
    );
  }

  private onCloseModal = () => {
    const {adminUserListState: {isRemoving}} = this.props;
    if (isRemoving) {
      return;
    }
    this.setState({isModalOpened: false});
  }

  private removeUser = async () => {
    const {adminUserListState: {isRemoving}} = this.props;
    if (isRemoving) {
      return;
    }

    this.props.onAdminUserCollectionRemoveStart();
    await axiosInstance().delete("/user/" + this.props.user.id)
      .then(() => {
        this.props.onAdminUserCollectionRemoveSuccess();
        this.setState({isModalOpened: false});
        this.props.onSuccess();
        addSuccessNotification("Successfully removed");
      })
      .catch((error) => {
        addErrorNotification(error.response.data.message || "Error on removing user");
        this.props.onAdminUserCollectionRemoveError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  adminUserListState: state.admin.user.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAdminUserCollectionRemoveStart: () => {
    dispatch(adminUserCollectionRemoveStart());
  },
  onAdminUserCollectionRemoveSuccess: () => {
    dispatch(adminUserCollectionRemoveSuccess());
  },
  onAdminUserCollectionRemoveError: (error: unknown) => {
    dispatch(adminUserCollectionRemoveError());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserTr);
