import { Field, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import * as Yup from "yup";
import { FormText } from "../../components/form";
import AnotherFormSelect from "../../components/form/AnotherFormSelect";
import FormCheckbox from "../../components/form/FormCheckbox";
import FormRadioCustomList from "../../components/form/FormRadioCustomList";
import BaseLayout from "../../components/Layout/BaseLayout";
import WrapperLoader from "../../components/loader/WrapperLoader";
import {
  addErrorNotification,
  addSuccessNotification,
} from "../../components/Notification";
import { ADMIN_USER_LIST } from "../../components/routes";
import axiosInstance from "../../instance/axios";
import { IDepartmentEntity } from "../../interface/entity/IDepartmentEntity";
import {
  IUserEntity,
  UserRole,
  userRoleKeyToLabel,
  UserValidationPermission,
} from "../../interface/entity/IUserEntity";
import { ICollectionResource } from "../../interface/rest/ICollectionResource";
import { AppState } from "../../store";
import {
  adminUserUpdateDeinit,
  adminUserUpdateError,
  adminUserUpdateFetchDepartmentsError,
  adminUserUpdateFetchDepartmentsStart,
  adminUserUpdateFetchDepartmentsSuccess,
  adminUserUpdateInit,
  adminUserUpdateStart,
  adminUserUpdateSuccess,
} from "../../store/admin/user/update/action";
import { IAdminUserUpdateState } from "../../store/admin/user/update/type";

interface IFormValues {
  isValidator: boolean;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  departmentId: { label: string; value: string } | null;
}

const validationSchema = Yup.object().shape({
  isValidator: Yup.boolean().required("Required"),
  role: Yup.string().required("Required"),
  firstName: Yup.string().trim().required("Required"),
  lastName: Yup.string().trim().required("Required"),
  email: Yup.string().trim().email().required("Required"),
  // TODo good to handle this anyway
  // departmentId: Yup.mixed().oneOf([
  //   Yup.object({
  //     label: Yup.string(),
  //     value: Yup.string(),
  //   }),
  //   null,
  // ]),
});

interface IProps extends RouteComponentProps {
  adminUserUpdateState: IAdminUserUpdateState;
  onAdminUserUpdateInit: (user: IUserEntity) => void;
  onAdminUserUpdateDeinit: () => void;
  onAdminUserUpdateStart: () => void;
  onAdminUserUpdateSuccess: () => void;
  onAdminUserUpdateError: () => void;
  match: {
    params: {
      id: number;
    };
    isExact: boolean;
    path: string;
    url: string;
  };
  onAdminUserUpdateFetchDepartmentsStart: () => void;
  onAdminUserUpdateFetchDepartmentsSuccess: (
    departments: IDepartmentEntity[]
  ) => void;
  onAdminUserUpdateFetchDepartmentsError: () => void;
}

interface IState {
  isHintVisible: boolean;
}

class UserUpdate extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isHintVisible: false,
    };
  }

  public async componentDidMount() {
    await this.fetchUser(this.props.match.params.id);
    await this.fetchDepartments();
  }

  public componentWillUnmount(): void {
    this.props.onAdminUserUpdateDeinit();
  }

  public render(): React.ReactNode {
    const { adminUserUpdateState } = this.props;

    return (
      <BaseLayout location={this.props.location}>
        <div className="mb-6">
          <Link to={ADMIN_USER_LIST}>&lt; Back to Users List</Link>
        </div>
        <WrapperLoader
          isLoading={
            !adminUserUpdateState.user || adminUserUpdateState.isLoading
          }
        >
          {this.getForm()}
        </WrapperLoader>
      </BaseLayout>
    );
  }

  private onSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const {
      adminUserUpdateState: { user },
    } = this.props;
    if (!user) {
      return;
    }
    this.props.onAdminUserUpdateStart();
    await axiosInstance()
      .put("/user/" + user.id, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role,
        isValidator: values.isValidator
          ? UserValidationPermission.Allow
          : UserValidationPermission.Deny,
        departmentId: values.departmentId ? values.departmentId.value : null,
      })
      .then(() => {
        const { history } = this.props;
        history.push(ADMIN_USER_LIST);
        addSuccessNotification("Successfully updated!");
        this.props.onAdminUserUpdateSuccess();
      })
      .catch(() => {
        addErrorNotification("Error on updating a user");
        this.props.onAdminUserUpdateError();
      });
  };

  private fetchUser = async (id: number) => {
    await axiosInstance()
      .get("/user/" + id)
      .then((response: { data: IUserEntity }) => {
        this.props.onAdminUserUpdateInit(response.data);
      })
      .catch(() => {
        // TODO handle this case
      });
  };

  private getForm = () => {
    const {
      adminUserUpdateState: { user },
    } = this.props;
    if (!user) {
      return;
    }

    const initialValues: IFormValues = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      isValidator: user.isValidator === UserValidationPermission.Allow,
      role: String(user.role),
      departmentId: user.department
        ? { value: String(user.department.id), label: user.department.name }
        : null,
    };
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validationSchema={validationSchema}
        render={(props: FormikProps<IFormValues>) => (
          <form onSubmit={props.handleSubmit}>
            <div className="details-header-line mb-4">
              <div className="pull-left">
                <h1 className="h2 m-0 pt-2">Update User</h1>
              </div>
              <div className="pull-right">
                <button
                  disabled={!props.isValid}
                  className="btn btn-primary btn-shade btn-width"
                  type="submit"
                >
                  Save User
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-3">
                    <div className="form-group">
                      <Field
                        name="role"
                        label="Select User Role*:"
                        options={[
                          {
                            value: String(UserRole.Manager),
                            label: userRoleKeyToLabel[UserRole.Manager],
                          },
                          {
                            value: String(UserRole.Admin),
                            label: userRoleKeyToLabel[UserRole.Admin],
                          },
                        ]}
                        component={FormRadioCustomList}
                      />
                    </div>
                  </div>
                  <div className="col-3 mt-6">
                    <div
                      className="form-group"
                      onClick={() => {
                        this.setState({ isHintVisible: true });
                      }}
                    >
                      <Field
                        name={"isValidator"}
                        label={"Can validate innovation"}
                        component={FormCheckbox}
                      />
                    </div>
                  </div>
                  <div className="col-6 mt-8">
                    {this.state.isHintVisible && (
                      <div className={"text-medium text-danger"}>
                        Role changes will be applied on the next user login.
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="form-group">
                      <Field
                        label="First Name*:"
                        name="firstName"
                        placeholder="Nelle"
                        component={FormText}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <Field
                        label="Last Name*:"
                        name="lastName"
                        placeholder="Elliott"
                        component={FormText}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <div className="form-group">
                  <Field
                    label="Email*:"
                    name="email"
                    placeholder="nelle.elliot@gmail.com"
                    component={FormText}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <Field
                    component={AnotherFormSelect}
                    name="departmentId"
                    selectProps={{
                      options: this.props.adminUserUpdateState.department.items.map(
                        (department: IDepartmentEntity) => {
                          return {
                            value: String(department.id),
                            label: department.name,
                          };
                        }
                      ),
                      placeholder: "Select Department",
                      isClearable: true,
                    }}
                    customProps={{
                      label: "Select Department",
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    );
  };

  private fetchDepartments = async () => {
    this.props.onAdminUserUpdateFetchDepartmentsStart();
    await axiosInstance()
      .get("/department/", {
        params: {
          page: 1,
          perPage: 1000,
        },
      })
      .then((response: { data: ICollectionResource<IDepartmentEntity> }) => {
        this.props.onAdminUserUpdateFetchDepartmentsSuccess(
          response.data.items
        );
      })
      .catch(() => {
        // TODO handle this case
        this.props.onAdminUserUpdateFetchDepartmentsError();
      });
  };
}

const mapStateToProps = (state: AppState) => ({
  adminUserUpdateState: state.admin.user.update,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAdminUserUpdateInit: (user: IUserEntity) => {
    dispatch(adminUserUpdateInit(user));
  },
  onAdminUserUpdateDeinit: () => {
    dispatch(adminUserUpdateDeinit());
  },
  onAdminUserUpdateStart: () => {
    dispatch(adminUserUpdateStart());
  },
  onAdminUserUpdateSuccess: () => {
    dispatch(adminUserUpdateSuccess());
  },
  onAdminUserUpdateError: () => {
    dispatch(adminUserUpdateError());
  },
  onAdminUserUpdateFetchDepartmentsStart: () => {
    dispatch(adminUserUpdateFetchDepartmentsStart());
  },
  onAdminUserUpdateFetchDepartmentsSuccess: (
    departments: IDepartmentEntity[]
  ) => {
    dispatch(adminUserUpdateFetchDepartmentsSuccess(departments));
  },
  onAdminUserUpdateFetchDepartmentsError: () => {
    dispatch(adminUserUpdateFetchDepartmentsError());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserUpdate)
);
