import {IInformationGateEntity} from "../../../interface/entity/IInformationGateEntity";
import {IInnovationEntity, InnovationStageKey} from "../../../interface/entity/IInnovationEntity";
import {
  INNOVATION_ITEM_CHANGE_ACTIVE_TIMELINE,
  INNOVATION_ITEM_CHANGE_GATE,
  INNOVATION_ITEM_FETCH_ERROR,
  INNOVATION_ITEM_FETCH_START,
  INNOVATION_ITEM_FETCH_SUCCESS,
  INNOVATION_ITEM_INIT,
} from "./type";

export const innovationItemFetchStart = () => {
  return {
    type: INNOVATION_ITEM_FETCH_START,
  };
};

export const innovationItemFetchError = (error: unknown) => {
  return {
    error,
    type: INNOVATION_ITEM_FETCH_ERROR,
  };
};

export const innovationItemFetchSuccess = (innovationEntity: IInnovationEntity) => {
  return {
    innovationEntity,
    type: INNOVATION_ITEM_FETCH_SUCCESS,
  };
};

export const innovationItemInit = () => {
  return {
    type: INNOVATION_ITEM_INIT,
  };
};

export const innovationItemChangeActiveTimeline = (stage: InnovationStageKey) => {
  return {
    stage,
    type: INNOVATION_ITEM_CHANGE_ACTIVE_TIMELINE,
  };
};

export const innovationItemChangeInformationGate = (gate: IInformationGateEntity) => {
  return {
    gate,
    type: INNOVATION_ITEM_CHANGE_GATE,
  };
};
