import React from "react";
import {IForm} from "./IForm";

const FormCheckbox = ({field, label, onChange, disabled = false}: IForm) => (
  <label className="form-checkbox text-regular form-control-static">
    <input
      {...field}
      className="form-checkbox-input"
      type="checkbox"
      checked={field.value}
      onChange={onChange || field.onChange}
      disabled={disabled}
    />
    <span className="form-checkbox-label">{label}</span>
  </label>
);

export default FormCheckbox;
