import {IUserEntity} from "../../../../interface/entity/IUserEntity";
import {ICollectionResource} from "../../../../interface/rest/ICollectionResource";
import {
  ADMIN_USER_COLLECTION_FETCH_ERROR,
  ADMIN_USER_COLLECTION_FETCH_START,
  ADMIN_USER_COLLECTION_FETCH_SUCCESS,
  ADMIN_USER_COLLECTION_INIT,
  ADMIN_USER_COLLECTION_REMOVE_ERROR,
  ADMIN_USER_COLLECTION_REMOVE_START,
  ADMIN_USER_COLLECTION_REMOVE_SUCCESS,
} from "./type";

export const adminUserCollectionRemoveStart = () => {
  return {
    type: ADMIN_USER_COLLECTION_REMOVE_START,
  };
};

export const adminUserCollectionRemoveSuccess = () => {
  return {
    type: ADMIN_USER_COLLECTION_REMOVE_SUCCESS,
  };
};

export const adminUserCollectionRemoveError = () => {
  return {
    type: ADMIN_USER_COLLECTION_REMOVE_ERROR,
  };
};

export const adminUserCollectionFetchStart = () => {
  return {
    type: ADMIN_USER_COLLECTION_FETCH_START,
  };
};

export const adminUserCollectionInit = () => {
  return {
    type: ADMIN_USER_COLLECTION_INIT,
  };
};

export const adminUserCollectionFetchSuccess = (collectionResource: ICollectionResource<IUserEntity>) => {
  return {
    collectionResource,
    type: ADMIN_USER_COLLECTION_FETCH_SUCCESS,
  };
};

export const adminUserCollectionFetchError = (error: unknown) => {
  return {
    error,
    type: ADMIN_USER_COLLECTION_FETCH_ERROR,
  };
};
