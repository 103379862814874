import {Field, Formik, FormikProps} from "formik";
import React from "react";

interface ISearchFormValues {
  q: string;
}

interface IProps {
  onSetQ: (q: string) => void;
  q?: string;
}

class InnovationSearch extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {q, onSetQ} = this.props;
    const initialValues: ISearchFormValues = {
      q: q || "",
    };

    // @ts-ignore
    return (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          onSetQ(values.q);
        }}
        render={({handleSubmit}: FormikProps<ISearchFormValues>) => (
          <div>
            <form onSubmit={handleSubmit}>
              <label className="form-control-wrap">
                <div className="input-group input-group-ghost">
                  <Field
                    className="form-control"
                    type="text"
                    placeholder="Search Innovations…"
                    name="q"
                  />
                  {this.props.q && (
                    <div className="input-group-append">
                      <div
                        style={{cursor: "pointer"}}
                        aria-hidden="true"
                        className=" css-tlfecz-indicatorContainer"
                        onClick={() => onSetQ("")}
                      >
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg">
                          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"/>
                        </svg>
                      </div>
                    </div>
                  )}
                  <div className="input-group-append">
                    <button className="btn" type="submit">
                      <i className="ico ico-search"/>
                    </button>
                  </div>
                </div>
              </label>
            </form>
          </div>
        )}
      />
    );
  }
}

export default InnovationSearch;
