import {Field, FormikProps} from "formik";
import React, {Fragment} from "react";
import {FormText} from "../../../../../../components/form";
import AnotherFormDatepicker from "../../../../../../components/form/AnotherFormDatepicker";
import FormRadioCustomList from "../../../../../../components/form/FormRadioCustomList";
import ImmediatelyUploadMultipleField from "../../../../../../components/form/upload/ImmediatelyUploadMultipleField";
import {Col, Row} from "../../../../../../components/Grid";
import {IExecuteInformationGate, YesNo} from "../../../../../../interface/entity/IInnovationEntity";
import ExecuteUtils from "../utils/ExecuteUtils";

interface IProps {
  form: FormikProps<IExecuteInformationGate>;
}

class Execute extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {form} = this.props;
    return (
      <Fragment>
        <div className="mb-4">
          <Row>
            <Col val="2">
              <div className="pt-label">
                <div className="form-control-static-sm text-primary-light">
                  {ExecuteUtils.salesPlan.title}
                </div>
              </div>
            </Col>
            <Col val="10">
              {ExecuteUtils.salesPlan.fields.map((field) =>
                <div className="form-group" key={field.name}>
                  <Field
                    name={field.name}
                    placeholder={field.placeholder}
                    label={field.label}
                    component={FormText}
                  />
                </div>,
              )}
              <div className="form-group">
                <Field
                  name="salesPlanAttachments"
                  component={ImmediatelyUploadMultipleField}
                  emptyMessage="No files Attached Yet..."
                  title="Browse File:"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="mb-4">
          <Row>
            <Col val="2">
              <div className="form-control-static-sm text-primary-light">
                {ExecuteUtils.marketingPlan.title}
              </div>
            </Col>
            <Col val="10">
              {ExecuteUtils.marketingPlan.fields.map((field) =>
                <div className="form-group" key={field.name}>
                  <Field
                    name={`marketingPlan.${field.name}`}
                    placeholder={field.placeholder}
                    label={field.label}
                    component={FormText}
                  />
                </div>,
              )}
            </Col>
          </Row>
        </div>
        <div className="mb-4">
          <Row>
            <Col val="2">
              <div className="form-control-static-sm text-primary-light">
                {ExecuteUtils.promoPricingPlan.title}
              </div>
            </Col>
            <Col val="10">
              {ExecuteUtils.promoPricingPlan.fields.map((field) =>
                <div className="form-group" key={field.name}>
                  <Field
                    name={`promoPricingPlan.${field.name}`}
                    placeholder={field.placeholder}
                    label={field.label}
                    component={FormText}
                  />
                </div>,
              )}
            </Col>
          </Row>
        </div>
        <div className="form-group">
          <Row>
            <Col val="3">
              <Field
                name={ExecuteUtils.harvestDeliveryDate.name}
                label={ExecuteUtils.harvestDeliveryDate.label}
                placeholder={ExecuteUtils.harvestDeliveryDate.placeholder}
                component={AnotherFormDatepicker}
                selectsEnd={true}
                selected={(form.values as any)[ExecuteUtils.harvestDeliveryDate.name]}
                isClearable={true}
              />
            </Col>
          </Row>
        </div>
        <div className="mb-4">
          <div className="form-control-static text-primary-light">
            {ExecuteUtils.capacityProductivityForecastOnTrack.title}
          </div>
          {ExecuteUtils.capacityProductivityForecastOnTrack.fields.map((field, index) =>
            <Row key={`capacityProductivityForecastOnTrack.${field.name}`}>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={`capacityProductivityForecastOnTrack.${field.name}`}
                    placeholder={field.placeholder}
                    label={field.label}
                    labelClass="text-regular text-primary-dark"
                    component={FormText}
                  />
                </div>
              </Col>
              <Col val={3}>
                <div className="form-group">
                  <Field
                    name={`capacityProductivityForecastOnTrack.${ExecuteUtils.capacityProductivityForecastOnTrackSelector.fields[index].name}`}
                    label={ExecuteUtils.capacityProductivityForecastOnTrackSelector.fields[index].label}
                    component={FormRadioCustomList}
                    options={ExecuteUtils.capacityProductivityForecastOnTrackSelector.fields[index].options}
                  />
                </div>
              </Col>
            </Row>,
          )}
        </div>
        <div className="form-group">
          <Field
            name={ExecuteUtils.areSalesOrderForecastOnTrack.name}
            label={ExecuteUtils.areSalesOrderForecastOnTrack.label}
            component={FormRadioCustomList}
            options={ExecuteUtils.areSalesOrderForecastOnTrack.options}
          />
        </div>
        <div className="form-group">
          <Field
            name="salesOrderForecastAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title="Sales Order Forecast on track:"
          />
        </div>
        {form.values.areSalesOrderForecastOnTrack === YesNo.No && (
          <div className="form-group">
            <Field
              name={ExecuteUtils.areSalesOrderForecastOnTrackAdjustment.name}
              placeholder={ExecuteUtils.areSalesOrderForecastOnTrackAdjustment.placeholder}
              label={ExecuteUtils.areSalesOrderForecastOnTrackAdjustment.label}
              labelClass="text-regular text-primary-dark"
              component={FormText}
            />
          </div>
        )}
        <div className="form-group">
          <Field
            name={ExecuteUtils.areFoodSafetyMetricsAcceptable.name}
            label={ExecuteUtils.areFoodSafetyMetricsAcceptable.label}
            component={FormRadioCustomList}
            options={ExecuteUtils.areFoodSafetyMetricsAcceptable.options}
          />
        </div>
        {form.values.areFoodSafetyMetricsAcceptable === YesNo.No && (
          <div className="form-group">
            <Field
              name={ExecuteUtils.areFoodSafetyMetricsAcceptableAdjustment.name}
              placeholder={ExecuteUtils.areFoodSafetyMetricsAcceptableAdjustment.placeholder}
              label={ExecuteUtils.areFoodSafetyMetricsAcceptableAdjustment.label}
              labelClass="text-regular text-primary-dark"
              component={FormText}
            />
          </div>
        )}
        <div className="form-group">
          <Field
            name="adjustmentsAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title="Monitor Food Safety Attachments:"
          />
        </div>
        <div className="form-group">
          <Field
            name="generalAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title="General Attachments:"
          />
        </div>
        <div className="form-group">
          <Field
            name={ExecuteUtils.anyOtherInformationYouWouldLikeToInclude.name}
            placeholder={ExecuteUtils.anyOtherInformationYouWouldLikeToInclude.placeholder}
            label={ExecuteUtils.anyOtherInformationYouWouldLikeToInclude.label}
            labelClass="text-regular text-primary-dark"
            component={FormText}
          />
        </div>
      </Fragment>
    );
  }
}

export default Execute;
