import {IInnovationEntity, InnovationStatusKey} from "../../interface/entity/IInnovationEntity";
import {ICollectionResource} from "../../interface/rest/ICollectionResource";
import {IInnovationCollectionFilters} from "../../services/innovation";

export interface IInnovationCollectionState {
  collectionResource: ICollectionResource<IInnovationEntity>;
  isFiltersModalOpen: boolean;
  filters: IInnovationCollectionFilters;
  sort: number;
  status: InnovationStatusKey;
}

export const INNOVATION_COLLECTION_SET_SORT = "INNOVATION_COLLECTION_SET_SORT";
export const INNOVATION_COLLECTION_SET_STATUS = "INNOVATION_COLLECTION_SET_STATUS";
export const INNOVATION_COLLECTION_SET_FILTERS = "INNOVATION_COLLECTION_SET_FILTERS";
export const INNOVATION_COLLECTION_OPEN_FILTER_MODAL = "INNOVATION_COLLECTION_OPEN_FILTER_MODAL";
export const INNOVATION_COLLECTION_CLOSE_FILTER_MODAL = "INNOVATION_COLLECTION_CLOSE_FILTER_MODAL";
export const INNOVATION_COLLECTION_FETCH_START = "INNOVATION_COLLECTION_FETCH_START";
export const INNOVATION_COLLECTION_FETCH_SUCCESS = "INNOVATION_COLLECTION_FETCH_SUCCESS";
export const INNOVATION_COLLECTION_FETCH_ERROR = "INNOVATION_COLLECTION_FETCH_ERROR";
export const INNOVATION_COLLECTION_INIT = "INNOVATION_COLLECTION_INIT";

interface IInnovationCollectionSetFiltersAction {
  type: typeof INNOVATION_COLLECTION_SET_FILTERS;
  filters: IInnovationCollectionFilters;
}

interface IInnovationCollectionSetSortAction {
  type: typeof INNOVATION_COLLECTION_SET_SORT;
  sort: number;
}

interface IInnovationCollectionSetStatusAction {
  type: typeof INNOVATION_COLLECTION_SET_STATUS;
  status: InnovationStatusKey;
}

interface IInnovationCollectionOpenFilterModalAction {
  type: typeof INNOVATION_COLLECTION_OPEN_FILTER_MODAL;
}

interface IInnovationCollectionCloseFilterModalAction {
  type: typeof INNOVATION_COLLECTION_CLOSE_FILTER_MODAL;
}

interface IInnovationCollectionFetchStartAction {
  type: typeof INNOVATION_COLLECTION_FETCH_START;
}

interface IInnovationCollectionFetchSuccessAction {
  type: typeof INNOVATION_COLLECTION_FETCH_SUCCESS;
  collectionResource: ICollectionResource<IInnovationEntity>;
}

interface IInnovationCollectionFetchErrorAction {
  type: typeof INNOVATION_COLLECTION_FETCH_ERROR;
  error: unknown;
}

interface IInnovationCollectionInitAction {
  type: typeof INNOVATION_COLLECTION_INIT;
}

export type IInnovationCollectionActionTypes =
  IInnovationCollectionFetchSuccessAction
  | IInnovationCollectionOpenFilterModalAction
  | IInnovationCollectionCloseFilterModalAction
  | IInnovationCollectionInitAction
  | IInnovationCollectionSetFiltersAction
  | IInnovationCollectionSetSortAction
  | IInnovationCollectionSetStatusAction
  | IInnovationCollectionFetchErrorAction
  | IInnovationCollectionFetchStartAction;
