import React, {ReactNode} from "react";

interface IGrid {
  children: ReactNode;
  className?: string;
}

interface IContainer extends IGrid {
  fluid?: boolean;
}

export const Container = ({children, fluid, className}: IContainer) => (
  <div className={`${fluid ? "container-fluid" : "container"} ${className ? className : ""}`}>
    {children}
  </div>
);

interface IRow extends IGrid {
  position?: string;
}

export const Row = ({children, className}: IRow) => (
  <div className={`row ${className ? className : ""}`}>
    {children}
  </div>
);

interface ICol extends IGrid {
  val: string | number;
  offset?: string;
}

export const Col = ({ val, offset, children, className}: ICol) => (
  <div className={`col-${val} ${offset ? `col-offset-${offset}` : ""} ${className ? className : ""}`}>
    {children}
  </div>
);
