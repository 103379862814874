import {FormikProps} from "formik";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import {RouteChildrenProps} from "react-router";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import store from "store";
import SubmitInnovationForm from "../components/form/SubmitInnovationForm";
import BaseLayout from "../components/Layout/BaseLayout";
import {INNOVATION_LIST} from "../components/routes";
import {LOCAL_STORAGE_INNOVATION_STATS} from "../constant/localStorage";
import {IInnovationEntity, InnovationStatusKey} from "../interface/entity/IInnovationEntity";
import {getStats} from "../services/innovation";
import {setInnovationStats} from "../store/system/action";
import SubmitFormWrapper, {IFormValues} from "./SubmitFormWrapper";

interface IProps extends RouteChildrenProps {
  onSetInnovationStats: (
    innovationStats: {
      status: InnovationStatusKey;
      statusValue: string;
      statusLabel: string;
      amount: number;
    }[]
  ) => void;
}

class InnovationAdd extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <BaseLayout location={this.props.location}>
        <div className="mb-6">
          <Link to={INNOVATION_LIST}>{"< Back to Innovations List"}</Link>
        </div>
        <SubmitFormWrapper onSuccess={this.onSuccess}>
          {(props: FormikProps<IFormValues>) =>
            <Fragment>
              <div className="details-header-line mb-4">
                <div className="pull-left">
                  <h1 className="h2 m-0 pt-2">Add Innovation</h1>
                </div>
                <div className="pull-right">
                  <button className="btn btn-primary btn-shade btn-width" disabled={!props.isValid} type="submit">
                    Save Innovation
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <SubmitInnovationForm/>
                </div>
              </div>
            </Fragment>
          }
        </SubmitFormWrapper>
      </BaseLayout>
    );
  }

  private onSuccess = async (innovation: IInnovationEntity) => {
    const {data: innovationStats} = await getStats();
    this.props.onSetInnovationStats(innovationStats);
    store.set(LOCAL_STORAGE_INNOVATION_STATS, innovationStats);

    const {history} = this.props;
    history.push("/innovation/" + innovation.id + "/concept");
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetInnovationStats: (
    innovationStats: {
      status: InnovationStatusKey;
      statusValue: string;
      statusLabel: string;
      amount: number;
    }[]
  ) => {
    dispatch(setInnovationStats(innovationStats));
  },
});

export default connect(null, mapDispatchToProps)(InnovationAdd);
