import {INotificationActionTypes, NOTIFICATION_ADD_NOTIFICATION, NOTIFICATION_REMOVE_NOTIFICATION, NotificationTypes} from "./type";

export const addNotification = (id: number, message: string, type: NotificationTypes): INotificationActionTypes => ({
  notification: {
    id,
    message,
    type,
  },
  type: NOTIFICATION_ADD_NOTIFICATION,
});

export const removeNotification = (id: number): INotificationActionTypes => ({
  id,
  type: NOTIFICATION_REMOVE_NOTIFICATION,
});
