import React from "react";
import { Redirect, RouteProps } from "react-router";
import { Route } from "react-router-dom";
import {
  APP_HOME_URLS,
  APP_LOGIN_URL,
  RouteAccessLevels,
} from "../constant/app";
import { InnovationStageKey } from "../interface/entity/IInnovationEntity";
import {
  IUserEntity,
  UserRole,
  UserValidationPermission,
} from "../interface/entity/IUserEntity";
import DepartmentAdd from "../route/admin/DepartmentAdd";
import DepartmentList from "../route/admin/DepartmentList";
import DepartmentUpdate from "../route/admin/DepartmentUpdate";
import UserAdd from "../route/admin/UserAdd";
import UserList from "../route/admin/UserList";
import UserUpdate from "../route/admin/UserUpdate";
import CommonLogin from "../route/CommonLogin";
import ForgotPassword from "../route/ForgotPassword";
import InnovationAdd from "../route/InnovationAdd";
import InnovationItem from "../route/InnovationItem";
import { ViewMode } from "../route/InnovationItem/InnovationItemStage/components/InnovationItemOversightComponent";
import InnovationCollection from "../route/lists/InnovationCollection";
import SubmissionCollection from "../route/lists/SubmissionCollection";
import RecoveryPassword from "../route/RecoveryPassword";
import SimpleLogin from "../route/SimpleLogin";
import Submit from "../route/Submit";

export const ensureHomeUrl = (user: IUserEntity) => {
  if (user.isValidator) {
    return SUBMISSION_LIST;
  }

  return APP_HOME_URLS[user.role];
};

export const PrivateRoute = ({
  isGuest,
  user,
  ...props
}: { isGuest: boolean; user?: IUserEntity } & IRoute) =>
  isGuest || !user
    ? React.createElement(Redirect, { to: APP_LOGIN_URL })
    : props.allowedRoles.includes(user.role) &&
      (props.customCheckAccess ? props.customCheckAccess(user) : true)
    ? React.createElement(Route, props)
    : React.createElement(Redirect, { to: ensureHomeUrl(user) });

export const GuestRoute = ({
  isGuest,
  user,
  ...props
}: { isGuest: boolean; user?: IUserEntity } & IRoute) =>
  isGuest || !user
    ? React.createElement(Route, props)
    : React.createElement(Redirect, { to: ensureHomeUrl(user) });

const innovationStages = Object.values(InnovationStageKey)
  .filter((item: InnovationStageKey) => isNaN(item))
  .map((item: string) => item.toLowerCase())
  .join("|");
const oversightViewModes = Object.values(ViewMode)
  .filter((item: ViewMode) => isNaN(item))
  .map((item: string) => item.toLowerCase())
  .join("|");

export const COMMON_LOGIN = "/login";
export const SIMPLE_LOGIN = "/";
export const FORGOT_PASSWORD = "/forgot-password";
export const RECOVERY_PASSWORD_PREFIX = "/set-password";
export const RECOVERY_PASSWORD =
  RECOVERY_PASSWORD_PREFIX + "/:token([0-9a-z]{32})";

export const SUBMIT_INNOVATION = "/submit";
export const ADD_INNOVATION = "/innovation/add";

export const INNOVATION_LIST = "/innovation";
export const SUBMISSION_LIST = "/submission";

export const INNOVATION_BASE_ROUTE = "/innovation/:id";
export const INNOVATION_STAGE_ROUTE =
  INNOVATION_BASE_ROUTE + "/:stage(" + innovationStages + ")";
export const INNOVATION_OVERSIGHT_ROUTE =
  INNOVATION_STAGE_ROUTE + "/:oversightViewMode(" + oversightViewModes + ")";
export const INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE =
  INNOVATION_OVERSIGHT_ROUTE + "/:departmentId(\\d+)";
export const INNOVATION_MILESTONE_ROUTE = INNOVATION_STAGE_ROUTE + "/milestone";

export const ADMIN_USER_LIST = "/admin/user";
export const ADMIN_USER_ADD = "/admin/user/add";
export const ADMIN_USER_UPDATE = "/admin/user/:id";
export const ADMIN_DEPARTMENT_LIST = "/admin/department";
export const ADMIN_DEPARTMENT_ADD = "/admin/department/add";
export const ADMIN_DEPARTMENT_UPDATE = "/admin/department/:id";

export interface IRoute extends RouteProps {
  accessLevel: RouteAccessLevels;
  allowedRoles: UserRole[];
  customCheckAccess?: (user: IUserEntity) => boolean;
}

const routes: IRoute[] = [
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin, UserRole.Manager],
    component: InnovationCollection,
    exact: true,
    path: INNOVATION_LIST,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin, UserRole.Manager],
    component: SubmissionCollection,
    exact: true,
    path: SUBMISSION_LIST,
    customCheckAccess: (user) => {
      return user && user.isValidator === UserValidationPermission.Allow;
    },
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin, UserRole.Manager],
    component: InnovationAdd,
    exact: true,
    path: ADD_INNOVATION,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin, UserRole.Manager],
    component: InnovationItem,
    exact: true,
    path: [
      INNOVATION_MILESTONE_ROUTE,
      INNOVATION_BASE_ROUTE,
      INNOVATION_STAGE_ROUTE,
      INNOVATION_OVERSIGHT_ROUTE,
      INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE,
    ],
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Employee],
    component: Submit,
    exact: true,
    path: SUBMIT_INNOVATION,
  },
  {
    accessLevel: RouteAccessLevels.Guest,
    allowedRoles: [],
    component: SimpleLogin,
    exact: true,
    path: SIMPLE_LOGIN,
  },
  {
    accessLevel: RouteAccessLevels.Guest,
    allowedRoles: [],
    component: CommonLogin,
    exact: true,
    path: COMMON_LOGIN,
  },
  {
    accessLevel: RouteAccessLevels.Guest,
    allowedRoles: [],
    component: ForgotPassword,
    exact: true,
    path: FORGOT_PASSWORD,
  },
  {
    accessLevel: RouteAccessLevels.Guest,
    allowedRoles: [],
    component: RecoveryPassword,
    exact: true,
    path: RECOVERY_PASSWORD,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin],
    component: UserList,
    exact: true,
    path: ADMIN_USER_LIST,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin],
    component: UserAdd,
    exact: true,
    path: ADMIN_USER_ADD,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin],
    component: UserUpdate,
    exact: true,
    path: ADMIN_USER_UPDATE,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin],
    component: DepartmentList,
    exact: true,
    path: ADMIN_DEPARTMENT_LIST,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin],
    component: DepartmentAdd,
    exact: true,
    path: ADMIN_DEPARTMENT_ADD,
  },
  {
    accessLevel: RouteAccessLevels.Private,
    allowedRoles: [UserRole.Admin],
    component: DepartmentUpdate,
    exact: true,
    path: ADMIN_DEPARTMENT_UPDATE,
  },
];

export default routes;
