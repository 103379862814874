import {IItemResource} from "../rest/IItemResource";
import {IAttachmentEntity} from "./IAttachmentEntity";
import {IInformationGateEntity} from "./IInformationGateEntity";
import {IInnovationDepartmentEntity} from "./IInnovationDepartmentEntity";

export enum InnovationStageKey {
  Concept = 0,
  Refine = 1,
  Estimate = 2,
  Action = 3,
  Test = 4,
  Execute = 5,
}

export const innovationStageKeyToValue: { [key in InnovationStageKey]: string } = {
  [InnovationStageKey.Concept]: "concept",
  [InnovationStageKey.Refine]: "refine",
  [InnovationStageKey.Estimate]: "estimate",
  [InnovationStageKey.Action]: "action",
  [InnovationStageKey.Test]: "test",
  [InnovationStageKey.Execute]: "execute",
};

export const innovationStageKeyToLabel: { [key in InnovationStageKey]: string } = {
  [InnovationStageKey.Concept]: "Concept",
  [InnovationStageKey.Refine]: "Refine",
  [InnovationStageKey.Estimate]: "Estimate",
  [InnovationStageKey.Action]: "Action",
  [InnovationStageKey.Test]: "Test",
  [InnovationStageKey.Execute]: "Execute",
};

export const innovationStageValueToKey: { [key: string]: InnovationStageKey } = {
  concept: InnovationStageKey.Concept,
  refine: InnovationStageKey.Refine,
  estimate: InnovationStageKey.Estimate,
  action: InnovationStageKey.Action,
  test: InnovationStageKey.Test,
  execute: InnovationStageKey.Execute,
};

export enum InnovationStatusKey {
  Active = 0,
  Complete = 1,
  Canceled = 2,
  Submitted = 3,
  Rejected = 4,
}

export const innovationStatusKeyToValue: { [key in InnovationStatusKey]: string } = {
  [InnovationStatusKey.Active]: "active",
  [InnovationStatusKey.Complete]: "complete",
  [InnovationStatusKey.Canceled]: "canceled",
  [InnovationStatusKey.Submitted]: "submitted",
  [InnovationStatusKey.Rejected]: "rejected",
};

export const innovationStatusKeyToLabel: { [key in InnovationStatusKey]: string } = {
  [InnovationStatusKey.Active]: "Active",
  [InnovationStatusKey.Complete]: "Complete",
  [InnovationStatusKey.Canceled]: "Archived",
  [InnovationStatusKey.Submitted]: "Submitted",
  [InnovationStatusKey.Rejected]: "Rejected",
};

export interface IInnovationEntity extends IItemResource {
  submitterFirstName: string;
  submitterLastName: string;
  submitterEmail: string;
  submitterJobTitle: string;
  submitterLocation: string;
  name: string;
  description: string;
  stage: InnovationStageKey;
  status: InnovationStatusKey;
  innovationDepartments: IInnovationDepartmentEntity[];
  deadlineAt?: string;
  completeAt?: string;
  informationGates: IInformationGateEntity[];

  readonly submitterFullName: string;

  readonly reviewCountWaiting: number;
  readonly reviewCountGo: number;
  readonly reviewCountNo: number;
  readonly reviewCountReturn: number;
}

export enum YesNo {
  No = "no",
  Yes = "yes",
}

export enum YesNoDescribe {
  No = "no",
  Yes = "yes",
  Describe = "describe",
}

export enum YesNoApproved {
  No = "no",
  Yes = "yes",
  Approved = "approved",
}

export enum IsThisAnItemThatDfffAlreadyGrowsContractGrows {
  No = "no",
  Yes = "yes",
  ContractGrows = "contractGrows",
  CoPack = "coPack",
}

export enum IsThisSolutionIntendedToBeOrganic {
  No = "no",
  Yes = "yes",
  FutureBusinessOpportunity = "futureBusinessOpportunity",
}

export enum IsThereATargetedBusinessSegmentToLaunch {
  No = "no",
  Retail = "retail",
  FoodService = "foodService",
  Industrial = "industrial",
}

export enum IfRequestedByASpecificCustomerPleaseIdentify {
  Banner = "banner",
  Market = "market",
  National = "national",
  NA = "na",
  Other = "other",
}

export interface IConceptInformationGate {
  isThisAnItemThatDfffAlreadyGrowsContractGrows: IsThisAnItemThatDfffAlreadyGrowsContractGrows;
  productType: {
    celery: boolean;
    corn: boolean;
    radish: boolean;
    leafyGreen: boolean;
    orange: boolean;
    lemon: boolean;
    lime: boolean;
    other: boolean;
  };
  productTypeOther: string;
  pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation: string;
  whatIsThePreliminaryProposedPricingPerUnit: string;
  innerPackagingType: {
    polybag: boolean;
    carton: boolean;
    clamshell: boolean;
    pouchbag: boolean;
    other: boolean;
  };
  innerPackagingTypeOther: string;
  outerPackagingType: {
    cup: boolean;
    tube: boolean;
    shrinkWrap: boolean;
    microwave: boolean;
    sustainable: boolean;
    other: boolean;
  };
  outerPackagingTypeOther: string;
  convenienceValueAdded: {
    freshCut: boolean;
    prepStep: boolean;
    combination: boolean;
    other: boolean;
  };
  convenienceValueAddedOther: string;
  cpgShelfStable: {
    condiment: boolean;
    sideDish: boolean;
    mealReplacement: boolean;
    juice: boolean;
    soup: boolean;
    snack: boolean;
    other: boolean;
  };
  cpgShelfStableOther: string;
  foodService: {
    mealPrep: boolean;
    serving: boolean;
    ingredient: boolean;
    combination: boolean;
    other: boolean;
  };
  foodServiceOther: string;
  isThisSolutionIntendedToBeOrganic: IsThisSolutionIntendedToBeOrganic;
  anyOtherAdditionalContactsToList: string;
  isThereATargetBusinessSegmentToLaunch: string;
  pleaseDescribeInDetailTheTargetConsumerForThisInnovation: string;
  productSpecs: YesNo;
  packSize: YesNo;
  packSizeValue: string;
  cartonsPerPallet: YesNo;
  cartonsPerPalletValue: string;
  tieHigh: YesNo;
  tieHighValue: string;
  grossWeight: YesNo;
  grossWeightValue: string;
  cartonSize: YesNo;
  cartonSizeValue: string;
  cartonCube: YesNo;
  cartonCubeValue: string;
  upc: YesNo;
  upcValue: string;
  gtin: YesNo;
  gtinValue: string;
  isThereATargetedBusinessSegmentToLaunch: IsThereATargetedBusinessSegmentToLaunch;
  ifRequestedByASpecificCustomerPleaseIdentify: IfRequestedByASpecificCustomerPleaseIdentify;
  targetBusinessSegmentToLaunchDetails: string;
  pleaseIdentifyCustomerName: string;
  managerName: string;
  officeOrStore: string;
  willingToTest: YesNo;
  volumeEstimate: string;
  canWeFollowUpWithThisBuyer: YesNo;
  buyersPhone: string;
  buyersEmail: string;
  doYouHaveAMarketingContact: YesNo;
  marketingContractDetails: string;
  doYouHaveAnRDContact: YesNo;
  rndContactDetails: string;
  doYouHaveAnInnovationContact: YesNo;
  innovationContactDetails: string;
  doYouHaveAFoodSafetyContact: YesNo;
  foodSafetyContactDetails: string;
  isThereAnExpectedLaunchDate: string;
  anyOtherInformationYouWouldLikeToInclude: string;
  proposedInnovationAttachments: IAttachmentEntity[];
  consumerResearchAttachments: IAttachmentEntity[];
  generalAttachments: IAttachmentEntity[];
}

export interface IRefineInformationGate {
  isConsumerResearchAvailable: YesNo;
  transactionData: {
    duda: boolean;
    retailer: boolean;
    syndicated: boolean;
    other: boolean;
  };
  transactionDataOther: string;
  qualitative: {
    inMarket: boolean;
    shopper: boolean;
    focusGroup: boolean;
    other: boolean;
  };
  qualitativeOther: string;
  quantitative: {
    concept: boolean;
    clt: boolean;
    hut: boolean;
    other: boolean;
  };
  quantitativeOther: string;
  isFieldResearchTestingProtocolPlanned: {
    structure: boolean;
    ergonomic: boolean;
    usability: boolean;
    display: boolean;
    pricing: boolean;
    shopper: boolean;
    other: boolean;
  };
  isFieldResearchTestingProtocolPlannedOther: string;
  isThisAnExistingPackageStructure: YesNo;
  whereIsThisPackageAvailable: string;
  doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType: YesNo;
  numberOfStoreLocations: string;
  unitPerStorePerDay: string;
  numberOfDaysInMarket: string;
  totalUnits: string;
  yieldPerAcre: string;
  isThisACropDudaFarmFreshFoodsCurrentlyGrows: YesNo;
  pleaseEstimateTheContractGrownPricePerUnit: string;
  doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation: YesNo;
  whatPartnerIsBeingConsideredToProcess: string;
  coPackCosts: string;
  isAnySpecialFormulationNecessaryForTheFinalProduct: YesNoDescribe;
  isAnySpecialFormulationNecessaryForTheFinalProductDescription: string;
  rndApprove: YesNoApproved;
  legalApprove: YesNoApproved;
  foodSafetyApprove: YesNoApproved;
  operationsApprove: YesNoApproved;
  financeApprove: YesNo;
  salesAndOpsApprove: YesNo;
  willThisInnovationIncludeAnAdditionalManufacturedItem: YesNo;
  additionalItemDescription: string;
  additionalItemCost: string;
  additionalItemPartner: string;
  additionalItemAudit: string;
  budgetProductSamples: YesNo;
  budgetProductSamplesValue: string;
  budgetArtwork: YesNo;
  budgetArtworkValues: string;
  budgetMerchandising: YesNo;
  budgetMerchandisingValue: string;
  budgetPhoto: YesNo;
  budgetPhotoValue: string;
  budgetNutrition: YesNo;
  budgetNutritionValue: string;
  budgetTrademark: YesNo;
  budgetTrademarkValue: string;
  budgetSales: YesNo;
  budgetSalesValue: string;
  budgetMinOrder: string;
  budgetPackaging: string;
  isThereATargetRetailPrice: string;
  whatIsTheTargetedDudaWholesalePrice: string;
  profitAbsolute: string;
  safetyField: string;
  profitPercent: string;
  safetyPlant: string;
  safetyMarket: string;
  preliminaryCogsIfKnown: string;
  pleaseConfirmEstimatedNumberOfUnitsNeeded: string;
  calculatePreliminaryTotalRevenue: string;
  anyOtherInformationYouWouldLikeToInclude: string;
  consumerResearchAttachments: IAttachmentEntity[];
  isThisAnExistingPackageStructureAttachments: IAttachmentEntity[];
  generalAttachments: IAttachmentEntity[];
}

interface IEstimateNeedUnits {
  stores: string;
  usd: string;
  days: string;
}

interface IEstimateNeedCost {
  seed: string;
  materials: string;
  harvest: string;
  dfffManufacture: string;
  coManufacture: string;
  packaging: string;
  acres: string;
  total: string;
  units: string;
  costUnit: string;
}

interface IEstimateMargin {
  duda: string;
  distributor: string;
  retailer: string;
  freight: string;
}

export interface IEstimateInformationGate {
  needUnits: {
    pilot: IEstimateNeedUnits,
    launch: IEstimateNeedUnits,
    fullMarket: IEstimateNeedUnits,
  };
  cost: {
    pilot: IEstimateNeedCost,
    launch: IEstimateNeedCost,
    fullMarket: IEstimateNeedCost,
  };
  margin: {
    pilot: IEstimateMargin,
    launch: IEstimateMargin,
    fullMarket: IEstimateMargin,
    targetRetailPrice: string,
    target: string;
  };
  additionalEquipment: {
    answer: YesNo,
    description: string,
  };
  capex: {
    amortize: {
      pilot: string,
      launch: string,
      fullMarket: string,
    },
  };
  anyOtherInformationYouWouldLikeToInclude: string;
  generalAttachments: IAttachmentEntity[];
}

export interface IActionInformationGate {
  size: string;
  weight: string;
  formula: string;
  yield: string;
  costUntil: string;
  salesTargetAggregated: YesNo;
  salesObjectivesByCustomerAccountOwner: YesNo;
  yieldAcre: string;
  plantProductivityMetrics: string;
  foodPlantSafetyMetrics: string;
  legalReview: string;
  ifContractManufacturedAreCogsConfirmed: YesNo;
  ifContractManufacturedAreCogsConfirmedAdjustment: string;
  doesPilotScaleToFullLandPlan: YesNo;
  doesPilotScaleToFullLandPlanAdjustment: string;
  doFinalPilotCogsMeetOrExceedHurdles: YesNo;
  doFinalPilotCogsMeetOrExceedHurdlesAdjustment: string;
  doSalesCommitmentsMatchProductForecast: YesNo;
  doSalesCommitmentsMatchProductForecastAdjustment: string;
  finalVolumeForecast: string;
  finalPriceUnit: string;
  finalCostPerUnit: string;
  finalMarginUnit: string;
  totalRevenueForecast: string;
  anyOtherInformationYouWouldLikeToInclude: string;
  uploadSalesForecastAttachments: IAttachmentEntity[];
  generalAttachments: IAttachmentEntity[];
}

export interface ITestInformationGate {
  testMetricsConsumerQualitative: {
    clt: boolean;
    hut: boolean;
    ups: boolean;
    cogs: boolean;
  };
  testMetricsConsumerQuantitative: {
    purchaseIntent: boolean;
    priceSensitivity: boolean;
    merchPromo: boolean;
    display: boolean;
  };
  testMetricsConsumerAdjustments: string;
  ups: string;
  weekStore: string;
  buyerAlignment: YesNo;
  units: string;
  price: string;
  timeframe: string;
  testMetricsCustomerAdjustmentsNeeded: string;
  harvest: string;
  uMinute: string;
  uShift: string;
  buyerUDay: string;
  capacityAndYieldAdjustmentsNeeded: string;
  qcSpecs: string;
  foodSafetySpecs: string;
  customerRejectionSpecs: string;
  isCogsUnitAcceptable: YesNo;
  isCogsUnitAcceptableValue: string;
  isOfUnitsAcceptable: YesNo;
  isOfUnitsAcceptableValue: string;
  isFobAcceptable: YesNo;
  isFobAcceptableValue: string;
  isMarketPriceAcceptable: YesNo;
  isMarketPriceAcceptableValue: string;
  isMarginDudaAcceptable: YesNo;
  isMarginDudaAcceptableValue: string;
  isMarginCustomerAcceptable: YesNo;
  isMarginCustomerAcceptableValue: string;
  isVolumeForecastAcceptable: YesNo;
  isVolumeForecastAcceptableValue: string;
  testMetricsFoodSafetyAdjustmentsNeeded: string;
  anyOtherInformationYouWouldLikeToInclude: string;
  generalAttachments: IAttachmentEntity[];
}

export interface IExecuteInformationGate {
  salesPlan: string;
  marketingPlan: {
    boardMarketLaunch: string,
    incentiveTradeAction: string,
  };
  promoPricingPlan: {
    pricingAction: string,
    incentiveTradeAction: string,
  };
  harvestDeliveryDate: string;
  capacityProductivityForecastOnTrack: {
    field: string,
    fieldSelector: YesNo,
    plant: string,
    plantSelector: YesNo,
    outbound: string,
    outboundSelector: YesNo,
  };
  areSalesOrderForecastOnTrack: YesNo;
  areSalesOrderForecastOnTrackAdjustments: string;
  areFoodSafetyMetricsAcceptable: YesNo;
  areFoodSafetyMetricsAcceptableAdjustments: string;
  anyOtherInformationYouWouldLikeToInclude: string;
  salesPlanAttachments: IAttachmentEntity[];
  salesOrderForecastAttachments: IAttachmentEntity[];
  adjustmentsAttachments: IAttachmentEntity[];
  generalAttachments: IAttachmentEntity[];
}

export type IInformationGate = IConceptInformationGate
  | IRefineInformationGate
  | IEstimateInformationGate
  | IActionInformationGate
  | ITestInformationGate
  | IExecuteInformationGate;
