import React, {ReactNode} from "react";

export enum LoaderSize {
  Small = "small",
  Medium = "medium",
}

interface IProps {
  isLoading: boolean;
  children: ReactNode;
  message?: string;
  size?: LoaderSize;
}

class WrapperLoader extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {children, isLoading, message} = this.props;
    return (
      <div className={isLoading ? `loading ${this.props.size || LoaderSize.Medium}` : undefined}>
        {isLoading && <div className="loading-text">
          {message || "Loading..."}
        </div>}
        {!isLoading && children}
      </div>
    );
  }
}

export default WrapperLoader;
