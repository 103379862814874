import React from "react";
import {connect} from "react-redux";
import {RouteChildrenProps} from "react-router";
import {Dispatch} from "redux";
import BaseLayout from "../../components/Layout/BaseLayout";
import WrapperLoader from "../../components/loader/WrapperLoader";
import Pagination from "../../components/Pagination";
import {ADMIN_DEPARTMENT_ADD} from "../../components/routes";
import axiosInstance from "../../instance/axios";
import {IDepartmentEntity} from "../../interface/entity/IDepartmentEntity";
import {ICollectionResource} from "../../interface/rest/ICollectionResource";
import {AppState} from "../../store";
import {
  adminDepartmentCollectionFetchError,
  adminDepartmentCollectionFetchStart,
  adminDepartmentCollectionFetchSuccess,
  adminDepartmentCollectionInit,
} from "../../store/admin/department/list/action";
import {IAdminDepartmentState} from "../../store/admin/department/list/type";
import DepartmentTr from "./DepartmentList/DepartmentTr";
import ToggleSwitch from "./ToggleSwitch";

interface IProps extends RouteChildrenProps {
  onAdminDepartmentCollectionInit: () => void;
  onAdminDepartmentCollectionFetchStart: () => void;
  onAdminDepartmentCollectionFetchSuccess: (collectionResource: ICollectionResource<IDepartmentEntity>) => void;
  onAdminDepartmentCollectionFetchError: (error: unknown) => void;

  adminDepartmentState: IAdminDepartmentState;
}

class DepartmentList extends React.Component<IProps> {
  public async componentDidMount() {
    this.props.onAdminDepartmentCollectionInit();

    await this.fetchDepartments();
  }

  public componentWillUnmount(): void {
    this.props.onAdminDepartmentCollectionInit();
  }

  public async componentDidUpdate(prevProps: IProps) {
    const prevLocation = new URLSearchParams(prevProps.location.search);
    const currentLocation = new URLSearchParams(this.props.location.search);

    if (prevLocation.get("page") !== currentLocation.get("page")) {
      await this.fetchDepartments();
    }
  }

  public render(): React.ReactNode {
    const {adminDepartmentState: {collectionResource}, history, location} = this.props;

    return (
      <BaseLayout location={this.props.location}>
        <ToggleSwitch location={this.props.location} link={{to: ADMIN_DEPARTMENT_ADD, title: "Add Department"}}/>
        <div>
          <WrapperLoader isLoading={collectionResource.isLoading} message={"We're loading departments. Please wait..."}>
            <table className="table table-default table-vertical-middle">
              <thead>
              <tr>
                <th>Department Name</th>
                <th>Description</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {collectionResource.items.map((department: IDepartmentEntity) => {
                return <DepartmentTr
                  department={department}
                  key={department.id}
                  onSuccess={async () => {
                    const {adminDepartmentState: {collectionResource: {pagination, items}}} = this.props;
                    let page = pagination ? pagination.page : 1;
                    if (items.length === 1) {
                      page--;

                      const currentPropsParams = new URLSearchParams(location.search);
                      if (page === 1) {
                        currentPropsParams.delete("page");
                      } else {
                        currentPropsParams.set("page", String(page));
                      }
                      history.push([location.pathname, ...[currentPropsParams ? "?" : "", currentPropsParams.toString()]].join(""));
                    }
                    await this.fetchDepartments(page);
                  }}
                />;
              })}
              </tbody>
            </table>
            {collectionResource.pagination
            && collectionResource.pagination.totalPages > 1
            && <Pagination
              {...collectionResource.pagination}
              onChange={(page: number) => {
                const currentPropsParams = new URLSearchParams(location.search);
                if (page === 1) {
                  currentPropsParams.delete("page");
                } else {
                  currentPropsParams.set("page", String(page));
                }
                history.push([location.pathname, ...[currentPropsParams ? "?" : "", currentPropsParams.toString()]].join(""));
              }}
            />}
          </WrapperLoader>
        </div>
      </BaseLayout>
    );
  }

  private fetchDepartments = async (page?: number) => {
    this.props.onAdminDepartmentCollectionFetchStart();

    const currentPropsParams = new URLSearchParams(this.props.location.search);

    await axiosInstance().get("/department", {
      params:  {
        page:  page || currentPropsParams.get("page") || 1,
      },
    })
      .then((response: {data: ICollectionResource<IDepartmentEntity> }) => {
        this.props.onAdminDepartmentCollectionFetchSuccess(response.data);
      })
      .catch((error) => {
        this.props.onAdminDepartmentCollectionFetchError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  adminDepartmentState:  state.admin.department.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAdminDepartmentCollectionFetchError:  (error: unknown) => {
    dispatch(adminDepartmentCollectionFetchError(error));
  },
  onAdminDepartmentCollectionFetchStart: () => {
    dispatch(adminDepartmentCollectionFetchStart());
  },
  onAdminDepartmentCollectionFetchSuccess: (collectionResource: ICollectionResource<IDepartmentEntity>) => {
    dispatch(adminDepartmentCollectionFetchSuccess(collectionResource));
  },
  onAdminDepartmentCollectionInit: () => {
    dispatch(adminDepartmentCollectionInit());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DepartmentList);
