import {IInnovationDepartmentEntity} from "../../../../../interface/entity/IInnovationDepartmentEntity";

export interface IInnovationItemStageOversightResolutionsState {
  innovationDepartmentEntities: IInnovationDepartmentEntity[];
  isCollectionLoading: boolean;
  isItemLoading: boolean;
}

export const INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_START = "INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_START";
export const INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_SUCCESS = "INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_SUCCESS";
export const INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_ERROR = "INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_ERROR";
export const INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_START = "INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_START";
export const INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_SUCCESS = "INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_SUCCESS";
export const INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_ERROR = "INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_ERROR";

interface IInnovationItemStageOversightResolutionsDepartmentFetchStartAction {
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_START;
}

interface IInnovationItemStageOversightResolutionsDepartmentFetchSuccessAction {
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_SUCCESS;
  innovationDepartmentEntities: IInnovationDepartmentEntity[];
}

interface IInnovationItemStageOversightResolutionsDepartmentFetchErrorAction {
  error: unknown;
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_ERROR;
}

interface IInnovationItemStageOversightResolutionsDepartmentUpdateStartAction {
  innovationDepartment: IInnovationDepartmentEntity;
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_START;
}

interface IInnovationItemStageOversightResolutionsDepartmentUpdateErrorAction {
  error: unknown;
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_ERROR;
}

interface IInnovationItemStageOversightResolutionsDepartmentUpdateSuccessAction {
  innovationDepartment: IInnovationDepartmentEntity;
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_SUCCESS;
}

export type IInnovationItemStageOversightResolutionsTypes =
  | IInnovationItemStageOversightResolutionsDepartmentFetchStartAction
  | IInnovationItemStageOversightResolutionsDepartmentFetchSuccessAction
  | IInnovationItemStageOversightResolutionsDepartmentUpdateStartAction
  | IInnovationItemStageOversightResolutionsDepartmentUpdateSuccessAction
  | IInnovationItemStageOversightResolutionsDepartmentUpdateErrorAction
  | IInnovationItemStageOversightResolutionsDepartmentFetchErrorAction
  ;
