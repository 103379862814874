import {IItemResource} from "../rest/IItemResource";
import {IDepartmentEntity} from "./IDepartmentEntity";
import {IInnovationDepartmentComment} from "./IInnovationDepartmentComment";

export enum InnovationDepartmentStatusKey {
  Approval = 0,
  Go = 1,
  No = 2,
  Returned = 3,
}

export const innovationDepartmentStatusKeyToValue: { [key in InnovationDepartmentStatusKey]: string } = {
  [InnovationDepartmentStatusKey.Approval]: "approval",
  [InnovationDepartmentStatusKey.Go]: "go",
  [InnovationDepartmentStatusKey.No]: "no",
  [InnovationDepartmentStatusKey.Returned]: "returned",
};

export const innovationDepartmentStatusKeyToMetaValue: { [key in InnovationDepartmentStatusKey]: string } = {
  [InnovationDepartmentStatusKey.Approval]: "Approval Needed",
  [InnovationDepartmentStatusKey.Go]: "Go",
  [InnovationDepartmentStatusKey.No]: "No",
  [InnovationDepartmentStatusKey.Returned]: "Returned for Additional Details",
};

export const innovationDepartmentStatusValueToKey: { [key: string]: InnovationDepartmentStatusKey } = {
  approval: InnovationDepartmentStatusKey.Approval,
  go: InnovationDepartmentStatusKey.Go,
  no: InnovationDepartmentStatusKey.No,
  returned: InnovationDepartmentStatusKey.Returned,
};

export interface IInnovationDepartmentEntity extends IItemResource {
  stage: number;
  status: InnovationDepartmentStatusKey;
  department?: IDepartmentEntity;
  comments?: IInnovationDepartmentComment[];
}
