import React, {FunctionComponent} from 'react';
import {InnovationStatusKey} from "../../interface/entity/IInnovationEntity";
import GeneralCollection from "./GeneralCollection";
import {SubmissionRow} from "./innovationRows";
import {submissionFilterModalForm} from "./modalForms";

const InnovationCollection: FunctionComponent = () => {
  return (
    <GeneralCollection
      allowedStatuses={[
        InnovationStatusKey.Submitted,
        InnovationStatusKey.Rejected,
      ]}
      defaultStatus={InnovationStatusKey.Submitted}
      filterModalForm={submissionFilterModalForm}
      innovationTrComponent={SubmissionRow}
    />
  );
};

export default InnovationCollection;
