import React, {Fragment} from "react";
import GateViewAttachmentsSection from "../../../../../../components/GateViewAttachmentsSection";
import {Col, Row} from "../../../../../../components/Grid";
import {IEstimateInformationGate, YesNo} from "../../../../../../interface/entity/IInnovationEntity";
import EstimateUtils from "../utils/EstimateUtils";
import {defaultRadioValue} from "./utils";

interface IProps {
  data: IEstimateInformationGate;
}

class Estimate extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {data} = this.props;

    return (
      <Row>
        <Col val="10">
          {EstimateUtils.fields.stagedFeilds.map((item) =>
            <div className="mb-2" key={item.name}>
              <span className="form-label mb-4">{item.header}</span>
              {item.stages.map((stage) =>
                <Fragment key={`${item.name}.${stage.name}`}>
                  <Row>
                    <Col val="2">
                      {(() => {
                        if (item.name !== EstimateUtils.costPerUnit.name) {
                          return <div className="pt-label">
                            <div className="form-control-static pt-0 text-primary-light">{stage.header}</div>
                          </div>;
                        } else {
                          return <span className="form-label text-primary-light">{stage.header}</span>;
                        }
                      })()}
                    </Col>
                    <Col val="10">
                      <Row>
                        {stage.fields.map((field) =>
                          <Col val="3" key={`${item.name}.${stage.name}.${field.name}`}>
                            <div className="mb-1">
                              <span className="form-label text-regular text-primary-dark">{field.label}</span>
                              <p className="text-medium">
                                {EstimateUtils.getValue(
                                  (data as any)[item.name] && (data as any)[item.name][stage.name]
                                  &&
                                  (data as any)[item.name][stage.name][field.name])}
                              </p>
                            </div>
                          </Col>,
                        )}
                        {item.name === EstimateUtils.needUnits.name && <Col val="3" key={`${item.name}.${stage.name}.total`}>
                            <div className="mb-1">
                            <span className="form-label text-regular text-primary-dark">
                              TOTAL:
                            </span>
                                <p className="text-medium">
                                  {EstimateUtils.calculateNumberOfPackages({
                                    stores: (data as any)[item.name][stage.name].stores,
                                    days: (data as any)[item.name][stage.name].days,
                                    usd: (data as any)[item.name][stage.name].usd,
                                  })}
                                </p>
                            </div>
                        </Col>}
                      </Row>
                    </Col>
                  </Row>
                  <hr className="hr-dashed"/>
                </Fragment>,
              )}
            </div>,
          )}
          <Row>
            <Col val="10" offset="2">
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">
                  {EstimateUtils.fields.margin.targetRetailPrice.label}
                </span>
                <p className="text-medium">{EstimateUtils.getValue(data.margin && data.margin.targetRetailPrice)}</p>
              </div>
            </Col>
          </Row>
          <hr className="hr-dashed"/>
          <Row>
            <Col val="8">
              <div className="mb-4">
                      <span className="form-label">
                        {EstimateUtils.fields.additionalEquipment.answer.label}
                      </span>
                <p className="text-medium">
                  {defaultRadioValue(
                    data.additionalEquipment && data.additionalEquipment.answer,
                    EstimateUtils.fields.additionalEquipment.answer.options,
                  )}
                </p>
              </div>
            </Col>
            {data.additionalEquipment && data.additionalEquipment.answer === YesNo.Yes &&
            <Col val="4">
                <div className="mb-4">
                      <span className="form-label text-regular text-primary-dark">
                        {EstimateUtils.fields.additionalEquipment.description.viewLabel}
                      </span>
                    <p className="text-medium">{EstimateUtils.getValue(data.additionalEquipment.description)}</p>
                </div>
            </Col>}
          </Row>
          <hr className="hr-dashed"/>
          <div className="mb-2">
            <span className="form-label mb-4">{EstimateUtils.fields.capex.amortize.header}</span>
            <Row>
              <Col val="10" offset="2">
                <Row>
                  {EstimateUtils.fields.capex.amortize.fields.map((field) =>
                    <Col val="3" key={`${EstimateUtils.fields.capex.amortize.name}.${field.name}`}>
                      <div className="mb-4">
                        <span className="form-label text-regular text-primary-dark">{field.header}</span>
                        <p className="text-medium">{EstimateUtils.getValue(data.capex && (data.capex.amortize as any)[field.name])}</p>
                      </div>
                    </Col>,
                  )}
                </Row>
              </Col>
            </Row>
          </div>
          <hr className="hr-dashed"/>
          <div className="mb-4">
            <GateViewAttachmentsSection
              title={"General documents (such as costing sheets, food safety reports, sales forecasts, etc.):"}
              attachments={data.generalAttachments}
            />
          </div>
          <div className="mb-4">
          <span className="form-label">
            {EstimateUtils.anyOtherInformationYouWouldLikeToInclude.label}
          </span>
            <p className="text-medium">{data.anyOtherInformationYouWouldLikeToInclude || "Not Added"}</p>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Estimate;
