import {combineReducers, createStore, Store} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {adminReducer} from "./admin/reducer";

import {innovationCollectionReducer} from "./innnovationCollection/reducer";
import {innovationItemReducer} from "./innovationItem/reducer";
import {notificationReducer} from "./notification/reducer";
import {systemReducer} from "./system/reducer";

const rootReducer = combineReducers({
  admin: adminReducer,
  innovationCollection: innovationCollectionReducer,
  innovationItem: innovationItemReducer,
  notification: notificationReducer,
  system: systemReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const getReduxStore = (preLoadedState: {} = {}): Store => {
  return createStore(
    rootReducer,
    preLoadedState,
    // TODO do not include in production mode
    composeWithDevTools(),
  );
};

export default getReduxStore;
