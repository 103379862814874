import React from "react";
import {InnovationStatusKey} from "../../interface/entity/IInnovationEntity";

interface ITabButtonProps<Value> {
  title: string;
  value: Value;
  isActive: boolean;
  onClickHandler: (value: Value) => void;
}

export class TabButton extends React.Component<ITabButtonProps<InnovationStatusKey>> {
  public render() {
    const {isActive, onClickHandler, title, value} = this.props;
    return <li className={`${isActive ? "active" : ""}`} onClick={() => {onClickHandler(value); }}><span>{title}</span></li>
      ;
  }
}
