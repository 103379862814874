import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {reduxStore} from "../index";
import {AppState} from "../store";
import {addNotification, removeNotification} from "../store/notification/action";
import {INotification, INotificationState, NotificationTypes} from "../store/notification/type";
import Ico from "./Ico";

interface IProps {
  notification: INotificationState;
  onRemoveNotification: (id: number) => void;
}

class Notification extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {notification, onRemoveNotification} = this.props;

    return notification.items.length > 0 && (
      <div className="notification-list">
        <div className="container">
        {notification.items.map((item: INotification) => (
          <div key={item.id} className="notification-item">
            <div className="notification-item-icon">
              {item.type === NotificationTypes.Success && <div className="success"><Ico name="check" /></div>}
              {item.type === NotificationTypes.Error && <div className="error"><Ico name="close" /></div>}
            </div>
            <div className="notification-item-text">{item.message}</div>
            <div className="notification-item-close">
              <button onClick={() => onRemoveNotification(item.id)} type="button"><Ico name="close-thin" /></button>
            </div>
          </div>))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  notification: state.notification,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRemoveNotification: (id: number) => {
    dispatch(removeNotification(id));
  },
});

export const addSuccessNotification = (message: string) => {
  const id = Date.now();
  reduxStore.dispatch(addNotification(id, message, NotificationTypes.Success));

  setTimeout(() => {
    reduxStore.dispatch(removeNotification(id));
  }, 3000);
};

export const addErrorNotification = (message: string) => {
  const id = Date.now();
  reduxStore.dispatch(addNotification(id, message, NotificationTypes.Error));

  setTimeout(() => {
    reduxStore.dispatch(removeNotification(id));
  }, 3000);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
