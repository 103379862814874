import {IInformationGate} from "../../../../interface/entity/IInnovationEntity";
import {
  INNOVATION_ITEM_STAGE_GATE_FETCH_START,
  INNOVATION_ITEM_STAGE_GATE_FETCH_SUCCESS,
  INNOVATION_ITEM_STAGE_GATE_UPDATE_DATA,
} from "./type";

export const innovationItemStageGateFetchStart = () => {
  return {
    type: INNOVATION_ITEM_STAGE_GATE_FETCH_START,
  };
};

export const innovationItemStageGateFetchSuccess = (data: IInformationGate) => {
  return {
    data,
    type: INNOVATION_ITEM_STAGE_GATE_FETCH_SUCCESS,
  };
};

export const innovationItemStageGateUpdateData = (data: IInformationGate) => {
  return {
    data,
    type: INNOVATION_ITEM_STAGE_GATE_UPDATE_DATA,
  };
};
