import React, {Fragment} from "react";
import {IFormText} from "../../../../../../components/form/IForm";
import GateViewAttachmentsSection from "../../../../../../components/GateViewAttachmentsSection";
import {Col, Row} from "../../../../../../components/Grid";
import {IActionInformationGate, YesNo} from "../../../../../../interface/entity/IInnovationEntity";
import ActionUtils from "../utils/ActionUtils";
import {defaultRadioValue} from "./utils";

interface IProps {
  data: IActionInformationGate;
}

class Action extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {data} = this.props;
    return (
      <Fragment>
        <div className="mb-4">
          <span className="form-label">{ActionUtils.finalProductFormAlignmentBasic.title}</span>
        </div>
        <div className="mb-4">
          <span className="form-label text-primary-light">Consumer Research Protocol - Final Prototype Validation</span>
        </div>
        <Row>
          {
            ActionUtils.finalProductFormAlignmentBasic.fields.map((field: IFormText, index: number) => (
              <div className="col-fifth" key={index}>
                <div className="mb-1">
                  <span className="form-label text-regular text-primary-dark">
                    {field.label}
                  </span>
                  <p className="text-medium">
                    {(data as any)[field.name] || "Not Added"}
                  </p>
                </div>
              </div>
            ))
          }
        </Row>
        <hr className="hr-dashed mb-6"/>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">
            {ActionUtils.salesTargetAggregated.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              (data as any)[ActionUtils.salesTargetAggregated.name],
              ActionUtils.salesTargetAggregated.options,
            )}
          </p>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">
            {ActionUtils.salesObjectivesByCustomerAccountOwner.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              (data as any)[ActionUtils.salesObjectivesByCustomerAccountOwner.name],
              ActionUtils.salesObjectivesByCustomerAccountOwner.options,
            )}
          </p>
        </div>
        <GateViewAttachmentsSection title={"Sales forecast by customer/account Owner:"} attachments={data.uploadSalesForecastAttachments}/>
        <hr className="hr-dashed mb-2"/>
        <div className="form-control-static">
          {ActionUtils.finalProductFormAlignmentExtra.title}
        </div>
        <Row>
          {ActionUtils.finalProductFormAlignmentExtra.fields.map((field: IFormText, index: number) => (
            <Col val="3" key={index}>
              <div className="mb-4">
                  <span className="form-label text-regular text-primary-dark">
                    {field.label}
                  </span>
                <p className="text-medium">
                  {(data as any)[field.name] || "Not Added"}
                </p>
              </div>
            </Col>
          ))}
        </Row>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">
            {ActionUtils.ifContractManufacturedAreCogsConfirmed.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              data && (data as any)[ActionUtils.ifContractManufacturedAreCogsConfirmed.name],
              ActionUtils.ifContractManufacturedAreCogsConfirmed.options,
            )}
          </p>
        </div>
        {data && (data as any)[ActionUtils.ifContractManufacturedAreCogsConfirmed.name] === YesNo.No && (
          <div className="mb-4">
          <span className="form-label text-regular">
            {ActionUtils.ifContractManufacturedAreCogsConfirmedAdjustment.label}
          </span>
            <p className="text-medium text-regular">
              {(data as any)[ActionUtils.ifContractManufacturedAreCogsConfirmedAdjustment.name] || "Not Added"}
            </p>
          </div>
        )}
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">
            {ActionUtils.doesPilotScaleToFullLandPlan.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              data && (data as any)[ActionUtils.doesPilotScaleToFullLandPlan.name],
              ActionUtils.doesPilotScaleToFullLandPlan.options,
            )}
          </p>
        </div>
        {data && (data as any)[ActionUtils.doesPilotScaleToFullLandPlan.name] === YesNo.No && (
          <div className="mb-4">
          <span className="form-label text-regular">
            {ActionUtils.doesPilotScaleToFullLandPlanAdjustment.label}
          </span>
            <p className="text-medium text-regular">
              {(data as any)[ActionUtils.doesPilotScaleToFullLandPlanAdjustment.name] || "Not Added"}
            </p>
          </div>
        )}
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">
            {ActionUtils.doFinalPilotCogsMeetOrExceedHurdles.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              data && (data as any)[ActionUtils.doFinalPilotCogsMeetOrExceedHurdles.name],
              ActionUtils.doFinalPilotCogsMeetOrExceedHurdles.options,
            )}
          </p>
        </div>
        {data && (data as any)[ActionUtils.doFinalPilotCogsMeetOrExceedHurdles.name] === YesNo.No && (
          <div className="mb-4">
          <span className="form-label text-regular">
            {ActionUtils.doFinalPilotCogsMeetOrExceedHurdlesAdjustment.label}
          </span>
            <p className="text-medium text-regular">
              {(data as any)[ActionUtils.doFinalPilotCogsMeetOrExceedHurdlesAdjustment.name] || "Not Added"}
            </p>
          </div>
        )}
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">
            {ActionUtils.doSalesCommitmentsMatchProductForecast.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              data && (data as any)[ActionUtils.doSalesCommitmentsMatchProductForecast.name],
              ActionUtils.doSalesCommitmentsMatchProductForecast.options,
            )}
          </p>
        </div>
        {data && (data as any)[ActionUtils.doSalesCommitmentsMatchProductForecast.name] === YesNo.No && (
          <div className="mb-4">
          <span className="form-label text-regular">
            {ActionUtils.doSalesCommitmentsMatchProductForecastAdjustment.label}
          </span>
            <p className="text-medium text-regular">
              {(data as any)[ActionUtils.doSalesCommitmentsMatchProductForecastAdjustment.name] || "Not Added"}
            </p>
          </div>
        )}
        <hr className="hr-dashed"/>
        {ActionUtils.finalValues.fields.map((field: IFormText, index: number) => (
          <div className="mb-4" key={index}>
              <span className="form-label">
                {field.label}
              </span>
            <p className="text-medium">
              {(data as any)[field.name] || "Not Added"}
            </p>
          </div>
        ))}
        <hr className="hr-dashed"/>
        <GateViewAttachmentsSection title={"General Documents:"} attachments={data.generalAttachments}/>
        <div className="mb-4">
          <span className="form-label">
            {ActionUtils.anyOtherInformationYouWouldLikeToInclude.label}
          </span>
          <p className="text-medium">{data.anyOtherInformationYouWouldLikeToInclude || "Not Added"}</p>
        </div>
      </Fragment>
    );
  }
}

export default Action;
