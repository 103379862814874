import React from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router";
import {Dispatch} from "redux";
import {INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE} from "../../../../../components/routes";
import {AppState} from "../../../../../store";
import InnovationItemOversightAllComments from "./InnovationItemOversightComments/InnovationItemOversightAllComments";
import InnovationItemOversightDepartmentComments from "./InnovationItemOversightComments/InnovationItemOversightDepartmentComments";

class InnovationItemOversightComments extends React.Component {
  public render(): React.ReactNode {
    return (
      <div>
        <Switch>
          <Route
            exact={true}
            path={INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE}
            component={InnovationItemOversightDepartmentComments}
          />
          <Route
            component={InnovationItemOversightAllComments}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemOversightComments);
