import {IFormText, IFormTextFields, IFormToggle, IFormToggleFields} from "../../../../../../components/form/IForm";
import {YesNo} from "../../../../../../interface/entity/IInnovationEntity";

class TestUtils {
  public static testMetricsConsumer: string = "Test Metrics - Consumer:";

  public static testMetricsConsumerQualitative: IFormToggle = {
    name: "testMetricsConsumerQualitative",
    label: "Qualitative:",
    options: [
      {value: "clt", label: "CLT", tooltip: "Central Location Test"},
      {value: "hut", label: "HUT", tooltip: "Home Usage Test"},
      {value: "shopper", label: "Shopper"},
      {value: "inHome", label: "In Home"},
    ],
  };

  public static testMetricsConsumerQuantitative: IFormToggle = {
    name: "testMetricsConsumerQuantitative",
    label: "Qualitative:",
    options: [
      {value: "purchaseIntent", label: "Purchase Intent"},
      {value: "priceSensitivity", label: "Price Sensitivity"},
      {value: "merchPromo", label: "Merch/Promo"},
      {value: "display", label: "Display"},
    ],
  };

  public static testMetricsConsumerQuantitativeAdjustmentsNeeded: IFormText = {
    label: "Adjustments Needed (if any):",
    name: "testMetricsConsumerAdjustments",
    placeholder: "Please fill in value…",
  };

  public static testMetricsCustomer: string = "Test Metrics - Customer:";

  public static testMetricsCustomerCustomerSuccess: IFormTextFields = {
    title: "Customer Success Metrics:",
    fields: [
      {
        label: "U/P/S",
        name: "ups",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "$/Week/Store",
        name: "weekStore",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Units",
        name: "units",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Price",
        name: "price",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Timeframe",
        name: "timeframe",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
    ],
  };

  public static testMetricsCustomerCustomerSuccessAdjustmentsNeeded: IFormText = {
    label: "Adjustments Needed (if any):",
    name: "testMetricsCustomerAdjustmentsNeeded",
    placeholder: "Please fill in value…",
  };

  public static testMetricsOperations: string = "Test Metrics - Operations:";

  public static testMetricsOperationsCapacityAndYield: IFormTextFields = {
    title: "Capacity and Yield Metrics:",
    fields: [
      {
        label: "Harvest",
        name: "harvest",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "U/Minute",
        name: "uMinute",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "U/Shift",
        name: "uShift",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Buyer U/Day",
        name: "buyerUDay",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
    ],
  };

  public static testMetricsOperationsCogs: IFormText = {
    label: "COGS",
    name: "cogs",
    placeholder: "Please fill in value…",
    labelClass: "text-regular",
  };

  public static testMetricsOperationsAdjustmentsNeeded: IFormText = {
    label: "Adjustments Needed (if any):",
    name: "capacityAndYieldAdjustmentsNeeded",
    placeholder: "Please fill in value…",
  };

  public static testMetricsQcFoodSafety: IFormTextFields = {
    title: "Test Metrics - QC/Food Safety:",
    fields: [
      {
        label: "QC Specs",
        name: "qcSpecs",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Food Safefty Specs",
        name: "foodSafeftySpecs",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Customer Rejection Specs",
        name: "customerRejectionSpecs",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
    ],
  };

  public static buyerAlignment: IFormToggle = {
    label: "Buyer Alignment",
    name: "buyerAlignment",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static testMetricsFinancialMetrics: IFormToggleFields = {
    title: "Financial Metrics:",
    fields: [
      {
        name: "isCogsUnitAcceptable",
        label: "Is COGS/Unit Acceptable?",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        name: "isOfUnitsAcceptable",
        label: "Is # of Units Acceptable?",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        name: "isFobAcceptable",
        label: "Is FOB Price Acceptable?",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        name: "isMarketPriceAcceptable",
        label: "Is Retail Price Acceptable?",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        name: "isMarginDudaAcceptable",
        label: "Is Duda Margin Acceptable?",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        name: "isMarginCustomerAcceptable",
        label: "Is Customer Margin Acceptable?",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        name: "isVolumeForecastAcceptable",
        label: "Is Vol Forecast Acceptable?",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
    ],
  };
  public static testMetricsFinancialMetricsValues: IFormTextFields = {
    fields: [
      {
        name: "isCogsUnitAcceptableValue",
        label: "Is COGS/Unit Acceptable Value?",
        placeholder: "Enter",
        labelClass: "sr-only",
      },
      {
        name: "isOfUnitsAcceptableValue",
        label: "Is # of Units Acceptable Value?",
        placeholder: "Enter",
        labelClass: "sr-only",
      },
      {
        name: "isFobPriceAcceptableValue",
        label: "Is FOB Price Acceptable Value?",
        placeholder: "Enter",
        labelClass: "sr-only",
      },
      {
        name: "isMarketPriceAcceptableValue",
        label: "Is Retail Price Acceptable Value?",
        placeholder: "Enter",
        labelClass: "sr-only",
      },
      {
        name: "isDudaMarginAcceptableValue",
        label: "Is Duda Margin Acceptable Value?",
        placeholder: "Enter",
        labelClass: "sr-only",
      },
      {
        name: "isCustomerMarginAcceptableValue",
        label: "Is Customer Margin Acceptable Value?",
        placeholder: "Enter",
        labelClass: "sr-only",
      },
      {
        name: "isVolumeForecastAcceptableValue",
        label: "Is Vol Forecast Acceptable Value?",
        placeholder: "Enter",
        labelClass: "sr-only",
      },
    ],
  };

  public static anyOtherInformationYouWouldLikeToInclude: IFormText = {
    label: "Any other information you would like to include:",
    name: "anyOtherInformationYouWouldLikeToInclude",
    placeholder: "Please Fill In Additional Information if any…",
  };
}

export default TestUtils;
