import {ErrorMessage} from "formik";
import {DateTime} from "luxon";
import React from "react";
import DatePicker from "react-datepicker";
import {Col, Row} from "../Grid";
import Ico from "../Ico";
import {IForm} from "./IForm";

const AnotherFormDatepicker = (
  {
    field,
    form,
    label,
    placeholder = "Enter",
    className,
    onChange = null,
    disabled = false,
    isClearable = false,
    col,
  }: IForm) => {
  const datePicker = (
    <div className={`input-group ${className}`}>
      <DatePicker
        {...field}
        isClearable={isClearable && !disabled}
        disabled={disabled}
        placeholderText={placeholder}
        className="form-control"
        dateFormat="MM/dd/y"
        autoComplete="off"
        preventOpenOnFocus={true}
        onChange={(v) => {
          form.setFieldValue(field.name, v ? DateTime.fromJSDate(v).toFormat("MM/dd/y") : null);
          if (onChange) {
            onChange(v ? DateTime.fromJSDate(v).toFormat("MM/dd/y") : null);
          }
        }}
        selected={field.value && typeof field.value === "string" ? DateTime.fromFormat(field.value, "MM/dd/y").toJSDate() : field.value}
      />
      <div className="input-group-append pl-2 pr-2">
        <Ico name="calendar"/>
      </div>
    </div>
  );

  return (
    <label className="form-datepicker-wrap" onClick={(e) => e.preventDefault()}>
      {label && <span className={`form-label ${label === field.name ? "sr-only" : ""}`}>{label}</span>}
      {col ? <Row><Col val={col}>{datePicker}</Col></Row> : datePicker}
      <ErrorMessage component="span" className="form-error" name={field.name}/>
    </label>
  );
};

export default AnotherFormDatepicker;
