import React, {FunctionComponent, Fragment, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {Link} from "react-router-dom";
import {Col, Row} from "../../components/Grid";
import Ico from "../../components/Ico";
import BaseLayout from "../../components/Layout/BaseLayout";
import WrapperLoader from "../../components/loader/WrapperLoader";
import Pagination from "../../components/Pagination";
import {areEqual} from "../../helpers/sortByKeys";
import {IInnovationEntity, InnovationStageKey, innovationStageKeyToValue, InnovationStatusKey,} from "../../interface/entity/IInnovationEntity";
import {IPagination} from "../../interface/rest/IPagination";
import {getCollection, IInnovationCollectionFilters, IInnovationCollectionSort,} from "../../services/innovation";
import InnovationFilterBar from "../InnovationCollection/InnovationFilterBar";
import InnovationFilterModal from "../InnovationCollection/InnovationFilterModal";
import InnovationSearch from "../InnovationCollection/InnovationSearch";
import InnovationStatusTabsBar from "../InnovationCollection/InnovationStatusTabsBar";
import {IInnovationRow} from "./innovationRows";
import {IFilterModalFormBody} from "./modalForms";

interface IUrlParams {
  page: number;
  sort: IInnovationCollectionSort;
  filters: IInnovationCollectionFilters;
  status: InnovationStatusKey;
}

interface IProps {
  allowedStatuses: InnovationStatusKey[];
  defaultStatus: InnovationStatusKey;
  filterModalForm: FunctionComponent<IFilterModalFormBody>;
  innovationTrComponent: FunctionComponent<IInnovationRow>;
}

const GeneralCollection: FunctionComponent<IProps> = (props) => {
  const defaultAppUrlParams: IUrlParams = {
    page: 1,
    sort: IInnovationCollectionSort.createAtDesc,
    filters: {},
    status: props.defaultStatus,
  };

  const appUrlToParams = (
    url: string,
    defaultParams: IUrlParams
  ): IUrlParams => {
    const urlParams = new URLSearchParams(url);

    const createDateFrom = urlParams.get("create_date_from");
    const createDateTo = urlParams.get("create_date_to");
    const deadlineDateFrom = urlParams.get("deadline_date_from");
    const deadlineDateTo = urlParams.get("deadline_date_to");
    const stages = urlParams.get("stages");
    const status = urlParams.get("status");
    const q = urlParams.get("q");
    const sort = urlParams.get("sort");
    const page = urlParams.get("page");

    const { filters: defaultFilters, status: defaultStatus } = defaultParams;

    return {
      page: page ? Number(page) : defaultAppUrlParams.page,
      sort: sort
        ? ((Number(sort) as unknown) as IInnovationCollectionSort)
        : defaultAppUrlParams.sort,
      filters: {
        ...(createDateFrom
          ? { createDateFrom }
          : { createDateFrom: defaultFilters.createDateFrom }),
        ...(createDateTo
          ? { createDateTo }
          : { createDateTo: defaultFilters.createDateTo }),
        ...(deadlineDateFrom
          ? { deadlineDateFrom }
          : { deadlineDateFrom: defaultFilters.deadlineDateFrom }),
        ...(deadlineDateTo
          ? { deadlineDateTo }
          : { deadlineDateTo: defaultFilters.deadlineDateTo }),
        ...(stages
          ? { stages: stages.split(",").map((v) => +v) as InnovationStageKey[] }
          : { stages: defaultFilters.stages }),
        ...(q ? { q } : { q: defaultFilters.q }),
      },
      status: status
        ? ((Number(status) as unknown) as InnovationStatusKey)
        : defaultStatus,
    };
  };

  const appParamsToUrl = (
    params: IUrlParams,
    defaultParams: IUrlParams
  ): string => {
    const urlParams = new URLSearchParams();

    const { page, sort, filters, status } = params;
    const {
      page: defaultPage,
      sort: defaultSort,
      filters: defaultFilters,
      status: defaultStatus,
    } = defaultParams;

    if (page !== defaultPage) {
      urlParams.set("page", String(page));
    }

    if (sort !== defaultSort) {
      urlParams.set("sort", String(sort));
    }

    if (filters.createDateFrom !== defaultFilters.createDateFrom) {
      urlParams.set("create_date_from", String(filters.createDateFrom));
    }

    if (filters.createDateTo !== defaultFilters.createDateTo) {
      urlParams.set("create_date_to", String(filters.createDateTo));
    }

    if (filters.deadlineDateFrom !== defaultFilters.deadlineDateFrom) {
      urlParams.set("deadline_date_from", String(filters.deadlineDateFrom));
    }

    if (filters.deadlineDateTo !== defaultFilters.deadlineDateTo) {
      urlParams.set("deadline_date_to", String(filters.deadlineDateTo));
    }

    if (filters.stages !== defaultFilters.stages) {
      urlParams.set("stages", String(filters.stages));
    }

    if (status !== defaultStatus) {
      urlParams.set("status", String(status));
    }

    if (filters.q !== defaultFilters.q) {
      urlParams.set("q", String(filters.q));
    }

    return urlParams.toString();
  };

  const { allowedStatuses } = props;
  const [collection, setCollection] = useState<IInnovationEntity[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(defaultAppUrlParams.page);
  const [status, setStatus] = useState<InnovationStatusKey>(
    InnovationStatusKey.Active
  );
  const [sort, setSort] = useState<IInnovationCollectionSort>(
    IInnovationCollectionSort.createAtAsc
  );
  const [filters, setFilters] = useState<IInnovationCollectionFilters>({});

  const location = useLocation();
  const history = useHistory();

  const handleParamChange = (
    page: number,
    sort: IInnovationCollectionSort,
    filters: IInnovationCollectionFilters,
    status: InnovationStatusKey
  ) => {
    const currentPropsParams = appParamsToUrl(
      { page, sort, filters, status },
      defaultAppUrlParams
    );

    history.push(
      [
        location.pathname,
        ...[currentPropsParams ? "?" : "", currentPropsParams],
      ].join("")
    );
  };

  const fetchInnovations = ({
    page,
    sort,
    filters,
    status,
  }: {
    page: number;
    sort: IInnovationCollectionSort;
    filters: IInnovationCollectionFilters;
    status: InnovationStatusKey;
  }) => {
    setIsLoading(true);
    getCollection({ page, sort, filters, status })
      .then((response) => {
        setCollection(response.data.items);
        setPagination(response.data.pagination);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const { page, sort, filters, status } = appUrlToParams(
      location.search,
      defaultAppUrlParams
    );

    setPage(page);
    setSort(sort);
    setStatus(status);
    setFilters(filters);

    fetchInnovations({ page, sort, filters, status });
    // eslint-disable-next-line
  }, [location]);

  const isSortByName = [
    IInnovationCollectionSort.nameAsc,
    IInnovationCollectionSort.nameDesc,
  ].includes(sort);
  const isSortByCreatedAt = [
    IInnovationCollectionSort.createAtAsc,
    IInnovationCollectionSort.createAtDesc,
  ].includes(sort);
  const isSortByStage = [
    IInnovationCollectionSort.stageAsc,
    IInnovationCollectionSort.stageDesc,
  ].includes(sort);
  const isSortByDeadlineAt = [
    IInnovationCollectionSort.deadlineAtAsc,
    IInnovationCollectionSort.deadlineAtDesc,
  ].includes(sort);

  const formatCountMessage = (count: number, status: InnovationStatusKey): string => {
    switch (status) {
      case InnovationStatusKey.Submitted:
        return `${count} submit${count > 1 ? 's' : ''} in the list`;
      case InnovationStatusKey.Rejected:
        return `${count} submit${count > 1 ? 's' : ''} rejected`;
      case InnovationStatusKey.Active:
        return `${count} innovation${count > 1 ? 's' : ''} active`;
      case InnovationStatusKey.Complete:
        return `${count} innovation${count > 1 ? 's' : ''} completed`;
      case InnovationStatusKey.Canceled:
        return `${count} innovation${count > 1 ? 's' : ''} cancelled`;
    }
  }

  return (
    <BaseLayout location={location}>
      <div className="header-line">
        <div className="pull-left">
          <h1 className="h2 te/Users/usualdesigner/Work/duda-app/src/route/lists/GeneralCollection.tsxxt-primary-darker">Innovations</h1>
          <p className="text-primary-darker">
            {pagination ? formatCountMessage(pagination.total, status) : ""}
          </p>
        </div>
        <div className="pull-right">
          <Link
            to={"/innovation/add"}
            className="btn btn-primary btn-shade btn-width"
            type="button"
          >
            <Ico name="plus " />
            &nbsp; Add Innovation
          </Link>
        </div>
      </div>

      <div className="mb-4">
        <Row>
          <Col val="5">
            <InnovationStatusTabsBar
              status={status}
              allowedStatuses={allowedStatuses}
              onSetStatus={(newStatus) => {
                handleParamChange(
                  defaultAppUrlParams.page,
                  defaultAppUrlParams.sort,
                  defaultAppUrlParams.filters,
                  newStatus
                );
              }}
            />
          </Col>
          <Col val="3">
            <InnovationFilterModal
              filters={filters}
              filterModalForm={props.filterModalForm}
              onSetFilters={(newFilters) => {
                handleParamChange(
                  defaultAppUrlParams.page,
                  sort,
                  newFilters,
                  status
                );
              }}
            />
          </Col>
          <Col val="4">
            <InnovationSearch
              q={filters.q}
              onSetQ={(q) => {
                handleParamChange(
                  defaultAppUrlParams.page,
                  sort,
                  { ...filters, q },
                  status
                );
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="mb-4">
        <InnovationFilterBar
          filters={filters}
          onSetFilters={(newFilters) => {
            handleParamChange(
              defaultAppUrlParams.page,
              sort,
              newFilters,
              status
            );
          }}
          onClearAll={() => {
            handleParamChange(
              defaultAppUrlParams.page,
              sort,
              defaultAppUrlParams.filters,
              status
            );
          }}
          isDefault={areEqual(
            filters as any,
            defaultAppUrlParams.filters as any
          )}
        />
      </div>

      <WrapperLoader
        isLoading={isLoading}
        message={"We're loading innovations. Please wait..."}
      >
        <table className="table table-default table-action">
          <thead>
            <tr>
              <th
                onClick={() => {
                  const newSort =
                    sort === IInnovationCollectionSort.nameAsc
                      ? IInnovationCollectionSort.nameDesc
                      : IInnovationCollectionSort.nameAsc;
                  handleParamChange(page, newSort, filters, status);
                }}
                className={`table-action-toggle ${
                  isSortByName ? "text-primary-dark" : ""
                }`}
              >
                <span className="table-action-toggle-inner">
                  Innovation Name{" "}
                  {sort === IInnovationCollectionSort.nameAsc && (
                    <Ico name="sort-desc" />
                  )}
                  {sort === IInnovationCollectionSort.nameDesc && (
                    <Ico name="sort-asc" />
                  )}
                </span>
              </th>
              <th>Submitted By</th>
              <th
                onClick={() => {
                  const newSort =
                    sort === IInnovationCollectionSort.createAtDesc
                      ? IInnovationCollectionSort.createAtAsc
                      : IInnovationCollectionSort.createAtDesc;
                  handleParamChange(page, newSort, filters, status);
                }}
                className={`table-action-toggle ${
                  isSortByCreatedAt ? "text-primary-dark" : ""
                }`}
              >
                <span className="table-action-toggle-inner">
                  Date Submitted{" "}
                  {sort === IInnovationCollectionSort.createAtAsc && (
                    <Ico name="sort-desc" />
                  )}
                  {sort === IInnovationCollectionSort.createAtDesc && (
                    <Ico name="sort-asc" />
                  )}
                </span>
              </th>
              {[InnovationStatusKey.Submitted, InnovationStatusKey.Rejected].includes(status) ? <Fragment>
                <th>Description</th>
                <th>Actions</th>
              </Fragment> : <Fragment>
                <th
                  onClick={() => {
                    const newSort =
                      sort === IInnovationCollectionSort.stageDesc
                        ? IInnovationCollectionSort.stageAsc
                        : IInnovationCollectionSort.stageDesc;
                    handleParamChange(page, newSort, filters, status);
                  }}
                  className={`table-action-toggle ${
                    isSortByStage ? "text-primary-dark" : ""
                  }`}
                >
                <span className="table-action-toggle-inner">
                  Current Stage{" "}
                  {sort === IInnovationCollectionSort.stageAsc && (
                    <Ico name="sort-desc" />
                  )}
                  {sort === IInnovationCollectionSort.stageDesc && (
                    <Ico name="sort-asc" />
                  )}
                </span>
                </th>
                <th>Current Stage Resolution</th>
                <th
                  onClick={() => {
                    const newSort =
                      sort === IInnovationCollectionSort.deadlineAtDesc
                        ? IInnovationCollectionSort.deadlineAtAsc
                        : IInnovationCollectionSort.deadlineAtDesc;
                    handleParamChange(page, newSort, filters, status);
                  }}
                  className={`table-action-toggle ${
                    isSortByDeadlineAt ? "text-primary-dark" : ""
                  }`}
                >
                <span className="table-action-toggle-inner">
                  Project Deadline{" "}
                  {sort === IInnovationCollectionSort.deadlineAtAsc && (
                    <Ico name="sort-desc" />
                  )}
                  {sort === IInnovationCollectionSort.deadlineAtDesc && (
                    <Ico name="sort-asc" />
                  )}
                </span>
                </th>
              </Fragment>}
            </tr>
          </thead>
          <tbody>
            {collection.length === 0 && (
              <tr>
                <td colSpan={6} className="comment-item-empty">
                  <div className="text-center">
                    <Ico name="comment" />
                    No Innovations Found
                  </div>
                </td>
              </tr>
            )}
            {collection.map((innovation: IInnovationEntity) =>
              React.createElement<IInnovationRow>(props.innovationTrComponent, {
                key: `innovation-${innovation.id}`,
                innovation,
                onClick: (clickedInnovation: IInnovationEntity) => {
                  history.push(
                    "/innovation/" +
                      clickedInnovation.id +
                      "/" +
                      innovationStageKeyToValue[clickedInnovation.stage]
                  );
                },
                onUpdate: (innovation) => {
                  fetchInnovations({page, sort, filters, status});
                }
              })
            )}
          </tbody>
        </table>
        {pagination && pagination.totalPages > 1 && (
          <Pagination
            {...pagination}
            onChange={(newPage) => {
              handleParamChange(newPage, sort, filters, status);
            }}
          />
        )}
      </WrapperLoader>
    </BaseLayout>
  );
};

export default GeneralCollection;
