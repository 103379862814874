import {DateTime} from "luxon";
import React, {Fragment, ReactNode} from "react";
import {
  ActivityType,
  IActivityEntity,
  ICommentWasAdded, ICommentWasUpdated,
  IInnovationStatusWasChanged,
  IInnovationWasAdvanced,
  IResolutionStatusWasChanged, ISubmissionWasApproved, ISubmissionWasRejected, ISubmissionWasReSubmitted, ITimelinesWasChanged,
} from "../../../interface/entity/IActivityEntity";
import {innovationDepartmentStatusKeyToMetaValue, innovationDepartmentStatusKeyToValue} from "../../../interface/entity/IInnovationDepartmentEntity";
import {innovationStageKeyToLabel, innovationStageKeyToValue, innovationStatusKeyToLabel} from "../../../interface/entity/IInnovationEntity";

interface IProps {
  activity: IActivityEntity;
}

class InnovationItemActivityRow extends React.Component<IProps> {

  private static activityContent(activity: IActivityEntity): ReactNode {
    switch (activity.type) {
      case ActivityType.ResolutionStatusWasChanged:
        const resolutionActivity: IResolutionStatusWasChanged = activity.data as IResolutionStatusWasChanged;
        return (
          <div className="activity-item-content">
            <div className="mb-1">
              <p>Innovation Status Update:</p>
              <div className="activity-status">
                <div className="pb-2 d-inline-block">
                  <div className={`activity-status-prev status-${innovationDepartmentStatusKeyToValue[resolutionActivity.oldResolutionStatus]}`}>
                    {innovationDepartmentStatusKeyToMetaValue[resolutionActivity.oldResolutionStatus]}
                  </div>
                </div>
                <div className="pb-2 d-inline-block">
                  <div className={`activity-status-new status-${innovationDepartmentStatusKeyToValue[resolutionActivity.newResolutionStatus]}`}>
                    {innovationDepartmentStatusKeyToMetaValue[resolutionActivity.newResolutionStatus]}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p>Comment was left to innovation:</p>
              <p className="text-primary">{resolutionActivity.comment}</p>
            </div>
            {(resolutionActivity.attachments && resolutionActivity.attachments.length > 0) && (
              <div className="mb-1">
                <p>File(s) attached to comment:</p>
                {resolutionActivity.attachments.map((attachment, i) => (
                  <div className="form-control-wrap" key={`${attachment.fileName}-${i}`}>
                    <span className="form-label sr-only"/>
                    <div className="form-control-static text-primary text-iconed py-0">
                      <a target={"_blank"} rel="noopener noreferrer" href={attachment.url}>
                        <i className="ico ico-doc "/>
                        &nbsp;{attachment.fileName}
                      </a>
                    </div>
                  </div>))}
              </div>)}
          </div>
        );
      case ActivityType.CommentWasAdded:
        const commentWasAddedData = activity.data as ICommentWasAdded;
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Comment was left to innovation:</p>
              <p className="text-primary">{commentWasAddedData.comment}</p>
              {commentWasAddedData.usersToNotify && commentWasAddedData.usersToNotify.length > 0 &&
              <Fragment>
                  <p>Notification Emails was sent to:</p>
                  <p className="text-primary">
                    {commentWasAddedData.usersToNotify.join(", ")}
                  </p>
              </Fragment>}
            </div>
            {(commentWasAddedData.attachments && commentWasAddedData.attachments.length > 0) && (
              <div className="mb-1">
                <p>File(s) attached to comment:</p>
                {commentWasAddedData.attachments.map((attachment, i) => (
                  <div className="form-control-wrap" key={`${attachment.fileName}-${i}`}>
                    <span className="form-label sr-only"/>
                    <div className="form-control-static text-primary text-iconed py-0">
                      <a target={"_blank"} rel="noopener noreferrer" href={attachment.url}>
                        <i className="ico ico-doc "/>
                        &nbsp;{attachment.fileName}
                      </a>
                    </div>
                  </div>))}
              </div>)}
          </div>
        );
      case ActivityType.CommentWasUpdated:
        const commentWasUpdatedData = activity.data as ICommentWasUpdated;
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Comment was updated:</p>
              <p className="text-primary">{commentWasUpdatedData.oldComment}</p>
              {commentWasUpdatedData.newComment && (
                <Fragment>
                  <p>Comment was updated to:</p>
                  <p className="text-primary">{commentWasUpdatedData.newComment}</p>
                </Fragment>)}
              {commentWasUpdatedData.usersToNotify && commentWasUpdatedData.usersToNotify.length > 0 &&
              <Fragment>
                  <p>Notification Emails was sent to:</p>
                  <p className="text-primary">
                    {commentWasUpdatedData.usersToNotify.join(", ")}
                  </p>
              </Fragment>}
            </div>
            {(commentWasUpdatedData.attachments && commentWasUpdatedData.attachments.length > 0) && (
              <div className="mb-1">
                <p>File(s) attached to comment:</p>
                {commentWasUpdatedData.attachments.map((attachment, i) => (
                  <div className="form-control-wrap" key={`${attachment.fileName}-${i}`}>
                    <span className="form-label sr-only"/>
                    <div className="form-control-static text-primary text-iconed py-0">
                      <a target={"_blank"} rel="noopener noreferrer" href={attachment.url}>
                        <i className="ico ico-doc "/>
                        &nbsp;{attachment.fileName}
                      </a>
                    </div>
                  </div>))}
              </div>)}
            {(commentWasUpdatedData.removedAttachments && commentWasUpdatedData.removedAttachments.length > 0) && (
              <div className="mb-1">
                <p>File(s) was removed from comment:</p>
                {commentWasUpdatedData.removedAttachments.map((attachment, i) => (
                  <div className="form-control-wrap" key={`${attachment.fileName}-${i}`}>
                    <span className="form-label sr-only"/>
                    <div className="form-control-static text-primary text-iconed py-0">
                      <a target={"_blank"} rel="noopener noreferrer" href={attachment.url}>
                        <i className="ico ico-doc "/>
                        &nbsp;{attachment.fileName}
                      </a>
                    </div>
                  </div>))}
              </div>)}
          </div>
        );
      case ActivityType.InnovationWasAdvanced:
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Innovation was advanced to {innovationStageKeyToLabel[(activity.data as IInnovationWasAdvanced).newStage]} Stage</p>
            </div>
          </div>
        );
      case ActivityType.InnovationStatusWasChanged:
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Innovation status was changed to {innovationStatusKeyToLabel[(activity.data as IInnovationStatusWasChanged).newInnovationStatus]}</p>
            </div>
          </div>
        );
      case ActivityType.TimelinesWasChanged:
        const timeline: ITimelinesWasChanged = activity.data as ITimelinesWasChanged;
        const date = DateTime.fromISO(timeline.newDate);
        let dateString = date.isValid ? date.toFormat("MM/dd/y") : timeline.newDate;
        dateString = timeline.newDate ? dateString : "N/A";
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>{timeline.dateType} was updated to <strong>{dateString}</strong></p>
            </div>
          </div>
        );
      case ActivityType.SubmissionWasCreated:
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Submission has been added</p>
            </div>
          </div>
        );
      case ActivityType.SubmissionWasApproved:
        const dataSubmissionWasApproved: ISubmissionWasApproved = activity.data as ISubmissionWasApproved;
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Submission has been approved</p>
              {dataSubmissionWasApproved.message ? <p>Comment: {dataSubmissionWasApproved.message}</p> : ""}
            </div>
          </div>
        );
      case ActivityType.SubmissionWasRejected:
        const dataSubmissionWasRejected: ISubmissionWasRejected = activity.data as ISubmissionWasRejected;
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Submission has been rejected</p>
              {dataSubmissionWasRejected.message ? <p>Comment: {dataSubmissionWasRejected.message}</p> : ""}
            </div>
          </div>
        );
      case ActivityType.SubmissionWasReSubmitted:
        const dataSubmissionWasReSubmitted: ISubmissionWasReSubmitted = activity.data as ISubmissionWasReSubmitted;
        return (
          <div className="activity-item-content">
            <div className="mb-4">
              <p>Submission has been re-submitted</p>
              {dataSubmissionWasReSubmitted.message ? <p>Comment: {dataSubmissionWasReSubmitted.message}</p> : ""}
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  public render(): React.ReactNode {
    const {activity} = this.props;

    return (
      <tr>
        <td className="py-6">
          <span className={`tag tag-${innovationStageKeyToValue[activity.stage]}`}>{innovationStageKeyToLabel[activity.stage]}</span>
        </td>
        <td className="py-6">
          {DateTime.fromISO(activity.createdAt).toFormat("MM/dd/y")}
        </td>
        <td>
          <div className="media">
            <div className="media-aside">
              <div className="user-avatar">{activity.user.fullName.split(" ").reduce((a, v) => a + v.charAt(0), "")}</div>
            </div>
            <div className="media-body vertical-middle pl-2">
              <p className="user-name">{activity.user.fullName}</p>
              <p className="user-position">{activity.user.department && activity.user.department.name}</p>
            </div>
          </div>
        </td>
        <td className={"w-500px"}>
          {InnovationItemActivityRow.activityContent(activity)}
        </td>
      </tr>
    );
  }
}

export default InnovationItemActivityRow;
