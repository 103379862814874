import {Field, Formik, FormikActions, FormikProps} from "formik";
import React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import * as Yup from "yup";
import {FormText} from "../components/form";
import AuthLayout from "../components/Layout/AuthLayout";
import {addErrorNotification, addSuccessNotification} from "../components/Notification";
import {COMMON_LOGIN} from "../components/routes";
import axiosInstance from "../instance/axios";
import mainLogo from "../markup/images/main-logo.png";

interface IForgotPasswordFormValuesInterface {
  email: string;
}

const initialValues = {
  email: "",
};

export const forgotPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email()
    .required("Required"),
});

class ForgotPassword extends React.Component<RouteComponentProps<{ token: string }>> {
  public render(): React.ReactNode {
    return (
      <AuthLayout>
        <header className="block-header">
          <div className="block-logo">
            <img src={mainLogo} alt="Duda Farms"/>
          </div>
          <h1 className="block-title h3">
            Forgot Password
          </h1>
        </header>
        <div className="block-body">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values: IForgotPasswordFormValuesInterface, actions: FormikActions<IForgotPasswordFormValuesInterface>) => {
              await this.onSubmit(values, actions);
            }}
            validationSchema={forgotPasswordFormValidationSchema}
            render={(props: FormikProps<IForgotPasswordFormValuesInterface>) => (
              <form onSubmit={props.handleSubmit}>
                <div className="form-group">
                  <Field
                    name="email"
                    label="Email:"
                    placeholder="Email"
                    component={FormText}
                  />
                  <div className="">
                    <Link to={COMMON_LOGIN}>
                      Remember your password?
                    </Link>
                  </div>
                </div>
                <button className="btn btn-primary btn-lg btn-block" type="submit">
                  Get instructions
                </button>
              </form>
            )}
          />
        </div>
      </AuthLayout>
    );
  }

  private onSubmit = async (
    values: IForgotPasswordFormValuesInterface,
    actions: FormikActions<IForgotPasswordFormValuesInterface>,
  ) => {
    await axiosInstance().put(`/auth/forgot-password`, {
      email: values.email,
    })
      .then(() => {
        addSuccessNotification("Check your email");
        const {history} = this.props;
        history.push(COMMON_LOGIN);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          actions.setErrors(error.response.data);
        } else {
          addErrorNotification("Something gets wrong");
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }
}

export default ForgotPassword;
