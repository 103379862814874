import {
  ADMIN_DEPARTMENT_UPDATE_DEINIT,
  ADMIN_DEPARTMENT_UPDATE_ERROR,
  ADMIN_DEPARTMENT_UPDATE_INIT,
  ADMIN_DEPARTMENT_UPDATE_START,
  ADMIN_DEPARTMENT_UPDATE_SUCCESS,
  IAdminDepartmentUpdateActionTypes,
  IAdminDepartmentUpdateState,
} from "./type";

const initialState: IAdminDepartmentUpdateState = {
  department: undefined,
  isLoading: false,
};

export const adminDepartmentUpdateReducer = (
  state = initialState,
  action: IAdminDepartmentUpdateActionTypes,
): IAdminDepartmentUpdateState => {
  switch (action.type) {
    case ADMIN_DEPARTMENT_UPDATE_INIT: {
      return {
        ...state,
        department: action.department,
      };
    }
    case ADMIN_DEPARTMENT_UPDATE_DEINIT: {
      return {
        ...state,
        department: undefined,
      };
    }
    case ADMIN_DEPARTMENT_UPDATE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADMIN_DEPARTMENT_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ADMIN_DEPARTMENT_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
