import { InnovationStatusKey } from "../../interface/entity/IInnovationEntity";
import { IUserEntity } from "../../interface/entity/IUserEntity";
import {
  ISystemActionTypes,
  SYSTEM_DELETE_AUTH_TOKEN,
  SYSTEM_DELETE_AUTH_TOKEN_AND_USER,
  SYSTEM_DELETE_USER,
  SYSTEM_SET_AUTH_TOKEN,
  SYSTEM_SET_AUTH_TOKEN_AND_USER,
  SYSTEM_SET_INNOVATION_STATS,
  SYSTEM_SET_USER,
} from "./type";

export const setInnovationStats = (
  innovationStats: {
    status: InnovationStatusKey;
    statusValue: string;
    statusLabel: string;
    amount: number;
  }[]
): ISystemActionTypes => ({
  innovationStats,
  type: SYSTEM_SET_INNOVATION_STATS,
});

export const setAuthToken = (authToken: string): ISystemActionTypes => ({
  authToken,
  type: SYSTEM_SET_AUTH_TOKEN,
});

export const setUser = (user: IUserEntity): ISystemActionTypes => ({
  type: SYSTEM_SET_USER,
  user,
});

export const setAuthTokenAndUser = (
  authToken: string,
  user: IUserEntity
): ISystemActionTypes => ({
  authToken,
  type: SYSTEM_SET_AUTH_TOKEN_AND_USER,
  user,
});

export const deleteAuthToken = (): ISystemActionTypes => ({
  type: SYSTEM_DELETE_AUTH_TOKEN,
});

export const deleteUser = (): ISystemActionTypes => ({
  type: SYSTEM_DELETE_USER,
});

export const deleteAuthTokenAndUser = (): ISystemActionTypes => ({
  type: SYSTEM_DELETE_AUTH_TOKEN_AND_USER,
});
