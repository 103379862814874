import {IItemResource} from "../rest/IItemResource";
import { InnovationDepartmentStatusKey } from "./IInnovationDepartmentEntity";
import {IInnovationEntity, InnovationStageKey, InnovationStatusKey} from "./IInnovationEntity";
import {IUserEntity} from "./IUserEntity";

export enum ActivityType {
  ResolutionStatusWasChanged,
  CommentWasAdded = 1,
  CommentWasUpdated = 2,
  InnovationWasAdvanced = 3,
  InnovationStatusWasChanged = 4,
  TimelinesWasChanged = 5,
  SubmissionWasCreated = 6,
  SubmissionWasApproved = 7,
  SubmissionWasRejected = 8,
  SubmissionWasReSubmitted = 9,
}

export const activityTypeKeyToValue: { [key in ActivityType]: string } = {
  [ActivityType.ResolutionStatusWasChanged]: "ResolutionStatusWasChanged",
  [ActivityType.CommentWasAdded]: "CommentWasAdded",
  [ActivityType.CommentWasUpdated]: "CommentWasUpdated",
  [ActivityType.InnovationWasAdvanced]: "InnovationWasAdvanced",
  [ActivityType.InnovationStatusWasChanged]: "InnovationStatusWasChanged",
  [ActivityType.TimelinesWasChanged]: "TimelinesWasChanged",
  [ActivityType.SubmissionWasCreated]: "SubmissionWasCreated",
  [ActivityType.SubmissionWasApproved]: "SubmissionWasApproved",
  [ActivityType.SubmissionWasRejected]: "SubmissionWasRejected",
  [ActivityType.SubmissionWasReSubmitted]: "SubmissionWasReSubmitted",
};

export const activityTypeKeyToLabel: { [key in ActivityType]: string } = {
  [ActivityType.ResolutionStatusWasChanged]: "Resolution Status Was Changed",
  [ActivityType.CommentWasAdded]: "Comment Was Added",
  [ActivityType.CommentWasUpdated]: "Comment Was Updated",
  [ActivityType.InnovationWasAdvanced]: "Innovation Was Advanced",
  [ActivityType.InnovationStatusWasChanged]: "Innovation Status Was Changed",
  [ActivityType.TimelinesWasChanged]: "Timelines Was Changed",
  [ActivityType.SubmissionWasCreated]: "Submission Was Created",
  [ActivityType.SubmissionWasApproved]: "Submission Was Approved",
  [ActivityType.SubmissionWasRejected]: "Submission Was Rejected",
  [ActivityType.SubmissionWasReSubmitted]: "Submission Was ReSubmitted",
};

export interface IActivityAttachment {
  url: string;
  fileName: string;
}

export interface IResolutionStatusWasChanged {
  newResolutionStatus: InnovationDepartmentStatusKey;
  oldResolutionStatus: InnovationDepartmentStatusKey;
  comment: string;
  attachments?: IActivityAttachment[];
}

export interface ICommentWasAdded {
  comment: string;
  usersToNotify?: string[];
  attachments?: IActivityAttachment[];
}

export interface ICommentWasUpdated {
  newComment?: string;
  oldComment?: string;
  usersToNotify?: string[];
  attachments?: IActivityAttachment[];
  removedAttachments?: IActivityAttachment[];
}

export interface IInnovationWasAdvanced {
  oldStage: InnovationStageKey;
  newStage: InnovationStageKey;
}

export interface IInnovationStatusWasChanged {
  oldInnovationStatus: InnovationStatusKey;
  newInnovationStatus: InnovationStatusKey;
}

export interface ITimelinesWasChanged {
  oldDate: string;
  newDate: string;
  dateType: string;
}

// tslint:disable-next-line:no-empty-interface
export interface ISubmissionWasCreated {}

export interface ISubmissionWasApproved {
  message: string;
}

export interface ISubmissionWasRejected {
  message: string;
}

export interface ISubmissionWasReSubmitted {
  message: string;
}

export type ActivityData = IResolutionStatusWasChanged |
  ICommentWasAdded |
  ICommentWasUpdated |
  IInnovationWasAdvanced |
  IInnovationStatusWasChanged |
  ITimelinesWasChanged |
  ISubmissionWasCreated |
  ISubmissionWasApproved |
  ISubmissionWasRejected |
  ISubmissionWasReSubmitted;

export interface IActivityEntity extends IItemResource {
  innovation: IInnovationEntity;
  data: ActivityData;
  stage: InnovationStageKey;
  user: IUserEntity;
  type: ActivityType;
}
