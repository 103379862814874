import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";
import {ICollectionResource} from "../../../../interface/rest/ICollectionResource";

export interface IAdminDepartmentState {
  collectionResource: ICollectionResource<IDepartmentEntity>;
  isRemoving: boolean;
}

export const ADMIN_DEPARTMENT_COLLECTION_INIT = "ADMIN_DEPARTMENT_COLLECTION_INIT";

export const ADMIN_DEPARTMENT_COLLECTION_FETCH_START = "ADMIN_DEPARTMENT_COLLECTION_FETCH_START";
export const ADMIN_DEPARTMENT_COLLECTION_FETCH_SUCCESS = "ADMIN_DEPARTMENT_COLLECTION_FETCH_SUCCESS";
export const ADMIN_DEPARTMENT_COLLECTION_FETCH_ERROR = "ADMIN_DEPARTMENT_COLLECTION_FETCH_ERROR";

export const ADMIN_DEPARTMENT_COLLECTION_REMOVE_START = "ADMIN_DEPARTMENT_COLLECTION_REMOVE_START";
export const ADMIN_DEPARTMENT_COLLECTION_REMOVE_SUCCESS = "ADMIN_DEPARTMENT_COLLECTION_REMOVE_SUCCESS";
export const ADMIN_DEPARTMENT_COLLECTION_REMOVE_ERROR = "ADMIN_DEPARTMENT_COLLECTION_REMOVE_ERROR";

interface IAdminDepartmentCollectionRemoveStartAction {
  type: typeof ADMIN_DEPARTMENT_COLLECTION_REMOVE_START;
}

interface IAdminDepartmentCollectionRemoveSuccessAction {
  type: typeof ADMIN_DEPARTMENT_COLLECTION_REMOVE_SUCCESS;
}

interface IAdminDepartmentCollectionRemoveErrorAction {
  type: typeof ADMIN_DEPARTMENT_COLLECTION_REMOVE_ERROR;
}

interface IAdminDepartmentCollectionInitAction {
  type: typeof ADMIN_DEPARTMENT_COLLECTION_INIT;
}

interface IAdminDepartmentCollectionFetchStartAction {
  type: typeof ADMIN_DEPARTMENT_COLLECTION_FETCH_START;
}

interface IAdminDepartmentCollectionFetchSuccessAction {
  type: typeof ADMIN_DEPARTMENT_COLLECTION_FETCH_SUCCESS;
  collectionResource: ICollectionResource<IDepartmentEntity>;
}

interface IAdminDepartmentCollectionFetchErrorAction {
  type: typeof ADMIN_DEPARTMENT_COLLECTION_FETCH_ERROR;
}

export type IAdminDepartmentActionTypes =
  | IAdminDepartmentCollectionRemoveStartAction
  | IAdminDepartmentCollectionRemoveSuccessAction
  | IAdminDepartmentCollectionRemoveErrorAction
  | IAdminDepartmentCollectionInitAction
  | IAdminDepartmentCollectionFetchStartAction
  | IAdminDepartmentCollectionFetchSuccessAction
  | IAdminDepartmentCollectionFetchErrorAction
  ;
