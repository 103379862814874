import {
  ADMIN_USER_CREATE_FETCH_DEPARTMENTS_ERROR,
  ADMIN_USER_CREATE_FETCH_DEPARTMENTS_START, ADMIN_USER_CREATE_FETCH_DEPARTMENTS_SUCCESS,
  IAdminUserCreateActionTypes,
  IAdminUserCreateState,
} from "./type";

const initialState: IAdminUserCreateState = {
  department: {
    items: [],
    isLoading: false,
  },
};

export const adminUserCreateReducer = (
  state = initialState,
  action: IAdminUserCreateActionTypes,
): IAdminUserCreateState => {
  switch (action.type) {
    case ADMIN_USER_CREATE_FETCH_DEPARTMENTS_START: {
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: true,
        },
      };
    }
    case ADMIN_USER_CREATE_FETCH_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: false,
          items: action.departments,
        },
      };
    }
    case ADMIN_USER_CREATE_FETCH_DEPARTMENTS_ERROR: {
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
