import React from "react";

interface IIco {
  name: string;
  className?: string;
}

const Ico = ({ name, className}: IIco) => (
  <i className={`ico ico-${name} ${className}`} />
);

export default Ico;
