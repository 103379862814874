import {Field, Form, Formik, FormikProps} from "formik";
import {DateTime} from "luxon";
import React, {Fragment} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {connect} from "react-redux";
import {match, Prompt, RouteChildrenProps} from "react-router";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import {v4} from "uuid";
import {FormCheckbox, FormText, FormTextArea} from "../../../components/form";
import AnotherFormDatepicker from "../../../components/form/AnotherFormDatepicker";
import AnotherFormSelect from "../../../components/form/AnotherFormSelect";
import ImmediatelyUploadMultipleField from "../../../components/form/upload/ImmediatelyUploadMultipleField";
import GateViewAttachmentsSection from "../../../components/GateViewAttachmentsSection";
import {Col, Row} from "../../../components/Grid";
import WrapperLoader from "../../../components/loader/WrapperLoader";
import axiosInstance from "../../../instance/axios";
import {IAttachmentEntity} from "../../../interface/entity/IAttachmentEntity";
import {
  IInformationGateEntity,
  IInformationGateMilestone,
  IMilestoneComment,
} from "../../../interface/entity/IInformationGateEntity";
import {
  IInnovationEntity,
  InnovationStageKey,
  innovationStageKeyToLabel,
  innovationStageKeyToValue,
  innovationStageValueToKey,
  InnovationStatusKey,
} from "../../../interface/entity/IInnovationEntity";
import {IUserEntity, UserRole, userRoleKeyToLabel} from "../../../interface/entity/IUserEntity";
import {ICollectionResource} from "../../../interface/rest/ICollectionResource";
import {AppState} from "../../../store";
import {
  innovationItemFetchError,
  innovationItemFetchStart,
  innovationItemFetchSuccess,
} from "../../../store/innovationItem/common/action";
import {IInnovationItemCommonState} from "../../../store/innovationItem/common/type";
import {
  informationGateFetchStart,
  informationGateFetchSuccess,
  innovationMilestoneAdminsFetchStart,
  innovationMilestoneAdminsFetchSuccess,
  innovationMilestoneChangeView,
  innovationMilestoneManagersFetchStart,
  innovationMilestoneManagersFetchSuccess,
} from "../../../store/innovationItem/milestone/action";
import {IInnovationMilestone, MilestoneView,} from "../../../store/innovationItem/milestone/type";
import {innovationItemStageCommonSetStage} from "../../../store/innovationItem/stage/common/action";
import {IInnovationItemStageCommonState} from "../../../store/innovationItem/stage/common/type";
import InnovationItemMenuComponent from "../InnovationItemStage/components/InnovationItemMenuComponent";

interface IProps extends RouteChildrenProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemCommonState: IInnovationItemCommonState;
  onInnovationItemStageCommonSetStage: (stage: InnovationStageKey) => void;
  match: match<{ stage: string }>;
  viewMode: MilestoneView;
  informationGate: IInnovationMilestone;

  onInnovationItemFetchStart: () => void;
  onInnovationItemFetchSuccess: (innovationEntity: IInnovationEntity) => void;
  onInnovationItemFetchError: (error: unknown) => void;

  onInformationGateFetchStart: () => void;
  onInformationGateFetchSuccess: (gate: IInformationGateEntity) => void;

  onInnovationMilestoneChangeView: (view: MilestoneView) => void;
  onInnovationMilestoneInit: (milestones: IInformationGateMilestone[]) => void;
  onInnovationMilestoneReorder: (
    milestones: IInformationGateMilestone[]
  ) => void;

  onInnovationMilestoneManagersFetchStart: () => void;
  onInnovationMilestoneManagersFetchSuccess: (managers: IUserEntity[]) => void;
  onInnovationMilestoneAdminsFetchStart: () => void;
  onInnovationMilestoneAdminsFetchSuccess: (managers: IUserEntity[]) => void;
}

interface IState {
  milestones: IInformationGateMilestone[];
  endDate: any;
  startDate: any;
  isBlocking: boolean;
}

const initialValue = {
  id: "",
  startDate: null,
  endDate: null,
  stage: 0,
  createdAt: "",
  milestone: { milestones: [] },
};

class InnovationItemMilestone extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
      milestones: [],
      startDate: null,
      endDate: null,
      isBlocking: false,
    };
    this.props.onInnovationItemStageCommonSetStage(
      innovationStageValueToKey[this.props.match.params.stage]
    );
  }

  public async componentDidMount() {
    await Promise.all([this.fetchGate(), this.fetchManagers(), this.fetchAdmins()]);
  }

  public async componentDidUpdate(prevProps: IProps) {
    if (this.props.match.params.stage !== prevProps.match.params.stage) {
      await this.props.onInnovationItemStageCommonSetStage(
        innovationStageValueToKey[this.props.match.params.stage]
      );
      await Promise.all([this.fetchGate(), this.fetchManagers(), this.fetchAdmins()]);
      this.setState({ isBlocking: false });
      this.props.onInnovationMilestoneChangeView(MilestoneView.View);
    }
  }

  public render(): React.ReactNode {
    const {
      innovationItemCommonState: { innovationEntity },
      innovationItemStageCommonState: { stage },
      viewMode,
      informationGate,
    } = this.props;

    return (
      <WrapperLoader isLoading={informationGate.isLoading}>
        <div className="mb-6">
          <Link
            to={`/innovation/${innovationEntity && innovationEntity.id}/${
              innovationStageKeyToValue[stage]
            }`}
          >
            &lt; Back to {innovationEntity && innovationEntity.name}
          </Link>
        </div>
        <InnovationItemMenuComponent additionalPath={["milestone"]} />
        <h2 className="text-regular">
          {innovationStageKeyToLabel[stage]} Stage
        </h2>
        <hr className="mt-2 mb-4" />
        {viewMode === MilestoneView.View
          ? this.renderView()
          : this.renderEdit()}
      </WrapperLoader>
    );
  }

  private renderView = () => {
    const {
      onInnovationMilestoneChangeView,
      informationGate,
      innovationItemCommonState: { innovationEntity },
    } = this.props;

    return (
      <Fragment>
        <div className="clearfix mb-4">
          <div className="pull-left">
            <div className="text-medium text-primary-light">
              To edit stage details click ‘Edit Details’ button.
            </div>
          </div>
          <div className="pull-right">
            <button
              className="btn btn-primary btn-shade btn-width"
              type="button"
              onClick={() =>
                onInnovationMilestoneChangeView(MilestoneView.Edit)
              }
              disabled={
                innovationEntity &&
                innovationEntity.status !== InnovationStatusKey.Active
              }
            >
              Edit Details
            </button>
          </div>
        </div>
        <div className="text-medium mb-4">
          {
            innovationStageKeyToLabel[
              (informationGate.gate && informationGate.gate.stage) || 0
            ]
          }
          &nbsp;
          <span className="text-primary-light">
            Stage Deadline:{" "}
            {(informationGate.gate && informationGate.gate.endDate) ||
              "Not Added"}
          </span>
        </div>
        <ul className="milestone-list">
          <li>
            <Row className="middle">
              <Col val="8">
                <div className="text-medium">
                  Start Date:{" "}
                  {(informationGate.gate && informationGate.gate.startDate) ||
                    "Not Added"}
                </div>
              </Col>
              <Col val="4">
                <div className="text-medium text-right">Check When Done:</div>
              </Col>
            </Row>
          </li>
          {this.state.milestones.map((milestone) => {
            const {comments} = milestone;
            return <li key={`${milestone.id}`}>
              <Row className="middle">
                <Col val="6">
                  <span className="text-primary-light">{milestone.name}</span>
                  &nbsp;
                  <span className="text-success ml-2">
                    {milestone.isCompleted && (
                        <Fragment>
                          <i className="ico ico-check ico-xs" />
                          &nbsp;Done
                        </Fragment>
                    )}
                  </span>
                  {milestone.endDate && (
                      <div className="mt-2">{milestone.endDate}</div>
                  )}
                </Col>
                <Col val="5">
                  {milestone.manager && `Manager: ${milestone.manager.label}`}
                </Col>
                <Col val="1">
                  <Formik
                      initialValues={milestone}
                      enableReinitialize={true}
                      onSubmit={() => null}
                      render={({
                                 handleSubmit,
                                 handleChange,
                               }: FormikProps<IInformationGateMilestone>) => {
                        return (
                            <Form>
                              <div className="text-right">
                                <Field
                                    name={`isCompleted`}
                                    component={FormCheckbox}
                                    onChange={async (e: any) => {
                                      handleChange(e);
                                      await this.saveMilestone(
                                          {
                                            ...milestone,
                                            isCompleted: e.target.checked,
                                          },
                                          milestone.id
                                      );
                                    }}
                                    disabled={
                                        innovationEntity &&
                                        innovationEntity.status !==
                                        InnovationStatusKey.Active
                                    }
                                />
                              </div>
                            </Form>
                        );
                      }}
                  />
                </Col>
              </Row>
              <div style={{ paddingTop: "10px" }} />
              <Row className="middle">
                <GateViewAttachmentsSection
                    title={"Attachments:"}
                    attachments={milestone.attachments || []}
                />
              </Row>
              <h4 className={"text-semibold"}>
                Milestone Comments ({(comments || []).length})
              </h4>
              <div>
                {(comments || []).length === 0 && <div>No comments yet...</div>}
                <div style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
                  {(comments || []).map(comment => <Row className="middle">
                    <Col val="6">
                      <div>
                        <div>
                          {DateTime.fromJSDate(new Date(comment.date)).toFormat("MM/dd/y H:m")}
                        </div>
                        <div>
                          {comment.message || 'No message'}
                        </div>
                      </div>
                    </Col>
                    {comment.subscribers ? <Col val="6">
                      Notified: {comment.subscribers.map(subscriber => subscriber.label).join(', ')}
                    </Col> : null}
                  </Row>)}
                </div>
              </div>
            </li>;
          })}
          <li>
            <Row className="middle">
              <Col val="8">
                <div className="text-medium">
                  End Date:{" "}
                  {(informationGate.gate && informationGate.gate.endDate) ||
                    "Not Added"}
                </div>
              </Col>
            </Row>
          </li>
        </ul>
      </Fragment>
    );
  };

  private renderEdit = () => {
    const { informationGate } = this.props;

    return (
      <Fragment>
        <Prompt
          when={this.state.isBlocking}
          message="You have unsaved changes. Your data will be lost if you leave this page. Continue?"
        />
        <Formik
          initialValues={{
            ...(informationGate.gate || initialValue),
            milestone: { milestones: this.state.milestones },
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          }}
          enableReinitialize={true}
          onSubmit={this.onSubmit}
          render={({
            handleChange,
            values,
          }: FormikProps<IInformationGateEntity>) => {
            return (
              <Form
                onChange={() => {
                  this.setState({ isBlocking: true });
                }}
              >
                <div className="clearfix mb-4">
                  <div className="pull-left">
                    <div className="text-medium text-primary-light">
                      Please fill in{" "}
                      {
                        innovationStageKeyToLabel[
                          (informationGate.gate &&
                            informationGate.gate.stage) ||
                            0
                        ]
                      }
                      &nbsp; Stage details and click ‘Save Innovation’ button.
                    </div>
                  </div>
                  <div className="pull-right">
                    <button
                      className="btn btn-primary btn-shade btn-width"
                      type="submit"
                    >
                      Save Details
                    </button>
                  </div>
                </div>
                <div className="text-medium mb-4">
                  {
                    innovationStageKeyToLabel[
                      (informationGate.gate && informationGate.gate.stage) || 0
                    ]
                  }
                  &nbsp;
                  <span className="text-primary-light">Stage Deadline:</span>
                </div>
                <Row className="mb-8">
                  <Col val="2">
                    <Field
                      name="startDate"
                      placeholder="Select Date"
                      label="Start Date"
                      component={AnotherFormDatepicker}
                      selectsStart={true}
                      isClearable={true}
                      onChange={(v: any) => {
                        this.setState({ startDate: v, isBlocking: true });
                      }}
                    />
                  </Col>
                  <Col val="2">
                    <Field
                      name="endDate"
                      placeholder="Select Date"
                      label="End Date"
                      component={AnotherFormDatepicker}
                      selectsStart={true}
                      isClearable={true}
                      onChange={(v: any) => {
                        this.setState({ endDate: v, isBlocking: true });
                      }}
                    />
                  </Col>
                </Row>
                <div className="text-medium mb-4">
                  {
                    innovationStageKeyToLabel[
                      (informationGate.gate && informationGate.gate.stage) || 0
                    ]
                  }
                  &nbsp;
                  <span className="text-primary-light">Stage Milestones:</span>
                </div>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div
                        className="table-draggable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {this.state.milestones.map((milestone, index) => {
                          const onCreateComment = () => {
                            const emptyComment: IMilestoneComment = {
                              id: v4(),
                              date: new Date().toString(),
                              message: '',
                              subscribers: [],
                            };

                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                milestones: prevState.milestones.map(currentMilestone => currentMilestone.id === milestone.id ? {
                                  ...currentMilestone,
                                  comments: [
                                      ...(currentMilestone.comments || []),
                                    emptyComment,
                                  ],
                                } : currentMilestone),
                              };
                            });
                          };
                          const onDeleteComment = (id: string) => {
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                milestones: prevState.milestones.map(currentMilestone => currentMilestone.id === milestone.id ? {
                                  ...currentMilestone,
                                  comments: (currentMilestone.comments || []).filter(comment => comment.id !== id),
                                } : currentMilestone),
                              };
                            });
                          };
                          return <Draggable
                              key={`edit-${milestone.id}`}
                              draggableId={milestone.id}
                              index={index}
                          >
                            {(p) =>
                                this.list(p, milestone, index, handleChange, onCreateComment, onDeleteComment)
                            }
                          </Draggable>;
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <button
                  className="btn btn-primary btn-shade btn-width"
                  type="button"
                  onClick={() => {
                    this.addMilestone(values.milestone.milestones);
                  }}
                >
                  <i className="ico ico-plus " />
                  &nbsp; Add Milestone
                </button>
              </Form>
            );
          }}
        />
      </Fragment>
    );
  };

  private list = (
    provided: any,
    item: IInformationGateMilestone,
    index: number,
    handleChange: any,
    onCreateComment: () => void,
    onDeleteComment: (id: string) => void,
  ) => (
    <div
      className="table-draggable-row"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className="table-draggable-control">
        <span />
        <span />
        <span />
      </div>
      <div style={{flexGrow: 1, paddingRight: '1em', marginRight: '1em', borderRight: '1px solid #ccc'}}>
        <div className="d-flex flex-fill">
          <div className="w-8pc pr-2">
            <Field
                name={`milestone.milestones[${index}].name`}
                label="Milestone Name:"
                placeholder={"Fill in name here…"}
                onChange={(e: any) => {
                  handleChange(e);
                  this.setState((prevState) => {
                    return {
                      milestones: prevState.milestones.map((milestone, i) =>
                          i === index
                              ? { ...milestone, name: e.target.value }
                              : milestone
                      ),
                      isBlocking: true,
                    };
                  });
                }}
                component={FormText}
            />
            <div style={{ paddingTop: "10px" }} />
            <div className="form-group">
              <Field
                  name={`milestone.milestones[${index}].attachments`}
                  component={ImmediatelyUploadMultipleField}
                  emptyMessage="No files Attached Yet..."
                  onChangeValue={(newValue: IAttachmentEntity[]) => {
                    this.setState((prevState) => {
                      return {
                        milestones: prevState.milestones.map((milestone, i) =>
                            i === index
                                ? {
                                  ...milestone,
                                  attachments: newValue,
                                }
                                : milestone
                        ),
                      };
                    });
                  }}
              />
            </div>
          </div>
          <div className="w-4pc pr-2">
            <Field
                name={`milestone.milestones[${index}].endDate`}
                placeholder="Select Date"
                label="End Date:"
                component={AnotherFormDatepicker}
                selectsStart={true}
                isClearable={true}
                onChange={(v: any) => {
                  handleChange(v);
                  this.setState((prevState) => {
                    return {
                      milestones: prevState.milestones.map((milestone, i) =>
                          i === index ? { ...milestone, endDate: v } : milestone
                      ),
                      isBlocking: true,
                    };
                  });
                }}
            />
          </div>
          <div className="w-4pc pr-2">
            <Field
                component={AnotherFormSelect}
                name={`milestone.milestones[${index}].manager`}
                selectProps={{
                  options: this.props.informationGate.managers.map(
                      (manager: IUserEntity) => {
                        return { value: manager.id, label: manager.fullName };
                      }
                  ),
                  placeholder: "Select Manager",
                  isClearable: true,
                }}
                customProps={{
                  label: "Select Manager",
                }}
                onChange={(e: any) => {
                  handleChange(e);
                  this.setState((prevState) => {
                    return {
                      milestones: prevState.milestones.map((milestone, i) =>
                          i === index ? { ...milestone, manager: e } : milestone
                      ),
                    };
                  });
                }}
            />
          </div>
          <div className="w-4pc pl-4 pt-6">
            <Field
                name={`milestone.milestones[${index}].isCompleted`}
                label="Check When Done"
                component={FormCheckbox}
                onChange={(e: any) => {
                  e.persist();
                  handleChange(e);
                  this.setState((prevState) => {
                    return {
                      milestones: prevState.milestones.map((milestone, i) =>
                          i === index
                              ? { ...milestone, isCompleted: e.target.checked }
                              : milestone
                      ),
                    };
                  });
                }}
            />
          </div>
        </div>
        <div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
          <h4 className={"text-semibold"} style={{flexGrow: 1}}>
            Milestone Comments
          </h4>
            <button className={"btn btn-primary btn-shade"} style={{marginLeft: '1rem'}} type={'button'} onClick={() => {
              onCreateComment();
            }}>
              Add new comment
            </button>
          </div>
          {(item.comments || []).length === 0 && <div>No comments yet...</div>}
          {(item.comments || []).map((currentComment, iCurrentComment) => <div>
            <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
              <div style={{flexGrow: 1}}>
                <Field
                    name={`milestone.milestones[${index}].comments[${iCurrentComment}].message`}
                    label="Comment:"
                    placeholder={"Fill in your comment here…"}
                    onChange={(e: any) => {
                      handleChange(e);
                      console.log(e);
                      this.setState((prevState) => {
                        return {
                          milestones: prevState.milestones.map((milestone, i) =>
                              i === index
                                  ? {
                                    ...milestone,
                                    comments: (milestone.comments || []).map(comment => comment.id === currentComment.id ? {
                                      ...comment,
                                      message: e.target.value,
                                    } : comment)
                                  }
                                  : milestone
                          ),
                          isBlocking: true,
                        };
                      });
                    }}
                    component={FormTextArea}
                />
                <br/>
                <div>
                  <Field
                      component={AnotherFormSelect}
                      customProps={{
                        label: "Select Recipient(s) for your comment:",
                      }}
                      name={`milestone.milestones[${index}].comments[${iCurrentComment}].subscribers`}
                      selectProps={{
                        isMulti: true,
                        defaultValue: [],
                        options: [
                            ...this.props.informationGate.managers.map(
                                (user: IUserEntity) => {
                                  return { value: user.id, label: [
                                      user.fullName,
                                        ...(user.role === UserRole.Admin ? [  ' ',
                                          '(', userRoleKeyToLabel[user.role], ')'] : [] )
                                    ].join('') };
                                }
                            ),
                            ...this.props.informationGate.admins.map(
                                (user: IUserEntity) => {
                                  return { value: user.id, label: [
                                      user.fullName,
                                      ...(user.role === UserRole.Admin ? [  ' ',
                                        '(', userRoleKeyToLabel[user.role], ')'] : [] )
                                    ].join('') };
                                }
                            )
                        ].sort((a,b) => a.label > b.label ? 1 : -1),
                        placeholder: "Select recipient(s) for your comment...",
                        isClearable: true,
                      }}
                      onChange={(e: any) => {
                        handleChange(e);
                        this.setState((prevState) => {
                          console.log({prevState, e});
                          return {
                            milestones: prevState.milestones.map((milestone, i) =>
                                i === index ? { ...milestone, comments: (milestone.comments || []).map((comment, commentI) => iCurrentComment === commentI ? {
                                    ...comment,
                                    subscribers: e
                                  } : comment) } : milestone
                            ),
                          };
                        });
                      }}
                  />
                </div>
              </div>
              <button
                  className="btn btn-ghost btn-sq mr-3 mt-6"
                  type={"button"}
                  onClick={() => {
                    onDeleteComment(currentComment.id);
                  }}
              >
                <i className="ico ico-delete" />
              </button>
            </div>
            <div style={{border: '1px solid #ccc', margin: '2rem 0', borderBottom: 'none'}}/>
          </div>)}
        </div>
      </div>
      <button
        className="btn btn-ghost btn-sq mr-3 mt-6"
        type="button"
        onClick={() => this.deleteMilestone(index)}
      >
        <i className="ico ico-delete" />
      </button>
    </div>
  );

  private onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.milestones,
      result.source.index,
      result.destination.index
    );
    this.setState({
      milestones: items,
      isBlocking: true,
    });
  };

  private reorder = (
    list: IInformationGateMilestone[],
    startIndex: number,
    endIndex: number
  ): IInformationGateMilestone[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  private saveMilestone = async (
    values: IInformationGateMilestone,
    milestoneId: string
  ) => {
    const {
      innovationItemCommonState: { innovationEntity },
      innovationItemStageCommonState: { stage },
    } = this.props;

    const data = {
      ...values,
      manager:
        values.manager && values.manager.value ? values.manager.value : null,
    };

    await axiosInstance()
      .put(
        "/innovation/" +
          (innovationEntity && innovationEntity.id) +
          "/milestone/" +
          stage +
          "/" +
          milestoneId,
        data
      )
      .then(async (response: { data: IInformationGateEntity }) => {
        await this.fetchInnovation();
        await Promise.all([this.fetchGate(), this.fetchManagers(), this.fetchAdmins()]);
      })
      .catch((error) => {
        // TODO handle this case
      });
  };

  private onSubmit = async (values: IInformationGateEntity) => {
    const {
      innovationItemCommonState: { innovationEntity },
      innovationItemStageCommonState: { stage },
      onInnovationMilestoneChangeView,
    } = this.props;
    await axiosInstance()
      .put(
        "/innovation/" +
          (innovationEntity && innovationEntity.id) +
          "/gate/" +
          stage +
          "/entity",
        {
          ...values,
          milestone: {
            milestones: values.milestone.milestones.map((milestone, index) => {
              return {
                ...milestone,
                endDate: milestone.endDate ? milestone.endDate : null,
                id: this.state.milestones[index].id,
                manager:
                  milestone.manager && milestone.manager.value
                    ? milestone.manager.value
                    : null,
              };
            }),
          },
        }
      )
      .then(async (response: { data: IInformationGateEntity }) => {
        onInnovationMilestoneChangeView(MilestoneView.View);
        await this.fetchInnovation();
        await Promise.all([this.fetchGate(), this.fetchManagers(), this.fetchAdmins()]);
        this.setState({ isBlocking: false });
      })
      .catch((error) => {
        // TODO handle this case
      });
  };

  private fetchInnovation = async () => {
    const {
      innovationItemCommonState: { innovationEntity },
    } = this.props;
    this.props.onInnovationItemFetchStart();
    await axiosInstance()
      .get("/innovation/" + (innovationEntity && innovationEntity.id))
      .then((response: { data: IInnovationEntity }) => {
        this.props.onInnovationItemFetchSuccess(response.data);
      })
      .catch((error) => {
        // TODO log error
        this.props.onInnovationItemFetchError(error);
      });
  };

  private fetchGate = async () => {
    const {
      innovationItemCommonState: { innovationEntity },
      match: {
        params: { stage },
      },
    } = this.props;
    this.props.onInformationGateFetchStart();
    await axiosInstance()
      .get(
        "/innovation/" +
          (innovationEntity && innovationEntity.id) +
          "/gate/" +
          innovationStageValueToKey[stage]
      )
      .then(async (response: { data: IInformationGateEntity }) => {
        await this.props.onInformationGateFetchSuccess({
          ...response.data,
          startDate: response.data.startDate
            ? DateTime.fromISO(response.data.startDate).toFormat("MM/dd/y")
            : undefined,
          endDate: response.data.endDate
            ? DateTime.fromISO(response.data.endDate).toFormat("MM/dd/y")
            : undefined,
        });
        const { informationGate } = this.props;

        this.setState({
          milestones:
            (informationGate.gate &&
              informationGate.gate.milestone &&
              informationGate.gate.milestone.milestones) ||
            [],
          startDate: informationGate.gate && informationGate.gate.startDate,
          endDate: informationGate.gate && informationGate.gate.endDate,
        });
      })
      .catch((error) => {
        // TODO log error
        this.props.onInnovationItemFetchError(error);
      });
  };

  private fetchManagers = async () => {
    this.props.onInnovationMilestoneManagersFetchStart();
    await axiosInstance()
      .get("/user", {
        params: {
          role: UserRole.Manager,
          page: 1,
          perPage: 1000,
        },
      })
      .then((response: { data: ICollectionResource<IUserEntity> }) => {
        this.props.onInnovationMilestoneManagersFetchSuccess(
          response.data.items
        );
      })
      .catch((error) => {
        // this.props.onAdminUserCollectionFetchError(error);
      });
  };

  private fetchAdmins = async () => {
    this.props.onInnovationMilestoneAdminsFetchStart();
    await axiosInstance()
        .get("/user", {
          params: {
            role: UserRole.Admin,
            page: 1,
            perPage: 1000,
          },
        })
        .then((response: { data: ICollectionResource<IUserEntity> }) => {
          this.props.onInnovationMilestoneAdminsFetchSuccess(
              response.data.items
          );
        })
        .catch((error) => {
          // this.props.onAdminUserCollectionFetchError(error);
        });
  };

  private addMilestone = (currentMilestones: IInformationGateMilestone[]) => {
    const newMilestones = [
      ...currentMilestones,
      { id: v4(), name: "", attachments: [], comments: [] },
    ];
    this.setState({ milestones: newMilestones, isBlocking: true });
  };

  private deleteMilestone(index: number) {
    const result = Array.from(this.state.milestones);
    result.splice(index, 1);

    this.setState({
      milestones: result,
      isBlocking: true,
    });
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
  viewMode: state.innovationItem.milestone.view,
  informationGate: state.innovationItem.milestone,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemStageCommonSetStage: (stage: InnovationStageKey) => {
    dispatch(innovationItemStageCommonSetStage(stage));
  },
  onInnovationItemFetchError: (error: unknown) => {
    dispatch(innovationItemFetchError(error));
  },
  onInnovationItemFetchStart: () => {
    dispatch(innovationItemFetchStart());
  },
  onInnovationItemFetchSuccess: (innovationEntity: IInnovationEntity) => {
    dispatch(innovationItemFetchSuccess(innovationEntity));
  },
  onInformationGateFetchStart: () => {
    dispatch(informationGateFetchStart());
  },
  onInformationGateFetchSuccess: (gate: IInformationGateEntity) => {
    dispatch(informationGateFetchSuccess(gate));
  },
  onInnovationMilestoneChangeView: (view: MilestoneView) => {
    dispatch(innovationMilestoneChangeView(view));
  },
  onInnovationMilestoneManagersFetchStart: () => {
    dispatch(innovationMilestoneManagersFetchStart());
  },
  onInnovationMilestoneManagersFetchSuccess: (managers: IUserEntity[]) => {
    dispatch(innovationMilestoneManagersFetchSuccess(managers));
  },
  onInnovationMilestoneAdminsFetchStart: () => {
    dispatch(innovationMilestoneAdminsFetchStart());
  },
  onInnovationMilestoneAdminsFetchSuccess: (managers: IUserEntity[]) => {
    dispatch(innovationMilestoneAdminsFetchSuccess(managers));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnovationItemMilestone);
