import {Field, Formik, FormikProps} from "formik";
import React from "react";
import {RouteComponentProps} from "react-router";
import {Link, withRouter} from "react-router-dom";
import * as Yup from "yup";
import {FormText} from "../../components/form";
import FormTextArea from "../../components/form/FormTextArea";
import BaseLayout from "../../components/Layout/BaseLayout";
import {addErrorNotification, addSuccessNotification} from "../../components/Notification";
import {ADMIN_DEPARTMENT_LIST} from "../../components/routes";
import axiosInstance from "../../instance/axios";

interface IFormValues {
  name: string;
  description: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3)
    .required("Required"),
  description: Yup.string(),
});

class DepartmentAdd extends React.Component<RouteComponentProps> {
  public render(): React.ReactNode {
    const initialValues: IFormValues = {
      name: "",
      description: "",
    };

    return (
      <BaseLayout location={this.props.location}>
        <div className="mb-6">
          <Link to={ADMIN_DEPARTMENT_LIST}>&lt; Back to Departments List</Link>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={validationSchema}
          render={(props: FormikProps<IFormValues>) => (
            <form onSubmit={props.handleSubmit}>
              <div className="details-header-line mb-4">
                <div className="pull-left">
                  <h1 className="h2 m-0 pt-2">Add Department</h1>
                </div>
                <div className="pull-right">
                  <button disabled={!props.isValid} className="btn btn-primary btn-shade btn-width" type="submit">
                    Save Department
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-8">
                      <div className="form-group">
                        <Field
                          label="Department Name*:"
                          name="name"
                          placeholder="e.g. Innovations"
                          component={FormText}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <Field
                      label="Department Description:"
                      name="description"
                      placeholder="Please fill in Department Description…"
                      component={FormTextArea}
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        />
      </BaseLayout>
    );
  }

  private onSubmit = async (data: IFormValues) => {
    await axiosInstance().post("/department", {
      name: data.name,
      description: data.description,
    })
      .then(() => {
        const {history} = this.props;
        history.push(ADMIN_DEPARTMENT_LIST);
        addSuccessNotification("Successfully added!");
      })
      .catch(() => {
        addErrorNotification("Error on adding a department");
      });
  }
}

export default withRouter(DepartmentAdd);
