import {Field, Formik, FormikActions, FormikProps} from "formik";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import * as Yup from "yup";
import {FormText} from "../components/form";
import AuthLayout from "../components/Layout/AuthLayout";
import {addErrorNotification, addSuccessNotification} from "../components/Notification";
import {COMMON_LOGIN} from "../components/routes";
import axiosInstance from "../instance/axios";
import mainLogo from "../markup/images/main-logo.png";

interface ISetPasswordFormValuesInterface {
  password: string;
  repeatPassword: string;
}

const initialValues = {
  password: "",
  repeatPassword: "",
};

export const setPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6)
    .trim()
    .required("Required"),
  repeatPassword: Yup.string()
    .required("Required")
    .test("passwords", "Passwords must be the same", function(value) {
      return this.parent.password === value;
    }),
});

class RecoveryPassword extends React.Component<RouteComponentProps<{ token: string }>> {
  public render(): React.ReactNode {
    return (
      <AuthLayout>
        <header className="block-header">
          <div className="block-logo">
            <img src={mainLogo} alt="Duda Farms"/>
          </div>
          <h1 className="block-title h3">
            Set a new password
          </h1>
        </header>
        <div className="block-body">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values: ISetPasswordFormValuesInterface, actions: FormikActions<ISetPasswordFormValuesInterface>) => {
              await this.onSubmit(values, actions);
            }}
            validationSchema={setPasswordFormValidationSchema}
            render={(props: FormikProps<ISetPasswordFormValuesInterface>) => (
              <form onSubmit={props.handleSubmit}>
                <div className="form-group">
                  <Field
                    name="password"
                    label="Password:"
                    placeholder="Password"
                    type={"password"}
                    component={FormText}
                  />
                </div>
                <div className="form-group">
                  <Field
                    name="repeatPassword"
                    label="Repeat password:"
                    placeholder="Repeat password"
                    type={"password"}
                    component={FormText}
                  />
                </div>
                <button className="btn btn-primary btn-lg btn-block" type="submit">
                  Save a new password
                </button>
              </form>
            )}
          />
        </div>
      </AuthLayout>
    );
  }

  private onSubmit = async (
    values: ISetPasswordFormValuesInterface,
    actions: FormikActions<ISetPasswordFormValuesInterface>,
  ) => {
    await axiosInstance().put(`/auth/set-password`, {
      password: values.password,
      token: this.props.match.params.token,
    })
      .then(() => {
        addSuccessNotification("New password was saved");

        const {history} = this.props;
        history.push(COMMON_LOGIN);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          actions.setErrors(error.response.data);
        } else {
          addErrorNotification("Something gets wrong");
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }
}

export default withRouter(RecoveryPassword);
