import {Field, FormikProps} from "formik";
import React, {Fragment} from "react";
import FormCheckboxList from "../../../../../../components/form/FormCheckboxList";
import FormRadioCustomList from "../../../../../../components/form/FormRadioCustomList";
import FormText from "../../../../../../components/form/FormText";
import ImmediatelyUploadMultipleField from "../../../../../../components/form/upload/ImmediatelyUploadMultipleField";
import {Col, Row} from "../../../../../../components/Grid";
import {IRefineInformationGate, YesNo, YesNoDescribe} from "../../../../../../interface/entity/IInnovationEntity";
import RefineUtils from "../utils/RefineUtils";

interface IProps {
  form: FormikProps<IRefineInformationGate>;
}

class Refine extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {form} = this.props;

    return (
      <Fragment>
        <div className="mb-1">
          <Row>
            <Col val={6}>
              <div className="form-group">
                <Field
                  name={RefineUtils.isConsumerResearchAvailable.name}
                  label={RefineUtils.isConsumerResearchAvailable.label}
                  component={FormRadioCustomList}
                  options={RefineUtils.isConsumerResearchAvailable.options}
                />
              </div>
            </Col>
            <Col val={6}>
              <div className="form-group">
                <Field
                  name="consumerResearchAttachments"
                  component={ImmediatelyUploadMultipleField}
                  emptyMessage="No files Attached Yet..."
                  title="Please provide file if available:"
                />
              </div>
            </Col>
          </Row>
        </div>
        {form.values.isConsumerResearchAvailable === YesNo.No && (
          <Fragment>
            <span className="form-label mb-4">If no, what consumer research is needed to support: </span>
            <div className="pl-6">
              <div className="form-group">
                <Field
                  name={RefineUtils.transactionData.name}
                  label={RefineUtils.transactionData.label}
                  component={FormCheckboxList}
                  options={RefineUtils.transactionData.options}
                />
              </div>
              {form.values.transactionData && form.values.transactionData.other && (
                <div className="form-group">
                  <Field
                    name={RefineUtils.transactionDataOther.name}
                    placeholder={RefineUtils.transactionDataOther.placeholder}
                    label={RefineUtils.transactionDataOther.label}
                    labelClass={RefineUtils.transactionDataOther.labelClass}
                    component={FormText}
                  />
                </div>
              )}
              <div className="form-group">
                <Field
                  name={RefineUtils.qualitative.name}
                  label={RefineUtils.qualitative.label}
                  component={FormCheckboxList}
                  options={RefineUtils.qualitative.options}
                />
              </div>
              {form.values.qualitative && form.values.qualitative.other && (
                <div className="form-group">
                  <Field
                    name={RefineUtils.qualitativeOther.name}
                    placeholder={RefineUtils.qualitativeOther.placeholder}
                    label={RefineUtils.qualitativeOther.label}
                    labelClass={RefineUtils.qualitativeOther.labelClass}
                    component={FormText}
                  />
                </div>
              )}
              <div className="form-group">
                <Field
                  name={RefineUtils.quantitative.name}
                  label={RefineUtils.quantitative.label}
                  component={FormCheckboxList}
                  options={RefineUtils.quantitative.options}
                />
              </div>
              {form.values.quantitative && form.values.quantitative.other && (
                <div className="form-group">
                  <Field
                    name={RefineUtils.quantitativeOther.name}
                    placeholder={RefineUtils.quantitativeOther.placeholder}
                    label={RefineUtils.quantitativeOther.label}
                    labelClass={RefineUtils.quantitativeOther.labelClass}
                    component={FormText}
                  />
                </div>
              )}
              <Row>
                <Col val={8}>
                  <div className="form-group">
                    <Field
                      name={RefineUtils.isFieldResearchTestingProtocolPlanned.name}
                      label={RefineUtils.isFieldResearchTestingProtocolPlanned.label}
                      component={FormCheckboxList}
                      options={RefineUtils.isFieldResearchTestingProtocolPlanned.options}
                      checkboxCol={3}
                    />
                  </div>
                </Col>
              </Row>
              {form.values.isFieldResearchTestingProtocolPlanned && form.values.isFieldResearchTestingProtocolPlanned.other && (
                <div className="form-group">
                  <Field
                    name={RefineUtils.isFieldResearchTestingProtocolPlannedOther.name}
                    placeholder={RefineUtils.isFieldResearchTestingProtocolPlannedOther.placeholder}
                    label={RefineUtils.isFieldResearchTestingProtocolPlannedOther.label}
                    labelClass={RefineUtils.isFieldResearchTestingProtocolPlannedOther.labelClass}
                    component={FormText}
                  />
                </div>
              )}
            </div>
          </Fragment>
        )}
        <div className="form-group">
          <Field
            name={RefineUtils.isThisAnExistingPackageStructure.name}
            label={RefineUtils.isThisAnExistingPackageStructure.label}
            component={FormRadioCustomList}
            options={RefineUtils.isThisAnExistingPackageStructure.options}
          />
        </div>
        <div className="form-group">
          <Field
            name="isThisAnExistingPackageStructureAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title="Please provide images or examples (if available):"
          />
        </div>
        <Row>
          <Col val={10}>
            <div className="form-group">
              <Field
                name={RefineUtils.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType.name}
                label={RefineUtils.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType.label}
                component={FormRadioCustomList}
                options={RefineUtils.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType.options}
              />
            </div>
            {form.values.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType === YesNo.No && (
              <div className="form-group">
                <Field
                  name={RefineUtils.whereIsThisPackageAvailable.name}
                  placeholder={RefineUtils.whereIsThisPackageAvailable.placeholder}
                  label={RefineUtils.whereIsThisPackageAvailable.label}
                  component={FormText}
                  labelClass={RefineUtils.whereIsThisPackageAvailable.labelClass}
                />
              </div>
            )}
            <div className="form-group">
              <span className="form-label">Please estimate the potential number of packages needed:</span>
              <Row>
                <Col val={3}>
                  <Field
                    name={RefineUtils.numberOfStoreLocations.name}
                    placeholder={RefineUtils.numberOfStoreLocations.placeholder}
                    label={RefineUtils.numberOfStoreLocations.label}
                    component={FormText}
                    labelClass={RefineUtils.numberOfStoreLocations.labelClass}
                  />
                </Col>
                <Col val={3}>
                  <Field
                    name={RefineUtils.unitPerStorePerDay.name}
                    placeholder={RefineUtils.unitPerStorePerDay.placeholder}
                    label={RefineUtils.unitPerStorePerDay.label}
                    component={FormText}
                    labelClass={RefineUtils.unitPerStorePerDay.labelClass}
                  />
                </Col>
                <Col val={3}>
                  <Field
                    name={RefineUtils.numberOfDaysInMarket.name}
                    placeholder={RefineUtils.numberOfDaysInMarket.placeholder}
                    label={RefineUtils.numberOfDaysInMarket.label}
                    component={FormText}
                    labelClass={RefineUtils.numberOfDaysInMarket.labelClass}
                  />
                </Col>
                <Col val={3}>
                  <label className="form-control-wrap">
                    <span className="form-label text-regular text-primary-dark">
                      Total:
                    </span>
                    <input
                      type="text"
                      disabled={true}
                      className="form-control"
                      value={RefineUtils.calculateNumberOfPackages({
                        numberOfStoreLocations: form.values.numberOfStoreLocations,
                        unitPerStorePerDay: form.values.unitPerStorePerDay,
                        numberOfDaysInMarket: form.values.numberOfDaysInMarket,
                      })}
                    />
                  </label>
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <span className="form-label">Please estimate the acres needed to meet the volume needed:</span>
              <Row>
                <Col val={3}>
                  <Field
                    name={RefineUtils.totalUnits.name}
                    placeholder={RefineUtils.totalUnits.placeholder}
                    label={RefineUtils.totalUnits.label}
                    component={FormText}
                    labelClass={RefineUtils.totalUnits.labelClass}
                  />
                </Col>
                <Col val={3}>
                  <Field
                    name={RefineUtils.yieldPerAcre.name}
                    placeholder={RefineUtils.yieldPerAcre.placeholder}
                    label={RefineUtils.yieldPerAcre.label}
                    component={FormText}
                    labelClass={RefineUtils.yieldPerAcre.labelClass}
                  />
                </Col>
              </Row>
            </div>
            <div className="form-group">
              <Field
                name={RefineUtils.isThisACropDudaFarmFreshFoodsCurrentlyGrows.name}
                label={RefineUtils.isThisACropDudaFarmFreshFoodsCurrentlyGrows.label}
                component={FormRadioCustomList}
                options={RefineUtils.isThisACropDudaFarmFreshFoodsCurrentlyGrows.options}
              />
            </div>
            {form.values.isThisACropDudaFarmFreshFoodsCurrentlyGrows === YesNo.No && (
              <div className="form-group">
                <Field
                  name={RefineUtils.pleaseEstimateTheContractGrownPricePerUnit.name}
                  placeholder={RefineUtils.pleaseEstimateTheContractGrownPricePerUnit.placeholder}
                  label={RefineUtils.pleaseEstimateTheContractGrownPricePerUnit.label}
                  labelClass={RefineUtils.pleaseEstimateTheContractGrownPricePerUnit.labelClass}
                  component={FormText}
                  col={3}
                />
              </div>
            )}
            <div className="form-group">
              <Field
                name={RefineUtils.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation.name}
                label={RefineUtils.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation.label}
                component={FormRadioCustomList}
                options={RefineUtils.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation.options}
              />
            </div>
            {form.values.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation === YesNo.No && (
              <Row>
                <Col val={7}>
                  <div className="form-group">
                    <Field
                      name={RefineUtils.whatPartnerIsBeingConsideredToProcess.name}
                      placeholder={RefineUtils.whatPartnerIsBeingConsideredToProcess.placeholder}
                      label={RefineUtils.whatPartnerIsBeingConsideredToProcess.label}
                      labelClass={RefineUtils.whatPartnerIsBeingConsideredToProcess.labelClass}
                      component={FormText}
                    />
                  </div>
                </Col>
                <Col val={5}>
                  <div className="form-group">
                    <Field
                      name={RefineUtils.coPackCosts.name}
                      placeholder={RefineUtils.coPackCosts.placeholder}
                      label={RefineUtils.coPackCosts.label}
                      labelClass={RefineUtils.coPackCosts.labelClass}
                      component={FormText}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <div className="form-group">
              <Field
                name={RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProduct.name}
                label={RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProduct.label}
                component={FormRadioCustomList}
                options={RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProduct.options}
              />
            </div>
            {form.values.isAnySpecialFormulationNecessaryForTheFinalProduct === YesNoDescribe.Describe && (
              <div className="form-group">
                <Field
                  name={RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProductDescription.name}
                  placeholder={RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProductDescription.placeholder}
                  label={RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProductDescription.label}
                  labelClass={RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProductDescription.labelClass}
                  component={FormText}
                />
              </div>
            )}
            <div className="form-control-static">Has a prototype or final formulation been reviewed by:</div>
            <Row>
              <Col val={5}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.rndApprove.name}
                    label={RefineUtils.rndApprove.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.rndApprove.options}
                    labelClass={RefineUtils.rndApprove.labelClass}
                  />
                </div>
              </Col>
              <Col val={5}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.legalApprove.name}
                    label={RefineUtils.legalApprove.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.legalApprove.options}
                    labelClass={RefineUtils.legalApprove.labelClass}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col val={5}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.foodSafetyApprove.name}
                    label={RefineUtils.foodSafetyApprove.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.foodSafetyApprove.options}
                    labelClass={RefineUtils.foodSafetyApprove.labelClass}
                  />
                </div>
              </Col>
              <Col val={5}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.operationsApprove.name}
                    label={RefineUtils.operationsApprove.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.operationsApprove.options}
                    labelClass={RefineUtils.operationsApprove.labelClass}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col val={5}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.financeApprove.name}
                    label={RefineUtils.financeApprove.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.financeApprove.options}
                    labelClass={RefineUtils.financeApprove.labelClass}
                  />
                </div>
              </Col>
              <Col val={5}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.salesAndOpsApprove.name}
                    label={RefineUtils.salesAndOpsApprove.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.salesAndOpsApprove.options}
                    labelClass={RefineUtils.salesAndOpsApprove.labelClass}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <Field
                name={RefineUtils.willThisInnovationIncludeAnAdditionalManufacturedItem.name}
                label={RefineUtils.willThisInnovationIncludeAnAdditionalManufacturedItem.label}
                component={FormRadioCustomList}
                options={RefineUtils.willThisInnovationIncludeAnAdditionalManufacturedItem.options}
              />
            </div>
            {form.values.willThisInnovationIncludeAnAdditionalManufacturedItem === YesNo.Yes && (
              <Fragment>
                <div className="form-group">
                  <Field
                    name={RefineUtils.additionalItemDescription.name}
                    placeholder={RefineUtils.additionalItemDescription.placeholder}
                    label={RefineUtils.additionalItemDescription.label}
                    labelClass={RefineUtils.additionalItemDescription.labelClass}
                    component={FormText}
                  />
                </div>
                <Row>
                  <Col val={4}>
                    <div className="form-group">
                      <Field
                        name={RefineUtils.additionalItemCost.name}
                        placeholder={RefineUtils.additionalItemCost.placeholder}
                        label={RefineUtils.additionalItemCost.label}
                        labelClass={RefineUtils.additionalItemCost.labelClass}
                        component={FormText}
                      />
                    </div>
                  </Col>
                  <Col val={4}>
                    <div className="form-group">
                      <Field
                        name={RefineUtils.additionalItemPartner.name}
                        placeholder={RefineUtils.additionalItemPartner.placeholder}
                        label={RefineUtils.additionalItemPartner.label}
                        labelClass={RefineUtils.additionalItemPartner.labelClass}
                        component={FormText}
                      />
                    </div>
                  </Col>
                  <Col val={4}>
                    <div className="form-group">
                      <Field
                        name={RefineUtils.additionalItemAudit.name}
                        placeholder={RefineUtils.additionalItemAudit.placeholder}
                        label={RefineUtils.additionalItemAudit.label}
                        labelClass={RefineUtils.additionalItemAudit.labelClass}
                        component={FormText}
                      />
                    </div>
                  </Col>
                </Row>
              </Fragment>
            )}
            <span className="form-label mb-4">What Budgeting Parameters are needed for the Package:</span>
            <Row>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetProductSamples.name}
                    label={RefineUtils.budgetProductSamples.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.budgetProductSamples.options}
                    labelHtml={<span className="text-regular">Budgeted?</span>}
                  />
                  <div className="pt-3">
                    <Field
                      name={RefineUtils.budgetProductSamplesValue.name}
                      placeholder={RefineUtils.budgetProductSamplesValue.placeholder}
                      label={RefineUtils.budgetProductSamplesValue.label}
                      labelClass={RefineUtils.budgetProductSamplesValue.labelClass}
                      component={FormText}
                      col={9}
                      disabled={form.values.budgetProductSamples === YesNo.No}
                      append="$"
                    />
                  </div>
                </div>
              </Col>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetArtwork.name}
                    label={RefineUtils.budgetArtwork.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.budgetArtwork.options}
                    labelHtml={<span className="text-regular">Budgeted?</span>}
                  />
                  <div className="pt-3">
                    <Field
                      name={RefineUtils.budgetArtworkValue.name}
                      placeholder={RefineUtils.budgetArtworkValue.placeholder}
                      label={RefineUtils.budgetArtworkValue.label}
                      labelClass={RefineUtils.budgetArtworkValue.labelClass}
                      component={FormText}
                      col={9}
                      disabled={form.values.budgetArtwork === YesNo.No}
                      append="$"
                    />
                  </div>
                </div>
              </Col>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetMerchandising.name}
                    label={RefineUtils.budgetMerchandising.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.budgetMerchandising.options}
                    labelHtml={<span className="text-regular">Budgeted?</span>}
                  />
                  <div className="pt-3">
                    <Field
                      name={RefineUtils.budgetMerchandisingValue.name}
                      placeholder={RefineUtils.budgetMerchandisingValue.placeholder}
                      label={RefineUtils.budgetMerchandisingValue.label}
                      labelClass={RefineUtils.budgetMerchandisingValue.labelClass}
                      component={FormText}
                      col={9}
                      disabled={form.values.budgetMerchandising === YesNo.No}
                      append="$"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetPhoto.name}
                    label={RefineUtils.budgetPhoto.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.budgetPhoto.options}
                    labelHtml={<span className="text-regular">Budgeted?</span>}
                  />
                  <div className="pt-3">
                    <Field
                      name={RefineUtils.budgetPhotoValue.name}
                      placeholder={RefineUtils.budgetPhotoValue.placeholder}
                      label={RefineUtils.budgetPhotoValue.label}
                      labelClass={RefineUtils.budgetPhotoValue.labelClass}
                      component={FormText}
                      col={9}
                      disabled={form.values.budgetPhoto === YesNo.No}
                      append="$"
                    />
                  </div>
                </div>
              </Col>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetNutrition.name}
                    label={RefineUtils.budgetNutrition.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.budgetNutrition.options}
                    labelHtml={<span className="text-regular">Budgeted?</span>}
                  />
                  <div className="pt-3">
                    <Field
                      name={RefineUtils.budgetNutritionValue.name}
                      placeholder={RefineUtils.budgetNutritionValue.placeholder}
                      label={RefineUtils.budgetNutritionValue.label}
                      labelClass={RefineUtils.budgetNutritionValue.labelClass}
                      component={FormText}
                      col={9}
                      disabled={form.values.budgetNutrition === YesNo.No}
                      append="$"
                    />
                  </div>
                </div>
              </Col>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetTrademark.name}
                    label={RefineUtils.budgetTrademark.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.budgetTrademark.options}
                    labelHtml={<span className="text-regular">Budgeted?</span>}
                  />
                  <div className="pt-3">
                    <Field
                      name={RefineUtils.budgetTrademarkValue.name}
                      placeholder={RefineUtils.budgetTrademarkValue.placeholder}
                      label={RefineUtils.budgetTrademarkValue.label}
                      labelClass={RefineUtils.budgetTrademarkValue.labelClass}
                      component={FormText}
                      col={9}
                      disabled={form.values.budgetTrademark === YesNo.No}
                      append="$"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetSales.name}
                    label={RefineUtils.budgetSales.label}
                    component={FormRadioCustomList}
                    options={RefineUtils.budgetSales.options}
                    labelHtml={<span className="text-regular">Budgeted?</span>}
                  />
                  <div className="pt-3">
                    <Field
                      name={RefineUtils.budgetSalesValue.name}
                      placeholder={RefineUtils.budgetSalesValue.placeholder}
                      label={RefineUtils.budgetSalesValue.label}
                      labelClass={RefineUtils.budgetSalesValue.labelClass}
                      component={FormText}
                      col={9}
                      disabled={form.values.budgetSales === YesNo.No}
                      append="$"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col val={3}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.isThereATargetRetailPrice.name}
                    placeholder={RefineUtils.isThereATargetRetailPrice.placeholder}
                    label={RefineUtils.isThereATargetRetailPrice.label}
                    component={FormText}
                    col={10}
                    append="$"
                  />
                </div>
              </Col>
              <Col val={4}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.whatIsTheTargetedDudaWhloesalePrice.name}
                    placeholder={RefineUtils.whatIsTheTargetedDudaWhloesalePrice.placeholder}
                    label={RefineUtils.whatIsTheTargetedDudaWhloesalePrice.label}
                    component={FormText}
                    col={10}
                    append="$"
                  />
                </div>
              </Col>
              <Col val={5}>
                <div className="form-group">
                  <span className="form-label">Is there a target profit margin per unit:</span>
                  <Row>
                    <Col val={6}>
                      <Field
                        name={RefineUtils.profitAbsolute.name}
                        placeholder={RefineUtils.profitAbsolute.placeholder}
                        label={RefineUtils.profitAbsolute.label}
                        labelClass={RefineUtils.profitAbsolute.labelClass}
                        component={FormText}
                        append="$"
                      />
                    </Col>
                    <Col val={6}>
                      <Field
                        name={RefineUtils.profitPercent.name}
                        placeholder={RefineUtils.profitPercent.placeholder}
                        label={RefineUtils.profitPercent.label}
                        labelClass={RefineUtils.profitPercent.labelClass}
                        component={FormText}
                        append="%"
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col val={6}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetMinOrder.name}
                    placeholder={RefineUtils.budgetMinOrder.placeholder}
                    label={RefineUtils.budgetMinOrder.label}
                    labelClass={RefineUtils.budgetMinOrder.labelClass}
                    component={FormText}
                    col={9}
                    append="$"
                  />
                </div>
              </Col>
              <Col val={6}>
                <div className="form-group">
                  <Field
                    name={RefineUtils.budgetPackaging.name}
                    placeholder={RefineUtils.budgetPackaging.placeholder}
                    label={RefineUtils.budgetPackaging.label}
                    labelClass={RefineUtils.budgetPackaging.labelClass}
                    component={FormText}
                    col={9}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <Field
                name={RefineUtils.preliminaryCogsIfKnown.name}
                placeholder={RefineUtils.preliminaryCogsIfKnown.placeholder}
                label={RefineUtils.preliminaryCogsIfKnown.label}
                component={FormText}
              />
            </div>
            <div className="form-group">
              <Field
                name={RefineUtils.pleaseConfirmEstimatedNumberOfUnitsNeeded.name}
                placeholder={RefineUtils.pleaseConfirmEstimatedNumberOfUnitsNeeded.placeholder}
                label={RefineUtils.pleaseConfirmEstimatedNumberOfUnitsNeeded.label}
                component={FormText}
                labelHtml={<span className="text-primary-light">( # of stores x U/P/S/D x days in market = …)</span>}
              />
            </div>
            <div className="form-group">
              <Field
                name={RefineUtils.calculatePreliminaryTotalRevenue.name}
                placeholder={RefineUtils.calculatePreliminaryTotalRevenue.placeholder}
                label={RefineUtils.calculatePreliminaryTotalRevenue.label}
                component={FormText}
              />
            </div>
            <span className="form-label">What food safety protocol is needed (please describe):</span>
            <div className="mb-6">
              <div className="form-group">
                <Field
                  name={RefineUtils.safetyField.name}
                  placeholder={RefineUtils.safetyField.placeholder}
                  label={RefineUtils.safetyField.label}
                  labelClass={RefineUtils.safetyField.labelClass}
                  component={FormText}
                />
              </div>
              <div className="form-group">
                <Field
                  name={RefineUtils.safetyPlant.name}
                  placeholder={RefineUtils.safetyPlant.placeholder}
                  label={RefineUtils.safetyPlant.label}
                  labelClass={RefineUtils.safetyPlant.labelClass}
                  component={FormText}
                />
              </div>
              <div className="form-group">
                <Field
                  name={RefineUtils.safetyMarket.name}
                  placeholder={RefineUtils.safetyMarket.placeholder}
                  label={RefineUtils.safetyMarket.label}
                  labelClass={RefineUtils.safetyMarket.labelClass}
                  component={FormText}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-group">
          <Field
            name="generalAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title={"General documents (such as costing sheets, food safety reports, sales forecasts, etc.):"}
          />
        </div>
        <div className="form-group">
          <Field
            name={RefineUtils.anyOtherInformationYouWouldLikeToInclude.name}
            label={RefineUtils.anyOtherInformationYouWouldLikeToInclude.label}
            placeholder={RefineUtils.anyOtherInformationYouWouldLikeToInclude.placeholder}
            component={FormText}
          />
        </div>
      </Fragment>
    );
  }
}

export default Refine;
