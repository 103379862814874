import {FieldProps} from "formik";
import React, {Fragment} from "react";
import checkFile from "../../../helpers/checkFile";
import {IAttachmentEntity} from "../../../interface/entity/IAttachmentEntity";
import Ico from "../../Ico";
import {addErrorNotification} from "../../Notification";

interface IAttachmentsField extends FieldProps {
  title?: string;
  emptyMessage: string;
  existingFiles?: IAttachmentEntity[];
  onRemoveExistingFile?: (attachmentId: string) => void;
}

const PostponedUploadMultipleField = ({emptyMessage, title, existingFiles, ...props}: IAttachmentsField) => {
  return (
    <Fragment>
      <div className="form-control-wrap">
        {title && <span className="form-label">{title}</span>}
        <div className="form-file-wrap">
          <label className="form-file-ico">
            <input
              className="form-file"
              type="file"
              onChange={(event: any) => {
                const file = event.target.files[0];
                event.target.value = null;

                checkFile(file, () => {
                  props.form.setFieldValue(props.field.name, [...props.field.value, file]);
                }, (error: string) => {
                  addErrorNotification(error);
                });

              }}
            />
          </label>
          {((props.field.value && props.field.value.length === 0) && ((existingFiles && existingFiles.length === 0) || !existingFiles)) &&
          <div className="form-file-label pr-10">
            {emptyMessage}
          </div>}
          {existingFiles && existingFiles
            .map((attachment: IAttachmentEntity) =>
            <div className="form-file-label" key={attachment.id}>
              {attachment.fileName}
              <button
                className="form-file-remove"
                onClick={(e) => {
                  e.preventDefault();
                  if (props.onRemoveExistingFile) {
                    props.onRemoveExistingFile(attachment.id);
                  }
                }}
              >
                <Ico name="close"/>
                Remove
              </button>
            </div>,
          )}
          {props.field.value.map((file: File, i: number) => (
            <div className="form-file-label" key={i}>
              {file.name}
              <button
                className="form-file-remove"
                onClick={() => {
                  const newValue = [...props.field.value];
                  newValue.splice(i, 1);
                  props.form.setFieldValue(props.field.name, newValue);
                }}
              >
                <Ico name="close"/>
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default PostponedUploadMultipleField;
