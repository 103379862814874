import {IItemResource} from "../rest/IItemResource";
import {IDepartmentEntity} from "./IDepartmentEntity";

export enum UserRole {
  Employee = 0,
  Manager = 1,
  Admin = 2,
}

export enum UserValidationPermission {
  Deny = 0,
  Allow = 1,
}

export const userRoleKeyToLabel: { [key in UserRole]: string } = {
  [UserRole.Employee]: "Employee",
  [UserRole.Manager]: "Manager",
  [UserRole.Admin]: "Admin",
};

export interface IUserEntity extends IItemResource {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  role: UserRole;
  isValidator: UserValidationPermission;

  department?: IDepartmentEntity;
}
