import {Field, FormikProps} from "formik";
import React, {Fragment} from "react";
import {FormText} from "../../../../../../components/form";
import AnotherFormDatepicker from "../../../../../../components/form/AnotherFormDatepicker";
import FormCheckboxList from "../../../../../../components/form/FormCheckboxList";
import FormRadioCustomList from "../../../../../../components/form/FormRadioCustomList";
import ImmediatelyUploadMultipleField from "../../../../../../components/form/upload/ImmediatelyUploadMultipleField";
import {Col, Row} from "../../../../../../components/Grid";
import {IConceptInformationGate, IsThereATargetedBusinessSegmentToLaunch, YesNo} from "../../../../../../interface/entity/IInnovationEntity";
import ConceptUtils from "../utils/ConceptUtils";

interface IProps {
  form: FormikProps<IConceptInformationGate>;
}

class Concept extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {form} = this.props;
    return (
      <Fragment>
        <div className="form-group">
          <Field
            name={ConceptUtils.isThisAnItemThatDfffAlreadyGrowsContractGrows.name}
            label={ConceptUtils.isThisAnItemThatDfffAlreadyGrowsContractGrows.label}
            component={FormRadioCustomList}
            options={ConceptUtils.isThisAnItemThatDfffAlreadyGrowsContractGrows.options}
          />
        </div>
        <div className="form-group">
          <Field
            name="proposedInnovationAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title="Images or illustrations of your proposed innovation:"
          />
        </div>
        <div className="form-group">
          <span className="form-label">Please select type of innovation you are proposing:</span>
        </div>
        <div className="pl-6">
          <div className="form-group">
            <Field
              name={ConceptUtils.productType.name}
              label={ConceptUtils.productType.label}
              component={FormCheckboxList}
              options={ConceptUtils.productType.options}
            />
          </div>
          {form.values.productType && form.values.productType.other && (
            <div className="form-group">
              <Field
                name={ConceptUtils.productTypeOther.name}
                placeholder={ConceptUtils.productTypeOther.placeholder}
                label={ConceptUtils.productTypeOther.label}
                labelClass={ConceptUtils.productTypeOther.labelClass}
                component={FormText}
              />
            </div>
          )}
          <Row>
            <Col val="7">
              <div className="form-group">
                <Field
                  name={ConceptUtils.pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation.name}
                  placeholder={ConceptUtils.pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation.placeholder}
                  label={ConceptUtils.pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation.label}
                  component={FormText}
                  labelClass={ConceptUtils.pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation.labelClass}
                />
              </div>
            </Col>
            <Col val="5">
              <div className="form-group">
                <Field
                  name={ConceptUtils.whatIsThePreliminaryProposedPricingPerUnit.name}
                  placeholder={ConceptUtils.whatIsThePreliminaryProposedPricingPerUnit.placeholder}
                  label={ConceptUtils.whatIsThePreliminaryProposedPricingPerUnit.label}
                  component={FormText}
                  labelClass={ConceptUtils.whatIsThePreliminaryProposedPricingPerUnit.labelClass}
                />
              </div>
            </Col>
          </Row>
          <div className="form-group">
            <Field
              name={ConceptUtils.innerPackagingType.name}
              label={ConceptUtils.innerPackagingType.label}
              component={FormCheckboxList}
              options={ConceptUtils.innerPackagingType.options}
            />
          </div>
          <div className="form-group">
            {form.values.innerPackagingType && form.values.innerPackagingType.other && (
              <div className="mt-4">
                <Field
                  name={ConceptUtils.innerPackagingTypeOther.name}
                  placeholder={ConceptUtils.innerPackagingTypeOther.placeholder}
                  component={FormText}
                  label={ConceptUtils.innerPackagingTypeOther.label}
                  labelClass={ConceptUtils.innerPackagingTypeOther.labelClass}
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <Field
              name={ConceptUtils.outerPackagingType.name}
              label={ConceptUtils.outerPackagingType.label}
              component={FormCheckboxList}
              options={ConceptUtils.outerPackagingType.options}
            />
          </div>
          <div className="form-group">
            {form.values.outerPackagingType && form.values.outerPackagingType.other && (
              <div className="mt-4">
                <Field
                  name={ConceptUtils.outerPackagingTypeOther.name}
                  placeholder={ConceptUtils.outerPackagingTypeOther.placeholder}
                  component={FormText}
                  label={ConceptUtils.outerPackagingTypeOther.label}
                  labelClass={ConceptUtils.outerPackagingTypeOther.labelClass}
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <Field
              name={ConceptUtils.productSpecs.name}
              label={ConceptUtils.productSpecs.label}
              options={ConceptUtils.productSpecs.options}
              component={FormRadioCustomList}
            />
          </div>
          {form.values.productSpecs === YesNo.Yes && (
            <Row>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.packSize.name}
                    label={ConceptUtils.packSize.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.packSize.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.packSizeValue.name}
                    label={ConceptUtils.packSizeValue.label}
                    labelClass={ConceptUtils.packSizeValue.labelClass}
                    placeholder={ConceptUtils.packSizeValue.placeholder}
                    component={FormText}
                    disabled={form.values.packSize !== YesNo.Yes}
                  />
                </div>
              </Col>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.cartonsPerPallet.name}
                    label={ConceptUtils.cartonsPerPallet.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.cartonsPerPallet.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.cartonsPerPalletValue.name}
                    label={ConceptUtils.cartonsPerPalletValue.label}
                    labelClass={ConceptUtils.cartonsPerPalletValue.labelClass}
                    placeholder={ConceptUtils.cartonsPerPalletValue.placeholder}
                    component={FormText}
                    disabled={form.values.cartonsPerPallet !== YesNo.Yes}
                  />
                </div>
              </Col>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.tieHigh.name}
                    label={ConceptUtils.tieHigh.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.tieHigh.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.tieHighValue.name}
                    label={ConceptUtils.tieHighValue.label}
                    labelClass={ConceptUtils.tieHighValue.labelClass}
                    placeholder={ConceptUtils.tieHighValue.placeholder}
                    component={FormText}
                    disabled={form.values.tieHigh !== YesNo.Yes}
                  />
                </div>
              </Col>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.grossWeight.name}
                    label={ConceptUtils.grossWeight.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.grossWeight.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.grossWeightValue.name}
                    label={ConceptUtils.grossWeightValue.label}
                    labelClass={ConceptUtils.grossWeightValue.labelClass}
                    placeholder={ConceptUtils.grossWeightValue.placeholder}
                    component={FormText}
                    disabled={form.values.grossWeight !== YesNo.Yes}
                  />
                </div>
              </Col>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.cartonSize.name}
                    label={ConceptUtils.cartonSize.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.cartonSize.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.cartonSizeValue.name}
                    label={ConceptUtils.cartonSizeValue.label}
                    labelClass={ConceptUtils.cartonSizeValue.labelClass}
                    placeholder={ConceptUtils.cartonSizeValue.placeholder}
                    component={FormText}
                    disabled={form.values.cartonSize !== YesNo.Yes}
                  />
                </div>
              </Col>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.cartonCube.name}
                    label={ConceptUtils.cartonCube.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.cartonCube.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.cartonCubeValue.name}
                    label={ConceptUtils.cartonCubeValue.label}
                    labelClass={ConceptUtils.cartonCubeValue.labelClass}
                    placeholder={ConceptUtils.cartonCubeValue.placeholder}
                    component={FormText}
                    disabled={form.values.cartonCube !== YesNo.Yes}
                  />
                </div>
              </Col>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.upc.name}
                    label={ConceptUtils.upc.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.upc.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.upcValue.name}
                    label={ConceptUtils.upcValue.label}
                    labelClass={ConceptUtils.upcValue.labelClass}
                    placeholder={ConceptUtils.upcValue.placeholder}
                    component={FormText}
                    disabled={form.values.upc !== YesNo.Yes}
                  />
                </div>
              </Col>
              <Col val="3">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.gtin.name}
                    label={ConceptUtils.gtin.label}
                    component={FormRadioCustomList}
                    options={ConceptUtils.gtin.options}
                  />
                </div>
                <div className="form-group maxw-14">
                  <Field
                    name={ConceptUtils.gtinValue.name}
                    label={ConceptUtils.gtinValue.label}
                    labelClass={ConceptUtils.gtinValue.labelClass}
                    placeholder={ConceptUtils.gtinValue.placeholder}
                    component={FormText}
                    disabled={form.values.gtin !== YesNo.Yes}
                  />
                </div>
              </Col>
            </Row>
          )}
          <div className="form-group">
            <Field
              name={ConceptUtils.convenienceValueAdded.name}
              label={ConceptUtils.convenienceValueAdded.label}
              component={FormCheckboxList}
              options={ConceptUtils.convenienceValueAdded.options}
            />
          </div>
          <div className="form-group">
            {form.values.convenienceValueAdded && form.values.convenienceValueAdded.other && (
              <div className="mt-4">
                <Field
                  name={ConceptUtils.convenienceValueAddedOther.name}
                  placeholder={ConceptUtils.convenienceValueAddedOther.placeholder}
                  component={FormText}
                  label={ConceptUtils.convenienceValueAddedOther.label}
                  labelClass={ConceptUtils.convenienceValueAddedOther.labelClass}
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <Field
              name={ConceptUtils.cpgShelfStable.name}
              label={ConceptUtils.cpgShelfStable.label}
              component={FormCheckboxList}
              options={ConceptUtils.cpgShelfStable.options}
            />
          </div>
          <div className="form-group">
            {form.values.cpgShelfStable && form.values.cpgShelfStable.other && (
              <div className="mt-4">
                <Field
                  name={ConceptUtils.cpgShelfStableOther.name}
                  placeholder={ConceptUtils.cpgShelfStableOther.placeholder}
                  component={FormText}
                  label={ConceptUtils.cpgShelfStableOther.label}
                  labelClass={ConceptUtils.cpgShelfStableOther.labelClass}
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <Field
              name={ConceptUtils.foodService.name}
              label={ConceptUtils.foodService.label}
              component={FormCheckboxList}
              options={ConceptUtils.foodService.options}
            />
          </div>
          <div className="form-group">
            {form.values.foodService && form.values.foodService.other && (
              <div className="mt-4">
                <Field
                  name={ConceptUtils.foodServiceOther.name}
                  placeholder={ConceptUtils.foodServiceOther.placeholder}
                  component={FormText}
                  label={ConceptUtils.foodServiceOther.label}
                  labelClass={ConceptUtils.foodServiceOther.labelClass}
                />
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <Field
            name={ConceptUtils.isThisSolutionIntendedToBeOrganic.name}
            label={ConceptUtils.isThisSolutionIntendedToBeOrganic.label}
            options={ConceptUtils.isThisSolutionIntendedToBeOrganic.options}
            component={FormRadioCustomList}
          />
        </div>
        <div className="form-group">
          <Field
            name={ConceptUtils.anyOtherAdditionalContactsToList.name}
            label={ConceptUtils.anyOtherAdditionalContactsToList.label}
            placeholder={ConceptUtils.anyOtherAdditionalContactsToList.placeholder}
            component={FormText}
          />
        </div>
        <div className="form-group">
          <Field
            name={ConceptUtils.pleaseDescribeInDetailTheTargetConsumerForThisInnovation.name}
            label={ConceptUtils.pleaseDescribeInDetailTheTargetConsumerForThisInnovation.label}
            placeholder={ConceptUtils.pleaseDescribeInDetailTheTargetConsumerForThisInnovation.placeholder}
            component={FormText}
          />
        </div>
        <div className="form-group">
          <Field
            name="consumerResearchAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title="Please attach any consumer research that helps define the consumer demand or need:"
          />
        </div>
        <div className="form-group">
          <Field
            name={ConceptUtils.isThereATargetedBusinessSegmentToLaunch.name}
            label={ConceptUtils.isThereATargetedBusinessSegmentToLaunch.label}
            options={ConceptUtils.isThereATargetedBusinessSegmentToLaunch.options}
            component={FormRadioCustomList}
          />
        </div>
        {form.values.isThereATargetedBusinessSegmentToLaunch !== IsThereATargetedBusinessSegmentToLaunch.No && <div className="form-group">
            <Field
                name={ConceptUtils.targetBusinessSegmentToLaunchDetails.name}
                label={ConceptUtils.targetBusinessSegmentToLaunchDetails.label}
                placeholder={ConceptUtils.targetBusinessSegmentToLaunchDetails.placeholder}
                component={FormText}
            />
        </div>}
        <div className="form-group">
          <Field
            name={ConceptUtils.ifRequestedByASpecificCustomerPleaseIdentify.name}
            label={ConceptUtils.ifRequestedByASpecificCustomerPleaseIdentify.label}
            options={ConceptUtils.ifRequestedByASpecificCustomerPleaseIdentify.options}
            component={FormRadioCustomList}
          />
        </div>
        <div className="form-group">
          <Field
            name={ConceptUtils.pleaseIdentifyCustomerName.name}
            label={ConceptUtils.pleaseIdentifyCustomerName.label}
            placeholder={ConceptUtils.pleaseIdentifyCustomerName.placeholder}
            component={FormText}
          />
        </div>
        <span className="form-label mb-4">
          Please provide the buyer or merchandising manager that requested this innovation:
        </span>
        <div className="pl-6">
          <Row>
            <Col val="4">
              <div className="form-group">
                <Field
                  name={ConceptUtils.managerName.name}
                  label={ConceptUtils.managerName.label}
                  placeholder={ConceptUtils.managerName.placeholder}
                  component={FormText}
                />
              </div>
            </Col>
            <Col val="4">
              <div className="form-group">
                <Field
                  name={ConceptUtils.officeOrStore.name}
                  label={ConceptUtils.officeOrStore.label}
                  placeholder={ConceptUtils.officeOrStore.placeholder}
                  component={FormText}
                />
              </div>
            </Col>
            <Col val="4">
              <div className="form-group">
                <Field
                  name={ConceptUtils.willingToTest.name}
                  label={ConceptUtils.willingToTest.label}
                  options={ConceptUtils.willingToTest.options}
                  component={FormRadioCustomList}
                />
              </div>
            </Col>
            <Col val="8">
              <div className="form-group">
                <Field
                  name={ConceptUtils.volumeEstimate.name}
                  label={ConceptUtils.volumeEstimate.label}
                  placeholder={ConceptUtils.volumeEstimate.placeholder}
                  component={FormText}
                />
              </div>
            </Col>
            <Col val="4">
              <div className="form-group">
                <Field
                  name={ConceptUtils.canWeFollowUpWithThisBuyer.name}
                  label={ConceptUtils.canWeFollowUpWithThisBuyer.label}
                  options={ConceptUtils.canWeFollowUpWithThisBuyer.options}
                  component={FormRadioCustomList}
                />
              </div>
            </Col>
            <Col val="4">
              <div className="form-group">
                <Field
                  name={ConceptUtils.buyersPhone.name}
                  label={ConceptUtils.buyersPhone.label}
                  placeholder={ConceptUtils.buyersPhone.placeholder}
                  component={FormText}
                />
              </div>
            </Col>
            <Col val="4">
              <div className="form-group">
                <Field
                  name={ConceptUtils.buyersEmail.name}
                  label={ConceptUtils.buyersEmail.label}
                  placeholder={ConceptUtils.buyersEmail.placeholder}
                  component={FormText}
                />
              </div>
            </Col>
            <Col val="12">
              <div className="form-group">
                <Field
                  name={ConceptUtils.doYouHaveAMarketingContact.name}
                  label={ConceptUtils.doYouHaveAMarketingContact.label}
                  options={ConceptUtils.doYouHaveAMarketingContact.options}
                  component={FormRadioCustomList}
                />
              </div>
            </Col>
            {form.values.doYouHaveAMarketingContact === YesNo.Yes && (
              <Col val="12">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.marketingContractDetails.name}
                    placeholder={ConceptUtils.marketingContractDetails.placeholder}
                    label={ConceptUtils.marketingContractDetails.label}
                    component={FormText}
                  />
                </div>
              </Col>)}
            <Col val="12">
              <div className="form-group">
                <Field
                  name={ConceptUtils.doYouHaveAnRDContact.name}
                  label={ConceptUtils.doYouHaveAnRDContact.label}
                  options={ConceptUtils.doYouHaveAnRDContact.options}
                  component={FormRadioCustomList}
                />
              </div>
            </Col>
            {form.values.doYouHaveAnRDContact === YesNo.Yes && (
              <Col val="12">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.rndContactDetails.name}
                    placeholder={ConceptUtils.rndContactDetails.placeholder}
                    label={ConceptUtils.rndContactDetails.label}
                    component={FormText}
                  />
                </div>
              </Col>)}
            <Col val="12">
              <div className="form-group">
                <Field
                  name={ConceptUtils.doYouHaveAnInnovationContact.name}
                  label={ConceptUtils.doYouHaveAnInnovationContact.label}
                  options={ConceptUtils.doYouHaveAnInnovationContact.options}
                  component={FormRadioCustomList}
                />
              </div>
            </Col>
            {form.values.doYouHaveAnInnovationContact === YesNo.Yes && (
              <Col val="12">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.innovationContactDetails.name}
                    placeholder={ConceptUtils.innovationContactDetails.placeholder}
                    label={ConceptUtils.innovationContactDetails.label}
                    component={FormText}
                  />
                </div>
              </Col>)}
            <Col val="12">
              <div className="form-group">
                <Field
                  name={ConceptUtils.doYouHaveAFoodSafetyContact.name}
                  label={ConceptUtils.doYouHaveAFoodSafetyContact.label}
                  options={ConceptUtils.doYouHaveAFoodSafetyContact.options}
                  component={FormRadioCustomList}
                />
              </div>
            </Col>
            {form.values.doYouHaveAFoodSafetyContact === YesNo.Yes && (
              <Col val="12">
                <div className="form-group">
                  <Field
                    name={ConceptUtils.foodSafetyContactDetails.name}
                    placeholder={ConceptUtils.foodSafetyContactDetails.placeholder}
                    label={ConceptUtils.foodSafetyContactDetails.label}
                    component={FormText}
                  />
                </div>
              </Col>)}
          </Row>
        </div>
        <Row>
          <Col val="4">
            <div className="form-group">
              <Field
                name={ConceptUtils.isThereAnExpectedLaunchDate.name}
                label={ConceptUtils.isThereAnExpectedLaunchDate.label}
                placeholder={ConceptUtils.isThereAnExpectedLaunchDate.placeholder}
                component={AnotherFormDatepicker}
                selectsStart={true}
                selected={form.values.isThereAnExpectedLaunchDate}
                isClearable={true}
                col={8}
              />
            </div>
          </Col>
          <Col val="6" offset="1">
            <div className="form-group">
              <Field
                name="generalAttachments"
                component={ImmediatelyUploadMultipleField}
                emptyMessage="No files Attached Yet..."
                title="General Documents:"
              />
            </div>
          </Col>
        </Row>
        <div className="form-group">
          <Field
            name={ConceptUtils.anyOtherInformationYouWouldLikeToInclude.name}
            label={ConceptUtils.anyOtherInformationYouWouldLikeToInclude.label}
            placeholder={ConceptUtils.anyOtherInformationYouWouldLikeToInclude.placeholder}
            component={FormText}
          />
        </div>
      </Fragment>
    );
  }
}

export default Concept;
