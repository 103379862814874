import {Field, Formik} from "formik";
import {DateTime} from "luxon";
import React from "react";
import {connect} from "react-redux";
import {RouteChildrenProps} from "react-router";
import {Link} from "react-router-dom";
// tslint:disable-next-line:no-submodule-imports
import {ValueType} from "react-select/src/types";
import {Dispatch} from "redux";
import AnotherFormDatepicker from "../../../components/form/AnotherFormDatepicker";
import AnotherFormSelect from "../../../components/form/AnotherFormSelect";
import {Col, Row} from "../../../components/Grid";
import Ico from "../../../components/Ico";
import Pagination from "../../../components/Pagination";
import {
  ACTIVITY_SORT_CREATED_AT_ASC,
  ACTIVITY_SORT_CREATED_AT_DESC,
  ACTIVITY_SORT_USER_ASC,
  ACTIVITY_SORT_USER_DESC,
} from "../../../constant/app";
import axiosInstance from "../../../instance/axios";
import {ActivityType, activityTypeKeyToLabel, IActivityEntity} from "../../../interface/entity/IActivityEntity";
import {innovationStageKeyToValue} from "../../../interface/entity/IInnovationEntity";
import {ICollectionResource} from "../../../interface/rest/ICollectionResource";
import {AppState} from "../../../store";
import {
  innovationItemActivityCollectionFetchError,
  innovationItemActivityCollectionFetchStart,
  innovationItemActivityCollectionFetchSuccess,
} from "../../../store/innovationItem/activity/action";
import {IInnovationItemActivityState} from "../../../store/innovationItem/activity/type";
import {IInnovationItemCommonState} from "../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../store/innovationItem/stage/common/type";
import InnovationItemActivityRow from "./InnovationItemActivityRow";

interface IProps extends RouteChildrenProps {
  innovationItemCommonState: IInnovationItemCommonState;
  innovationItemActivityState: IInnovationItemActivityState;
  stageState: IInnovationItemStageCommonState;

  onInnovationItemActivityCollectionFetchStart: () => void;
  onInnovationItemActivityCollectionFetchSuccess: (collectionResource: ICollectionResource<IActivityEntity>) => void;
  onInnovationItemActivityCollectionFetchError: (error: unknown) => void;
}

class InnovationItemActivity extends React.Component<IProps> {
  public async componentDidMount() {
    const currentPropsParams = new URLSearchParams(this.props.location.search);
    await this.fetchActivities(Number(currentPropsParams.get("page") || 1));
  }

  public async componentDidUpdate(prevProps: IProps) {
    const prevPropsParams = new URLSearchParams(prevProps.location.search);
    const currentPropsParams = new URLSearchParams(this.props.location.search);

    if (prevPropsParams.get("page") !== currentPropsParams.get("page")
      || prevPropsParams.get("type") !== currentPropsParams.get("type")
      || prevPropsParams.get("create_date_from") !== currentPropsParams.get("create_date_from")
      || prevPropsParams.get("create_date_to") !== currentPropsParams.get("create_date_to")
    ) {
      await this.fetchActivities(Number(currentPropsParams.get("page")));
    }
  }

  public render(): React.ReactNode {
    const {
      innovationItemCommonState: {innovationEntity},
      innovationItemActivityState: {activityCollectionResource},
      history,
      location,
      stageState,
    } = this.props;
    const sort = +(new URLSearchParams(this.props.location.search).get("sort") || ACTIVITY_SORT_CREATED_AT_ASC);
    const currentPropsParams = new URLSearchParams(this.props.location.search);
    const currentType = currentPropsParams.get("type");

    const initialValues = {
      type: currentType ? {value: currentType, label: activityTypeKeyToLabel[+currentType as ActivityType]} : undefined,
      createDateFrom: currentPropsParams.get("create_date_from"),
      createDateTo: currentPropsParams.get("create_date_to"),
    };

    const isSortByDate = [ACTIVITY_SORT_CREATED_AT_ASC, ACTIVITY_SORT_CREATED_AT_DESC].includes(sort);
    const isSortByUser = [ACTIVITY_SORT_USER_ASC, ACTIVITY_SORT_USER_DESC].includes(sort);
    return (
      <div>
        <div className="mb-6">
          <Link
            to={`/innovation/${innovationEntity && innovationEntity.id}/${innovationEntity && innovationStageKeyToValue[stageState.stage]}`}
          >
            &lt; Back to {innovationEntity && innovationEntity.name} Details
          </Link>
        </div>
        <div className="header-line">
          <h1 className="h2 text-primary-darker">Activity Log for {innovationEntity && innovationEntity.name}</h1>
        </div>
        <Formik
          onSubmit={() => null}
          initialValues={initialValues}
          render={({handleSubmit, values}) =>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <Row>
                  <Col val="6">
                    <Row>
                      <Col val="4">
                        <div className="form-control-static text-primary-light text-regular">Activity to Display for:</div>
                      </Col>
                      <Col val="4">
                        <Field
                          name="createDateFrom"
                          placeholder="Select Date"
                          labelClass="text-primary-light"
                          component={AnotherFormDatepicker}
                          selectsEnd={true}
                          selected={initialValues.createDateFrom}
                          isClearable={true}
                          onChange={(v: string) => {
                            // @ts-ignore
                            this.setFilters({...values, createDateFrom: v ? v : null});
                          }}
                        />
                      </Col>
                      <Col val="4">
                        <Field
                          name="createDateTo"
                          placeholder="Select Date"
                          labelClass="text-primary-light"
                          component={AnotherFormDatepicker}
                          selectsEnd={true}
                          selected={initialValues.createDateTo}
                          isClearable={true}
                          onChange={(v: string) => {
                            // @ts-ignore
                            this.setFilters({...values, createDateTo: v ? v : null});
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col val="6">
                    <Row>
                      <Col val="4">
                        <div className="form-control-static text-primary-light text-regular text-right">Activity Type to Display:</div>
                      </Col>
                      <Col val="8">
                        <Field
                          component={AnotherFormSelect}
                          name="type"
                          selectProps={{
                            options: Object.keys(activityTypeKeyToLabel).map((type: any) =>
                              ({value: type.toString(), label: activityTypeKeyToLabel[type as ActivityType]}),
                            ),
                            placeholder: "All activity",
                            isClearable: true,
                          }}
                          customProps={{}}
                          onChange={(v: ValueType<{ value: string, label: string }>) => {
                            this.setFilters({...values, type: v});
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </form>
          }
        />
        <table className="table table-default table-action">
          <thead>
          <tr>
            <th>Innovation Stage</th>
            <th
              className={`table-action-toggle ${isSortByDate ? "text-primary-dark" : ""}`}
              onClick={() => {
                this.setSort(sort === ACTIVITY_SORT_CREATED_AT_DESC
                  ? ACTIVITY_SORT_CREATED_AT_ASC
                  : ACTIVITY_SORT_CREATED_AT_DESC,
                );
              }}
            >
              <span className="table-action-toggle-inner">
                  Activity Date {sort === ACTIVITY_SORT_CREATED_AT_ASC && (
                <Ico name="sort-desc"/>)}{sort === ACTIVITY_SORT_CREATED_AT_DESC && (<Ico name="sort-asc"/>)}
                </span>
            </th>
            <th
              className={`table-action-toggle ${isSortByUser ? "text-primary-dark" : ""}`}
              onClick={() => {
                this.setSort(sort === ACTIVITY_SORT_USER_DESC
                  ? ACTIVITY_SORT_USER_ASC
                  : ACTIVITY_SORT_USER_DESC,
                );
              }}
            >
              <span className="table-action-toggle-inner">
                  Performed By {sort === ACTIVITY_SORT_USER_ASC && (
                <Ico name="sort-desc"/>)}{sort === ACTIVITY_SORT_USER_DESC && (<Ico name="sort-asc"/>)}
                </span>
            </th>
            <th>Activity Type</th>
          </tr>
          </thead>
          <tbody>
          {activityCollectionResource.items.map((activity: IActivityEntity) => <InnovationItemActivityRow key={activity.id} activity={activity}/>)}
          {(!activityCollectionResource.items || activityCollectionResource.items.length === 0) && <tr>
            <td className="comment-item-empty" colSpan={5}>
                <div className="text-center">
                  No activity yet...
                </div>
            </td>
          </tr>}
          </tbody>
        </table>
        {activityCollectionResource.pagination
        && activityCollectionResource.pagination.totalPages > 1
        && <Pagination
          {...activityCollectionResource.pagination}
          onChange={(page: number) => {
            if (page === 1) {
              currentPropsParams.delete("page");
            } else {
              currentPropsParams.set("page", String(page));
            }
            history.push([location.pathname, ...[currentPropsParams ? "?" : "", currentPropsParams.toString()]].join(""));
          }}
        />}
      </div>
    );
  }

  private fetchActivities = async (page?: number) => {

    this.props.onInnovationItemActivityCollectionFetchStart();

    const {innovationItemCommonState: {innovationEntity}} = this.props;
    const currentPropsParams = new URLSearchParams(this.props.location.search);

    const createDateFrom = currentPropsParams.get("create_date_from");
    const createDateFromDate = createDateFrom ? DateTime.fromFormat(createDateFrom, "MM/dd/y") : undefined;
    const createDateTo = currentPropsParams.get("create_date_to");
    const createDateToDate = createDateTo ? DateTime.fromFormat(createDateTo, "MM/dd/y") : null;

    await axiosInstance().get("/innovation/" + (innovationEntity && innovationEntity.id) + "/activity", {
      params: {
        page: page || currentPropsParams.get("page") || 1,
        sort: currentPropsParams.get("sort") || 1,
        create_date_from: createDateFromDate ? createDateFromDate
          .setZone("UTC")
          .set({hour: 0, minute: 0, second: 0, day: createDateFromDate.day})
          .toJSDate() : undefined,
        create_date_to: createDateToDate ? createDateToDate
          .setZone("UTC")
          .set({hour: 23, minute: 59, second: 59, day: createDateToDate.day})
          .toJSDate() : undefined,
        type: currentPropsParams.get("type") || undefined,
      },
    })
      .then((response: { data: ICollectionResource<IActivityEntity> }) => {
        this.props.onInnovationItemActivityCollectionFetchSuccess(response.data);
      })
      .catch((error) => {
        // TODO log error
        this.props.onInnovationItemActivityCollectionFetchError(error);
      });
  }

  private setSort = async (sort: number) => {
    const {location, history} = this.props;

    await this.fetchActivities();

    const currentPropsParams = new URLSearchParams(location.search);

    currentPropsParams.set("sort", String(sort));

    history.push([location.pathname, ...[currentPropsParams ? "?" : "", currentPropsParams.toString()]].join(""));
  }

  private setFilters = (values: any) => {
    const {location, history} = this.props;

    // this.props.onInnovationCollectionSetFilters({
    //   ...(values.createDateFrom ? {createDateFrom: values.createDateFrom} : {}),
    //   ...(values.createDateTo ? {createDateTo: values.createDateTo} : {}),
    //   ...(values.deadlineDateFrom ? {deadlineDateFrom: values.deadlineDateFrom} : {}),
    //   ...(values.deadlineDateTo ? {deadlineDateTo: values.deadlineDateTo} : {}),
    //   ...(values.q ? {q: values.q} : {}),
    // });
    const currentPropsParams = new URLSearchParams(location.search);

    values.type
      ? currentPropsParams.set("type", values.type.value)
      : currentPropsParams.delete("type");

    values.createDateFrom
      ? currentPropsParams.set("create_date_from", values.createDateFrom)
      : currentPropsParams.delete("create_date_from");

    values.createDateTo
      ? currentPropsParams.set("create_date_to", values.createDateTo)
      : currentPropsParams.delete("create_date_to");

    history.push([location.pathname, ...[currentPropsParams ? "?" : "", currentPropsParams.toString()]].join(""));
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemActivityState: state.innovationItem.activity,
  innovationItemCommonState: state.innovationItem.common,
  stageState: state.innovationItem.stage.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemActivityCollectionFetchError: (error: unknown) => {
    dispatch(innovationItemActivityCollectionFetchError(error));
  },
  onInnovationItemActivityCollectionFetchStart: () => {
    dispatch(innovationItemActivityCollectionFetchStart());
  },
  onInnovationItemActivityCollectionFetchSuccess: (activityCollectionResource: ICollectionResource<IActivityEntity>) => {
    dispatch(innovationItemActivityCollectionFetchSuccess(activityCollectionResource));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemActivity);
