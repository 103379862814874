import {IInformationGate} from "../../../../interface/entity/IInnovationEntity";

export interface IInnovationItemStageGateState<D> {
  data?: D;
  isLoading: boolean;
}

export const INNOVATION_ITEM_STAGE_GATE_FETCH_START = "INNOVATION_ITEM_STAGE_GATE_FETCH_START";
export const INNOVATION_ITEM_STAGE_GATE_FETCH_SUCCESS = "INNOVATION_ITEM_STAGE_GATE_FETCH_SUCCESS";
export const INNOVATION_ITEM_STAGE_GATE_UPDATE_DATA = "INNOVATION_ITEM_STAGE_GATE_UPDATE_DATA";

interface IInnovationItemStageGateFetchStartAction {
  type: typeof INNOVATION_ITEM_STAGE_GATE_FETCH_START;
}

interface IInnovationItemStageGateFetchSuccessAction {
  type: typeof INNOVATION_ITEM_STAGE_GATE_FETCH_SUCCESS;
  data: IInformationGate;
}

interface IInnovationItemStageGateUpdateData {
  type: typeof INNOVATION_ITEM_STAGE_GATE_UPDATE_DATA;
  data: IInformationGate;
}

export type IInnovationItemStageGateActionTypes =
  | IInnovationItemStageGateFetchStartAction
  | IInnovationItemStageGateFetchSuccessAction
  | IInnovationItemStageGateUpdateData
  ;
