import React from "react";

export const defaultRadioValue = (value: string, options: Array<{ value: string, label: string }>) => {
  if (value) {
    switch (value) {
      case "no":
        return <span className="text-danger"><i className="ico ico-close ico-xs"/>&nbsp; No</span>;
      case "yes":
        return <span className="text-success"><i className="ico ico-check ico-xs"/>&nbsp;Yes</span>;
      default:
        return (
          (options.find((option) => option.value === value) as any) ? (options.find((option) => option.value === value) as any).label : null
        );
    }
  } else {
    return "Not Added";
  }
};

export const defaultCheckboxValue = (obj: any, options: Array<{ value: string; label: string }>, other?: any) => {
  const result: any = [];
  if (obj) {
    Object.keys(obj).forEach((key) => (obj[key] && key !== "other") &&
      result.push((options.find((option) => option.value === key) as any).label));
    if (obj.other && other) {
      result.push(other);
    }
  }
  return result.length > 0 ? result.join(", ") : "Not Added";
};

export const defaultFormValue = (data: any, key: string) => data && data[key] ? data[key] : "Not Added";
