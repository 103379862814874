import {
  INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_START,
  INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_SUCCESS,
} from "./type";

const initialState = {
  collectionResource: {
    items: [],
    isLoading: false,
  },
};

export const innovationItemStageOversightGeneralCommentsReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_START: {
      return {
        ...state,
        collectionResource: {
          items: [],
          isLoading: true,
        },
      };
    }
    case INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_SUCCESS: {
      return {
        ...state,
        collectionResource: {
          ...action.collectionResource,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
