import {Field, FormikProps} from "formik";
import React, {Fragment} from "react";
import {FormRadioCustomList, FormText} from "../../../../../../components/form";
import FormCheckboxList from "../../../../../../components/form/FormCheckboxList";
import ImmediatelyUploadMultipleField from "../../../../../../components/form/upload/ImmediatelyUploadMultipleField";
import {Col, Row} from "../../../../../../components/Grid";
import {ITestInformationGate} from "../../../../../../interface/entity/IInnovationEntity";
import TestUtils from "../utils/TestUtils";

interface IProps {
  form: FormikProps<ITestInformationGate>;
}

class Test extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <Fragment>
        <div className="form-group">
          <span className="form-label">{TestUtils.testMetricsConsumer}</span>
        </div>
        <div className="form-group">
          <span className="form-label text-primary-light">Consumer Research Protocol - Final Prototype Validation</span>
        </div>
        <div className="form-group">
          <Field
            name={TestUtils.testMetricsConsumerQualitative.name}
            label={TestUtils.testMetricsConsumerQualitative.label}
            component={FormCheckboxList}
            options={TestUtils.testMetricsConsumerQualitative.options}
          />
        </div>
        <div className="form-group">
          <Field
            name={TestUtils.testMetricsConsumerQuantitative.name}
            label={TestUtils.testMetricsConsumerQuantitative.label}
            component={FormCheckboxList}
            options={TestUtils.testMetricsConsumerQuantitative.options}
          />
        </div>
        <div className="form-group">
          <Field
            label={TestUtils.testMetricsConsumerQuantitativeAdjustmentsNeeded.label}
            name={TestUtils.testMetricsConsumerQuantitativeAdjustmentsNeeded.name}
            placeholder={TestUtils.testMetricsConsumerQuantitativeAdjustmentsNeeded.placeholder}
            component={FormText}
          />
        </div>
        <div className="form-group">
          <span className="form-label">{TestUtils.testMetricsCustomer}</span>
        </div>
        <div className="form-group">
          <span className="form-label text-primary-light">{TestUtils.testMetricsCustomerCustomerSuccess.title}</span>
        </div>
        <Row>
          <Col val={9}>
            <Row>
              {TestUtils.testMetricsCustomerCustomerSuccess.fields.map((field, index) => (
                <Col val="4" key={index}>
                  <div className="form-group">
                    <Field
                      label={field.label}
                      name={field.name}
                      placeholder={field.placeholder}
                      labelClass={field.labelClass}
                      component={FormText}
                    />
                  </div>
                </Col>
              ))}
              <Col val="4">
                <div className="form-group">
                  <Field
                    name={TestUtils.buyerAlignment.name}
                    label={TestUtils.buyerAlignment.label}
                    options={TestUtils.buyerAlignment.options}
                    component={FormRadioCustomList}
                    isLabelBold={false}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="form-group">
          <Field
            label={TestUtils.testMetricsCustomerCustomerSuccessAdjustmentsNeeded.label}
            name={TestUtils.testMetricsCustomerCustomerSuccessAdjustmentsNeeded.name}
            placeholder={TestUtils.testMetricsCustomerCustomerSuccessAdjustmentsNeeded.placeholder}
            component={FormText}
          />
        </div>
        <div className="form-group">
          <span className="form-label">{TestUtils.testMetricsOperations}</span>
        </div>
        <div className="form-group">
          <span className="form-label text-primary-light">{TestUtils.testMetricsOperationsCapacityAndYield.title}</span>
        </div>
        <Row>
          {TestUtils.testMetricsOperationsCapacityAndYield.fields.map((field, index) => (
            <Col val="3" key={index}>
              <div className="form-group">
                <Field
                  label={field.label}
                  name={field.name}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  component={FormText}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className="form-group">
          <Field
            label={TestUtils.testMetricsOperationsCogs.label}
            name={TestUtils.testMetricsOperationsCogs.name}
            placeholder={TestUtils.testMetricsOperationsCogs.placeholder}
            labelClass={TestUtils.testMetricsOperationsCogs.labelClass}
            component={FormText}
          />
        </div>
        <div className="form-group">
          <Field
            label={TestUtils.testMetricsOperationsAdjustmentsNeeded.label}
            name={TestUtils.testMetricsOperationsAdjustmentsNeeded.name}
            placeholder={TestUtils.testMetricsOperationsAdjustmentsNeeded.placeholder}
            component={FormText}
          />
        </div>
        <div className="mb-2">
          <span className="form-label">{TestUtils.testMetricsQcFoodSafety.title}</span>
        </div>
        {TestUtils.testMetricsQcFoodSafety.fields.map((field, index) => (
          <div className="form-group" key={index}>
            <Field
              label={field.label}
              name={field.name}
              placeholder={field.placeholder}
              labelClass={field.labelClass}
              component={FormText}
            />
          </div>
        ))}
        <div className="mb-2">
          <span className="form-label">{TestUtils.testMetricsFinancialMetrics.title}</span>
        </div>
        <Row>
          {TestUtils.testMetricsFinancialMetrics.fields.map((field, index) => (
            <Col val="4" key={index}>
              <div className="form-group">
                <Field
                  name={field.name}
                  label={field.label}
                  options={field.options}
                  component={FormRadioCustomList}
                />
              </div>
              <div className="form-group maxw-14">
                <Field
                  label={TestUtils.testMetricsFinancialMetricsValues.fields[index].label}
                  name={TestUtils.testMetricsFinancialMetricsValues.fields[index].name}
                  placeholder={TestUtils.testMetricsFinancialMetricsValues.fields[index].placeholder}
                  labelClass={TestUtils.testMetricsFinancialMetricsValues.fields[index].labelClass}
                  component={FormText}
                />
              </div>
            </Col>
          ))}
        </Row>
        <div className="form-group">
          <Field
            name="generalAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title={"General documents (such as costing sheets, food safety reports, sales forecasts, etc.):"}
          />
        </div>
        <div className="form-group">
          <Field
            name={TestUtils.anyOtherInformationYouWouldLikeToInclude.name}
            label={TestUtils.anyOtherInformationYouWouldLikeToInclude.label}
            placeholder={TestUtils.anyOtherInformationYouWouldLikeToInclude.placeholder}
            component={FormText}
          />
        </div>
      </Fragment>
    );
  }
}

export default Test;
