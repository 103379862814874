import {Formik} from "formik";
import React, {Fragment, FunctionComponent, useState} from "react";
import Modal from "react-modal";
import Ico from "../../components/Ico";
import {IInnovationCollectionFilters} from "../../services/innovation";
import {IFilterModalFormBody} from "../lists/modalForms";

interface IProps {
  filters: IInnovationCollectionFilters;
  onSetFilters: (values: IInnovationCollectionFilters) => void;
  filterModalForm: FunctionComponent<IFilterModalFormBody>;
}

const InnovationFilterModal: FunctionComponent<IProps> = (props) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const {onSetFilters, filters, filterModalForm} = props;

  const initialValues = {
    createDateFrom: filters.createDateFrom || undefined,
    createDateTo: filters.createDateTo || undefined,
    deadlineDateFrom: filters.deadlineDateFrom || undefined,
    deadlineDateTo: filters.deadlineDateTo || undefined,
    stages: filters.stages || undefined,
  };

  return <Fragment>
    <div className="text-right">
      <button className="btn btn-ghost" type="button" onClick={() => {
        setIsOpened(true);
      }}>
        <Ico name="settings"/>
      </button>
    </div>
    <Modal
      isOpen={isOpened}
      onRequestClose={() => {
        setIsOpened(false);
      }}
      contentLabel="Filters"
      className="modal modal-sm"
    >
      <header className="modal-header clearfix">
        <h3 className="modal-title pull-left">Filter Innovations</h3>
        <button onClick={() => {
          setIsOpened(false);
        }} className="modal-close">
          <i className="ico ico-close-thin"/>
        </button>
      </header>
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values: any) => {
            onSetFilters({...values, q: filters.q});
            setIsOpened(false);
          }}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                {React.createElement<IFilterModalFormBody>(filterModalForm, {
                  initialValues,
                })}
              </div>
              <footer className="modal-footer text-right">
                <button className="btn btn-primary btn-shade" type="submit">Apply filters</button>
              </footer>
            </form>
          )}
        />
      </div>
    </Modal>
  </Fragment>;
};

export default InnovationFilterModal;
