export const INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_MODAL = "INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_MODAL";
export const INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_EDIT_MODAL = "INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_EDIT_MODAL";
export const INNOVATION_ITEM_COMMENTS_DEPARTMENT_CLOSE_COMMENT_MODAL = "INNOVATION_ITEM_COMMENTS_DEPARTMENT_CLOSE_COMMENT_MODAL";
export const INNOVATION_ITEM_COMMENTS_DEPARTMENT_ADD = "INNOVATION_ITEM_COMMENTS_DEPARTMENT_ADD";
export const INNOVATION_ITEM_COMMENTS_DEPARTMENT_FETCH_USERS = "INNOVATION_ITEM_COMMENTS_DEPARTMENT_FETCH_USERS";
export const INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_START = "INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_START";
export const INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_SUCCESS = "INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_SUCCESS";

interface IInnovationItemOpenCommentModalAction {
  type: typeof INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_MODAL;
}

interface IInnovationItemOpenCommentModalEditAction {
  type: typeof INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_EDIT_MODAL;
}

interface IInnovationItemCloseCommentModalAction {
  type: typeof INNOVATION_ITEM_COMMENTS_DEPARTMENT_CLOSE_COMMENT_MODAL;
}

interface IInnovationItemCommentAddAction {
  type: typeof INNOVATION_ITEM_COMMENTS_DEPARTMENT_ADD;
}

interface IInnovationItemCommentFetchUsersAction {
  type: typeof INNOVATION_ITEM_COMMENTS_DEPARTMENT_FETCH_USERS;
}

interface IInnovationItemCommentDepartmentLoadingCommentStartAction {
  type: typeof INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_START;
}

interface IInnovationItemCommentDepartmentLoadingCommentSuccessAction {
  type: typeof INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_SUCCESS;
}
