import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";
import {IUserEntity} from "../../../../interface/entity/IUserEntity";
import {
  ADMIN_USER_UPDATE_DEINIT,
  ADMIN_USER_UPDATE_ERROR,
  ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_ERROR,
  ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_START,
  ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_SUCCESS,
  ADMIN_USER_UPDATE_INIT,
  ADMIN_USER_UPDATE_START,
  ADMIN_USER_UPDATE_SUCCESS,
} from "./type";

export const adminUserUpdateInit = (user: IUserEntity) => {
  return {
    type: ADMIN_USER_UPDATE_INIT,
    user,
  };
};

export const adminUserUpdateDeinit = () => {
  return {
    type: ADMIN_USER_UPDATE_DEINIT,
  };
};

export const adminUserUpdateStart = () => {
  return {
    type: ADMIN_USER_UPDATE_START,
  };
};

export const adminUserUpdateSuccess = () => {
  return {
    type: ADMIN_USER_UPDATE_SUCCESS,
  };
};

export const adminUserUpdateError = () => {
  return {
    type: ADMIN_USER_UPDATE_ERROR,
  };
};

export const adminUserUpdateFetchDepartmentsStart = () => {
  return {
    type: ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_START,
  };
};

export const adminUserUpdateFetchDepartmentsSuccess = (departments: IDepartmentEntity[]) => {
  return {
    type: ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_SUCCESS,
    departments,
  };
};

export const adminUserUpdateFetchDepartmentsError = () => {
  return {
    type: ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_ERROR,
  };
};
