import {Formik, FormikActions, FormikProps} from "formik";
import React from "react";
import * as Yup from "yup";
import {addErrorNotification} from "../components/Notification";
import axiosInstance from "../instance/axios";
import {IInnovationEntity} from "../interface/entity/IInnovationEntity";

export interface IFormValues {
  submitterFirstName: string;
  submitterLastName: string;
  submitterEmail: string;
  submitterJobTitle: string;
  submitterLocation: string;
  name: string;
  description: string;
}

const validationSchema = Yup.object().shape({
  submitterFirstName: Yup.string()
    .trim()
    .required("Required"),
  submitterLastName: Yup.string()
    .trim()
    .required("Required"),
  // tslint:disable-next-line
  submitterEmail: Yup.string()
    .email("Sorry, but email format is wrong")
    .trim()
    .required("Required"),
  submitterJobTitle: Yup.string()
    .trim()
    .required("Required"),
  submitterLocation: Yup.string()
    .trim()
    .required("Required"),
  name: Yup.string()
    .trim()
    .max(75)
    .required("Required"),
  description: Yup.string()
    .trim()
    .max(500)
    .required("Required"),
});

const initialValues = {
  submitterFirstName: "",
  submitterLastName: "",
  // tslint:disable-next-line
  submitterEmail: "",
  submitterJobTitle: "",
  submitterLocation: "",
  name: "",
  description: "",
};

interface IProps {
  onSuccess: (innovation: IInnovationEntity) => void;
  children: any;
}

class SubmitFormWrapper extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validateOnChange={true}
        validateOnBlur={false}
        validationSchema={validationSchema}
        render={(props: FormikProps<IFormValues>) => (
          <form onSubmit={props.handleSubmit}>
            {this.props.children(props)}
          </form>
        )}
      />
    );
  }

  private onSubmit = async (values: IFormValues, actions: FormikActions<IFormValues>) => {

    const {
      submitterFirstName,
      submitterLastName,
      submitterEmail,
      submitterJobTitle,
      submitterLocation,
      name,
      description,
    } = values;

    await axiosInstance().post(`/innovation`, {
      submitterFirstName,
      submitterLastName,
      // tslint:disable-next-line
      submitterEmail,
      submitterJobTitle,
      submitterLocation,
      name,
      description,
    })
      .then((response: { data: IInnovationEntity }) => {
        actions.resetForm();
        this.props.onSuccess(response.data);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          actions.setErrors(error.response.data);
        } else {
          // TODO log error
          addErrorNotification("Something gets wrong");
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }
}

export default SubmitFormWrapper;
