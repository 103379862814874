import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";
import {ADMIN_USER_CREATE_FETCH_DEPARTMENTS_ERROR, ADMIN_USER_CREATE_FETCH_DEPARTMENTS_START, ADMIN_USER_CREATE_FETCH_DEPARTMENTS_SUCCESS} from "./type";

export const adminUserCreateFetchDepartmentsStart = () => {
  return {
    type: ADMIN_USER_CREATE_FETCH_DEPARTMENTS_START,
  };
};

export const adminUserCreateFetchDepartmentsSuccess = (departments: IDepartmentEntity[]) => {
  return {
    type: ADMIN_USER_CREATE_FETCH_DEPARTMENTS_SUCCESS,
    departments,
  };
};

export const adminUserCreateFetchDepartmentsError = () => {
  return {
    type: ADMIN_USER_CREATE_FETCH_DEPARTMENTS_ERROR,
  };
};
