import {InnovationStatusKey} from "../../interface/entity/IInnovationEntity";
import {IUserEntity} from "../../interface/entity/IUserEntity";

export interface ISystemState {
  isGuest: boolean;
  authToken?: string;
  user?: IUserEntity;
  innovationStats: {status: InnovationStatusKey, statusValue: string, statusLabel: string, amount: number}[]
}

export const SYSTEM_SET_INNOVATION_STATS = "SYSTEM_SET_INNOVATION_STATS";
export const SYSTEM_SET_AUTH_TOKEN = "SYSTEM_SET_AUTH_TOKEN";
export const SYSTEM_SET_USER = "SYSTEM_SET_USER";
export const SYSTEM_SET_AUTH_TOKEN_AND_USER = "SYSTEM_SET_AUTH_TOKEN_AND_USER";

export const SYSTEM_DELETE_AUTH_TOKEN = "SYSTEM_DELETE_AUTH_TOKEN";
export const SYSTEM_DELETE_USER = "SYSTEM_DELETE_USER";
export const SYSTEM_DELETE_AUTH_TOKEN_AND_USER = "SYSTEM_DELETE_AUTH_TOKEN_AND_USER";

interface ISetInnovationStatsAction {
  type: typeof SYSTEM_SET_INNOVATION_STATS;
  innovationStats: {status: InnovationStatusKey, statusValue: string, statusLabel: string, amount: number}[];
}

interface ISetAuthTokenAction {
  type: typeof SYSTEM_SET_AUTH_TOKEN;
  authToken: string;
}

interface ISetUserAction {
  type: typeof SYSTEM_SET_USER;
  user: IUserEntity;
}

interface ISetAuthTokenAndUserAction {
  authToken: string;
  type: typeof SYSTEM_SET_AUTH_TOKEN_AND_USER;
  user: IUserEntity;
}

interface IDeleteAuthTokenAction {
  type: typeof SYSTEM_DELETE_AUTH_TOKEN;
}

interface IDeleteUserAction {
  type: typeof SYSTEM_DELETE_USER;
}

interface IDeleteAuthTokenAndUserAction {
  type: typeof SYSTEM_DELETE_AUTH_TOKEN_AND_USER;
}

export type ISystemActionTypes =
  ISetAuthTokenAction
  | ISetUserAction
  | ISetAuthTokenAndUserAction
  | IDeleteAuthTokenAction
  | IDeleteUserAction
  | IDeleteAuthTokenAndUserAction
  | ISetInnovationStatsAction;
