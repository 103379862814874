import {isInteger} from "formik";
import {IFormText, IFormToggle} from "../../../../../../components/form/IForm";
import {YesNo, YesNoApproved, YesNoDescribe} from "../../../../../../interface/entity/IInnovationEntity";

class RefineUtils {
  public static isConsumerResearchAvailable: IFormToggle = {
    name: "isConsumerResearchAvailable",
    label: "Is consumer research available to support consumer need:",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static transactionData: IFormToggle = {
    name: "transactionData",
    label: "Transaction Data:",
    answer: "Seed or plant variety",
    options: [
      {value: "duda", label: "Duda"},
      {value: "retailer", label: "Retailer"},
      {value: "syndicated", label: "Syndicated"},
      {value: "other", label: "Other"},
    ],
  };
  public static transactionDataOther: IFormText = {
    name: "transactionDataOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static qualitative: IFormToggle = {
    name: "qualitative",
    label: "Qualitative::",
    answer: "Seed or plant variety",
    options: [
      {value: "inMarket", label: "In Market"},
      {value: "shopper", label: "Shopper"},
      {value: "focusGroup", label: "Focus Group"},
      {value: "other", label: "Other"},
    ],
  };
  public static qualitativeOther: IFormText = {
    name: "qualitativeOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static quantitative: IFormToggle = {
    name: "quantitative",
    label: "Quantitative:",
    answer: "Seed or plant variety",
    options: [
      {value: "concept", label: "Concept"},
      {value: "clt", label: "CLT", tooltip: "Central Location Test"},
      {value: "hut", label: "HUT", tooltip: "Home Usage Test"},
      {value: "other", label: "Other"},
    ],
  };
  public static quantitativeOther: IFormText = {
    name: "quantitativeOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static isFieldResearchTestingProtocolPlanned: IFormToggle = {
    name: "isFieldResearchTestingProtocolPlanned",
    label: "Is field research testing protocol planned?",
    answer: "Field research testing protocol:",
    options: [
      {value: "structure", label: "Structure"},
      {value: "ergonomic", label: "Ergonomic"},
      {value: "usability", label: "Usability"},
      {value: "display", label: "Display"},
      {value: "pricing", label: "Pricing"},
      {value: "shopper", label: "Shopper"},
      {value: "other", label: "Other"},
    ],
  };
  public static isFieldResearchTestingProtocolPlannedOther: IFormText = {
    name: "isFieldResearchTestingProtocolPlannedOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static isThisAnExistingPackageStructure: IFormToggle = {
    name: "isThisAnExistingPackageStructure",
    label: "Is this an existing package structure?",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType: IFormToggle = {
    name: "doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType",
    label: "Does Duda Farm Fresh Foods currently offer the package structure or type:",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static whereIsThisPackageAvailable: IFormText = {
    name: "whereIsThisPackageAvailable",
    placeholder: "Please fill in details...",
    label: "Where is this package available:",
    labelClass: "text-regular text-primary-dark",
  };

  public static numberOfStoreLocations: IFormText = {
    name: "numberOfStoreLocations",
    placeholder: "e.g. 5",
    label: "Number of store locations:",
    labelClass: "text-regular text-primary-dark",
  };

  public static unitPerStorePerDay: IFormText = {
    name: "unitPerStorePerDay",
    placeholder: "e.g. 5",
    label: "Unit per store per day:",
    labelClass: "text-regular text-primary-dark",
  };

  public static numberOfDaysInMarket: IFormText = {
    name: "numberOfDaysInMarket",
    placeholder: "e.g. 5",
    label: "Number of days in market:",
    labelClass: "text-regular text-primary-dark",
  };

  public static totalUnits: IFormText = {
    name: "totalUnits",
    placeholder: "e.g. 5",
    label: "Total Units:",
    labelClass: "text-regular text-primary-dark",
  };

  public static yieldPerAcre: IFormText = {
    name: "yieldPerAcre",
    placeholder: "e.g. 5",
    label: "Yields per Acre:",
    labelClass: "text-regular text-primary-dark",
  };

  public static isThisACropDudaFarmFreshFoodsCurrentlyGrows: IFormToggle = {
    name: "isThisACropDudaFarmFreshFoodsCurrentlyGrows",
    label: "Is this a crop Duda Farm Fresh Foods currently grows:",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static pleaseEstimateTheContractGrownPricePerUnit: IFormText = {
    name: "pleaseEstimateTheContractGrownPricePerUnit",
    placeholder: "e.g. 5",
    label: "Please estimate the contract grown price per unit:",
    answer: "Contract grown price per unit",
    labelClass: "text-regular text-primary-dark",
  };

  public static doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation: IFormToggle = {
    name: "doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation",
    label: "Does Duda Farm Fresh Foods currently have the processing capability to produce this innovation:",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static whatPartnerIsBeingConsideredToProcess: IFormText = {
    name: "whatPartnerIsBeingConsideredToProcess",
    placeholder: "Please fill in details...",
    label: "What partner is being considered to process:",
    labelClass: "text-regular text-primary-dark",
  };

  public static coPackCosts: IFormText = {
    name: "coPackCosts",
    placeholder: "Please fill in details...",
    label: "Co-Pack Costs:",
    labelClass: "text-regular text-primary-dark",
  };

  public static isAnySpecialFormulationNecessaryForTheFinalProduct: IFormToggle = {
    name: "isAnySpecialFormulationNecessaryForTheFinalProduct",
    label: "Are any special formulations necessary for the final product to be produced?",
    answer: "Is any special formulation necessary for the final product:",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
      {value: YesNoDescribe.Describe, label: "Describe"},
    ],
  };
  public static isAnySpecialFormulationNecessaryForTheFinalProductDescription: IFormText = {
    name: "isAnySpecialFormulationNecessaryForTheFinalProductDescription",
    placeholder: "Please fill in details...",
    label: "Describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static rndApprove: IFormToggle = {
    name: "rndApprove",
    label: "R&D",
    options: [
      {value: YesNoApproved.Yes, label: "Yes"},
      {value: YesNoApproved.No, label: "No"},
      {value: YesNoApproved.Approved, label: "Approved"},
    ],
    labelClass: "text-regular text-primary-dark",
  };
  public static legalApprove: IFormToggle = {
    name: "legalApprove",
    label: "Legal",
    options: [
      {value: YesNoApproved.Yes, label: "Yes"},
      {value: YesNoApproved.No, label: "No"},
      {value: YesNoApproved.Approved, label: "Approved"},
    ],
    labelClass: "text-regular text-primary-dark",
  };
  public static foodSafetyApprove: IFormToggle = {
    name: "foodSafetyApprove",
    label: "Food Safety",
    options: [
      {value: YesNoApproved.Yes, label: "Yes"},
      {value: YesNoApproved.No, label: "No"},
      {value: YesNoApproved.Approved, label: "Approved"},
    ],
    labelClass: "text-regular text-primary-dark",
  };
  public static operationsApprove: IFormToggle = {
    name: "operationsApprove",
    label: "Operations",
    options: [
      {value: YesNoApproved.Yes, label: "Yes"},
      {value: YesNoApproved.No, label: "No"},
      {value: YesNoApproved.Approved, label: "Approved"},
    ],
    labelClass: "text-regular text-primary-dark",
  };
  public static financeApprove: IFormToggle = {
    name: "financeApprove",
    label: "Finance",
    options: [
      {value: YesNoApproved.Yes, label: "Yes"},
      {value: YesNoApproved.No, label: "No"},
      {value: YesNoApproved.Approved, label: "Approved"},
    ],
    labelClass: "text-regular text-primary-dark",
  };
  public static salesAndOpsApprove: IFormToggle = {
    name: "salesAndOpsApprove",
    label: "Sales and Ops",
    options: [
      {value: YesNoApproved.Yes, label: "Yes"},
      {value: YesNoApproved.No, label: "No"},
      {value: YesNoApproved.Approved, label: "Approved"},
    ],
    labelClass: "text-regular text-primary-dark",
  };

  public static willThisInnovationIncludeAnAdditionalManufacturedItem: IFormToggle = {
    name: "willThisInnovationIncludeAnAdditionalManufacturedItem",
    label: "Will this innovation include an additional manufactured item:",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static additionalItemDescription: IFormText = {
    name: "additionalItemDescription",
    placeholder: "Please fill in details...",
    label: "Name of item:",
    labelClass: "text-regular text-primary-dark",
  };
  public static additionalItemCost: IFormText = {
    name: "additionalItemCost",
    placeholder: "Please fill in details...",
    label: "Cost/Item:",
    labelClass: "text-regular text-primary-dark",
  };
  public static additionalItemPartner: IFormText = {
    name: "additionalItemPartner",
    placeholder: "Please fill in details...",
    label: "Partner:",
    labelClass: "text-regular text-primary-dark",
  };
  public static additionalItemAudit: IFormText = {
    name: "additionalItemAudit",
    placeholder: "Please fill in details...",
    label: "Audit:",
    labelClass: "text-regular text-primary-dark",
  };

  public static budgetProductSamples: IFormToggle = {
    name: "budgetProductSamples",
    label: "Product samples.",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static budgetProductSamplesValue: IFormText = {
    name: "budgetProductSamplesValue",
    placeholder: "Enter",
    label: "Product samples. Value",
    labelClass: "sr-only",
  };
  public static budgetArtwork: IFormToggle = {
    name: "budgetArtwork",
    label: "Artwork.",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static budgetArtworkValue: IFormText = {
    name: "budgetArtworkValue",
    placeholder: "Enter",
    label: "Artwork. Value",
    labelClass: "sr-only",
  };
  public static budgetMerchandising: IFormToggle = {
    name: "budgetMerchandising",
    label: "Merchandising.",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static budgetMerchandisingValue: IFormText = {
    name: "budgetMerchandisingValue",
    placeholder: "Enter",
    label: "Merchandising. Value",
    labelClass: "sr-only",
  };
  public static budgetPhoto: IFormToggle = {
    name: "budgetPhoto",
    label: "Product Photo.",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static budgetPhotoValue: IFormText = {
    name: "budgetPhotoValue",
    placeholder: "Enter",
    label: "Photo. Value",
    labelClass: "sr-only",
  };
  public static budgetNutrition: IFormToggle = {
    name: "budgetNutrition",
    label: "Nutrition label.",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static budgetNutritionValue: IFormText = {
    name: "budgetNutritionValue",
    placeholder: "Enter",
    label: "Nutrition. Value",
    labelClass: "sr-only",
  };
  public static budgetTrademark: IFormToggle = {
    name: "budgetTrademark",
    label: "Trademark.",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static budgetTrademarkValue: IFormText = {
    name: "budgetTrademarkValue",
    placeholder: "Enter",
    label: "Trademark. Value",
    labelClass: "sr-only",
  };
  public static budgetSales: IFormToggle = {
    name: "budgetSales",
    label: "Sales sheet.",
    options: [
      {value: YesNoDescribe.Yes, label: "Yes"},
      {value: YesNoDescribe.No, label: "No"},
    ],
  };
  public static budgetSalesValue: IFormText = {
    name: "budgetSalesValue",
    placeholder: "Enter",
    label: "Sales. Value",
    labelClass: "sr-only",
  };
  public static budgetMinOrder: IFormText = {
    name: "budgetMinOrder",
    placeholder: "Enter",
    label: "Is there a minimum number of units that can be ordered?",
  };
  public static budgetPackaging: IFormText = {
    name: "budgetPackaging",
    placeholder: "Enter",
    label: "Is the a preferred package vendor for this project?",
  };

  public static isThereATargetRetailPrice: IFormText = {
    name: "isThereATargetRetailPrice",
    placeholder: "Enter",
    label: "Is there a target retail price?",
  };

  public static whatIsTheTargetedDudaWhloesalePrice: IFormText = {
    name: "whatIsTheTargetedDudaWhloesalePrice",
    placeholder: "Enter",
    label: "The targeted Duda Wholesale Price?",
  };

  public static profitAbsolute: IFormText = {
    name: "profitAbsolute",
    placeholder: "Enter",
    label: "Profit absolute",
    labelClass: "sr-only",
  };

  public static profitPercent: IFormText = {
    name: "profitPercent",
    placeholder: "Enter",
    label: "Profit percent",
    labelClass: "sr-only",
  };
  public static preliminaryCogsIfKnown: IFormText = {
    name: "preliminaryCogsIfKnown",
    placeholder: "Please fill in details...",
    label: "Preliminary COGS (if known):",
  };
  public static pleaseConfirmEstimatedNumberOfUnitsNeeded: IFormText = {
    name: "pleaseConfirmEstimatedNumberOfUnitsNeeded",
    placeholder: "Please fill in details...",
    label: "Please confirm estimated number of units needed",
  };
  public static calculatePreliminaryTotalRevenue: IFormText = {
    name: "calculatePreliminaryTotalRevenue",
    placeholder: "Please fill in details...",
    label: "Calculate Preliminary Total Revenue:",
  };
  public static safetyField: IFormText = {
    name: "safetyField",
    placeholder: "Please fill in details...",
    label: "In field:",
    labelClass: "text-regular text-primary-dark",
  };
  public static safetyPlant: IFormText = {
    name: "safetyPlant",
    placeholder: "Please fill in details...",
    label: "In plant:",
    labelClass: "text-regular text-primary-dark",
  };
  public static safetyMarket: IFormText = {
    name: "safetyMarket",
    placeholder: "Please fill in details...",
    label: "In market:",
    labelClass: "text-regular text-primary-dark",
  };
  public static anyOtherInformationYouWouldLikeToInclude: IFormText = {
    label: "Any other information you would like to include:",
    name: "anyOtherInformationYouWouldLikeToInclude",
    placeholder: "Please Fill In Additional Information if any…",
  };

  // tslint:disable-next-line:max-line-length
  public static calculateNumberOfPackages({numberOfStoreLocations, unitPerStorePerDay, numberOfDaysInMarket}: { numberOfStoreLocations: string, unitPerStorePerDay: string, numberOfDaysInMarket: string }, noMessage: string = "Not Added") {
    if (!isInteger(numberOfStoreLocations) || !isInteger(unitPerStorePerDay) || !isInteger(numberOfDaysInMarket)) {
      return noMessage;
    }
    return (Number(numberOfStoreLocations) * Number(unitPerStorePerDay) * Number(numberOfDaysInMarket)).toLocaleString();
  }
}

export default RefineUtils;
