import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import axiosInstance from "../../../../../../instance/axios";
import {IInnovationDepartmentEntity, InnovationDepartmentStatusKey} from "../../../../../../interface/entity/IInnovationDepartmentEntity";
import {IInnovationEntity, InnovationStageKey, innovationStageKeyToValue, InnovationStatusKey} from "../../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../../store";
import {innovationItemFetchSuccess} from "../../../../../../store/innovationItem/common/action";
import {IInnovationItemCommonState} from "../../../../../../store/innovationItem/common/type";
import {innovationItemStageCommonSetStage} from "../../../../../../store/innovationItem/stage/common/action";
import {IInnovationItemStageCommonState} from "../../../../../../store/innovationItem/stage/common/type";
import {ViewMode} from "../../InnovationItemOversightComponent";

interface IProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemCommonState: IInnovationItemCommonState;
  innovationDepartments: IInnovationDepartmentEntity[];
  viewMode: ViewMode;
  onCommentAdd?: () => void;
  onInnovationItemFetchSuccess: (innovationEntity: IInnovationEntity) => void;
  setStage: (stage: InnovationStageKey) => void;
}

class InnovationItemOversightSwitchBar extends React.Component<IProps> {
  public render() {
    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}, viewMode} = this.props;

    const dashboardLink = "/innovation/" + (innovationEntity && innovationEntity.id) + "/" + innovationStageKeyToValue[stage || InnovationStageKey.Concept];
    // tslint:disable-next-line
    const commentsLink = "/innovation/" + (innovationEntity && innovationEntity.id) + "/" + innovationStageKeyToValue[stage || InnovationStageKey.Concept] + "/comments";

    return (
      <div className="clearfix mb-4">
        <div className="pull-left">
          <ul className="nav-shade">
            <li className={viewMode === ViewMode.Dashboard ? "active" : ""}>
              <Link to={dashboardLink}>
                <i className="ico ico-table "/>
              </Link>
            </li>
            <li className={(viewMode === ViewMode.Comments || viewMode === ViewMode.DepartmentComments) ? "active" : ""}>
              <Link to={commentsLink}>
                <i className="ico ico-chat "/>
              </Link>
            </li>
          </ul>
        </div>
        {this.buttons()}
      </div>
    );
  }

  private buttons = () => {
    const {innovationDepartments, innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;

    switch (this.props.viewMode) {
      case ViewMode.Dashboard:
        return (
          <div className="pull-right">
            <button
              className="btn-ghost-danger ml-4"
              type="button"
              onClick={
                () => this.updateInnovation({
                  status: innovationEntity && innovationEntity.status === InnovationStatusKey.Active ?
                    InnovationStatusKey.Canceled :
                    InnovationStatusKey.Active,
                })
              }
            >
              {innovationEntity && innovationEntity.status === InnovationStatusKey.Active ? "Archive" : "Unarchive"}
            </button>
            {innovationEntity && (innovationEntity.stage === stage) && innovationEntity.status !== InnovationStatusKey.Complete &&
            <button
                className="btn-ghost ml-4"
                type="button"
                disabled={
                  (innovationDepartments.filter((id) => id.status === InnovationDepartmentStatusKey.Go).length !== innovationDepartments.length) ||
                  innovationEntity.status === InnovationStatusKey.Canceled
                }
                onClick={() => {
                  const newStage = innovationEntity ? innovationEntity.stage + 1 : 0;
                  this.updateInnovation(innovationEntity.stage === InnovationStageKey.Execute ? {status: InnovationStatusKey.Complete} : {stage: newStage});
                }}
            >
              {innovationEntity.stage === InnovationStageKey.Execute ? "Complete" : "Advance"}
            </button>
            }
          </div>
        );
      case ViewMode.DepartmentComments:
        return (
          <div className="pull-right">
            <button
              className="btn btn-primary btn-shade btn-width ml-4"
              type="button"
              onClick={this.props.onCommentAdd}
            >
              Add comment
            </button>
          </div>
        );
      default:
        return null;
    }
  }

  private updateInnovation = async (values: any) => {
    const {innovationItemCommonState: {innovationEntity}} = this.props;
    await axiosInstance().put(
      "/innovation/" + (innovationEntity && innovationEntity.id),
      values,
    )
      .then((response: { data: IInnovationEntity }) => {
        this.props.onInnovationItemFetchSuccess(response.data);
        this.props.setStage(response.data.stage);
      })
      .catch((error) => {
        // TODO log error
        // this.props.onInnovationItemFetchError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
  innovationDepartments: state.innovationItem.stage.oversight.resolutions.innovationDepartmentEntities,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemFetchSuccess: (innovationEntity: IInnovationEntity) => {
    dispatch(innovationItemFetchSuccess(innovationEntity));
  },
  setStage: (stage: InnovationStageKey) => {
    dispatch(innovationItemStageCommonSetStage(stage));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemOversightSwitchBar);
