import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import WrapperLoader from "../../../../components/loader/WrapperLoader";
import axiosInstance from "../../../../instance/axios";
import {IInformationGate, innovationStageKeyToLabel} from "../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../store";
import {IInnovationItemCommonState} from "../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../store/innovationItem/stage/common/type";
import {innovationItemStageGateFetchStart, innovationItemStageGateFetchSuccess} from "../../../../store/innovationItem/stage/gate/action";
import {IInnovationItemStageGateState} from "../../../../store/innovationItem/stage/gate/type";
import InnovationItemInformationGateFormComponent from "./InnovationItemInformationGateComponent/InnovationItemInformationGateFormComponent";
import InnovationItemInformationGateViewComponent from "./InnovationItemInformationGateComponent/InnovationItemInformationGateViewComponent";

export enum ViewMode {
  View = 0,
  Edit = 1,
}

interface IProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemCommonState: IInnovationItemCommonState;
  innovationItemStageGateState: IInnovationItemStageGateState<IInformationGate>;

  onInnovationItemStageGateFetchStart: () => void;
  onInnovationItemStageGateFetchSuccess: (data: IInformationGate) => void;
}

interface IState {
  viewMode: ViewMode;
}

class InnovationItemInformationGateComponent extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      viewMode: ViewMode.View,
    };
  }

  public componentWillUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): void {
    if (nextProps.innovationItemStageCommonState.stage !== this.props.innovationItemStageCommonState.stage) {
      this.setState({viewMode: ViewMode.View});
    }
  }

  public async componentDidMount() {
    await this.fetchGateData();
  }

  public async componentDidUpdate(prevProps: IProps) {
    if (prevProps.innovationItemStageCommonState.stage !== this.props.innovationItemStageCommonState.stage) {
      await this.fetchGateData();
    }
  }

  public render(): React.ReactNode {
    const {viewMode} = this.state;

    const {innovationItemStageGateState: {isLoading}} = this.props;

    return (
      <WrapperLoader isLoading={isLoading}>
        <div className="mb-8">
          <h2 className="text-regular">
            {innovationStageKeyToLabel[this.props.innovationItemStageCommonState.stage]} Stage Innovation Details
          </h2>
          <hr className="mt-2"/>
          {viewMode === ViewMode.Edit
            ? <InnovationItemInformationGateFormComponent onSetViewMode={this.setViewMode}/>
            : <InnovationItemInformationGateViewComponent onSetViewMode={this.setViewMode}/>
          }
        </div>
      </WrapperLoader>
    );
  }

  private setViewMode = (viewMode: ViewMode) => {
    this.setState({viewMode});
  }

  private fetchGateData = async () => {

    this.props.onInnovationItemStageGateFetchStart();

    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;

    await axiosInstance().get("/innovation/" + (innovationEntity && innovationEntity.id) + "/gate/" + stage)
      .then((response: { data: { data: IInformationGate } }) => {
        this.props.onInnovationItemStageGateFetchSuccess(response.data.data);
      })
      .catch((error) => {
        // this.props.onInnovationItemOversightResolutionsDepartmentsFetchError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
  innovationItemStageGateState: state.innovationItem.stage.gate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemStageGateFetchStart: () => {
    dispatch(innovationItemStageGateFetchStart());
  },
  onInnovationItemStageGateFetchSuccess: (data: IInformationGate) => {
    dispatch(innovationItemStageGateFetchSuccess(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemInformationGateComponent);
