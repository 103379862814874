import {Field, Formik, FormikActions, FormikProps} from "formik";
import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {FormPassword} from "../components/form";
import AuthLayout from "../components/Layout/AuthLayout";
import {ILoginFormValuesInterface, loginFormValidationSchema, onLoginFormSubmit} from "../components/login";
import {APP_GENERAL_USER_EMAIL} from "../constant/app";
import {IUserEntity} from "../interface/entity/IUserEntity";
import mainLogo from "../markup/images/main-logo.png";
import {setAuthTokenAndUser} from "../store/system/action";

interface IProps {
  onSetAuthTokenAndUser: (authToken: string, user: IUserEntity) => void;
}

const initialValues = {
  email: APP_GENERAL_USER_EMAIL,
  password: "",
};

class SimpleLogin extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <AuthLayout>
        <header className="block-header">
          <div className="block-logo">
            <img src={mainLogo} alt="Duda Farms"/>
          </div>
          <h1 className="block-title h3">Welcome</h1>
        </header>
        <div className="block-body">
          <p>Welcome to Duda Farm Fresh Foods CREATE Innovation Portal. <br/> To continue, please enter the password sent via email:
          </p>
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={async (values: ILoginFormValuesInterface, actions: FormikActions<ILoginFormValuesInterface>) => {
              await onLoginFormSubmit(values, actions, (authToken, user) => {
                const {onSetAuthTokenAndUser} = this.props;
                onSetAuthTokenAndUser(authToken, user);
              });
            }}
            validationSchema={loginFormValidationSchema}
            render={(props: FormikProps<ILoginFormValuesInterface>) => (
              <form onSubmit={props.handleSubmit}>
                <div className="form-group">
                  <Field
                    name="password"
                    label="Password:"
                    placeholder="Password"
                    component={FormPassword}
                  />
                </div>
                <button className="btn btn-primary btn-lg btn-block" type="submit">Continue</button>
              </form>
            )}
          />
        </div>
      </AuthLayout>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetAuthTokenAndUser: (authToken: string, userEntity: IUserEntity) => {
    dispatch(setAuthTokenAndUser(authToken, userEntity));
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(SimpleLogin);
