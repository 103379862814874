import React from "react";
import {IInnovationEntity} from "../../../interface/entity/IInnovationEntity";

interface IProps {
  innovationEntity: IInnovationEntity;
}

class InnovationTRChart extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {innovationEntity} = this.props;

    const sum =
      Number(innovationEntity.reviewCountGo) +
      Number(innovationEntity.reviewCountNo) +
      Number(innovationEntity.reviewCountReturn) +
      Number(innovationEntity.reviewCountWaiting);

    const goWidth = innovationEntity.reviewCountGo * 100 / sum;
    const noWidth = innovationEntity.reviewCountNo * 100 / sum;
    const waitingWidth = innovationEntity.reviewCountWaiting * 100 / sum;
    const returnedWidth = innovationEntity.reviewCountReturn * 100 / sum;

    return (
      <div className="vote-chart">
        <div className="vote-chart-item vote-status-go" style={{width: "calc(" + goWidth + "%)"}}>
          {innovationEntity.reviewCountGo}
        </div>
        <div className="vote-chart-item vote-status-returned" style={{width: "calc(" + returnedWidth + "%)"}}>
          {innovationEntity.reviewCountReturn}
        </div>
        <div className="vote-chart-item vote-status-no" style={{width: "calc(" + noWidth + "%)"}}>
          {innovationEntity.reviewCountNo}
        </div>
        <div className="vote-chart-item vote-status-approval" style={{width: "calc(" + waitingWidth + "%)"}}>
          {innovationEntity.reviewCountWaiting}
        </div>
      </div>
    );
  }
}

export default InnovationTRChart;
