import React, { FunctionComponent } from "react";
import { InnovationStatusKey } from "../../interface/entity/IInnovationEntity";
import GeneralCollection from "./GeneralCollection";
import { InnovationRow } from "./innovationRows";
import { innovationFilterModalForm } from "./modalForms";

const InnovationCollection: FunctionComponent = () => {
  return (
    <GeneralCollection
      allowedStatuses={[
        InnovationStatusKey.Active,
        InnovationStatusKey.Canceled,
        InnovationStatusKey.Complete,
      ]}
      defaultStatus={InnovationStatusKey.Active}
      filterModalForm={innovationFilterModalForm}
      innovationTrComponent={InnovationRow}
    />
  );
};

export default InnovationCollection;
