// because 'history' is a dependency of the react-router package
// tslint:disable-next-line no-implicit-dependencies
import * as H from "history";
import React from "react";
import {Link} from "react-router-dom";
import {ADMIN_DEPARTMENT_LIST, ADMIN_USER_LIST} from "../../components/routes";
import SetGeneralEmployeePassword from "./UserList/SetGeneralEmployeePassword";

interface IProps {
  location: H.Location;
  link: {
    to: string,
    title: string,
  };
}

class ToggleSwitch extends React.Component<IProps> {
  public render() {

    const menuItems: Array<{ to: string, icon: string, isActive: () => boolean }> = [
      {
        to: ADMIN_USER_LIST,
        icon: "ico-admin",
        isActive: () => this.props.location.pathname === ADMIN_USER_LIST,
      },
      {
        to: ADMIN_DEPARTMENT_LIST,
        icon: "ico-dependency",
        isActive: () => this.props.location.pathname === ADMIN_DEPARTMENT_LIST,
      },
    ];

    return (
      <div className="clearfix mb-6">
        <div className="pull-left">
          <ul className="nav-shade">
            {menuItems.map((item) => (
              <li key={item.to} className={item.isActive() ? "active" : ""}>
                <Link to={item.to}>
                  <i className={`ico ${item.icon}`}/>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="pull-right">
          <SetGeneralEmployeePassword />
          <Link className="btn btn-primary btn-shade btn-width" to={this.props.link.to}>
            <i className="ico ico-plus "/>
            &nbsp; {this.props.link.title}
          </Link>
        </div>
      </div>
    );
  }
}

export default ToggleSwitch;
