// because 'history' is a dependency of the react-router package
// tslint:disable-next-line no-implicit-dependencies
import * as H from "history";
import React, {ReactNode} from "react";
import {Container} from "../Grid";
import Header from "../Header";

interface IProps {
  children: ReactNode;
  location: H.Location;
}

class BaseLayout extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {children} = this.props;
    return (
      <div className="page-wrap">
        <Header location={this.props.location}/>
        <Container className="mb-6">
          {children}
        </Container>
      </div>
    );
  }
}

export default BaseLayout;
