import React from "react";
import { connect } from "react-redux";
import { Route, RouteChildrenProps, Switch } from "react-router";
import { Dispatch } from "redux";
import BaseLayout from "../components/Layout/BaseLayout";
import PageLoader from "../components/loader/PageLoader";
import {
  INNOVATION_BASE_ROUTE,
  INNOVATION_MILESTONE_ROUTE,
  INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE,
  INNOVATION_OVERSIGHT_ROUTE,
  INNOVATION_STAGE_ROUTE,
} from "../components/routes";
import axiosInstance from "../instance/axios";
import {
  IInnovationEntity,
  InnovationStatusKey,
} from "../interface/entity/IInnovationEntity";
import { AppState } from "../store";
import {
  innovationItemFetchError,
  innovationItemFetchStart,
  innovationItemFetchSuccess,
  innovationItemInit,
} from "../store/innovationItem/common/action";
import { IInnovationItemCommonState } from "../store/innovationItem/common/type";
import InnovationItemActivity from "./InnovationItem/InnovationItemActivity/InnovationItemActivity";
import InnovationItemMilestone from "./InnovationItem/InnovationItemMilestone/InnovationItemMilestone";
import InnovationItemStage from "./InnovationItem/InnovationItemStage/InnovationItemStage";
import SubmissionItem from "./InnovationItem/SubmissionItem";

interface IProps extends RouteChildrenProps {
  match: {
    params: {
      id: number;
      stage: string;
      mode: string;
    };
    isExact: boolean;
    path: string;
    url: string;
  };
  onInnovationItemFetchStart: () => void;
  onInnovationItemFetchSuccess: (innovationEntity: IInnovationEntity) => void;
  onInnovationItemFetchError: (error: unknown) => void;
  onInnovationItemInit: () => void;

  innovationItemCommonState: IInnovationItemCommonState;
}

class InnovationItem extends React.Component<IProps> {
  public async componentDidMount() {
    this.props.onInnovationItemInit();
    await this.fetchInnovation();
  }

  public componentWillUnmount(): void {
    this.props.onInnovationItemInit();
  }

  public render(): React.ReactNode {
    const {
      innovationItemCommonState: { innovationEntity, isLoading },
    } = this.props;

    return (
      <BaseLayout location={this.props.location}>
        {innovationEntity && (
          <PageLoader isLoading={isLoading}>
            <Switch>
              <Route
                exact={true}
                path={INNOVATION_BASE_ROUTE}
                component={InnovationItemActivity}
              />
              <Route
                exact={true}
                path={INNOVATION_MILESTONE_ROUTE}
                component={InnovationItemMilestone}
              />
              <Route
                exact={true}
                path={[
                  INNOVATION_STAGE_ROUTE,
                  INNOVATION_OVERSIGHT_ROUTE,
                  INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE,
                ]}
                component={
                  [
                    InnovationStatusKey.Submitted,
                    InnovationStatusKey.Rejected,
                  ].includes(innovationEntity.status)
                    ? SubmissionItem
                    : InnovationItemStage
                }
              />
            </Switch>
          </PageLoader>
        )}
      </BaseLayout>
    );
  }

  private fetchInnovation = async () => {
    this.props.onInnovationItemFetchStart();
    await axiosInstance()
      .get("/innovation/" + this.props.match.params.id)
      .then((response: { data: IInnovationEntity }) => {
        this.props.onInnovationItemFetchSuccess(response.data);
      })
      .catch((error) => {
        // TODO log error
        this.props.onInnovationItemFetchError(error);
      });
  };
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemFetchError: (error: unknown) => {
    dispatch(innovationItemFetchError(error));
  },
  onInnovationItemFetchStart: () => {
    dispatch(innovationItemFetchStart());
  },
  onInnovationItemFetchSuccess: (innovationEntity: IInnovationEntity) => {
    dispatch(innovationItemFetchSuccess(innovationEntity));
  },
  onInnovationItemInit: () => {
    dispatch(innovationItemInit());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InnovationItem);
