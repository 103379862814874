import React, {ReactNode} from "react";

interface IProps {
  isLoading: boolean;
  children: ReactNode;
}

class PageLoader extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {children, isLoading} = this.props;
    return isLoading ? "Loading page" : children;
  }
}

export default PageLoader;
