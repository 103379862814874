import {IInformationGateEntity} from "../../../interface/entity/IInformationGateEntity";
import {IUserEntity} from "../../../interface/entity/IUserEntity";

export enum MilestoneView {
  View,
  Edit,
}

export interface IInnovationMilestone {
  isLoading: boolean;
  view: MilestoneView;
  gate?: IInformationGateEntity;
  managers: IUserEntity[];
  managersLoading: boolean;
  admins: IUserEntity[];
  adminsLoading: boolean;
}

export const INNOVATION_MILESTONE_VIEW = "INNOVATION_MILESTONE_VIEW";
export const INNOVATION_MILESTONE_GATE_FETCH_START = "INNOVATION_MILESTONE_GATE_FETCH_START";
export const INNOVATION_MILESTONE_GATE_FETCH_SUCCESS = "INNOVATION_MILESTONE_GATE_FETCH_SUCCESS";
export const INNOVATION_MILESTONE_REORDER = "INNOVATION_MILESTONE_REORDER";
export const INNOVATION_MILESTONE_MANAGERS_FETCH_START = "INNOVATION_MILESTONE_MANAGERS_FETCH_START";
export const INNOVATION_MILESTONE_MANAGERS_FETCH_SUCCESS = "INNOVATION_MILESTONE_MANAGERS_FETCH_SUCCESS";
export const INNOVATION_MILESTONE_ADMINS_FETCH_START = "INNOVATION_MILESTONE_ADMINS_FETCH_START";
export const INNOVATION_MILESTONE_ADMINS_FETCH_SUCCESS = "INNOVATION_MILESTONE_ADMINS_FETCH_SUCCESS";

interface IInnovationMilestoneView {
  type: typeof INNOVATION_MILESTONE_VIEW;
}
