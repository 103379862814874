export enum NotificationTypes {
  Success,
  Error,
}

export interface INotification {
  id: number;
  message: string;
  type: NotificationTypes;
}

export interface INotificationState {
  items: INotification[];
}

export const NOTIFICATION_ADD_NOTIFICATION = "NOTIFICATION_ADD_NOTIFICATION";
export const NOTIFICATION_REMOVE_NOTIFICATION = "NOTIFICATION_REMOVE_NOTIFICATION";

interface IAddNotificationAction {
  type: typeof NOTIFICATION_ADD_NOTIFICATION;
  notification: INotification;
}

interface IRemoveNotificationAction {
  type: typeof NOTIFICATION_REMOVE_NOTIFICATION;
  id: number;
}

export type INotificationActionTypes = IAddNotificationAction | IRemoveNotificationAction;
