import React from "react";
import {
  InnovationStatusKey,
  innovationStatusKeyToLabel,
} from "../../interface/entity/IInnovationEntity";
import { TabButton } from "./TabButton";

interface IInnovationStatusTabsBarProps {
  allowedStatuses: InnovationStatusKey[];
  onSetStatus: (status: InnovationStatusKey) => void;
  status: InnovationStatusKey;
}

class InnovationStatusTabsBar extends React.Component<
  IInnovationStatusTabsBarProps
> {
  public render(): React.ReactNode {
    const { allowedStatuses, onSetStatus, status } = this.props;
    return (
      <ul className="nav-status">
        {allowedStatuses.map((item) => (
          <TabButton
            key={`tab_${item.toString()}`}
            isActive={status === item}
            value={item}
            title={innovationStatusKeyToLabel[item]}
            onClickHandler={onSetStatus}
          />
        ))}
      </ul>
    );
  }
}

export default InnovationStatusTabsBar;
