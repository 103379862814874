import {IActivityEntity} from "../../../interface/entity/IActivityEntity";
import {ICollectionResource} from "../../../interface/rest/ICollectionResource";

export interface IInnovationItemActivityState {
  activityCollectionResource: ICollectionResource<IActivityEntity>;
}

export const INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_START = "INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_START";
export const INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_SUCCESS = "INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_SUCCESS";
export const INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_ERROR = "INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_ERROR";

interface IInnovationItemActivityCollectionFetchStartAction {
  type: typeof INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_START;
}

interface IInnovationItemActivityCollectionFetchSuccessAction {
  type: typeof INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_SUCCESS;
  activityCollectionResource: ICollectionResource<IActivityEntity>;
}

interface IInnovationItemActivityCollectionFetchErrorAction {
  type: typeof INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_ERROR;
}

export type IInnovationItemActionTypes =
  | IInnovationItemActivityCollectionFetchStartAction
  | IInnovationItemActivityCollectionFetchErrorAction
  | IInnovationItemActivityCollectionFetchSuccessAction;
