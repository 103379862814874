import {Form, Formik, FormikActions, FormikProps} from "formik";
import React from "react";
import {connect} from "react-redux";
import {Prompt} from "react-router-dom";
import {Dispatch} from "redux";
import {Col, Row} from "../../../../../components/Grid";
import prepareFormWithAttachments from "../../../../../helpers/prepareFormWithAttachments";
import axiosInstance from "../../../../../instance/axios";
import {IInformationGateEntity} from "../../../../../interface/entity/IInformationGateEntity";
import {
  IActionInformationGate,
  IConceptInformationGate,
  IEstimateInformationGate,
  IExecuteInformationGate,
  IInformationGate,
  InnovationStageKey,
  innovationStageKeyToLabel,
  IRefineInformationGate,
  ITestInformationGate,
} from "../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../store";
import {innovationItemChangeInformationGate} from "../../../../../store/innovationItem/common/action";
import {IInnovationItemCommonState} from "../../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../../store/innovationItem/stage/common/type";
import {innovationItemStageGateFetchStart, innovationItemStageGateFetchSuccess} from "../../../../../store/innovationItem/stage/gate/action";
import {IInnovationItemStageGateState} from "../../../../../store/innovationItem/stage/gate/type";
import {ViewMode} from "../InnovationItemInformationGateComponent";
import Action from "./form/Action";
import Concept from "./form/Concept";
import Estimate from "./form/Estimate";
import Execute from "./form/Execute";
import Refine from "./form/Refine";
import Test from "./form/Test";

interface IProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemCommonState: IInnovationItemCommonState;
  innovationItemStageGateState: IInnovationItemStageGateState<any>;
  onSetViewMode: (viewMode: ViewMode) => void;

  onInnovationItemStageGateFetchStart: () => void;
  onInnovationItemStageGateFetchSuccess: (data: IInformationGate) => void;
  updateInnovationGate: (data: IInformationGateEntity) => void;
}

interface IState {
  isBlocking: boolean;
}

class InnovationItemInformationGateFormComponent extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      isBlocking: false,
    };
  }

  public render(): React.ReactNode {
    const {innovationItemStageCommonState: {stage}} = this.props;
    const initialValues: IInformationGate = this.props.innovationItemStageGateState.data;

    const saveButton = (
      <Row className=" mb-4">
        <Col val="10">
          <div className="form-control-static text-regular text-primary-light">
            {/* tslint:disable-next-line:max-line-length */}
            Please fill in {innovationStageKeyToLabel[this.props.innovationItemStageCommonState.stage]} Stage innovation Details and click 'Save Innovation'
            button.
          </div>
        </Col>
        <Col val="2">
          <button type="submit" className="btn btn-primary btn-shade w-20pc">
            Save Innovation
          </button>
        </Col>
      </Row>
    );

    return (
      <div>
        <Prompt
          when={this.state.isBlocking}
          message="You have unsaved changes. Your data will be lost if you leave this page. Continue?"
        />
        <Formik
          initialValues={initialValues as any}
          onSubmit={async (values: IInformationGate, actions: FormikActions<IInformationGate>) => {
            actions.setSubmitting(true);
            await this.onSubmit(values);
            actions.setSubmitting(false);
          }}
          render={(formikBag: FormikProps<IInformationGate>) => (
            <Form
              onChange={() => {
                this.setState({isBlocking: true});
              }}
            >
              {saveButton}
              {stage === InnovationStageKey.Concept && <Concept form={(formikBag as FormikProps<IConceptInformationGate>)}/>}
              {stage === InnovationStageKey.Refine && <Refine form={(formikBag as FormikProps<IRefineInformationGate>)}/>}
              {stage === InnovationStageKey.Estimate && <Estimate form={(formikBag as FormikProps<IEstimateInformationGate>)}/>}
              {stage === InnovationStageKey.Action && <Action form={(formikBag as FormikProps<IActionInformationGate>)}/>}
              {stage === InnovationStageKey.Test && <Test form={(formikBag as FormikProps<ITestInformationGate>)}/>}
              {stage === InnovationStageKey.Execute && <Execute form={(formikBag as FormikProps<IExecuteInformationGate>)}/>}
              {saveButton}
            </Form>
          )}
        />
      </div>
    );
  }

  // private onSubmit = async (values: IInformationGate) => {
  private onSubmit = async (values: any) => {

    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;

    this.props.onInnovationItemStageGateFetchStart();

    await axiosInstance().put(
      "/innovation/" + (innovationEntity && innovationEntity.id) + "/gate/" + stage,
      {
        data: JSON.stringify(prepareFormWithAttachments(values, stage)),
      })
      .then((response: { data: IInformationGateEntity }) => {
        if (response.data.data) {
          this.props.onInnovationItemStageGateFetchSuccess(response.data.data);
        }
        this.props.onSetViewMode(ViewMode.View);
        this.props.updateInnovationGate(response.data);
      })
      .catch(() => {
        // TODO handle this case
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
  innovationItemStageGateState: state.innovationItem.stage.gate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemStageGateFetchStart: () => {
    dispatch(innovationItemStageGateFetchStart());
  },
  onInnovationItemStageGateFetchSuccess: (data: IInformationGate) => {
    dispatch(innovationItemStageGateFetchSuccess(data));
  },
  updateInnovationGate: (data: IInformationGateEntity) => {
    dispatch(innovationItemChangeInformationGate(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemInformationGateFormComponent);
