import {DateTime} from "luxon";
import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {IInformationGateEntity} from "../../../../../interface/entity/IInformationGateEntity";
import {IInnovationEntity, InnovationStageKey, innovationStageKeyToLabel} from "../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../store";
import {innovationItemChangeActiveTimeline} from "../../../../../store/innovationItem/common/action";
import {IInnovationItemCommonState} from "../../../../../store/innovationItem/common/type";

interface IProps {
  gate: IInformationGateEntity;
  innovationEntity: IInnovationEntity;
  innovationItemCommonState: IInnovationItemCommonState;

  onInnovationItemChangeActiveTimeline: (stage: InnovationStageKey) => void;
}

class TimelineItem extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {gate, innovationEntity, innovationItemCommonState, onInnovationItemChangeActiveTimeline} = this.props;

    let cssClass = "";
    if (innovationEntity.stage > gate.stage) {
      cssClass = "filled";
    } else if (innovationEntity.stage === gate.stage && gate.endDate) {
      cssClass = DateTime.fromISO(gate.endDate) < DateTime.local() ? "failed" : "filled";
    } else if (innovationEntity.stage < gate.stage && gate.endDate) {
      cssClass = DateTime.fromISO(gate.endDate) < DateTime.local() ? "failed" : "";
    }

    const activeTimeline =
      innovationItemCommonState.activeTimelineStage === null ?
        innovationEntity.stage
        :
        innovationItemCommonState.activeTimelineStage;

    return (
      <div className={`timeline-item ${cssClass} ${gate.stage === activeTimeline ? "active" : null}`}>
        <span
          className="timeline-item-letter"
          onClick={() => onInnovationItemChangeActiveTimeline(gate.stage)}
        >
          {innovationStageKeyToLabel[gate.stage][0]}
        </span>
        <div className="timeline-item-inner">
          <span className="timeline-item-title">{innovationStageKeyToLabel[gate.stage]}</span>
          <dl className="timeline-item-info">
              <dt>Start:</dt>
              <dd>{gate.startDate && DateTime.fromISO(gate.startDate).toFormat("MM/dd/y")}</dd>
          </dl>
          <dl className="timeline-item-info">
              <dt>End:</dt>
              <dd>{gate.endDate && DateTime.fromISO(gate.endDate).toFormat("MM/dd/y")}</dd>
          </dl>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemChangeActiveTimeline: (stage: InnovationStageKey) => {
    dispatch(innovationItemChangeActiveTimeline(stage));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TimelineItem);
