import {IFormText, IFormTextFields, IFormToggle, IFormToggleFields} from "../../../../../../components/form/IForm";
import {YesNo} from "../../../../../../interface/entity/IInnovationEntity";

class ExecuteUtils {
  public static salesPlan: IFormTextFields = {
    title: "Sales Plan:",
    fields: [
      {
        label: "Populate by customer and/or sales lead/team:",
        name: "salesPlan",
        placeholder: "Please fill in value…",
      },
    ],
  };

  public static marketingPlan: IFormTextFields = {
    title: "Marketing Plan:",
    fields: [
      {
        label: "Dollars for broad market launch:",
        name: "boardMarketLaunch",
        placeholder: "Please fill in value…",
      },
      {
        label: "Dollars by incentive/trade action:",
        name: "incentiveTradeAction",
        placeholder: "Please fill in value…",
      },
    ],
  };

  public static promoPricingPlan: IFormTextFields = {
    title: "Promo/Pricing Plan:",
    fields: [
      {
        label: "Dollars by pricing action:",
        name: "pricingAction",
        placeholder: "Please fill in value…",
      },
      {
        label: "Dollars by incentive/trade action:",
        name: "incentiveTradeAction",
        placeholder: "Please fill in value…",
      },
    ],
  };

  public static harvestDeliveryDate: IFormText = {
    name: "harvestDeliveryDate",
    label: "Harvest/Delivery Date:",
    placeholder: "Select Date…",
  };

  public static capacityProductivityForecastOnTrack: IFormTextFields = {
    title: "Capacity/Productivity forecast on track:",
    fields: [
      {
        label: "Field",
        name: "field",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Plant",
        name: "plant",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Outbound",
        name: "outbound",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
    ],
  };

  public static capacityProductivityForecastOnTrackSelector: IFormToggleFields = {
    title: "Capacity/Productivity forecast on track selectors:",
    fields: [
      {
        label: "On Track",
        name: "fieldSelector",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        label: "On Track",
        name: "plantSelector",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      {
        label: "On Track",
        name: "outboundSelector",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
    ],
  };

  public static areSalesOrderForecastOnTrack: IFormToggle = {
    label: "Sales Order Forecast on track:",
    name: "areSalesOrderForecastOnTrack",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static areSalesOrderForecastOnTrackAdjustment: IFormText = {
    label: "Adjustments:",
    name: "areSalesOrderForecastOnTrackAdjustment",
    placeholder: "Please fill in value…",
  };

  public static areFoodSafetyMetricsAcceptable: IFormToggle = {
    label: "Are QC/Food Safety Metrics Acceptable:",
    name: "areFoodSafetyMetricsAcceptable",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static areFoodSafetyMetricsAcceptableAdjustment: IFormText = {
    label: "Adjustments:",
    name: "areFoodSafetyMetricsAcceptableAdjustment",
    placeholder: "Please fill in value…",
  };

  public static anyOtherInformationYouWouldLikeToInclude: IFormText = {
    label: "Any other information you would like to include:",
    name: "anyOtherInformationYouWouldLikeToInclude",
    placeholder: "Please Fill In Additional Information if any…",
  };
}

export default ExecuteUtils;
