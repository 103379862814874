import { IInnovationDepartmentComment } from "../../../../../../interface/entity/IInnovationDepartmentComment";
import {ICollectionResource} from "../../../../../../interface/rest/ICollectionResource";

export const INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_START = "INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_START";
export const INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_SUCCESS = "INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_SUCCESS";

interface IInnovationItemStageOversightResolutionsDepartmentFetchStartAction {
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_START;
}

interface IInnovationItemStageOversightResolutionsDepartmentFetchSuccessAction {
  type: typeof INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_SUCCESS;
  collectionResource: ICollectionResource<IInnovationDepartmentComment>;
}
