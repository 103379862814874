import {
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_CLOSE_COMMENT_MODAL,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_FETCH_USERS,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_START,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_SUCCESS,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_EDIT_MODAL,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_MODAL,
} from "./type";

const initialState = {
  isCommentLoading: false,
  isAddCommentModalOpen: false,
  comment: null,
  departmentId: null,
  users: {
    items: [],
  },
};

export const innovationItemStageOversightDepartmentCommentsReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_MODAL: {
      return {
        ...state,
        isAddCommentModalOpen: true,
        departmentId: action.departmentId,
      };
    }
    case INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_EDIT_MODAL: {
      return {
        ...state,
        isAddCommentModalOpen: true,
        departmentId: action.departmentId,
        comment: action.comment,
      };
    }
    case INNOVATION_ITEM_COMMENTS_DEPARTMENT_CLOSE_COMMENT_MODAL: {
      return {
        ...state,
        isAddCommentModalOpen: false,
        departmentId: null,
        comment: null,
      };
    }
    case INNOVATION_ITEM_COMMENTS_DEPARTMENT_FETCH_USERS: {
      return {
        ...state,
        users: action.users,
      };
    }
    case INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_START: {
      return {
        ...state,
        isCommentLoading: true,
      };
    }
    case INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_SUCCESS: {
      return {
        ...state,
        isCommentLoading: false,
      };
    }
    default:
      return state;
  }
};
