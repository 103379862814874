import { Field, Formik } from "formik";
import { DateTime } from "luxon";
import React, { Fragment, FunctionComponent, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import store from "store";
import * as Yup from "yup";
import { FormTextArea } from "../../components/form";
import WrapperLoader from "../../components/loader/WrapperLoader";
import {
  addErrorNotification,
  addSuccessNotification,
} from "../../components/Notification";
import { LOCAL_STORAGE_INNOVATION_STATS } from "../../constant/localStorage";
import {
  IConceptInformationGate,
  IInnovationEntity,
  InnovationStageKey,
  innovationStageKeyToLabel,
  innovationStageKeyToValue,
  InnovationStatusKey,
  innovationStatusKeyToLabel,
} from "../../interface/entity/IInnovationEntity";
import {
  approveInnovation,
  getStats,
  rejectInnovation,
  reSubmitInnovation,
} from "../../services/innovation";
import { setInnovationStats } from "../../store/system/action";
import InnovationTRChart from "../InnovationCollection/InnovationTR/InnovationTRChart";

export interface IInnovationRow {
  innovation: IInnovationEntity;
  onClick: (innovation: IInnovationEntity) => void;
  onUpdate: (innovation: IInnovationEntity) => void;
}

const FirstTr: FunctionComponent<{ innovation: IInnovationEntity }> = ({
  innovation,
}) => {
  const getStatusClass = (status: InnovationStatusKey): string => {
    switch (status) {
      case InnovationStatusKey.Submitted:
        return "text-primary-darker";
      case InnovationStatusKey.Rejected:
      case InnovationStatusKey.Canceled:
        return "text-no";
      case InnovationStatusKey.Complete:
        return "text-completed";
      case InnovationStatusKey.Active:
        return "text-approval";
    }
  };

  return (
    <td>
      <p className="text-primary text-medium">{innovation.name}</p>
      <span className={getStatusClass(innovation.status)}>
        {innovationStatusKeyToLabel[innovation.status]}
      </span>
    </td>
  );
};

export const InnovationRow: React.FunctionComponent<IInnovationRow> = ({
  innovation,
  onClick,
  onUpdate,
}) => {
  const conceptStage = innovation.informationGates.find(
    (gate) => gate.stage === InnovationStageKey.Concept
  );
  const deadline = conceptStage
    ? (conceptStage.data as IConceptInformationGate).isThereAnExpectedLaunchDate
    : null;
  const currentStage = innovation.informationGates.find(
    (gate) => gate.stage === innovation.stage
  );
  const currentStageDeadline = currentStage ? currentStage.endDate : null;

  return (
    <tr
      onClick={() => {
        onClick(innovation);
      }}
    >
      <FirstTr innovation={innovation} />
      <td>
        <p className="mb-2 text-primary-darker">
          {innovation.submitterFullName}
        </p>
        <span className="text-primary-light text-nowrap text-ellipsis maxw-14">
          {innovation.submitterJobTitle}
        </span>
      </td>
      <td>
        <span className="text-primary-darker">
          {DateTime.fromISO(innovation.createdAt).toFormat("MM/dd/y")}
        </span>
      </td>
      <td>
        <div className="mb-2">
          <span
            className={"tag tag-" + innovationStageKeyToValue[innovation.stage]}
          >
            {innovationStageKeyToLabel[innovation.stage]}
          </span>
        </div>
        <p className="mb-0 text-primary-light text-nowrap">
          Deadline:{" "}
          <span className="text-primary-dark">
            {currentStageDeadline
              ? DateTime.fromISO(currentStageDeadline).toFormat("MM/dd/y")
              : "Not Added"}
          </span>
        </p>
      </td>
      <td>
        <p className="vote-chart-title">
          {innovation.reviewCountWaiting} /{" "}
          {Number(innovation.reviewCountGo) +
            Number(innovation.reviewCountNo) +
            Number(innovation.reviewCountReturn) +
            Number(innovation.reviewCountWaiting)}{" "}
          approvers pending action
        </p>
        <InnovationTRChart innovationEntity={innovation} />
      </td>
      <td>
        {deadline ? (
          deadline
        ) : (
          <span className={"text-primary-light"}>Not Added</span>
        )}
      </td>
    </tr>
  );
};

interface IConfirmModal {
  title: string;
  actionTitle: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: (values: { message: string }) => void;
  submitButtonClass: string;
}

export const ConfirmModal: FunctionComponent<IConfirmModal> = ({
  title,
  actionTitle,
  isOpen,
  onClose,
  isLoading,
  onSubmit,
  submitButtonClass,
}) => {
  const handleClose = () => {
    if (isLoading) {
      return;
    }

    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Remove department"
      className="modal modal-sm"
    >
      <WrapperLoader isLoading={isLoading}>
        <Formik
          initialValues={{ message: "" }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            message: Yup.string().trim().required("Required"),
          })}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <header className="modal-header clearfix">
                <h3 className="modal-title pull-left">{title}</h3>
                <button onClick={handleClose} className="modal-close">
                  <i className="ico ico-close-thin" />
                </button>
              </header>
              <div className="mobal-body px-5 py-6">
                <div className="form-group">
                  <Field
                    label="Add your comment here:"
                    name="message"
                    placeholder="Fill in text here…"
                    rows="3"
                    showCounter={true}
                    maxLength={300}
                    component={FormTextArea}
                  />
                </div>
              </div>
              <footer className="modal-footer text-right">
                <button
                  className="btn btn-shade ml-4"
                  type="button"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  className={`btn btn-shade ml-4 ${submitButtonClass}`}
                  type="submit"
                >
                  {actionTitle}
                </button>
              </footer>
            </form>
          )}
        />
      </WrapperLoader>
    </Modal>
  );
};

export const SubmissionRow: React.FunctionComponent<IInnovationRow> = ({
  innovation,
  onClick,
  onUpdate,
}) => {
  const [isApproveModalOpened, setIsApproveModalOpened] = useState<boolean>(
    false
  );
  const [isRejectModalOpened, setIsRejectModalOpened] = useState<boolean>(
    false
  );
  const [isReSubmitModalOpened, setIsReSubmitModalOpened] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const approveButton = (
    <div className="col">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsApproveModalOpened(true);
        }}
        className="btn-block btn btn-ghost"
      >
        Verify
      </button>
    </div>
  );

  const rejectButton = (
    <div className="col">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsRejectModalOpened(true);
        }}
        className="btn-block btn btn-ghost-danger"
      >
        Reject
      </button>
    </div>
  );

  const reSubmitButton = (
    <div className="col">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsReSubmitModalOpened(true);
        }}
        className="btn-block btn btn-ghost text-nowrap"
      >
        Re-submit
      </button>
    </div>
  );

  const dispatch = useDispatch();

  const refreshInnovationStats = async () => {
    const { data: innovationStats } = await getStats();
    dispatch(setInnovationStats(innovationStats));
    store.set(LOCAL_STORAGE_INNOVATION_STATS, innovationStats);
  };

  const approveModal = (
    <ConfirmModal
      actionTitle={"Verify"}
      isOpen={isApproveModalOpened}
      onClose={() => {
        setIsApproveModalOpened(false);
      }}
      title={"Verify innovation"}
      isLoading={isLoading}
      submitButtonClass={"btn-ghost"}
      onSubmit={async (values) => {
        setIsLoading(true);
        await approveInnovation({
          innovation,
          message: values.message,
        })
          .then(() => {
            onUpdate(innovation);
            setIsApproveModalOpened(false);
            addSuccessNotification("Innovation has been approved");
            refreshInnovationStats();
          })
          .catch(() => {
            addErrorNotification("Error on approving innovation");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    />
  );

  const rejectModal = (
    <ConfirmModal
      actionTitle={"Reject"}
      isOpen={isRejectModalOpened}
      onClose={() => {
        setIsRejectModalOpened(false);
      }}
      title={"Reject innovation"}
      isLoading={isLoading}
      submitButtonClass={"btn-ghost-danger"}
      onSubmit={async (values) => {
        setIsLoading(true);
        await rejectInnovation({
          innovation,
          message: values.message,
        })
          .then(() => {
            onUpdate(innovation);
            setIsRejectModalOpened(false);
            addSuccessNotification("Innovation has been rejected");
            refreshInnovationStats();
          })
          .catch(() => {
            addErrorNotification("Error on rejecting innovation");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    />
  );

  const reSubmitModal = (
    <ConfirmModal
      actionTitle={"Re-submit"}
      isOpen={isReSubmitModalOpened}
      onClose={() => {
        setIsReSubmitModalOpened(false);
      }}
      title={"Re-submit innovation"}
      isLoading={isLoading}
      submitButtonClass={"btn-ghost"}
      onSubmit={async (values) => {
        setIsLoading(true);
        await reSubmitInnovation({
          innovation,
          message: values.message,
        })
          .then(() => {
            onUpdate(innovation);
            setIsRejectModalOpened(false);
            addSuccessNotification("Innovation has been re-submitted");
            refreshInnovationStats();
          })
          .catch(() => {
            addErrorNotification("Error on re-submitting innovation");
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
    />
  );

  return (
    <Fragment>
      {approveModal}
      {rejectModal}
      {reSubmitModal}
      <tr
        onClick={() => {
          onClick(innovation);
        }}
      >
        <FirstTr innovation={innovation} />
        <td>
          <p className="mb-2 text-primary-darker">
            {innovation.submitterFullName}
          </p>
          <span className="text-primary-light text-nowrap text-ellipsis maxw-14">
            {innovation.submitterJobTitle}
          </span>
        </td>
        <td>
          <span className="text-primary-darker">
            {DateTime.fromISO(innovation.createdAt).toFormat("MM/dd/y")}
          </span>
        </td>
        <td>{innovation.description}</td>
        <td>
          {innovation.status === InnovationStatusKey.Submitted ? (
            <div className="row nowrap">
              {approveButton}
              {rejectButton}
            </div>
          ) : (
            ""
          )}
          {innovation.status === InnovationStatusKey.Rejected
            ? reSubmitButton
            : ""}
        </td>
      </tr>
    </Fragment>
  );
};
