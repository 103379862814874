import React from "react";
import {connect} from "react-redux";
import {RouteChildrenProps} from "react-router";
import {Dispatch} from "redux";
import BaseLayout from "../../components/Layout/BaseLayout";
import WrapperLoader from "../../components/loader/WrapperLoader";
import Pagination from "../../components/Pagination";
import {ADMIN_USER_ADD} from "../../components/routes";
import axiosInstance from "../../instance/axios";
import {IUserEntity} from "../../interface/entity/IUserEntity";
import {ICollectionResource} from "../../interface/rest/ICollectionResource";
import {AppState} from "../../store";
import {
  adminUserCollectionFetchError,
  adminUserCollectionFetchStart,
  adminUserCollectionFetchSuccess,
  adminUserCollectionInit,
} from "../../store/admin/user/list/action";
import {IAdminUserListState} from "../../store/admin/user/list/type";
import ToggleSwitch from "./ToggleSwitch";
import UserTr from "./UserList/UserTr";

interface IProps extends RouteChildrenProps {
  onAdminUserCollectionInit: () => void;
  onAdminUserCollectionFetchStart: () => void;
  onAdminUserCollectionFetchSuccess: (collectionResource: ICollectionResource<IUserEntity>) => void;
  onAdminUserCollectionFetchError: (error: unknown) => void;

  adminUserListState: IAdminUserListState;
}

class UserList extends React.Component<IProps> {
  public async componentDidMount() {
    this.props.onAdminUserCollectionInit();

    await this.fetchUsers();
  }

  public componentWillUnmount(): void {
    this.props.onAdminUserCollectionInit();
  }

  public async componentDidUpdate(prevProps: IProps) {
    const prevLocation = new URLSearchParams(prevProps.location.search);
    const currentLocation = new URLSearchParams(this.props.location.search);

    if (prevLocation.get("page") !== currentLocation.get("page")) {
      await this.fetchUsers();
    }
  }

  public render(): React.ReactNode {
    const {adminUserListState: {collectionResource}, history, location} = this.props;

    return (
      <BaseLayout location={this.props.location}>
        <ToggleSwitch location={this.props.location} link={{to: ADMIN_USER_ADD, title: "Add User"}}/>
        <div>
          <WrapperLoader isLoading={collectionResource.isLoading} message={"We're loading users. Please wait..."}>
            <table className="table table-default table-vertical-middle">
              <thead>
              <tr>
                <th className="pl-16">User Name</th>
                <th>User Role</th>
                <th>Department</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {collectionResource.items.map((user: IUserEntity) => {
                return <UserTr
                  user={user}
                  key={user.id}
                  onSuccess={async () => {
                    const {adminUserListState: {collectionResource: {pagination, items}}} = this.props;
                    let page = pagination ? pagination.page : 1;
                    if (items.length === 1) {
                      page--;

                      const currentPropsParams = new URLSearchParams(location.search);
                      if (page === 1) {
                        currentPropsParams.delete("page");
                      } else {
                        currentPropsParams.set("page", String(page));
                      }
                      history.push([location.pathname, ...[currentPropsParams ? "?" : "", currentPropsParams.toString()]].join(""));
                    }
                    await this.fetchUsers(page);
                  }}
                />;
              })}
              </tbody>
            </table>
            {collectionResource.pagination
            && collectionResource.pagination.totalPages > 1
            && <Pagination
              {...collectionResource.pagination}
              onChange={(page: number) => {
                const currentPropsParams = new URLSearchParams(location.search);
                if (page === 1) {
                  currentPropsParams.delete("page");
                } else {
                  currentPropsParams.set("page", String(page));
                }
                history.push([location.pathname, ...[currentPropsParams ? "?" : "", currentPropsParams.toString()]].join(""));
              }}
            />}
          </WrapperLoader>
        </div>
      </BaseLayout>
    );
  }

  private fetchUsers = async (page?: number) => {
    this.props.onAdminUserCollectionFetchStart();

    const currentPropsParams = new URLSearchParams(this.props.location.search);

    await axiosInstance().get("/user", {
      params: {
        page: page || currentPropsParams.get("page") || 1,
      },
    })
      .then((response: { data: ICollectionResource<IUserEntity> }) => {
        this.props.onAdminUserCollectionFetchSuccess(response.data);
      })
      .catch((error) => {
        this.props.onAdminUserCollectionFetchError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  adminUserListState: state.admin.user.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAdminUserCollectionFetchError: (error: unknown) => {
    dispatch(adminUserCollectionFetchError(error));
  },
  onAdminUserCollectionFetchStart: () => {
    dispatch(adminUserCollectionFetchStart());
  },
  onAdminUserCollectionFetchSuccess: (collectionResource: ICollectionResource<IUserEntity>) => {
    dispatch(adminUserCollectionFetchSuccess(collectionResource));
  },
  onAdminUserCollectionInit: () => {
    dispatch(adminUserCollectionInit());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserList);
