import {IInnovationDepartmentComments} from "../../../../../../interface/entity/IInnovationDepartmentComment";

import {
  INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_START,
  INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_SUCCESS,
} from "./type";

export const innovationItemOversightStageCommentsFetchStart = () => {
  return {
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_START,
  };
};

export const innovationItemOversightStageCommentsFetchSuccess = (collectionResource: IInnovationDepartmentComments) => {
  return {
    collectionResource,
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_COMMENTS_FETCH_SUCCESS,
  };
};
