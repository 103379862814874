import {FieldProps} from "formik";
import React, {Fragment, ReactNode} from "react";

interface IFormRadioList extends FieldProps {
  label?: string;
  labelHtml?: ReactNode;
  labelClass?: string;
  options: Array<{ value: string, label: string, className?: string }>;
  isLabelRegular?: boolean;
}

const FormRadioCustomList = ({field, label, options, labelClass, labelHtml, isLabelRegular = true}: IFormRadioList) => {
  return (
    <Fragment>
      {label &&
      <span className={`form-label${label === field.name ? " sr-only" : ""}${labelClass ? (" " + labelClass) : ""}${isLabelRegular ? (" text-regular") : ""}`}>
        {label} {labelHtml}
      </span>}
      <div className="form-radio-custom-wrap">
        {
          options.map((item, index: number) => (
            <label key={index} className={`form-radio-custom ${item.className ? item.className : ""}`}>
              <input
                {...field}
                value={item.value}
                checked={item.value === field.value}
                type="radio"
                className="form-radio-custom-input"
              />
              <span className="form-radio-custom-label">{item.label}</span>
            </label>
          ))
        }
      </div>
    </Fragment>
  );
};

export default FormRadioCustomList;
