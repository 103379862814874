import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {reduxStore} from "../index";
import {AppState} from "../store";
import {deleteAuthTokenAndUser} from "../store/system/action";

const axiosInstance = (overloadConfig?: AxiosRequestConfig): AxiosInstance => {

  const store = reduxStore;

  const {system: {authToken}}: AppState = store.getState();

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: "json",
    ...overloadConfig,
  });

  instance.interceptors.request.use((config: AxiosRequestConfig) => {
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  }, (err: any) => Promise.reject(err));

  instance.interceptors.response.use((response: AxiosResponse) => response, (error: any) => {
    if (error.response.status === 401) {
      store.dispatch(deleteAuthTokenAndUser());
    } else if (error.response.status === 422) {
      const errors = error.response.data.errors;
      Object.keys(errors).forEach((key: string) => {
        errors[key] = errors[key].msg;
      });

      error.response.data = errors;
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  });

  return instance;
};

export default axiosInstance;
