import {Field, Formik, FormikProps} from "formik";
import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Link, withRouter} from "react-router-dom";
import {Dispatch} from "redux";
import * as Yup from "yup";
import {FormText} from "../../components/form";
import FormTextArea from "../../components/form/FormTextArea";
import BaseLayout from "../../components/Layout/BaseLayout";
import WrapperLoader from "../../components/loader/WrapperLoader";
import {addErrorNotification, addSuccessNotification} from "../../components/Notification";
import {ADMIN_DEPARTMENT_LIST} from "../../components/routes";
import axiosInstance from "../../instance/axios";
import {IDepartmentEntity} from "../../interface/entity/IDepartmentEntity";
import {AppState} from "../../store";
import {
  adminDepartmentUpdateDeinit, adminDepartmentUpdateError,
  adminDepartmentUpdateInit,
  adminDepartmentUpdateStart,
  adminDepartmentUpdateSuccess,
} from "../../store/admin/department/update/action";
import {IAdminDepartmentUpdateState} from "../../store/admin/department/update/type";

interface IFormValues {
  name: string;
  description: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(3)
    .required("Required"),
  description: Yup.string(),
});

interface IProps extends RouteComponentProps {
  adminDepartmentUpdate: IAdminDepartmentUpdateState;
  onAdminDepartmentUpdateInit: (department: IDepartmentEntity) => void;
  onAdminDepartmentUpdateDeinit: () => void;
  onAdminDepartmentUpdateStart: () => void;
  onAdminDepartmentUpdateSuccess: () => void;
  onAdminDepartmentUpdateError: () => void;
  match: {
    params: {
      id: number;
    },
    isExact: boolean;
    path: string;
    url: string;
  };
}

class DepartmentUpdate extends React.Component<IProps> {
  public async componentDidMount() {
    await this.fetchDepartment(this.props.match.params.id);
  }

  public componentWillUnmount(): void {
    this.props.onAdminDepartmentUpdateDeinit();
  }

  public render(): React.ReactNode {

    const {adminDepartmentUpdate} = this.props;

    return (
      <BaseLayout location={this.props.location}>
        <div className="mb-6">
          <Link to={ADMIN_DEPARTMENT_LIST}>&lt; Back to Departments List</Link>
        </div>
        <WrapperLoader isLoading={!adminDepartmentUpdate.department || adminDepartmentUpdate.isLoading}>
          {this.getForm()}
        </WrapperLoader>
      </BaseLayout>
    );
  }

  private onSubmit = async (data: IFormValues) => {
    const {adminDepartmentUpdate: {department}} = this.props;
    if (!department) {
      return;
    }
    this.props.onAdminDepartmentUpdateStart();
    await axiosInstance().put("/department/" + department.id, {
      name: data.name,
      description: data.description,
    })
      .then(() => {
        const {history} = this.props;
        history.push(ADMIN_DEPARTMENT_LIST);
        addSuccessNotification("Successfully updated!");
        this.props.onAdminDepartmentUpdateSuccess();
      })
      .catch(() => {
        addErrorNotification("Error on updating a department");
        this.props.onAdminDepartmentUpdateError();
      });
  }

  private fetchDepartment = async (id: number) => {
    await axiosInstance().get("/department/" + id)
      .then((response: { data: IDepartmentEntity }) => {
        this.props.onAdminDepartmentUpdateInit(response.data);
      })
      .catch(() => {
        // TODO handle this case
      });
  }

  private getForm = () => {
    const {adminDepartmentUpdate: {department}} = this.props;
    if (!department) {
      return;
    }

    const initialValues: IFormValues = {
      name: department.name,
      description: department.description,
    };
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validationSchema={validationSchema}
        render={(props: FormikProps<IFormValues>) => (
          <form onSubmit={props.handleSubmit}>
            <div className="details-header-line mb-4">
              <div className="pull-left">
                <h1 className="h2 m-0 pt-2">Update Department</h1>
              </div>
              <div className="pull-right">
                <button disabled={!props.isValid} className="btn btn-primary btn-shade btn-width" type="submit">
                  Save Department
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-8">
                    <div className="form-group">
                      <Field
                        label="Department Name*:"
                        name="name"
                        placeholder="e.g. Innovations"
                        component={FormText}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <Field
                    label="Department Description:"
                    name="description"
                    placeholder="Please fill in Department Description…"
                    component={FormTextArea}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      />
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  adminDepartmentUpdate: state.admin.department.update,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAdminDepartmentUpdateInit: (department: IDepartmentEntity) => {
    dispatch(adminDepartmentUpdateInit(department));
  },
  onAdminDepartmentUpdateDeinit: () => {
    dispatch(adminDepartmentUpdateDeinit());
  },
  onAdminDepartmentUpdateStart: () => {
    dispatch(adminDepartmentUpdateStart());
  },
  onAdminDepartmentUpdateSuccess: () => {
    dispatch(adminDepartmentUpdateSuccess());
  },
  onAdminDepartmentUpdateError: () => {
    dispatch(adminDepartmentUpdateError());
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DepartmentUpdate));
