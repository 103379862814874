const checkFile = (file: File, success: () => void, error: (error: string) => void) => {

  const maxFileSize = process.env.REACT_APP_STORAGE_MAX_FILE_SIZE ? Number(process.env.REACT_APP_STORAGE_MAX_FILE_SIZE) : 1 * 1024 * 1024;
  const allowedExtensions = process.env.REACT_APP_STORAGE_ALLOWED_EXTENSION
    ? process.env.REACT_APP_STORAGE_ALLOWED_EXTENSION.split(",").map((item) => item.replace(".", ""))
    : ["jpg", "jpeg", "png", "pdf"];
  const extension = file.name.split(".").pop() || "";

  if (extension === "") {
    error("File type not supported");
    return;
  } else if (!allowedExtensions.includes(extension)) {
    error("File type not supported");
    return;
  } else if (file.size > maxFileSize) {
    error("File size limit");
    return;
  } else {
    success();
    return;
  }

};

export default checkFile;
