import {DateTime} from "luxon";
import React, {Fragment, FunctionComponent, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import store from "store";
import {addErrorNotification, addSuccessNotification,} from "../../components/Notification";
import {SUBMISSION_LIST} from "../../components/routes";
import {LOCAL_STORAGE_INNOVATION_STATS} from "../../constant/localStorage";
import axiosInstance from "../../instance/axios";
import {IInnovationEntity, InnovationStatusKey, innovationStatusKeyToLabel,} from "../../interface/entity/IInnovationEntity";
import {approveInnovation, getStats, rejectInnovation, reSubmitInnovation,} from "../../services/innovation";
import {AppState} from "../../store";
import {innovationItemFetchSuccess} from "../../store/innovationItem/common/action";
import {IInnovationItemCommonState} from "../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../store/innovationItem/stage/common/type";
import {setInnovationStats} from "../../store/system/action";
import {ConfirmModal} from "../lists/innovationRows";

interface IProps {
  innovationItemCommonState: IInnovationItemCommonState;
  innovationItemStageCommonState: IInnovationItemStageCommonState;

  onSetInnovationStats: (
    innovationStats: {
      status: InnovationStatusKey;
      statusValue: string;
      statusLabel: string;
      amount: number;
    }[]
  ) => void;
}

const SubmissionItem: FunctionComponent<IProps> = ({
  innovationItemCommonState: { innovationEntity: innovation },
  onSetInnovationStats,
}) => {
  const dispatch = useDispatch();
  const [isApproveModalOpened, setIsApproveModalOpened] = useState<boolean>(
    false
  );
  const [isRejectModalOpened, setIsRejectModalOpened] = useState<boolean>(
    false
  );
  const [isReSubmitModalOpened, setIsReSubmitModalOpened] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updateInnovation = async () => {
    await axiosInstance()
      .get("/innovation/" + (innovation && innovation.id))
      .then((response: { data: IInnovationEntity }) => {
        dispatch(innovationItemFetchSuccess(response.data));
      });
  };

  const approveModal = (
    <ConfirmModal
      actionTitle={"Verify"}
      isOpen={isApproveModalOpened}
      onClose={() => {
        setIsApproveModalOpened(false);
      }}
      title={"Verify innovation"}
      isLoading={isLoading}
      submitButtonClass={"btn-ghost"}
      onSubmit={async (values) => {
        if (innovation) {
          setIsLoading(true);
          await approveInnovation({
            innovation,
            message: values.message,
          })
            .then(async () => {
              setIsApproveModalOpened(false);
              addSuccessNotification("Innovation has been approved");
              await updateInnovation();
              await refreshInnovationStats();
            })
            .catch(() => {
              addErrorNotification("Error on approving innovation");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }}
    />
  );

  const rejectModal = (
    <ConfirmModal
      actionTitle={"Reject"}
      isOpen={isRejectModalOpened}
      onClose={() => {
        setIsRejectModalOpened(false);
      }}
      title={"Reject innovation"}
      isLoading={isLoading}
      submitButtonClass={"btn-ghost"}
      onSubmit={async (values) => {
        if (innovation) {
          setIsLoading(true);
          await rejectInnovation({
            innovation,
            message: values.message,
          })
            .then(async () => {
              setIsRejectModalOpened(false);
              addSuccessNotification("Innovation has been rejected");
              await updateInnovation();
              await refreshInnovationStats();
            })
            .catch(() => {
              addErrorNotification("Error on rejecting innovation");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }}
    />
  );

  const reSubmitModal = (
    <ConfirmModal
      actionTitle={"Re-submit"}
      isOpen={isReSubmitModalOpened}
      onClose={() => {
        setIsReSubmitModalOpened(false);
      }}
      title={"Re-submit innovation"}
      isLoading={isLoading}
      submitButtonClass={"btn-ghost"}
      onSubmit={async (values) => {
        if (innovation) {
          setIsLoading(true);
          await reSubmitInnovation({
            innovation,
            message: values.message,
          })
            .then(async () => {
              setIsReSubmitModalOpened(false);
              addSuccessNotification("Innovation has been re-submitted");
              await updateInnovation();
              await refreshInnovationStats();
            })
            .catch(() => {
              addErrorNotification("Error on re-submitting innovation");
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }}
    />
  );

  const approveButton = (
    <div className="col">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsApproveModalOpened(true);
        }}
        className="btn-block btn btn-ghost"
      >
        Verify
      </button>
    </div>
  );

  const rejectButton = (
    <div className="col">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsRejectModalOpened(true);
        }}
        className="btn-block btn btn-ghost-danger"
      >
        Reject
      </button>
    </div>
  );

  const reSubmitButton = (
    <div className="col">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsReSubmitModalOpened(true);
        }}
        className="btn-block btn btn-ghost"
      >
        Re-submit
      </button>
    </div>
  );

  const refreshInnovationStats = async () => {
    const { data: innovationStats } = await getStats();
    onSetInnovationStats(innovationStats);
    store.set(LOCAL_STORAGE_INNOVATION_STATS, innovationStats);
  };

  return (
    <div>
      <div className="mb-6">
        <Link to={`${SUBMISSION_LIST}${innovation && innovation.status === InnovationStatusKey.Rejected ? ("?status="+InnovationStatusKey.Rejected) : ""}`}>&lt; Back to Innovations List</Link>
      </div>
      <div className="details-header-line">
        <div className="pull-left">
          <h1 className="m-0 pt-1">{innovation && innovation.name}</h1>
        </div>
        <div className="pull-right">
          <Link to={"/innovation/" + (innovation && innovation.id)}>
            <button className="btn btn-ghost btn-ico">
              <i className="ico ico-activity-log" />
            </button>
          </Link>
        </div>
      </div>
      <header className="details-header">
        <div className="row">
          <div className="col-3">
            <div className="mb-5">
              <p className="text-primary-light">Status</p>
              <p className="text-medium">
                {innovation && innovationStatusKeyToLabel[innovation.status]}
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="mb-5">
              <p className="text-primary-light">Submitted By</p>
              <p className="text-medium">
                {innovation && innovation.submitterFullName}
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="mb-5">
              <p className="text-primary-light">Job Title/Function</p>
              <p>{innovation && innovation.submitterJobTitle}</p>
            </div>
          </div>
          <div className="col-3">
            <div className="mb-5">
              <p className="text-primary-light">Email</p>
              <p>{innovation && innovation.submitterEmail}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="mb-5">
              <p className="text-primary-light">Location</p>
              <p>{innovation && innovation.submitterLocation}</p>
            </div>
          </div>
          <div className="col-3">
            <div className="mb-5">
              <p className="text-primary-light">Submission Date</p>
              <p>
                {innovation &&
                  DateTime.fromISO(innovation.createdAt).toFormat("MM/dd/y")}
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-5">
              <p className="text-primary-light">
                Innovation (Project) Brief Description
              </p>
              <p className="text-medium">
                {innovation && innovation.description}
              </p>
            </div>
          </div>
        </div>
      </header>
      {innovation && (
        <Fragment>
          {reSubmitModal}
          {approveModal}
          {rejectModal}
          <div className={"row"}>
            <div className={"col-10"} />
            {innovation.status === InnovationStatusKey.Submitted ? (
              <Fragment>
                {" "}
                {approveButton}
                {rejectButton}
              </Fragment>
            ) : null}
            {innovation.status === InnovationStatusKey.Rejected
              ? reSubmitButton
              : null}
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetInnovationStats: (
    innovationStats: {
      status: InnovationStatusKey;
      statusValue: string;
      statusLabel: string;
      amount: number;
    }[]
  ) => {
    dispatch(setInnovationStats(innovationStats));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionItem);
