import {ErrorMessage} from "formik";
import React from "react";
import {IForm} from "./IForm";

const FormTextArea = (
  {
    field,
    form,
    label,
    placeholder = "Enter",
    rows = "3",
    className = "form-control",
    showCounter = false,
    maxLength,
      onChange = null,
  }: IForm) => (
  <label className={`form-control-wrap ${(form.errors[field.name] && form.touched[field.name]) ? "form-error-wrap" : ""}`}>
    {(label || showCounter) && <div className={"row"}>
      {label && <div className={"col-8"}>
          <span className={`form-label ${label === field.name ? "sr-only" : ""}`}>{label}</span>
      </div>}
      {showCounter && maxLength && <div className={"col-4 text-right text-primary-light"}>{field.value.length}/{maxLength}</div>}
    </div>}
    <textarea
      {...field}
      onChange={onChange || field.onChange}
      placeholder={placeholder}
      className={className}
      rows={rows}
      maxLength={maxLength}
    />
    <ErrorMessage component="span" className="form-error" name={field.name} />
  </label>
);

export default FormTextArea;
