import React from "react";
import {connect} from "react-redux";
import {match, RouteChildrenProps} from "react-router";
import {Dispatch} from "redux";
import PageLoader from "../../../components/loader/PageLoader";
import {InnovationStageKey, innovationStageValueToKey} from "../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../store";
import {IInnovationItemCommonState} from "../../../store/innovationItem/common/type";
import {innovationItemStageCommonSetStage} from "../../../store/innovationItem/stage/common/action";
import {IInnovationItemStageCommonState} from "../../../store/innovationItem/stage/common/type";
import InnovationItemHeaderComponent from "./components/InnovationItemHeaderComponent";
import InnovationItemInformationGateComponent from "./components/InnovationItemInformationGateComponent";
import InnovationItemMenuComponent from "./components/InnovationItemMenuComponent";
import InnovationItemOversightComponent from "./components/InnovationItemOversightComponent";

interface IProps extends RouteChildrenProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemCommonState: IInnovationItemCommonState;
  onInnovationItemStageCommonSetStage: (stage: InnovationStageKey) => void;
  match: match<{ stage: string }>;
}

class InnovationItemStage extends React.Component<IProps> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.props.onInnovationItemStageCommonSetStage(innovationStageValueToKey[this.props.match.params.stage]);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.match.params.stage !== prevProps.match.params.stage) {
      this.props.onInnovationItemStageCommonSetStage(innovationStageValueToKey[this.props.match.params.stage]);
    }
  }

  public render(): React.ReactNode {
    const {innovationItemStageCommonState: {stage}} = this.props;
    return (
      <PageLoader isLoading={typeof stage === undefined}>
        <InnovationItemHeaderComponent/>
        <InnovationItemMenuComponent/>
        <InnovationItemOversightComponent/>
        <InnovationItemInformationGateComponent/>
      </PageLoader>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemStageCommonSetStage: (stage: InnovationStageKey) => {
    dispatch(innovationItemStageCommonSetStage(stage));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemStage);
