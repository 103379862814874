import {
  ADMIN_USER_UPDATE_DEINIT,
  ADMIN_USER_UPDATE_ERROR, ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_ERROR, ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_START, ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_SUCCESS,
  ADMIN_USER_UPDATE_INIT,
  ADMIN_USER_UPDATE_START,
  ADMIN_USER_UPDATE_SUCCESS,
  IAdminUserUpdateActionTypes,
  IAdminUserUpdateState,
} from "./type";

const initialState: IAdminUserUpdateState = {
  user: undefined,
  isLoading: false,
  department: {
    items: [],
    isLoading: false,
  },
};

export const adminUserUpdateReducer = (
  state = initialState,
  action: IAdminUserUpdateActionTypes,
): IAdminUserUpdateState => {
  switch (action.type) {
    case ADMIN_USER_UPDATE_INIT: {
      return {
        ...state,
        user: action.user,
      };
    }
    case ADMIN_USER_UPDATE_DEINIT: {
      return {
        ...state,
        user: undefined,
      };
    }
    case ADMIN_USER_UPDATE_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADMIN_USER_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ADMIN_USER_UPDATE_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_START: {
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: true,
        },
      };
    }
    case ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: false,
          items: action.departments,
        },
      };
    }
    case ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_ERROR: {
      return {
        ...state,
        department: {
          ...state.department,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
