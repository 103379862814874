import React, {Fragment} from "react";
import Modal from "react-modal";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import {addErrorNotification, addSuccessNotification} from "../../../components/Notification";
import axiosInstance from "../../../instance/axios";
import {IDepartmentEntity} from "../../../interface/entity/IDepartmentEntity";
import {AppState} from "../../../store";
import {
  adminDepartmentCollectionRemoveError,
  adminDepartmentCollectionRemoveStart,
  adminDepartmentCollectionRemoveSuccess,
} from "../../../store/admin/department/list/action";
import {IAdminDepartmentState} from "../../../store/admin/department/list/type";

interface IProps {
  department: IDepartmentEntity;
  adminDepartmentState: IAdminDepartmentState;
  onSuccess: () => void;
  onAdminDepartmentCollectionRemoveStart: () => void;
  onAdminDepartmentCollectionRemoveSuccess: () => void;
  onAdminDepartmentCollectionRemoveError: (error: unknown) => void;
}

interface IState {
  isModalOpened: boolean;
}

class DepartmentTr extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      isModalOpened: false,
    };
  }

  public render(): React.ReactNode {
    const {isModalOpened} = this.state;
    const {department} = this.props;

    const modal = (
      <Modal
        isOpen={isModalOpened}
        onRequestClose={this.onCloseModal}
        contentLabel="Remove department"
        className="modal modal-sm"
      >
        <header className="modal-header clearfix">
          <h3 className="modal-title pull-left">Remove Department</h3>
          <button onClick={this.onCloseModal} className="modal-close">
            <i className="ico ico-close-thin"/>
          </button>
        </header>
        <div className="mobal-body px-5 py-6">
          <p>
            Are you sure you want to remove this department?
          </p>
        </div>
        <footer className="modal-footer text-right">
          <button
            className="btn btn-shade ml-4"
            type="button"
            onClick={this.onCloseModal}
          >
            Close
          </button>
          <button
            className="btn btn-shade ml-4 btn-danger"
            type="button"
            onClick={async () => {
              await this.removeDepartment();
            }}
          >
            Remove Department
          </button>
        </footer>
      </Modal>
    );

    return (
      <Fragment>
        {modal}
        <tr>
          <td className="text-medium">
            {department.name}
          </td>
          <td>
            {department.description || "Waiting for Department Description"}
          </td>
          <td className="text-right">
            <button
              className="btn btn-ghost btn-sq ml-3"
              type="button"
              onClick={() => {
                this.setState({isModalOpened: true});
              }}
            >
              <i className="ico ico-delete"/>
            </button>
            <Link to={`/admin/department/${this.props.department.id}`} className="btn btn-ghost btn-sq ml-3" type="button">
              <i className="ico ico-edit"/>
            </Link>
          </td>
        </tr>
      </Fragment>
    );
  }

  private onCloseModal = () => {
    const {adminDepartmentState: {isRemoving}} = this.props;
    if (isRemoving) {
      return;
    }
    this.setState({isModalOpened: false});
  }

  private removeDepartment = async () => {
    const {adminDepartmentState: {isRemoving}} = this.props;
    if (isRemoving) {
      return;
    }

    this.props.onAdminDepartmentCollectionRemoveStart();
    await axiosInstance().delete("/department/" + this.props.department.id)
      .then(() => {
        this.props.onAdminDepartmentCollectionRemoveSuccess();
        this.setState({isModalOpened: false});
        this.props.onSuccess();
        addSuccessNotification("Successfully removed");
      })
      .catch((error) => {
        addErrorNotification(error.response.data.message || "Error on removing department");
        this.props.onAdminDepartmentCollectionRemoveError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  adminDepartmentState: state.admin.department.list,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAdminDepartmentCollectionRemoveStart: () => {
    dispatch(adminDepartmentCollectionRemoveStart());
  },
  onAdminDepartmentCollectionRemoveSuccess: () => {
    dispatch(adminDepartmentCollectionRemoveSuccess());
  },
  onAdminDepartmentCollectionRemoveError: (error: unknown) => {
    dispatch(adminDepartmentCollectionRemoveError());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DepartmentTr);
