import {IInnovationDepartmentEntity} from "../../../../../interface/entity/IInnovationDepartmentEntity";
import {
  IInnovationItemStageOversightResolutionsState,
  IInnovationItemStageOversightResolutionsTypes,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_ERROR,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_START,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_SUCCESS,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_START,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_SUCCESS,
} from "./type";

const initialState: IInnovationItemStageOversightResolutionsState = {
  innovationDepartmentEntities: [],
  isCollectionLoading: false,
  isItemLoading: false,
};

export const innovationItemStageOversightResolutionsReducer = (
  state = initialState,
  action: IInnovationItemStageOversightResolutionsTypes,
): IInnovationItemStageOversightResolutionsState => {
  switch (action.type) {
    case INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_START: {
      return {
        ...state,
        innovationDepartmentEntities: [],
        isCollectionLoading: true,
      };
    }
    case INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_SUCCESS: {
      return {
        ...state,
        innovationDepartmentEntities: action.innovationDepartmentEntities.sort((next, current) => {
          if (current.department && current.department.id && Number(current.department.id) === 7) {
            return -1;
          }
          return 0;
        }).sort((next, current) => {
          if (current.department && current.department.id && Number(current.department.id) === 8) {
            return -1;
          }
          return 0;
        }),
        isCollectionLoading: false,
      };
    }
    case INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_START: {
      return {
        ...state,
        isItemLoading: true,
      };
    }
    case INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_SUCCESS: {
      return {
        ...state,
        innovationDepartmentEntities: state.innovationDepartmentEntities.map((innovationDepartmentEntity: IInnovationDepartmentEntity) => {
          return innovationDepartmentEntity.id === action.innovationDepartment.id
            ? action.innovationDepartment
            : innovationDepartmentEntity;
        }),
        isItemLoading: false,
      };
    }
    case INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_ERROR: {
      return {
        ...state,
        isItemLoading: false,
      };
    }
    default:
      return state;
  }
};
