import {FormikActions} from "formik";
import store from "store";
import * as Yup from "yup";
import {LOCAL_STORAGE_AUTH_TOKEN, LOCAL_STORAGE_USER} from "../constant/localStorage";
import axiosInstance from "../instance/axios";
import {IUserEntity} from "../interface/entity/IUserEntity";
import {addErrorNotification} from "./Notification";

export interface ILoginFormValuesInterface {
  email: string;
  password: string;
}

export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email()
    .required("Required"),
  password: Yup.string()
    .trim()
    .required("Required"),
});

export const onLoginFormSubmit = async (
  values: ILoginFormValuesInterface,
  actions: FormikActions<ILoginFormValuesInterface>,
  onSuccess: (authToken: string, user: IUserEntity) => void,
  onRedirect?: (passwordResetToken: string) => void,
) => {

  const {email, password} = values;

  await axiosInstance().post(`/auth/issue`, {email, password})
    .then((response) => {
      const {authToken, user, passwordResetToken} = response.data;

      if (passwordResetToken && onRedirect) {
        onRedirect(passwordResetToken);
      } else {
        store.set(LOCAL_STORAGE_AUTH_TOKEN, authToken);
        store.set(LOCAL_STORAGE_USER, user);

        onSuccess(authToken, user);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 422) {
        actions.setErrors(error.response.data);
      } else {
        // TODO log error
        addErrorNotification("Something gets wrong");
      }
    })
    .finally(() => {
      actions.setSubmitting(false);
    });
};
