import {
  IInnovationItemStageGateActionTypes,
  IInnovationItemStageGateState,
  INNOVATION_ITEM_STAGE_GATE_FETCH_START,
  INNOVATION_ITEM_STAGE_GATE_FETCH_SUCCESS,
  INNOVATION_ITEM_STAGE_GATE_UPDATE_DATA,
} from "./type";

const initialState: IInnovationItemStageGateState<any> = {
  isLoading: false,
};

export const innovationItemStageGateReducer = (
  state = initialState,
  action: IInnovationItemStageGateActionTypes,
): IInnovationItemStageGateState<any> => {
  switch (action.type) {
    case INNOVATION_ITEM_STAGE_GATE_FETCH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case INNOVATION_ITEM_STAGE_GATE_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    case INNOVATION_ITEM_STAGE_GATE_UPDATE_DATA: {
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    }
    default:
      return state;
  }
};
