import React from "react";
import {Route, Switch} from "react-router";
import {INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE, INNOVATION_OVERSIGHT_ROUTE} from "../../../../components/routes";
import InnovationItemOversightComments from "./InnovationItemOversightComponent/InnovationItemOversightComments";
import InnovationItemOversightDashboard from "./InnovationItemOversightComponent/InnovationItemOversightDashboard";

export enum ViewMode {
  Dashboard = 0,
  Comments = 1,
  DepartmentComments= 2,
}

class InnovationItemOversightComponent extends React.Component {
  public render(): React.ReactNode {
    return (
      <div>
        <h2 className="text-regular">
          Oversight Committee Review &amp; Comments
        </h2>
        <hr className="mt-2 mb-4"/>
        <Switch>
          <Route
            exact={true}
            path={[
              INNOVATION_OVERSIGHT_ROUTE,
              INNOVATION_OVERSIGHT_DEPARTMENT_ROUTE,
            ]}
            component={InnovationItemOversightComments}
          />
          <Route
            component={InnovationItemOversightDashboard}
          />
        </Switch>
      </div>
    );
  }
}

export default InnovationItemOversightComponent;
