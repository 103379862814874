import {FieldProps} from "formik";
import React, {Fragment} from "react";
import Select from "react-select";
// tslint:disable-next-line
import {Props} from "react-select/base";
// tslint:disable-next-line
import {OptionsType, ValueType} from "react-select/src/types";

interface IFormSelectMultiple extends FieldProps {
  selectProps: Props;
  customProps: {
    label?: string;
  };
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    "border": "2px solid #d8dfe9",
    "minHeight": "44px",
    "box-shadow": "none",
    "border-radius": "2px",

    ":hover": {
      border: "2px solid #d8dfe9",
    },
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    padding: "4px 8px",
  }),
  multiValue: (provided: any) => ({
    ...provided,
    background: "none",
    fontWeight: 500,
  }),
  multiValueLabel: () => ({
    background: "#e7edfa",
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
    padding: "4px 10px",
    marginRight: "1px",
  }),
  multiValueRemove: () => ({
    background: "#e7edfa",
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    padding: "4px 7px 4px 5px",
    marginRight: "1px",
    color: "#f54c41",
    fontSize: "21px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: () => ({
    color: "#7b889a",
  }),
  menu: (provided: any) => ({
    ...provided,
    "z-index": 20,
  }),
  option: (provided: any) => ({
    ...provided,
    "background": "#fff",

    ":hover": {
      background: "#004b86",
      color: "#fff",
    },
  }),
};

const FormSelectMultiple = ({selectProps, customProps, ...props}: IFormSelectMultiple) => (
  <Fragment>
    {customProps.label && <label className={`form-label`}>{customProps.label}</label>}
    <div className="form-control-wrap">
      <Select
        isSearchable={false}
        isMulti={true}
        {...selectProps}
        styles={customStyles}
        onChange={(value: ValueType<{ label: string; value: any }>) => {
          props.form.setFieldValue(
            props.field.name,
            value ? (value as OptionsType<{ label: string; value: any }>).map((option) => option.value) : null,
          );
        }}
      />
    </div>
  </Fragment>
);

export default FormSelectMultiple;
