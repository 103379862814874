import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";
import {IUserEntity} from "../../../../interface/entity/IUserEntity";

export interface IAdminUserUpdateState {
  user?: IUserEntity;
  isLoading: boolean;
  department: {
    items: IDepartmentEntity[];
    isLoading: boolean;
  };
}

export const ADMIN_USER_UPDATE_INIT = "ADMIN_USER_UPDATE_INIT";
export const ADMIN_USER_UPDATE_DEINIT = "ADMIN_USER_UPDATE_DEINIT";
export const ADMIN_USER_UPDATE_START = "ADMIN_USER_UPDATE_START";
export const ADMIN_USER_UPDATE_SUCCESS = "ADMIN_USER_UPDATE_SUCCESS";
export const ADMIN_USER_UPDATE_ERROR = "ADMIN_USER_UPDATE_ERROR";
export const ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_START = "ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_START";
export const ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_SUCCESS = "ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_SUCCESS";
export const ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_ERROR = "ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_ERROR";

interface IAdminUserUpdateInitAction {
  type: typeof ADMIN_USER_UPDATE_INIT;
  user: IUserEntity;
}

interface IAdminUserUpdateDeinitAction {
  type: typeof ADMIN_USER_UPDATE_DEINIT;
}

interface IAdminUserUpdateStartAction {
  type: typeof ADMIN_USER_UPDATE_START;
}

interface IAdminUserUpdateSuccessAction {
  type: typeof ADMIN_USER_UPDATE_SUCCESS;
}

interface IAdminUserUpdateErrorAction {
  type: typeof ADMIN_USER_UPDATE_ERROR;
}

interface IAdminUserUpdateFetchDepartmentsStartAction {
  type: typeof ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_START;
}

interface IAdminUserUpdateFetchDepartmentsSuccessAction {
  type: typeof ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_SUCCESS;
  departments: IDepartmentEntity[];
}

interface IAdminUserUpdateFetchDepartmentsErrorAction {
  type: typeof ADMIN_USER_UPDATE_FETCH_DEPARTMENTS_ERROR;
}

export type IAdminUserUpdateActionTypes =
  | IAdminUserUpdateInitAction
  | IAdminUserUpdateDeinitAction
  | IAdminUserUpdateStartAction
  | IAdminUserUpdateSuccessAction
  | IAdminUserUpdateErrorAction
  | IAdminUserUpdateFetchDepartmentsStartAction
  | IAdminUserUpdateFetchDepartmentsSuccessAction
  | IAdminUserUpdateFetchDepartmentsErrorAction
  ;
