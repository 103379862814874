import {IInformationGateEntity} from "../../../interface/entity/IInformationGateEntity";
import {IInnovationEntity, InnovationStageKey} from "../../../interface/entity/IInnovationEntity";

export interface IInnovationItemCommonState {
  innovationEntity?: IInnovationEntity;
  activeTimelineStage: InnovationStageKey | null;
  isLoading: boolean;
}

export const INNOVATION_ITEM_FETCH_START = "INNOVATION_ITEM_FETCH_START";
export const INNOVATION_ITEM_FETCH_SUCCESS = "INNOVATION_ITEM_FETCH_SUCCESS";
export const INNOVATION_ITEM_FETCH_ERROR = "INNOVATION_ITEM_FETCH_ERROR";
export const INNOVATION_ITEM_INIT = "INNOVATION_ITEM_INIT";
export const INNOVATION_ITEM_CHANGE_ACTIVE_TIMELINE = "INNOVATION_ITEM_CHANGE_ACTIVE_TIMELINE";
export const INNOVATION_ITEM_CHANGE_GATE = "INNOVATION_ITEM_CHANGE_GATE";

interface IInnovationItemFetchStartAction {
  type: typeof INNOVATION_ITEM_FETCH_START;
}

interface IInnovationItemFetchSuccessAction {
  type: typeof INNOVATION_ITEM_FETCH_SUCCESS;
  innovationEntity: IInnovationEntity;
}

interface IInnovationItemFetchErrorsAction {
  type: typeof INNOVATION_ITEM_FETCH_ERROR;
  innovationEntity: IInnovationEntity;
}

interface IInnovationItemInitAction {
  type: typeof INNOVATION_ITEM_INIT;
}

interface IInnovationItemChangeActiveTimelineAction {
  type: typeof INNOVATION_ITEM_CHANGE_ACTIVE_TIMELINE;
  stage: InnovationStageKey;
}

interface IInnovationItemChangeInformationGate {
  type: typeof INNOVATION_ITEM_CHANGE_GATE;
  gate: IInformationGateEntity;
}

export type IInnovationItemActionTypes =
  IInnovationItemFetchSuccessAction
  | IInnovationItemFetchErrorsAction
  | IInnovationItemInitAction
  | IInnovationItemFetchStartAction
  | IInnovationItemChangeActiveTimelineAction
  | IInnovationItemChangeInformationGate
  ;
