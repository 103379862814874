import {IFormText, IFormToggle} from "../../../../../../components/form/IForm";
import {
  IfRequestedByASpecificCustomerPleaseIdentify,
  IsThereATargetedBusinessSegmentToLaunch,
  IsThisAnItemThatDfffAlreadyGrowsContractGrows,
  IsThisSolutionIntendedToBeOrganic,
  YesNo,
} from "../../../../../../interface/entity/IInnovationEntity";

class ConceptUtils {
  public static isThisAnItemThatDfffAlreadyGrowsContractGrows: IFormToggle = {
    name: "isThisAnItemThatDfffAlreadyGrowsContractGrows",
    label: "Is this a product or crop Duda Farm Fresh Foods already grows and or purchases?",
    options: [
      {value: IsThisAnItemThatDfffAlreadyGrowsContractGrows.Yes, label: "Yes"},
      {value: IsThisAnItemThatDfffAlreadyGrowsContractGrows.No, label: "No"},
      {value: IsThisAnItemThatDfffAlreadyGrowsContractGrows.ContractGrows, label: "Contract Grows"},
      {value: IsThisAnItemThatDfffAlreadyGrowsContractGrows.CoPack, label: "Co-Pack"},
    ],
  };

  public static productType: IFormToggle = {
    name: "productType",
    label: "Seed or plant variety:",
    answer: "Seed or plant variety",
    options: [
      {value: "celery", label: "Celery"},
      {value: "corn", label: "Corn"},
      {value: "radish", label: "Radish"},
      {value: "leafyGreen", label: "Leafy Green"},
      {value: "orange", label: "Orange"},
      {value: "lemon", label: "Lemon"},
      {value: "lime", label: "Lime"},
      {value: "other", label: "Other"},
    ],
  };
  public static productTypeOther: IFormText = {
    name: "productTypeOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation: IFormText = {
    name: "pleaseDescribeThePackagingConfigurationOrItemCountForThisInnovation",
    label: "Please describe the packaging configuration or item count for this innovation:",
    placeholder: "5oz cup with 2oz cup of dip or peanut butter inserted and sealed",
    labelClass: "text-regular text-primary-dark",
  };

  public static whatIsThePreliminaryProposedPricingPerUnit: IFormText = {
    name: "whatIsThePreliminaryProposedPricingPerUnit",
    label: "What is the preliminary proposed pricing, per unit?",
    placeholder: "$2.99 retail price",
    labelClass: "text-regular text-primary-dark",
  };

  public static innerPackagingType: IFormToggle = {
    name: "innerPackagingType",
    label: "Package structure (current):",
    options: [
      {value: "polybag", label: "Polybag"},
      {value: "carton", label: "Carton"},
      {value: "clamshell", label: "Clamshell"},
      {value: "pouchbag", label: "Pouchbag"},
      {value: "other", label: "Other"},
    ],
  };
  public static innerPackagingTypeOther: IFormText = {
    name: "innerPackagingTypeOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static outerPackagingType: IFormToggle = {
    name: "outerPackagingType",
    label: "Package structure (new):",
    options: [
      {value: "cup", label: "Cup"},
      {value: "tube", label: "Tube"},
      {value: "shrinkwrap", label: "Shrinkwrap"},
      {value: "microwave", label: "Microwave"},
      {value: "sustainable", label: "Sustainable"},
      {value: "other", label: "Other"},
    ],
  };
  public static outerPackagingTypeOther: IFormText = {
    name: "outerPackagingTypeOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static convenienceValueAdded: IFormToggle = {
    name: "convenienceValueAdded",
    label: "Convenience/Value Added:",
    options: [
      {value: "freshCut", label: "Fresh Cut"},
      {value: "prepStep", label: "Prep Step"},
      {value: "combination", label: "Combination"},
      {value: "other", label: "Other"},
    ],
  };
  public static convenienceValueAddedOther: IFormText = {
    name: "convenienceValueAddedOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static cpgShelfStable: IFormToggle = {
    name: "cpgShelfStable",
    label: "CPG/Shelf Stable:",
    options: [
      {value: "condiment", label: "Condiment"},
      {value: "sideDish", label: "Side Dish"},
      {value: "mealReplacement", label: "Meal Replacement"},
      {value: "juice", label: "Juice"},
      {value: "soup", label: "Soup"},
      {value: "snack", label: "Snack"},
      {value: "other", label: "Other"},
    ],
  };
  public static cpgShelfStableOther: IFormText = {
    name: "cpgShelfStableOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static foodService: IFormToggle = {
    name: "foodService",
    label: "Foodservice:",
    options: [
      {value: "mealPrep", label: "Meal Prep"},
      {value: "serving", label: "Serving"},
      {value: "ingredient", label: "Ingredient"},
      {value: "combination", label: "Combination"},
      {value: "other", label: "Other"},
    ],
  };
  public static foodServiceOther: IFormText = {
    name: "foodServiceOther",
    placeholder: "Please fill in details...",
    label: "Please describe:",
    labelClass: "text-regular text-primary-dark",
  };

  public static isThisSolutionIntendedToBeOrganic: IFormToggle = {
    name: "isThisSolutionIntendedToBeOrganic",
    label: "Is this solution intended to be organic?",
    options: [
      {value: IsThisSolutionIntendedToBeOrganic.Yes, label: "Yes"},
      {value: IsThisSolutionIntendedToBeOrganic.No, label: "No"},
      {value: IsThisSolutionIntendedToBeOrganic.FutureBusinessOpportunity, label: "Future Business Opportunity"},
    ],
  };

  public static anyOtherAdditionalContactsToList: IFormText = {
    name: "anyOtherAdditionalContactsToList",
    label: "Any other additional contacts to list?",
    placeholder: "Please fill in details...",
  };

  public static pleaseDescribeInDetailTheTargetConsumerForThisInnovation: IFormText = {
    name: "pleaseDescribeInDetailTheTargetConsumerForThisInnovation",
    label: "Please describe in detail the target consumer for this innovation:",
    placeholder: "Please fill in details...",
  };

  public static productSpecs: IFormToggle = {
    name: "productSpecs",
    label: "Do you have any product specs already available?",
    options: [
      {value: IsThisSolutionIntendedToBeOrganic.Yes, label: "Yes"},
      {value: IsThisSolutionIntendedToBeOrganic.No, label: "No"},
    ],
  };

  public static packSize: IFormToggle = {
    name: "packSize",
    label: "Pack Size",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static packSizeValue: IFormText = {
    name: "packSizeValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static cartonsPerPallet: IFormToggle = {
    name: "cartonsPerPallet",
    label: "Cartons Per Pallet",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static cartonsPerPalletValue: IFormText = {
    name: "cartonsPerPalletValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static tieHigh: IFormToggle = {
    name: "tieHigh",
    label: "Tie & High",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static tieHighValue: IFormText = {
    name: "tieHighValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static grossWeight: IFormToggle = {
    name: "grossWeight",
    label: "Gross Weight (lbs)",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static grossWeightValue: IFormText = {
    name: "grossWeightValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static cartonSize: IFormToggle = {
    name: "cartonSize",
    label: "Carton Size (LxWxH)",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static cartonSizeValue: IFormText = {
    name: "cartonSizeValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static cartonCube: IFormToggle = {
    name: "cartonCube",
    label: "Carton Cube (cubic ft.)",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static cartonCubeValue: IFormText = {
    name: "cartonCubeValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static upc: IFormToggle = {
    name: "upc",
    label: "UPC",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static upcValue: IFormText = {
    name: "upcValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static gtin: IFormToggle = {
    name: "gtin",
    label: "GTIN",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static gtinValue: IFormText = {
    name: "gtinValue",
    placeholder: "Please fill",
    label: "Please fill",
    labelClass: "sr-only",
  };

  public static isThereATargetedBusinessSegmentToLaunch: IFormToggle = {
    name: "isThereATargetedBusinessSegmentToLaunch",
    label: "Is there a targeted business segment to launch?",
    answer: "Targeted business segment to launch:",
    options: [
      {value: IsThereATargetedBusinessSegmentToLaunch.No, label: "No"},
      {value: IsThereATargetedBusinessSegmentToLaunch.Retail, label: "Retail"},
      {value: IsThereATargetedBusinessSegmentToLaunch.FoodService, label: "Food Service"},
      {value: IsThereATargetedBusinessSegmentToLaunch.Industrial, label: "Industrial"},
    ],
  };

  public static ifRequestedByASpecificCustomerPleaseIdentify: IFormToggle = {
    name: "ifRequestedByASpecificCustomerPleaseIdentify",
    label: "If requested by a specific customer, please identify",
    options: [
      {value: IfRequestedByASpecificCustomerPleaseIdentify.NA, label: "N/A"},
      {value: IfRequestedByASpecificCustomerPleaseIdentify.Banner, label: "Banner"},
      {value: IfRequestedByASpecificCustomerPleaseIdentify.Market, label: "Market/Region"},
      {value: IfRequestedByASpecificCustomerPleaseIdentify.National, label: "National"},
      {value: IfRequestedByASpecificCustomerPleaseIdentify.Other, label: "Other"},
    ],
  };

  public static targetBusinessSegmentToLaunchDetails: IFormText = {
    name: "targetBusinessSegmentToLaunchDetails",
    label: "Targeted business segment details",
    placeholder: "Please fill in details...",
  };

  public static pleaseIdentifyCustomerName: IFormText = {
    name: "pleaseIdentifyCustomerName",
    placeholder: "Please fill in details...",
    label: "Please identify customer name:",
  };

  public static managerName: IFormText = {
    name: "managerName",
    label: "Name:",
    placeholder: "Please fill in details...",
  };
  public static officeOrStore: IFormText = {
    name: "officeOrStore",
    label: "Office or Store:",
    placeholder: "Please fill in details...",
  };
  public static willingToTest: IFormToggle = {
    name: "willingToTest",
    label: "Willing to Test:",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static volumeEstimate: IFormText = {
    name: "volumeEstimate",
    label: "Volume Estimate:",
    placeholder: "Please fill in details...",
  };
  public static canWeFollowUpWithThisBuyer: IFormToggle = {
    name: "canWeFollowUpWithThisBuyer",
    label: "Can we follow up with this buyer:",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static buyersPhone: IFormText = {
    name: "buyersPhone",
    label: "Buyer’s Phone:",
    placeholder: "Please fill in details...",
  };
  public static buyersEmail: IFormText = {
    name: "buyersEmail",
    label: "Buyer’s Email:",
    placeholder: "Please fill in details...",
  };
  public static doYouHaveAMarketingContact: IFormToggle = {
    name: "doYouHaveAMarketingContact",
    label: "Do you have a marketing contact?",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static marketingContractDetails: IFormText = {
    name: "marketingContractDetails",
    label: "Please fill in contact information:",
    placeholder: "Please fill in details...",
  };
  public static doYouHaveAnRDContact: IFormToggle = {
    name: "doYouHaveAnRDContact",
    label: "Do you have an R&D contact?",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static rndContactDetails: IFormText = {
    name: "rndContactDetails",
    label: "Please fill in contact information:",
    placeholder: "Please fill in details...",
  };
  public static doYouHaveAnInnovationContact: IFormToggle = {
    name: "doYouHaveAnInnovationContact",
    label: "Do you have an Innovation contact?",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static innovationContactDetails: IFormText = {
    name: "innovationContactDetails",
    label: "Please fill in contact information:",
    placeholder: "Please fill in details...",
  };
  public static doYouHaveAFoodSafetyContact: IFormToggle = {
    name: "doYouHaveAFoodSafetyContact",
    label: "Do you have a Food Safety contact?",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };
  public static foodSafetyContactDetails: IFormText = {
    name: "foodSafetyContactDetails",
    label: "Please fill in contact information:",
    placeholder: "Please fill in details...",
  };

  public static isThereAnExpectedLaunchDate: IFormText = {
    name: "isThereAnExpectedLaunchDate",
    label: "Is there an expected launch date?",
    placeholder: "Select date...",
  };

  public static anyOtherInformationYouWouldLikeToInclude: IFormText = {
    label: "Any other information you would like to include:",
    name: "anyOtherInformationYouWouldLikeToInclude",
    placeholder: "Please Fill In Additional Information if any…",
  };
}

export default ConceptUtils;
