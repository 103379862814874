import {DateTime} from "luxon";
import axiosInstance from "../instance/axios";
import {IInnovationEntity, InnovationStageKey, InnovationStatusKey} from "../interface/entity/IInnovationEntity";

export interface IInnovationCollectionFilters {
  createDateFrom?: string;
  createDateTo?: string;
  deadlineDateFrom?: string;
  deadlineDateTo?: string;
  q?: string;
  stages?: InnovationStageKey[];
}

export enum IInnovationCollectionSort {
  createAtAsc = 2,
  createAtDesc = 3,
  deadlineAtAsc = 6,
  deadlineAtDesc = 7,
  nameAsc = 0,
  nameDesc = 1,
  stageAsc = 4,
  stageDesc = 5,
}

export const getCollection = async ({page, sort, filters, status}: {page: number, sort: IInnovationCollectionSort, filters: IInnovationCollectionFilters, status: InnovationStatusKey}) => {
  const {q, stages} = filters;

  const createDateFrom = filters.createDateFrom ? DateTime.fromFormat(filters.createDateFrom, "MM/dd/y") : null;
  const createDateTo = filters.createDateTo ? DateTime.fromFormat(filters.createDateTo, "MM/dd/y") : null;

  const deadlineDateFrom = filters.deadlineDateFrom ? DateTime.fromFormat(filters.deadlineDateFrom, "MM/dd/y") : null;
  const deadlineDateTo = filters.deadlineDateTo ? DateTime.fromFormat(filters.deadlineDateTo, "MM/dd/y") : null;

  return axiosInstance().get("/innovation", {
    params: {
      page,
      sort,
      ...(createDateFrom ? {create_date_from: createDateFrom.setZone("UTC").set({day: createDateFrom.day, hour: 0, minute: 0, second: 0}).toJSDate()} : {}),
      ...(createDateTo ? {create_date_to: createDateTo.setZone("UTC").set({day: createDateTo.day, hour: 23, minute: 59, second: 59}).toJSDate()} : {}),
      // tslint:disable-next-line:max-line-length
      ...(deadlineDateFrom ? {deadline_date_from: deadlineDateFrom.setZone("UTC").set({day: deadlineDateFrom.day, hour: 0, minute: 0, second: 0}).toJSDate()} : {}),
      ...(deadlineDateTo ? {deadline_date_to: deadlineDateTo.setZone("UTC").set({day: deadlineDateTo.day, hour: 23, minute: 59, second: 59}).toJSDate()} : {}),
      ...(stages ? {stages} : {}),
      ...(status !== undefined ? {status} : {}),
      ...(q ? {q} : {}),
    },
  });
};

export const approveInnovation = async ({innovation, message}: {innovation: IInnovationEntity, message: string}) => {
  return axiosInstance().put(`/innovation/${innovation.id}/approve`, {message});
};

export const rejectInnovation = async ({innovation, message}: {innovation: IInnovationEntity, message: string}) => {
  return axiosInstance().put(`/innovation/${innovation.id}/reject`, {message});
};

export const reSubmitInnovation = async ({innovation, message}: {innovation: IInnovationEntity, message: string}) => {
  return axiosInstance().put(`/innovation/${innovation.id}/resubmit`, {message});
};

export const getStats = async () => {
  return axiosInstance().get(`/innovation/stats`);
};
