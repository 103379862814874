import {Field, Form, Formik, FormikProps} from "formik";
import {DateTime} from "luxon";
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import AnotherFormDatepicker from "../../../../components/form/AnotherFormDatepicker";
import {Col, Row} from "../../../../components/Grid";
import {INNOVATION_LIST} from "../../../../components/routes";
import axiosInstance from "../../../../instance/axios";
import {IInformationGateEntity} from "../../../../interface/entity/IInformationGateEntity";
import {
  IConceptInformationGate,
  IInformationGate,
  InnovationStageKey,
  innovationStageKeyToValue,
  InnovationStatusKey, innovationStatusKeyToLabel,
  innovationStatusKeyToValue,
} from "../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../store";
import {innovationItemChangeInformationGate} from "../../../../store/innovationItem/common/action";
import {IInnovationItemCommonState} from "../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../store/innovationItem/stage/common/type";
import {innovationItemStageGateUpdateData} from "../../../../store/innovationItem/stage/gate/action";
import TimelineItem from "./InnovationItemHeaderComponent/TimelineItem";

interface IProps {
  innovationItemCommonState: IInnovationItemCommonState;
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  onUpdateData: (data: IInformationGate) => void;
  onUpdateData2: (data: IInformationGateEntity) => void;
}

class InnovationItemHeaderComponent extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;

    const executeStage = innovationEntity && innovationEntity.informationGates.find((gate) => gate.stage === InnovationStageKey.Execute);
    // const deadline = (executeStage && executeStage.endDate) ? DateTime.fromISO(executeStage.endDate).toFormat("MM/dd/y") : "Not Added";

    const conceptStage = innovationEntity && innovationEntity.informationGates.find((gate) => gate.stage === InnovationStageKey.Concept);

    return (
      <div>
        <div className="mb-6">
          <Link to={INNOVATION_LIST}>&lt; Back to Innovations List</Link>
        </div>
        <div className="details-header-line">
          <div className="pull-left">
            <h1 className="m-0 pt-1">
              {innovationEntity && innovationEntity.name}
              &nbsp;
              {innovationEntity &&
              <span className={"tag tag-" + innovationStageKeyToValue[innovationEntity.stage]}>
                {innovationStageKeyToValue[innovationEntity.stage]}
              </span>}
            </h1>
          </div>
          <div className="pull-right">
            <Link to={"/innovation/" + (innovationEntity && innovationEntity.id)}>
              <button className="btn btn-ghost btn-ico">
                <i className="ico ico-activity-log"/>
              </button>
            </Link>
          </div>
        </div>
        <header className="details-header">
          <div className="row">
            <div className="col-3">
              <div className="mb-5">
                <p className="text-primary-light">Status</p>
                <p className="text-medium">
                  {innovationEntity &&
                  <span className={`status-${innovationStatusKeyToValue[innovationEntity.status]}`}>
                    {innovationStatusKeyToLabel[innovationEntity.status]}
                  </span>}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-5">
                <p className="text-primary-light">Submitted By</p>
                <p className="text-medium">
                  {innovationEntity && innovationEntity.submitterFullName}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-5">
                <p className="text-primary-light">Job Title/Function</p>
                <p>
                  {innovationEntity && innovationEntity.submitterJobTitle}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-5">
                <p className="text-primary-light">Email</p>
                <p>
                  {innovationEntity && innovationEntity.submitterEmail}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="mb-5">
                <p className="text-primary-light">Location</p>
                <p>
                  {innovationEntity && innovationEntity.submitterLocation}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className="mb-5">
                <p className="text-primary-light">Submission Date</p>
                <p>
                  {innovationEntity && DateTime.fromISO(innovationEntity.createdAt).toFormat("MM/dd/y")}
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="mb-5">
                <p className="text-primary-light">
                  {innovationEntity && innovationEntity.name}
                </p>
                <p className="text-medium">
                  {innovationEntity && innovationEntity.description}
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="mb-5">
          <Row>
            <Col val="4">
              <Row>
                <Col val="6">
                  <div className="form-control-static text-primary-light text-regular">Project Deadline
                    Date:
                  </div>
                </Col>
                <Col val="6">
                  <Formik
                    initialValues={conceptStage && conceptStage.data}
                    enableReinitialize={true}
                    onSubmit={() => null}
                    render={({handleSubmit, handleChange}: FormikProps<IInformationGate | undefined>) => {
                      return (
                        <Form>
                          <Field
                            name="isThereAnExpectedLaunchDate"
                            placeholder="Select Date"
                            labelClass="text-primary-light"
                            component={AnotherFormDatepicker}
                            selectsStart={true}
                            isClearable={true}
                            onChange={async (v: string) => {
                              await this.saveConcept({...(conceptStage && conceptStage.data), isThereAnExpectedLaunchDate: v});
                            }}
                            disabled={innovationEntity && (innovationEntity.status !== InnovationStatusKey.Active)}
                          />
                        </Form>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col val="5">
              <Row>
                <Col val="7">
                  <div className="form-control-static text-primary-light text-regular text-right">Estimated Project
                    Completion Date:
                  </div>
                </Col>
                <Col val="5">
                  <Formik
                    initialValues={
                      executeStage &&
                      {...executeStage, endDate: executeStage.endDate ? DateTime.fromISO(executeStage.endDate).toFormat("MM/dd/y") : undefined}
                    }
                    enableReinitialize={true}
                    onSubmit={() => null}
                    render={({handleSubmit, handleChange}: FormikProps<IInformationGateEntity | undefined>) => {
                      return (
                        <Form>
                          <Field
                            name="endDate"
                            placeholder="Select Date"
                            labelClass="text-primary-light"
                            component={AnotherFormDatepicker}
                            selectsStart={true}
                            isClearable={true}
                            onChange={async (v: string) => {
                              await this.saveExecute({endDate: v});
                            }}
                            disabled={innovationEntity && (innovationEntity.status !== InnovationStatusKey.Active)}
                          />
                        </Form>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <div className="col-3">
              <div className="text-right">
                <Link
                  className="btn btn-ghost"
                  to={`/innovation/${innovationEntity && innovationEntity.id}/${innovationEntity && innovationStageKeyToValue[stage]}/milestone`}
                >
                  Project Milestones
                </Link>
              </div>
            </div>
          </Row>
        </div>
        <div className="timeline-list">
          {innovationEntity && innovationEntity.informationGates
            .sort((a, b) => a.stage - b.stage)
            .map((gate) => <TimelineItem gate={gate} innovationEntity={innovationEntity} key={`timeline-${gate.id}`}/>,
            )}
          <div className="timeline-item active last">
            <div className="timeline-item-inner"><span className="timeline-item-title">Deadline</span>
              <dl className="timeline-item-info">
                <dd>
                  {conceptStage && conceptStage.data && (conceptStage.data as IConceptInformationGate).isThereAnExpectedLaunchDate}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private saveConcept = async (values: any) => {
    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;

    await axiosInstance().put(
      "/innovation/" + (innovationEntity && innovationEntity.id) + "/gate/" + InnovationStageKey.Concept,
      {data: JSON.stringify(values)})
      .then(async (response: { data: IInformationGateEntity }) => {
        if (response.data.data) {

          this.props.onUpdateData2(response.data);
          if (stage === InnovationStageKey.Concept) {
            this.props.onUpdateData(response.data.data);
          }
        }
      })
      .catch((error) => {
        // TODO handle this case
      });
  }

  private saveExecute = async (values: any) => {
    const {innovationItemCommonState: {innovationEntity}} = this.props;

    await axiosInstance().put(
      "/innovation/" + (innovationEntity && innovationEntity.id) + "/gate/" + InnovationStageKey.Execute + "/entity",
      values)
      .then(async (response: { data: IInformationGateEntity }) => {
        this.props.onUpdateData2(response.data);
      })
      .catch((error) => {
        // TODO handle this case
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onUpdateData: (data: IInformationGate) => {
    dispatch(innovationItemStageGateUpdateData(data));
  },
  onUpdateData2: (data: IInformationGateEntity) => {
    dispatch(innovationItemChangeInformationGate(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemHeaderComponent);
