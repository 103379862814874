import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";
import {
  ADMIN_DEPARTMENT_UPDATE_DEINIT,
  ADMIN_DEPARTMENT_UPDATE_ERROR,
  ADMIN_DEPARTMENT_UPDATE_INIT,
  ADMIN_DEPARTMENT_UPDATE_START,
  ADMIN_DEPARTMENT_UPDATE_SUCCESS,
} from "./type";

export const adminDepartmentUpdateInit = (department: IDepartmentEntity) => {
  return {
    type: ADMIN_DEPARTMENT_UPDATE_INIT,
    department,
  };
};

export const adminDepartmentUpdateDeinit = () => {
  return {
    type: ADMIN_DEPARTMENT_UPDATE_DEINIT,
  };
};

export const adminDepartmentUpdateStart = () => {
  return {
    type: ADMIN_DEPARTMENT_UPDATE_START,
  };
};

export const adminDepartmentUpdateSuccess = () => {
  return {
    type: ADMIN_DEPARTMENT_UPDATE_SUCCESS,
  };
};

export const adminDepartmentUpdateError = () => {
  return {
    type: ADMIN_DEPARTMENT_UPDATE_ERROR,
  };
};
