import {IInnovationDepartmentEntity} from "../../../../../interface/entity/IInnovationDepartmentEntity";
import {
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_ERROR,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_START,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_SUCCESS,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_ERROR,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_START,
  INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_SUCCESS,
} from "./type";

export const innovationItemOversightResolutionsDepartmentsFetchStart = () => {
  return {
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_START,
  };
};

export const innovationItemOversightDashboardDepartmentsFetchSuccess = (innovationDepartmentEntities: IInnovationDepartmentEntity[]) => {
  return {
    innovationDepartmentEntities,
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_SUCCESS,
  };
};

export const innovationItemOversightDashboardDepartmentsFetchError = (error: unknown) => {
  return {
    error,
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENTS_FETCH_ERROR,
  };
};

export const innovationItemOversightDashboardDepartmentUpdateStart = () => {
  return {
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_START,
  };
};

export const innovationItemOversightDashboardDepartmentUpdateSuccess = (innovationDepartment: IInnovationDepartmentEntity) => {
  return {
    innovationDepartment,
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_SUCCESS,
  };
};

export const innovationItemOversightDashboardDepartmentUpdateError = (error: unknown) => {
  return {
    error,
    type: INNOVATION_ITEM_STAGE_OVERSIGHT_RESOLUTIONS_DEPARTMENT_UPDATE_ERROR,
  };
};
