import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import {InnovationStageKey} from "../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../store";
import {IInnovationItemCommonState} from "../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../store/innovationItem/stage/common/type";

interface IProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemCommonState: IInnovationItemCommonState;
  additionalPath?: string[];
}

class InnovationItemMenuComponent extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}, additionalPath} = this.props;

    return (
      <ul className="nav-stage">
        {Object.values(InnovationStageKey).filter((item: InnovationStageKey) => !isNaN(item)).map((currentStage: number) => {
          return (
            <li key={currentStage} className={`nav-stage-${InnovationStageKey[currentStage].toLowerCase()} ${stage === currentStage ? "active" : ""}`}>
              <Link
                to={["/innovation", innovationEntity && innovationEntity.id, InnovationStageKey[currentStage].toLowerCase()].concat(additionalPath).join("/")}
              >
                {InnovationStageKey[currentStage]}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemMenuComponent);
