import {DateTime} from "luxon";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import {RouterProps} from "react-router";
import {Dispatch} from "redux";
import {Col, Row} from "../../../../../../components/Grid";
import axiosInstance from "../../../../../../instance/axios";
import {IAttachmentEntity} from "../../../../../../interface/entity/IAttachmentEntity";
import {IInnovationDepartmentComments} from "../../../../../../interface/entity/IInnovationDepartmentComment";
import {IInnovationDepartmentEntity} from "../../../../../../interface/entity/IInnovationDepartmentEntity";
import {InnovationStageKey, innovationStageKeyToValue} from "../../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../../store";
import {IInnovationItemCommonState} from "../../../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../../../store/innovationItem/stage/common/type";
import {
  innovationItemOversightStageCommentsFetchStart,
  innovationItemOversightStageCommentsFetchSuccess,
} from "../../../../../../store/innovationItem/stage/oversight/comments/common/action";
import {
  innovationItemOversightDashboardDepartmentsFetchError,
  innovationItemOversightDashboardDepartmentsFetchSuccess,
  innovationItemOversightResolutionsDepartmentsFetchStart,
} from "../../../../../../store/innovationItem/stage/oversight/resolutions/action";
import {ViewMode} from "../../InnovationItemOversightComponent";
import InnovationItemOversightSwitchBar from "../common/InnonvationItemOversightSwitchBar";

interface IProps extends RouterProps {
  innovationDepartmentComments: IInnovationDepartmentComments;
  innovationItemCommonState: IInnovationItemCommonState;
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationDepartments: IInnovationDepartmentEntity[];

  // onInnovationItemOversightResolutionsDepartmentsFetchError: (error: unknown) => void;
  onInnovationItemOversightStageCommentsFetchStart: () => void;
  onInnovationItemOversightStageCommentsFetchSuccess: (comments: IInnovationDepartmentComments) => void;

  onInnovationItemOversightResolutionsDepartmentsFetchError: (error: unknown) => void;
  onInnovationItemOversightResolutionsDepartmentsFetchStart: () => void;
  onInnovationItemOversightResolutionsDepartmentsFetchSuccess: (departments: IInnovationDepartmentEntity[]) => void;
}

class InnovationItemOversightAllComments extends React.Component<IProps> {
  public async componentDidMount() {
    await this.fetchDepartmentsWithComments();
  }

  public render(): React.ReactNode {
    const {innovationDepartments} = this.props;

    return (
      <Fragment>
        <InnovationItemOversightSwitchBar viewMode={ViewMode.Comments}/>
        <div className="comment-list mb-4">
          {innovationDepartments.length > 0 &&
          innovationDepartments.map((innovationDepartment) => {
            const lastComment = !innovationDepartment.comments || innovationDepartment.comments.length === 0
              ? null : innovationDepartment.comments[0];

            return <div className="comment-item" key={`department-last-comment-${innovationDepartment.id}`}>
              <Row>
                <Col val="3">
                  <p className="text-medium mb-2">{innovationDepartment.department ? innovationDepartment.department.name : null}</p>
                  <p className="text-medium text-primary-light mb-0">
                    {innovationDepartment.comments ? `${innovationDepartment.comments.length} comment added` : null}
                  </p>
                </Col>
                <Col val="6">
                    {(innovationDepartment.comments && innovationDepartment.comments[0]) ? (
                    <div className="media">
                        <div className="media-aside comment-item-avatar">
                            <div className="user-avatar">
                              {innovationDepartment.comments[0].user.firstName.toUpperCase().charAt(0)}
                              {innovationDepartment.comments[0].user.lastName.toUpperCase().charAt(0)}
                            </div>
                        </div>
                        <div className="media-body pl-2 clearfix">
                          <div className="clearfix">
                            <div className="pull-left">
                              <p className="text-medium mb-2">
                                {(innovationDepartment.comments && innovationDepartment.comments[0]) ? innovationDepartment.comments[0].user.fullName : null}
                              </p>
                            </div>
                            <div className="pull-right">
                              <p className="text-primary-light mb-2">
                                {/* tslint:disable-next-line:max-line-length */}
                                {(innovationDepartment.comments && innovationDepartment.comments[0]) ? `Added: ${DateTime.fromISO(innovationDepartment.comments[0].user.createdAt).toFormat("MM/dd/y")}` : null}
                              </p>
                            </div>
                          </div>
                          <div className="comment-item-content">
                            <p>
                              {(innovationDepartment.comments && innovationDepartment.comments[0]) ? innovationDepartment.comments[0].message : null}
                            </p>
                          </div>
                          {lastComment && lastComment.attachments && lastComment.attachments.length > 0 && (
                        <Fragment>
                          {lastComment.attachments.map((attachment: IAttachmentEntity) => {
                            return (
                              <div key={attachment.id} className="form-control-wrap">
                                <span className="form-label sr-only"/>
                                <div className="form-control-static text-primary text-iconed my-2 py-0">
                                  <a target={"_blank"} rel="noopener noreferrer" href={attachment.cdnUrl}>
                                    <i className="ico ico-doc "/>
                                    &nbsp;{attachment.fileName}
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                        </Fragment>
                      )}
                        </div>
                    </div>
                    ) : <div className="text-empty-col">Waiting for comments…</div>}
                </Col>
                <Col val="3">
                  <div className="comment-item-action">
                    <button
                      className="btn btn-ghost btn-width"
                      type="button"
                      onClick={() => {
                        const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;
                        // tslint:disable-next-line:max-line-length
                        const departmentCommentsLink = `/innovation/${innovationEntity && innovationEntity.id}/${innovationStageKeyToValue[stage || InnovationStageKey.Concept]}/comments/${innovationDepartment.id}`;
                        this.props.history.push(departmentCommentsLink);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </Col>
              </Row>
            </div>;
          })}
        </div>
      </Fragment>
    );
  }

  private fetchDepartmentsWithComments = async () => {

    this.props.onInnovationItemOversightResolutionsDepartmentsFetchStart();

    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;

    await axiosInstance().get("/innovation/" + (innovationEntity && innovationEntity.id) + "/comments-for-stage/" + stage)
      .then((response: { data: IInnovationDepartmentEntity[] }) => {
        this.props.onInnovationItemOversightResolutionsDepartmentsFetchSuccess(response.data);
      })
      .catch((error) => {
        this.props.onInnovationItemOversightResolutionsDepartmentsFetchError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationDepartmentComments: state.innovationItem.stage.oversight.comments.common,
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
  innovationDepartments: state.innovationItem.stage.oversight.resolutions.innovationDepartmentEntities,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemOversightStageCommentsFetchStart: () => {
    dispatch(innovationItemOversightStageCommentsFetchStart());
  },
  onInnovationItemOversightStageCommentsFetchSuccess: (collectionResource: IInnovationDepartmentComments) => {
    dispatch(innovationItemOversightStageCommentsFetchSuccess(collectionResource));
  },
  onInnovationItemOversightResolutionsDepartmentsFetchError: (error: unknown) => {
    dispatch(innovationItemOversightDashboardDepartmentsFetchError(error));
  },
  onInnovationItemOversightResolutionsDepartmentsFetchStart: () => {
    dispatch(innovationItemOversightResolutionsDepartmentsFetchStart());
  },
  onInnovationItemOversightResolutionsDepartmentsFetchSuccess: (innovationDepartmentEntities: IInnovationDepartmentEntity[]) => {
    dispatch(innovationItemOversightDashboardDepartmentsFetchSuccess(innovationDepartmentEntities));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemOversightAllComments);
