import {Field, FormikProps} from "formik";
import React, {Fragment} from "react";
import {FormRadioCustomList, FormText} from "../../../../../../components/form";
import ImmediatelyUploadMultipleField from "../../../../../../components/form/upload/ImmediatelyUploadMultipleField";
import {Col, Row} from "../../../../../../components/Grid";
import {IActionInformationGate, YesNo} from "../../../../../../interface/entity/IInnovationEntity";
import ActionUtils from "../utils/ActionUtils";

interface IProps {
  form: FormikProps<IActionInformationGate>;
}

class Action extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {form} = this.props;
    return (
      <Fragment>
        <div className="form-control-static">{ActionUtils.finalProductFormAlignmentBasic.title}</div>
        <Row>
          {ActionUtils.finalProductFormAlignmentBasic.fields.map((item, index) => (
            <div className="col-fifth" key={index}>
              <div className="form-group">
                <Field
                  label={item.label}
                  name={item.name}
                  placeholder={item.placeholder}
                  labelClass={item.labelClass}
                  component={FormText}
                />
              </div>
            </div>
          ))}
        </Row>
        <span className="form-label mb-4">Has the Volume Forecast been confirmed:</span>
        <div className="form-group">
          <Field
            name={ActionUtils.salesTargetAggregated.name}
            label={ActionUtils.salesTargetAggregated.label}
            options={ActionUtils.salesTargetAggregated.options}
            component={FormRadioCustomList}
          />
        </div>
        <div className="form-group">
          <Field
            name={ActionUtils.salesObjectivesByCustomerAccountOwner.name}
            label={ActionUtils.salesObjectivesByCustomerAccountOwner.label}
            options={ActionUtils.salesObjectivesByCustomerAccountOwner.options}
            component={FormRadioCustomList}
          />
        </div>
        <Row>
          <Col val="6">
            <div className="form-group">
              <Field
                name="uploadSalesForecastAttachments"
                component={ImmediatelyUploadMultipleField}
                emptyMessage="No files Attached Yet..."
                title="Upload Sales forecast by customer/account Owner:"
              />
            </div>
          </Col>
        </Row>
        <div className="form-control-static">{ActionUtils.finalProductFormAlignmentExtra.title}</div>
        <Row>
          {
            ActionUtils.finalProductFormAlignmentExtra.fields.map((item, index) => (
              <Col val="3" key={index}>
                <div className="form-group">
                  <Field
                    label={item.label}
                    name={item.name}
                    placeholder={item.placeholder}
                    labelClass={item.labelClass}
                    component={FormText}
                  />
                </div>
              </Col>
            ))
          }
        </Row>
        <div className="form-group">
          <Field
            label={ActionUtils.ifContractManufacturedAreCogsConfirmed.label}
            name={ActionUtils.ifContractManufacturedAreCogsConfirmed.name}
            options={ActionUtils.ifContractManufacturedAreCogsConfirmed.options}
            component={FormRadioCustomList}
          />
          {form.values.ifContractManufacturedAreCogsConfirmed === YesNo.No && (
            <div className="mt-4">
              <Field
                name={ActionUtils.ifContractManufacturedAreCogsConfirmedAdjustment.name}
                placeholder={ActionUtils.ifContractManufacturedAreCogsConfirmedAdjustment.placeholder}
                label={ActionUtils.ifContractManufacturedAreCogsConfirmedAdjustment.label}
                labelClass={ActionUtils.ifContractManufacturedAreCogsConfirmedAdjustment.labelClass}
                component={FormText}
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <Field
            label={ActionUtils.doesPilotScaleToFullLandPlan.label}
            name={ActionUtils.doesPilotScaleToFullLandPlan.name}
            options={ActionUtils.doesPilotScaleToFullLandPlan.options}
            component={FormRadioCustomList}
          />
          {form.values.doesPilotScaleToFullLandPlan === YesNo.No && (
            <div className="mt-4">
              <Field
                name={ActionUtils.doesPilotScaleToFullLandPlanAdjustment.name}
                placeholder={ActionUtils.doesPilotScaleToFullLandPlanAdjustment.placeholder}
                label={ActionUtils.doesPilotScaleToFullLandPlanAdjustment.label}
                labelClass={ActionUtils.doesPilotScaleToFullLandPlanAdjustment.labelClass}
                component={FormText}
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <Field
            label={ActionUtils.doFinalPilotCogsMeetOrExceedHurdles.label}
            name={ActionUtils.doFinalPilotCogsMeetOrExceedHurdles.name}
            options={ActionUtils.doFinalPilotCogsMeetOrExceedHurdles.options}
            component={FormRadioCustomList}
          />
          {form.values.doFinalPilotCogsMeetOrExceedHurdles === YesNo.No && (
            <div className="mt-4">
              <Field
                name={ActionUtils.doFinalPilotCogsMeetOrExceedHurdlesAdjustment.name}
                placeholder={ActionUtils.doFinalPilotCogsMeetOrExceedHurdlesAdjustment.placeholder}
                component={FormText}
                label={ActionUtils.doFinalPilotCogsMeetOrExceedHurdlesAdjustment.label}
                labelClass={ActionUtils.doFinalPilotCogsMeetOrExceedHurdlesAdjustment.labelClass}
              />
            </div>
          )}
        </div>
        <div className="form-group">
          <Field
            label={ActionUtils.doSalesCommitmentsMatchProductForecast.label}
            name={ActionUtils.doSalesCommitmentsMatchProductForecast.name}
            options={ActionUtils.doSalesCommitmentsMatchProductForecast.options}
            component={FormRadioCustomList}
          />
          {form.values.doSalesCommitmentsMatchProductForecast === YesNo.No && (
            <div className="mt-4">
              <Field
                name={ActionUtils.doSalesCommitmentsMatchProductForecastAdjustment.name}
                placeholder={ActionUtils.doSalesCommitmentsMatchProductForecastAdjustment.placeholder}
                component={FormText}
                label={ActionUtils.doSalesCommitmentsMatchProductForecastAdjustment.label}
                labelClass={ActionUtils.doSalesCommitmentsMatchProductForecastAdjustment.labelClass}
              />
            </div>
          )}
        </div>
        <Row>
          {
            ActionUtils.finalValues.fields.map((item, index) => (
              <Col val="5" key={index}>
                <div className="form-group">
                  <Field
                    label={item.label}
                    name={item.name}
                    placeholder={item.placeholder}
                    component={FormText}
                  />
                </div>
              </Col>
            ))
          }
        </Row>
        <div className="form-group">
          <Field
            name="generalAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title="General Documents:"
          />
        </div>
        <div className="form-group">
          <Field
            name={ActionUtils.anyOtherInformationYouWouldLikeToInclude.name}
            label={ActionUtils.anyOtherInformationYouWouldLikeToInclude.label}
            placeholder={ActionUtils.anyOtherInformationYouWouldLikeToInclude.placeholder}
            component={FormText}
          />
        </div>
      </Fragment>
    );
  }
}

export default Action;
