import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";
import {ICollectionResource} from "../../../../interface/rest/ICollectionResource";
import {
  ADMIN_DEPARTMENT_COLLECTION_FETCH_ERROR,
  ADMIN_DEPARTMENT_COLLECTION_FETCH_START,
  ADMIN_DEPARTMENT_COLLECTION_FETCH_SUCCESS,
  ADMIN_DEPARTMENT_COLLECTION_INIT,
  ADMIN_DEPARTMENT_COLLECTION_REMOVE_ERROR,
  ADMIN_DEPARTMENT_COLLECTION_REMOVE_START,
  ADMIN_DEPARTMENT_COLLECTION_REMOVE_SUCCESS,
} from "./type";

export const adminDepartmentCollectionRemoveStart = () => {
  return {
    type: ADMIN_DEPARTMENT_COLLECTION_REMOVE_START,
  };
};

export const adminDepartmentCollectionRemoveSuccess = () => {
  return {
    type: ADMIN_DEPARTMENT_COLLECTION_REMOVE_SUCCESS,
  };
};

export const adminDepartmentCollectionRemoveError = () => {
  return {
    type: ADMIN_DEPARTMENT_COLLECTION_REMOVE_ERROR,
  };
};

export const adminDepartmentCollectionFetchStart = () => {
  return {
    type: ADMIN_DEPARTMENT_COLLECTION_FETCH_START,
  };
};

export const adminDepartmentCollectionInit = () => {
  return {
    type: ADMIN_DEPARTMENT_COLLECTION_INIT,
  };
};

export const adminDepartmentCollectionFetchSuccess = (collectionResource: ICollectionResource<IDepartmentEntity>) => {
  return {
    collectionResource,
    type: ADMIN_DEPARTMENT_COLLECTION_FETCH_SUCCESS,
  };
};

export const adminDepartmentCollectionFetchError = (error: unknown) => {
  return {
    error,
    type: ADMIN_DEPARTMENT_COLLECTION_FETCH_ERROR,
  };
};
