import { FieldProps } from "formik";
import React, { useState } from "react";
import { Prompt } from "react-router";
import checkFile from "../../../helpers/checkFile";
import axiosInstance from "../../../instance/axios";
import { IAttachmentEntity } from "../../../interface/entity/IAttachmentEntity";
import Ico from "../../Ico";
import {
  addErrorNotification,
  addSuccessNotification,
} from "../../Notification";

interface IAttachmentsField extends FieldProps {
  title?: string;
  emptyMessage: string;
  onChangeValue?: (attachments: IAttachmentEntity[]) => void;
}

const ImmediatelyUploadMultipleField = ({
  emptyMessage,
  title,
  onChangeValue,
  ...props
}: IAttachmentsField) => {
  const value: IAttachmentEntity[] = props.field.value;

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="form-control-wrap">
      <Prompt
        when={isLoading}
        message="Attachment is processing. Are you sure you want to terminate it?"
      />
      {title && <span className="form-label">{title}</span>}
      {isLoading && (
        <div className="form-file-wrap">
          <div className="form-loading-ico" />
          <div className="form-file-label pr-10 text-primary-light">
            Your file is uploading, please wait
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="form-file-wrap">
          <label className="form-file-ico">
            <input
              disabled={isLoading}
              className="form-file"
              type="file"
              onChange={(event: any) => {
                const file = event.target.files[0];
                event.target.value = null;

                checkFile(
                  file,
                  async () => {
                    const data = new FormData();
                    data.append("file", file);

                    setIsLoading(true);

                    await axiosInstance()
                      .post("/attachment/", data, {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      })
                      .then((response: { data: IAttachmentEntity }) => {
                        const newValue = [
                          ...(props.field.value || []),
                          response.data,
                        ];
                        props.form.setFieldValue(props.field.name, newValue);
                        if (onChangeValue && typeof onChangeValue === "function") {
                          onChangeValue(newValue);
                        }
                        addSuccessNotification(
                          "File was successfully attached"
                        );
                      })
                      .catch((error) => {
                        addErrorNotification(
                          error.response.data.message ||
                            "Error on adding a file"
                        );
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  },
                  (error: string) => {
                    addErrorNotification(error);
                  }
                );
              }}
            />
          </label>
          {(!value || (value && value.length === 0)) && (
            <div className="form-file-label pr-10">{emptyMessage}</div>
          )}
          {value &&
            value.map((item) => (
              <div className="form-file-label" key={item.id}>
                {item.fileName}
                <button
                  className="form-file-remove"
                  type="button"
                  onClick={async () => {
                    setIsLoading(true);
                    await axiosInstance()
                      .delete("/attachment/" + item.id)
                      .then(() => {
                        addSuccessNotification("File was successfully removed");
                        props.form.setFieldValue(
                          props.field.name,
                          props.field.value.filter(
                            (currentItem: IAttachmentEntity) =>
                              item.id !== currentItem.id
                          )
                        );
                      })
                      .catch((error) => {
                        addErrorNotification(
                          error.response.data.message ||
                            "Error on removing a file"
                        );
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                  }}
                >
                  <Ico name="close" />
                  Remove
                </button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ImmediatelyUploadMultipleField;
