import {combineReducers} from "redux";
import {adminUserCreateReducer} from "./create/reducer";
import {adminUserListReducer} from "./list/reducer";
import {adminUserUpdateReducer} from "./update/reducer";

export const adminUserReducer = combineReducers({
  list: adminUserListReducer,
  update: adminUserUpdateReducer,
  create: adminUserCreateReducer,
});
