import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import WrapperLoader from "../../../../../components/loader/WrapperLoader";
import axiosInstance from "../../../../../instance/axios";
import {IInnovationDepartmentEntity} from "../../../../../interface/entity/IInnovationDepartmentEntity";
import {innovationStageKeyToValue} from "../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../store";
import {IInnovationItemCommonState} from "../../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../../store/innovationItem/stage/common/type";
import {
  innovationItemOversightDashboardDepartmentsFetchError,
  innovationItemOversightDashboardDepartmentsFetchSuccess,
  innovationItemOversightDashboardDepartmentUpdateError,
  innovationItemOversightDashboardDepartmentUpdateStart,
  innovationItemOversightDashboardDepartmentUpdateSuccess,
  innovationItemOversightResolutionsDepartmentsFetchStart,
} from "../../../../../store/innovationItem/stage/oversight/resolutions/action";
import {IInnovationItemStageOversightResolutionsState} from "../../../../../store/innovationItem/stage/oversight/resolutions/type";
import {ViewMode} from "../InnovationItemOversightComponent";
import InnovationItemOversightSwitchBar from "./common/InnonvationItemOversightSwitchBar";
// tslint:disable-next-line
import InnovationItemOversightDashboardDepartmentRow, {IInnovationDepartmentResolution} from "./InnovationItemOversightDashboard/InnovationItemOversightDashboardDepartmentRow";

interface IProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemCommonState: IInnovationItemCommonState;
  innovationItemStageOversightResolutionsState: IInnovationItemStageOversightResolutionsState;

  onInnovationItemOversightResolutionsDepartmentsFetchError: (error: unknown) => void;
  onInnovationItemOversightResolutionsDepartmentsFetchStart: () => void;
  onInnovationItemOversightResolutionsDepartmentsFetchSuccess: (departments: IInnovationDepartmentEntity[]) => void;

  onInnovationItemOversightDashboardDepartmentUpdateStart: () => void;
  onInnovationItemOversightDashboardDepartmentUpdateSuccess: (innovationDepartmentEntity: IInnovationDepartmentEntity) => void;
  onInnovationItemOversightDashboardDepartmentUpdateError: (error: unknown) => void;
}

class InnovationItemOversightDashboard extends React.Component<IProps> {
  public async componentDidMount() {
    await this.fetchDepartments();
  }

  public async componentDidUpdate(prevProps: IProps) {
    if (prevProps.innovationItemStageCommonState.stage !== this.props.innovationItemStageCommonState.stage) {
      await this.fetchDepartments();
    }
  }

  public render(): React.ReactNode {
    const {
      innovationItemStageOversightResolutionsState: {isCollectionLoading, innovationDepartmentEntities},
      innovationItemCommonState: {innovationEntity},
      innovationItemStageCommonState: {stage},
    } = this.props;
    const commentsLink = "/innovation/" + (innovationEntity && innovationEntity.id) + "/" + innovationStageKeyToValue[stage] + "/comments";

    return (
      <WrapperLoader isLoading={isCollectionLoading}>
        <InnovationItemOversightSwitchBar viewMode={ViewMode.Dashboard}/>
        <table className="table-grade">
          <tbody>
          {innovationDepartmentEntities.map((innovationDepartmentItem: IInnovationDepartmentEntity) => (
            <InnovationItemOversightDashboardDepartmentRow
              key={innovationDepartmentItem.id}
              innovationDepartmentItem={innovationDepartmentItem}
              commentsLink={commentsLink}
              innovationEntity={innovationEntity}
              onSubmit={async (values: IInnovationDepartmentResolution) => {
                await this.updateStatus(values, innovationDepartmentItem);
              }}
            />
          ))}
          </tbody>
        </table>
      </WrapperLoader>
    );
  }

  private fetchDepartments = async () => {

    this.props.onInnovationItemOversightResolutionsDepartmentsFetchStart();

    const {innovationItemCommonState: {innovationEntity}, innovationItemStageCommonState: {stage}} = this.props;

    await axiosInstance().get("/innovation/" + (innovationEntity && innovationEntity.id) + "/department/" + stage)
      .then((response: { data: IInnovationDepartmentEntity[] }) => {
        this.props.onInnovationItemOversightResolutionsDepartmentsFetchSuccess(response.data);
      })
      .catch((error) => {
        this.props.onInnovationItemOversightResolutionsDepartmentsFetchError(error);
      });
  }

  private updateStatus = async (values: IInnovationDepartmentResolution, innovationDepartmentItem: IInnovationDepartmentEntity) => {
    this.props.onInnovationItemOversightDashboardDepartmentUpdateStart();

    const data = new FormData();
    if (values.attachments && values.attachments.length > 0) {
      values.attachments.forEach((attachment) => {
        data.append("attachments", attachment);
      });
    }
    data.append("comment", values.comment);
    data.append("status", String(values.status));

    await axiosInstance()
      .put(
        "/innovation-department/" + innovationDepartmentItem.id,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      .then(async (response: { data: IInnovationDepartmentEntity }) => {
        this.props.onInnovationItemOversightDashboardDepartmentUpdateSuccess(response.data);
        await this.fetchDepartments();
      })
      .catch((error) => {
        this.props.onInnovationItemOversightDashboardDepartmentUpdateError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
  innovationItemStageOversightResolutionsState: state.innovationItem.stage.oversight.resolutions,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemOversightResolutionsDepartmentsFetchError: (error: unknown) => {
    dispatch(innovationItemOversightDashboardDepartmentsFetchError(error));
  },
  onInnovationItemOversightResolutionsDepartmentsFetchStart: () => {
    dispatch(innovationItemOversightResolutionsDepartmentsFetchStart());
  },
  onInnovationItemOversightResolutionsDepartmentsFetchSuccess: (innovationDepartmentEntities: IInnovationDepartmentEntity[]) => {
    dispatch(innovationItemOversightDashboardDepartmentsFetchSuccess(innovationDepartmentEntities));
  },
  onInnovationItemOversightDashboardDepartmentUpdateError: (error: unknown) => {
    dispatch(innovationItemOversightDashboardDepartmentUpdateError(error));
  },
  onInnovationItemOversightDashboardDepartmentUpdateStart: () => {
    dispatch(innovationItemOversightDashboardDepartmentUpdateStart());
  },
  onInnovationItemOversightDashboardDepartmentUpdateSuccess: (innovationDepartmentEntity: IInnovationDepartmentEntity) => {
    dispatch(innovationItemOversightDashboardDepartmentUpdateSuccess(innovationDepartmentEntity));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemOversightDashboard);
