import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";

export interface IAdminDepartmentUpdateState {
  department?: IDepartmentEntity;
  isLoading: boolean;
}

export const ADMIN_DEPARTMENT_UPDATE_INIT = "ADMIN_DEPARTMENT_UPDATE_INIT";
export const ADMIN_DEPARTMENT_UPDATE_DEINIT = "ADMIN_DEPARTMENT_UPDATE_DEINIT";
export const ADMIN_DEPARTMENT_UPDATE_START = "ADMIN_DEPARTMENT_UPDATE_START";
export const ADMIN_DEPARTMENT_UPDATE_SUCCESS = "ADMIN_DEPARTMENT_UPDATE_SUCCESS";
export const ADMIN_DEPARTMENT_UPDATE_ERROR = "ADMIN_DEPARTMENT_UPDATE_ERROR";

interface IAdminDepartmentUpdateInitAction {
  type: typeof ADMIN_DEPARTMENT_UPDATE_INIT;
  department: IDepartmentEntity;
}

interface IAdminDepartmentUpdateDeinitAction {
  type: typeof ADMIN_DEPARTMENT_UPDATE_DEINIT;
}

interface IAdminDepartmentUpdateStartAction {
  type: typeof ADMIN_DEPARTMENT_UPDATE_START;
}

interface IAdminDepartmentUpdateSuccessAction {
  type: typeof ADMIN_DEPARTMENT_UPDATE_SUCCESS;
}

interface IAdminDepartmentUpdateErrorAction {
  type: typeof ADMIN_DEPARTMENT_UPDATE_ERROR;
}

export type IAdminDepartmentUpdateActionTypes =
  | IAdminDepartmentUpdateInitAction
  | IAdminDepartmentUpdateDeinitAction
  | IAdminDepartmentUpdateStartAction
  | IAdminDepartmentUpdateSuccessAction
  | IAdminDepartmentUpdateErrorAction
  ;
