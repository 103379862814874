import React from "react";
import {Col, Row} from "../../components/Grid";
import Ico from "../../components/Ico";
import {innovationStageKeyToLabel} from "../../interface/entity/IInnovationEntity";
import {IInnovationCollectionFilters} from "../../services/innovation";

interface IProps {
  filters: IInnovationCollectionFilters;
  onSetFilters: (values: IInnovationCollectionFilters) => void;
  onClearAll: () => void;
  isDefault: boolean;
}

class InnovationFilterBar extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {
      filters: {stages, deadlineDateFrom, deadlineDateTo, createDateFrom, createDateTo},
      filters,
      onSetFilters,
      onClearAll,
      isDefault,
    } = this.props;

    return (
      <Row>
        <Col val="10">
          {(createDateFrom || createDateTo) &&
          <div className="tag-filter-row">
            <span className="tag-filter-label">
                Submission Date Range:
            </span>
              <span className="tag-filter-value">
              {createDateFrom ? createDateFrom : "Not Added"} - {createDateTo ? createDateTo : "Not Added"}
            </span>
          </div>}{(deadlineDateFrom || deadlineDateTo) &&
        <div className="tag-filter-row">
            <span className="tag-filter-label">
                Project Deadline Date Range:
            </span>
            <span className="tag-filter-value">
              {deadlineDateFrom ? deadlineDateFrom : "Not Added"} - {deadlineDateTo ? deadlineDateTo : "Not Added"}
            </span>
        </div>}
          {stages &&
          <div className="tag-filter-row">
            <span className="tag-filter-label">Innovation Current Stage(s):</span>
            {stages.map((stage) => {
              const newStages = stages.filter((v) => v !== stage);
              return (<span key={`status-${stage}`} className="tag-filter-tag">
                {innovationStageKeyToLabel[stage]}
                  <span
                    className="tag-filter-tag-control"
                    onClick={() => onSetFilters({...filters, stages: newStages.length > 0 ? newStages : undefined})}
                  >
                    &times;
                  </span>
              </span>
              );
            })}
          </div>}
        </Col>
        <Col val="2">
          {!isDefault &&
          <div className="text-right">
              <button
                  className="btn-link-danger"
                  onClick={() => {
                    onClearAll();
                  }}
              >
                  <Ico name="close" className="ico-xs mr-1"/>
                  Clear all filters
              </button>
          </div>}
        </Col>
      </Row>
    );
  }
}

export default InnovationFilterBar;
