import { Field, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import * as Yup from "yup";
import { FormCheckbox, FormText } from "../../components/form";
import AnotherFormSelect from "../../components/form/AnotherFormSelect";
import FormRadioCustomList from "../../components/form/FormRadioCustomList";
import BaseLayout from "../../components/Layout/BaseLayout";
import WrapperLoader from "../../components/loader/WrapperLoader";
import {
  addErrorNotification,
  addSuccessNotification,
} from "../../components/Notification";
import { ADMIN_USER_LIST } from "../../components/routes";
import axiosInstance from "../../instance/axios";
import { IDepartmentEntity } from "../../interface/entity/IDepartmentEntity";
import {
  UserRole,
  userRoleKeyToLabel,
  UserValidationPermission,
} from "../../interface/entity/IUserEntity";
import { ICollectionResource } from "../../interface/rest/ICollectionResource";
import { AppState } from "../../store";
import {
  adminUserCreateFetchDepartmentsError,
  adminUserCreateFetchDepartmentsStart,
  adminUserCreateFetchDepartmentsSuccess,
} from "../../store/admin/user/create/action";
import { IAdminUserCreateState } from "../../store/admin/user/create/type";

interface IFormValues {
  isValidator: boolean;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  departmentId: { label: string; value: string } | null;
}

const validationSchema = Yup.object().shape({
  isValidator: Yup.boolean().required("Required"),
  role: Yup.string().required("Required"),
  firstName: Yup.string().trim().required("Required"),
  lastName: Yup.string().trim().required("Required"),
  email: Yup.string().trim().email().required("Required"),
  // TODo good to handle this anyway
  // departmentId: Yup.mixed().oneOf([
  //   Yup.object({
  //     label: Yup.string(),
  //     value: Yup.string(),
  //   }),
  //   null,
  // ]),
});

interface IProps extends RouteComponentProps {
  adminUserCreateState: IAdminUserCreateState;

  onAdminUserCreateFetchDepartmentsStart: () => void;
  onAdminUserCreateFetchDepartmentsSuccess: (
    departments: IDepartmentEntity[]
  ) => void;
  onAdminUserCreateFetchDepartmentsError: () => void;
}

class UserAdd extends React.Component<IProps> {
  public async componentDidMount() {
    await this.fetchDepartments();
  }

  public componentWillUnmount(): void {
    // this.props.onAdminDepartmentUpdateDeinit();
  }

  public render(): React.ReactNode {
    const {
      adminUserCreateState: {
        department: { isLoading },
      },
    } = this.props;
    return (
      <BaseLayout location={this.props.location}>
        <div className="mb-6">
          <Link to={ADMIN_USER_LIST}>&lt; Back to Users List</Link>
        </div>
        <WrapperLoader isLoading={isLoading}>{this.getForm()}</WrapperLoader>
      </BaseLayout>
    );
  }

  private getForm = () => {
    const initialValues: IFormValues = {
      firstName: "",
      lastName: "",
      email: "",
      isValidator: false,
      role: String(UserRole.Manager),
      departmentId: null,
    };
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        validationSchema={validationSchema}
        render={(props: FormikProps<IFormValues>) => (
          <form onSubmit={props.handleSubmit}>
            <div className="details-header-line mb-4">
              <div className="pull-left">
                <h1 className="h2 m-0 pt-2">Add User</h1>
              </div>
              <div className="pull-right">
                <button
                  disabled={!props.isValid}
                  className="btn btn-primary btn-shade btn-width"
                  type="submit"
                >
                  Save User
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <Field
                        name="role"
                        label="Select User Role*:"
                        options={[
                          {
                            value: String(UserRole.Manager),
                            label: userRoleKeyToLabel[UserRole.Manager],
                          },
                          {
                            value: String(UserRole.Admin),
                            label: userRoleKeyToLabel[UserRole.Admin],
                          },
                        ]}
                        component={FormRadioCustomList}
                      />
                    </div>
                  </div>
                  <div className="col-6 mt-6">
                    <div className="form-group">
                      <Field
                        name={"isValidator"}
                        label={"Can validate innovation"}
                        component={FormCheckbox}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <Field
                        label="First Name*:"
                        name="firstName"
                        placeholder="Nelle"
                        component={FormText}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Field
                        label="Last Name*:"
                        name="lastName"
                        placeholder="Elliott"
                        component={FormText}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Field
                        label="Email*:"
                        name="email"
                        placeholder="nelle.elliot@gmail.com"
                        component={FormText}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Field
                        component={AnotherFormSelect}
                        name="departmentId"
                        selectProps={{
                          options: this.props.adminUserCreateState.department.items.map(
                            (department: IDepartmentEntity) => {
                              return {
                                value: String(department.id),
                                label: department.name,
                              };
                            }
                          ),
                          placeholder: "Select Department",
                          isClearable: true,
                        }}
                        customProps={{
                          label: "Select Department",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    );
  };

  private onSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    await axiosInstance()
      .post("/user", {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        role: values.role,
        isValidator: values.isValidator
          ? UserValidationPermission.Allow
          : UserValidationPermission.Deny,
        ...(values.departmentId
          ? { departmentId: values.departmentId.value }
          : {}),
      })
      .then(() => {
        actions.resetForm();

        const { history } = this.props;
        history.push(ADMIN_USER_LIST);
        addSuccessNotification("Successfully added!");
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          actions.setErrors(error.response.data);
        } else {
          addErrorNotification("Error on adding a user");
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  private fetchDepartments = async () => {
    this.props.onAdminUserCreateFetchDepartmentsStart();
    await axiosInstance()
      .get("/department/", {
        params: {
          page: 1,
          perPage: 1000,
        },
      })
      .then((response: { data: ICollectionResource<IDepartmentEntity> }) => {
        this.props.onAdminUserCreateFetchDepartmentsSuccess(
          response.data.items
        );
      })
      .catch(() => {
        // TODO handle this case
        this.props.onAdminUserCreateFetchDepartmentsError();
      });
  };
}

const mapStateToProps = (state: AppState) => ({
  adminUserCreateState: state.admin.user.create,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onAdminUserCreateFetchDepartmentsStart: () => {
    dispatch(adminUserCreateFetchDepartmentsStart());
  },
  onAdminUserCreateFetchDepartmentsSuccess: (
    departments: IDepartmentEntity[]
  ) => {
    dispatch(adminUserCreateFetchDepartmentsSuccess(departments));
  },
  onAdminUserCreateFetchDepartmentsError: () => {
    dispatch(adminUserCreateFetchDepartmentsError());
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserAdd)
);
