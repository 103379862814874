import {IInnovationDepartmentComment} from "../../../../../../interface/entity/IInnovationDepartmentComment";
import {IUserEntity} from "../../../../../../interface/entity/IUserEntity";
import {ICollectionResource} from "../../../../../../interface/rest/ICollectionResource";
import {
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_CLOSE_COMMENT_MODAL,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_FETCH_USERS,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_START,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_SUCCESS,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_EDIT_MODAL,
  INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_MODAL,
} from "./type";

export const innovationItemCommentsDepartmentOpenModal = () => {
  return {
    type: INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_MODAL,
  };
};

export const innovationItemCommentsDepartmentOpenEditModal = (departmentId: number, comment: IInnovationDepartmentComment) => {
  return {
    type: INNOVATION_ITEM_COMMENTS_DEPARTMENT_OPEN_COMMENT_EDIT_MODAL,
    comment,
  };
};

export const innovationItemCommentsDepartmentCloseModal = () => {
  return {
    type: INNOVATION_ITEM_COMMENTS_DEPARTMENT_CLOSE_COMMENT_MODAL,
  };
};

export const innovationItemCommentsDepartmentFetchUsers = (users: ICollectionResource<IUserEntity>) => {
  return {
    users,
    type: INNOVATION_ITEM_COMMENTS_DEPARTMENT_FETCH_USERS,
  };
};

export const innovationItemCommentsDepartmentLoadingCommentStart = () => {
  return {
    type: INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_START,
  };
};

export const innovationItemCommentsDepartmentLoadingCommentSuccess = () => {
  return {
    type: INNOVATION_ITEM_COMMENTS_DEPARTMENT_LOADING_COMMENT_SUCCESS,
  };
};
