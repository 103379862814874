import React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Col, Row} from "../../../../../components/Grid";
import WrapperLoader from "../../../../../components/loader/WrapperLoader";
import {
  IActionInformationGate,
  IConceptInformationGate,
  IEstimateInformationGate,
  IExecuteInformationGate,
  IInformationGate,
  InnovationStageKey,
  innovationStageKeyToLabel,
  InnovationStatusKey,
  IRefineInformationGate,
  ITestInformationGate,
} from "../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../store";
import {IInnovationItemCommonState} from "../../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../../store/innovationItem/stage/common/type";
import {ViewMode} from "../InnovationItemInformationGateComponent";
import Action from "./view/Action";
import Concept from "./view/Concept";
import Estimate from "./view/Estimate";
import Execute from "./view/Execute";
import Refine from "./view/Refine";
import Test from "./view/Test";

interface IProps {
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  innovationItemStageGateDataState: IInformationGate;
  innovationItemCommonState: IInnovationItemCommonState;
  onSetViewMode: (viewMode: ViewMode) => void;
}

class InnovationItemInformationGateViewComponent extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {innovationItemStageCommonState: {stage}, innovationItemStageGateDataState, innovationItemCommonState: {innovationEntity}} = this.props;
    return (
      <div>
        <Row className=" mb-4">
          <Col val="10">
            <div className="form-control-static text-regular text-primary-light">
              To edit {innovationStageKeyToLabel[this.props.innovationItemStageCommonState.stage]} Innovation details please click 'Edit Innovation' button:
            </div>
          </Col>
          <Col val="2">
            <button
              onClick={() => {
                this.props.onSetViewMode(ViewMode.Edit);
              }}
              className="btn btn-primary btn-shade w-20pc"
              disabled={innovationEntity && (innovationEntity.status !== InnovationStatusKey.Active)}
            >
              Edit Innovation
            </button>
          </Col>
        </Row>
        <div>
          <WrapperLoader isLoading={!innovationItemStageGateDataState}>
            {stage === InnovationStageKey.Concept && <Concept data={(innovationItemStageGateDataState as IConceptInformationGate)}/>}
            {stage === InnovationStageKey.Refine && <Refine data={(innovationItemStageGateDataState as IRefineInformationGate)}/>}
            {stage === InnovationStageKey.Estimate && <Estimate data={(innovationItemStageGateDataState as IEstimateInformationGate)}/>}
            {stage === InnovationStageKey.Action && <Action data={(innovationItemStageGateDataState as IActionInformationGate)}/>}
            {stage === InnovationStageKey.Test && <Test data={(innovationItemStageGateDataState as ITestInformationGate)}/>}
            {stage === InnovationStageKey.Execute && <Execute data={(innovationItemStageGateDataState as IExecuteInformationGate)}/>}
          </WrapperLoader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemStageCommonState: state.innovationItem.stage.common,
  innovationItemStageGateDataState: state.innovationItem.stage.gate.data,
  innovationItemCommonState: state.innovationItem.common,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemInformationGateViewComponent);
