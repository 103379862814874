import React, {Fragment} from "react";
import {connect} from "react-redux";
import {RouterProps} from "react-router";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import {Col, Row} from "../../../../../../components/Grid";
import Ico from "../../../../../../components/Ico";
import axiosInstance from "../../../../../../instance/axios";
import {IInnovationDepartmentComment as IDCEntity, IInnovationDepartmentComments} from "../../../../../../interface/entity/IInnovationDepartmentComment";
import {IInnovationDepartmentEntity} from "../../../../../../interface/entity/IInnovationDepartmentEntity";
import {InnovationStageKey, innovationStageKeyToValue} from "../../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../../store";
import {IInnovationItemCommonState} from "../../../../../../store/innovationItem/common/type";
import {IInnovationItemStageCommonState} from "../../../../../../store/innovationItem/stage/common/type";
import {
  innovationItemOversightStageCommentsFetchStart,
  innovationItemOversightStageCommentsFetchSuccess,
} from "../../../../../../store/innovationItem/stage/oversight/comments/common/action";
import {
  innovationItemCommentsDepartmentCloseModal,
  innovationItemCommentsDepartmentLoadingCommentStart,
  innovationItemCommentsDepartmentLoadingCommentSuccess,
  innovationItemCommentsDepartmentOpenEditModal,
  innovationItemCommentsDepartmentOpenModal,
} from "../../../../../../store/innovationItem/stage/oversight/comments/department/action";
import {ViewMode} from "../../InnovationItemOversightComponent";
import InnovationItemOversightSwitchBar from "../common/InnonvationItemOversightSwitchBar";
import CommentRow from "./CommentRow";
import InnovationItemDepartmentAddCommentModal, {IInnovationDepartmentComment} from "./InnovationItemDepartmentAddCommentModal";

interface IProps extends RouterProps {
  match: {
    params: {
      departmentId: number;
    };
  };
  innovationItemCommonState: IInnovationItemCommonState;
  innovationDepartmentComments: IInnovationDepartmentComments;
  innovationItemStageCommonState: IInnovationItemStageCommonState;
  onInnovationItemOversightStageCommentsFetchStart: () => void;
  onInnovationItemCommentsDepartmentOpenModal: (departmentId: number) => void;
  onInnovationItemCommentsDepartmentOpenEditModal: (departmentId: number, comment: any) => void;
  onInnovationItemCommentsDepartmentCloseModal: () => void;
  onInnovationItemOversightStageCommentsFetchSuccess: (comments: IInnovationDepartmentComments) => void;
  onInnovationItemCommentsDepartmentLoadingCommentStart: () => void;
  onInnovationItemCommentsDepartmentLoadingCommentSuccess: () => void;
  comment: IDCEntity;
}

interface IState {
  department: any;
}

class InnovationItemOversightDepartmentComments extends React.Component<IProps, IState> {

  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      department: undefined,
    };
  }

  public async componentDidMount() {
    await this.fetchComments();
    await this.fetchInnovationDepartment();
  }

  public render(): React.ReactNode {
    const {
      innovationItemCommonState: {innovationEntity},
      innovationItemStageCommonState: {stage},
      onInnovationItemCommentsDepartmentOpenEditModal,
    } = this.props;
    // tslint:disable-next-line:max-line-length
    const commentsLink = "/innovation/" + (innovationEntity && innovationEntity.id) + "/" + innovationStageKeyToValue[stage || InnovationStageKey.Concept] + "/comments";
    const department = this.state.department !== null ? this.state.department : undefined;
    return (
      <Fragment>
        <InnovationItemOversightSwitchBar
          viewMode={ViewMode.DepartmentComments}
          onCommentAdd={() => this.props.onInnovationItemCommentsDepartmentOpenModal(this.props.match.params.departmentId)}
        />
        <header className="comment-header">
          <Row>
            <Col val="3"><Link to={commentsLink}> {"<"} Back to Full table</Link></Col>
            <Col val="6">
              <div className="text-uppercase text-primary-darker text-medium">
                {department && department.department.name} Department Commenting Thread
              </div>
            </Col>
            <Col val="3">
              <div className="text-primary-light text-medium text-right">
                {this.props.innovationDepartmentComments.collectionResource.pagination ?
                  this.props.innovationDepartmentComments.collectionResource.pagination.total : 0}
                {" "}
                comments added
              </div>
            </Col>
          </Row>
        </header>
        <div className="comment-list mb-8">
          {this.props.innovationDepartmentComments.collectionResource.pagination &&
          !this.props.innovationDepartmentComments.collectionResource.pagination.total &&
          <div className="comment-item-empty">
              <Ico name="comment"/>
              No Comments Added Yet
          </div>}
          {this.props.innovationDepartmentComments.collectionResource.items.map((comment) =>
            <CommentRow
              comment={comment}
              key={comment.id}
              editModal={onInnovationItemCommentsDepartmentOpenEditModal}
              departmentId={this.props.match.params.departmentId}
              onDelete={this.onDelete}
            />,
          )}
        </div>
        <InnovationItemDepartmentAddCommentModal onSubmit={this.onSubmit}/>
      </Fragment>
    );
  }

  private fetchComments = async () => {

    this.props.onInnovationItemOversightStageCommentsFetchStart();
    //
    await axiosInstance().get(`/innovation-department/${this.props.match.params.departmentId}/comments`)
      .then((response: { data: IInnovationDepartmentComments }) => {
        this.props.onInnovationItemOversightStageCommentsFetchSuccess(response.data);
      })
      .catch((error) => {
        // this.props.onInnovationItemOversightResolutionsDepartmentsFetchError(error);
      });
  }

  private fetchInnovationDepartment = async () => {

    await axiosInstance().get(`/innovation-department/${this.props.match.params.departmentId}`)
      .then((response: { data: IInnovationDepartmentEntity }) => {
        this.setState({department: response.data});
        // this.props.onInnovationItemOversightStageCommentsFetchSuccess(response.data);
      })
      .catch((error) => {
        // this.props.onInnovationItemOversightResolutionsDepartmentsFetchError(error);
      });
  }

  private onSubmit = async (values: IInnovationDepartmentComment, deletedAttachmentIds: string[], cb?: () => void) => {
    const {comment} = this.props;

    const data = new FormData();

    if (values.attachments && values.attachments.length > 0) {
      values.attachments.forEach((attachment) => {
        data.append("attachments", attachment);
      });
    }

    data.append("users", JSON.stringify(values.users));
    data.append("message", values.message);
    data.append("deletedAttachmentIds", JSON.stringify(deletedAttachmentIds));

    this.props.onInnovationItemCommentsDepartmentLoadingCommentStart();

    if (comment) {
      await axiosInstance().put(
        `/innovation-department/${this.props.match.params.departmentId}/comment/${comment.id}`,
        data,
      ).finally(() => {
        this.props.onInnovationItemCommentsDepartmentLoadingCommentSuccess();
      });
    } else {
      await axiosInstance().post(
        `/innovation-department/${this.props.match.params.departmentId}/comment`,
        data,
      ).finally(() => {
        this.props.onInnovationItemCommentsDepartmentLoadingCommentSuccess();
      });
    }
    this.props.onInnovationItemCommentsDepartmentCloseModal();
    await this.fetchComments();

    if (cb) {
      cb();
    }
  }

  private onDelete = async (commentId: string) => {
    if (window.confirm("Delete comment?")) {
      await axiosInstance().delete(
        `/innovation-department/${this.props.match.params.departmentId}/comment/${commentId}`,
      );
      await this.fetchComments();
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationDepartmentComments: state.innovationItem.stage.oversight.comments.common,
  innovationItemCommonState: state.innovationItem.common,
  innovationItemStageCommonState: state.innovationItem.stage.common,
  comment: state.innovationItem.stage.oversight.comments.department.comment,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemOversightStageCommentsFetchStart: () => {
    dispatch(innovationItemOversightStageCommentsFetchStart());
  },
  onInnovationItemCommentsDepartmentOpenModal: () => {
    dispatch(innovationItemCommentsDepartmentOpenModal());
  },
  onInnovationItemCommentsDepartmentOpenEditModal: (departmentId: number, comment: any) => {
    dispatch(innovationItemCommentsDepartmentOpenEditModal(departmentId, comment));
  },
  onInnovationItemCommentsDepartmentCloseModal: () => {
    dispatch(innovationItemCommentsDepartmentCloseModal());
  },
  onInnovationItemOversightStageCommentsFetchSuccess: (collectionResource: IInnovationDepartmentComments) => {
    dispatch(innovationItemOversightStageCommentsFetchSuccess(collectionResource));
  },
  onInnovationItemCommentsDepartmentLoadingCommentStart: () => {
    dispatch(innovationItemCommentsDepartmentLoadingCommentStart());
  },
  onInnovationItemCommentsDepartmentLoadingCommentSuccess: () => {
    dispatch(innovationItemCommentsDepartmentLoadingCommentSuccess());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemOversightDepartmentComments);
