import {
  IInnovationItemActionTypes,
  IInnovationItemCommonState,
  INNOVATION_ITEM_CHANGE_ACTIVE_TIMELINE,
  INNOVATION_ITEM_CHANGE_GATE,
  INNOVATION_ITEM_FETCH_ERROR,
  INNOVATION_ITEM_FETCH_START,
  INNOVATION_ITEM_FETCH_SUCCESS,
  INNOVATION_ITEM_INIT,
} from "./type";

const initialState: IInnovationItemCommonState = {
  isLoading: false,
  activeTimelineStage: null,
};

export const innovationItemCommonReducer = (
  state = initialState,
  action: IInnovationItemActionTypes,
): IInnovationItemCommonState => {
  switch (action.type) {
    case INNOVATION_ITEM_FETCH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case INNOVATION_ITEM_FETCH_SUCCESS: {
      return {
        ...state,
        innovationEntity: action.innovationEntity,
        isLoading: false,
      };
    }
    case INNOVATION_ITEM_FETCH_ERROR: {
      return {
        ...state,
        innovationEntity: undefined,
        isLoading: false,
      };
    }
    case INNOVATION_ITEM_CHANGE_ACTIVE_TIMELINE: {
      return {
        ...state,
        activeTimelineStage: action.stage,
      };
    }
    case INNOVATION_ITEM_CHANGE_GATE: {
      if (state.innovationEntity) {
        return  {
          ...state,
          innovationEntity: {
            ...state.innovationEntity,
            informationGates: state.innovationEntity.informationGates.map((gate) => gate.id === action.gate.id ? action.gate : gate),
          },
        };
      }

      return state;
    }
    case INNOVATION_ITEM_INIT: {
      return initialState;
    }
    default:
      return state;
  }
};
