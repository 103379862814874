import {Field, Formik, FormikActions, FormikProps} from "formik";
import React, {Fragment} from "react";
import Modal from "react-modal";
import * as Yup from "yup";
import {FormText} from "../../../components/form";
import WrapperLoader from "../../../components/loader/WrapperLoader";
import {addErrorNotification, addSuccessNotification} from "../../../components/Notification";
import axiosInstance from "../../../instance/axios";

interface IState {
  isModalOpened: boolean;
  isLoading: boolean;
}

interface ISetGeneralEmployeePasswordFormValues {
  password: string;
}

const initialValues = {
  password: "",
};

export const setGeneralEmployeePasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6)
    .trim()
    .required("Required"),
});

class SetGeneralEmployeePassword extends React.Component<any, IState> {
  constructor(props: Readonly<any>) {
    super(props);

    this.state = {
      isModalOpened: false,
      isLoading: false,
    };
  }

  public render(): React.ReactNode {
    const {isModalOpened} = this.state;

    const modal = (
      <Modal
        isOpen={isModalOpened}
        onRequestClose={this.onCloseModal}
        contentLabel="Set new general password"
        className="modal modal-sm"
      >
        <WrapperLoader isLoading={this.state.isLoading}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values: ISetGeneralEmployeePasswordFormValues, actions: FormikActions<ISetGeneralEmployeePasswordFormValues>) => {
              await this.onSubmit(values, actions);
            }}
            validationSchema={setGeneralEmployeePasswordFormValidationSchema}
            render={(props: FormikProps<ISetGeneralEmployeePasswordFormValues>) => (
              <form onSubmit={props.handleSubmit}>
                <header className="modal-header clearfix">
                  <h3 className="modal-title pull-left">
                    Set new general password
                  </h3>
                  <button onClick={this.onCloseModal} className="modal-close">
                    <i className="ico ico-close-thin"/>
                  </button>
                </header>
                <div className="mobal-body px-5 py-6">
                  <p>
                    You're going to set a new password for the general user.
                  </p>
                  <div className="form-group">
                    <Field
                      name="password"
                      label="Password:"
                      placeholder="Password"
                      type={"password"}
                      component={FormText}
                    />
                  </div>
                </div>
                <footer className="modal-footer text-right">
                  <button
                    className="btn btn-shade ml-4"
                    type="button"
                    onClick={this.onCloseModal}
                  >
                    Close
                  </button>
                  <button
                    className="btn btn-shade ml-4 btn-danger"
                    type="submit"
                  >
                    Save password
                  </button>
                </footer>
              </form>
            )}
          />
        </WrapperLoader>
      </Modal>
    );

    return (
      <Fragment>
        <button
          onClick={() => {
            this.setState({isModalOpened: true});
          }}
          className="btn btn-primary btn-shade btn-width mr-3"
        >
          Set general user password
        </button>
        {modal}
      </Fragment>
    );
  }

  private onCloseModal = () => {
    if (this.state.isLoading) {
      return;
    }
    this.setState({isModalOpened: false});
  }

  private onSubmit = async (values: ISetGeneralEmployeePasswordFormValues, actions: FormikActions<ISetGeneralEmployeePasswordFormValues>) => {
    if (this.state.isLoading) {
      return;
    }

    await axiosInstance().put("/user/set-general-employee-password", {
      password: values.password,
    })
      .then(() => {
        this.setState({isModalOpened: false});
        addSuccessNotification("Password has been updated");
      })
      .catch((error) => {
        addErrorNotification(error.response.data.message || "Error on updating a password");
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }
}

export default SetGeneralEmployeePassword;
