import {FieldProps} from "formik";
import React, {Fragment} from "react";
import {Col, Row} from "../Grid";

interface IFormCheckboxList extends FieldProps {
  label?: string;
  options: Array<{ value: string, label: string, tooltip?: string }>;
  checkboxCol?: string;
}

const FormCheckboxList = ({field, label, options, checkboxCol = "2"}: IFormCheckboxList) => {
  return (
    <Fragment>
      {label && <p>{label}</p>}
      <Row>
        {
          options.map((item, index: number) => (
            <Col val={checkboxCol} key={index}>
              <label className="form-checkbox">
                <input
                  {...field}
                  name={`${field.name}.${item.value}`}
                  type="checkbox"
                  className="form-checkbox-input"
                  checked={field.value && field.value[item.value]}
                />
                <span className="form-checkbox-label">{item.label}</span>
                {item.tooltip && (
                  <div className="tooltip-common ml-1">
                    <div className="tooltip-target">?</div>
                    <div className="tooltip-popup">{item.tooltip}</div>
                  </div>
                )}
              </label>
            </Col>
          ))
        }
      </Row>
    </Fragment>
  );
};

export default FormCheckboxList;
