import {isInteger} from "formik";
import {IFormText} from "../../../../../../components/form/IForm";
import {YesNo} from "../../../../../../interface/entity/IInnovationEntity";

class EstimateUtils {

  public static stages = [
    {name: "pilot", header: "Pilot:"},
    {name: "launch", header: "Launch:"},
    {name: "fullMarket", header: "Full Market:"},
  ];

  public static needUnitsFields = [
    {name: "stores", placeholder: "Please fill in value…", label: "# of stores"},
    {name: "usd", placeholder: "Please fill in value…", label: "# of Units/Store/Day"},
    {name: "days", placeholder: "Please fill in value…", label: "# of days"},
  ];

  public static costFields = [
    {name: "seed", placeholder: "Please fill in value…", label: "Seed"},
    {name: "fieldInputs", placeholder: "Please fill in value…", label: "Field Inputs"},
    {name: "harvest", placeholder: "Please fill in value…", label: "Harvest"},
    {name: "dfffManufacture", placeholder: "Please fill in value…", label: "DFFF Manuf"},
    {name: "coManufacture", placeholder: "Please fill in value…", label: "Co-Manuf"},
    {name: "packaging", placeholder: "Please fill in value…", label: "Packaging"},
    {name: "acres", placeholder: "Please fill in value…", label: "# of acres"},
    {name: "total", placeholder: "Please fill in value…", label: "TOTAL"},
    {name: "units", placeholder: "Please fill in value…", label: "# of units"},
    {name: "costUnit", placeholder: "Please fill in value…", label: "Cost/unit"},
    {name: "finalWholesalePricePerUnit", placeholder: "Please fill in value…", label: "Final Wholesale price/unit"},
  ];

  public static marginFields = [
    {name: "duda", placeholder: "Please fill in value…", label: "Duda"},
    {name: "distributor", placeholder: "Please fill in value…", label: "Distributor"},
    {name: "retailer", placeholder: "Please fill in value…", label: "Retailer"},
    {name: "freight", placeholder: "Please fill in value…", label: "Freight"},
  ];

  public static needUnits = {
    name: "needUnits",
    stages: EstimateUtils.stages.map((stage) => ({...stage, fields: EstimateUtils.needUnitsFields})),
    header: "Please estimate number of units needed:",
  };

  public static costPerUnit = {
    name: "cost",
    stages: EstimateUtils.stages.map((stage) => ({...stage, fields: EstimateUtils.costFields})),
    header: "Please estimate the cost per unit (acre):",
  };

  public static margin = {
    name: "margin",
    stages: EstimateUtils.stages.map((stage) => ({...stage, fields: EstimateUtils.marginFields})),
    header: "Estimate the Target Margin per Unit:",
  };

  public static fields = {
    stagedFeilds: [
      EstimateUtils.needUnits,
      EstimateUtils.costPerUnit,
      EstimateUtils.margin,
    ],
    margin: {
      targetRetailPrice: {
        name: "margin.targetRetailPrice",
        placeholder: "Please fill in value…",
        label: "Target Retail Price:",
      },
    },
    additionalEquipment: {
      name: "additionalEquipment",
      answer: {
        name: "answer",
        placeholder: "Please fill in value…",
        label: "Is any additional equipment needed to grow/harvest/process/pack:",
        viewLabel: "Additional Equipment Needed:",
        options: [
          {value: YesNo.Yes, label: "Yes"},
          {value: YesNo.No, label: "No"},
        ],
      },
      description: {
        name: "description",
        placeholder: "Please fill in value…",
        label: "Please Describe:",
        viewLabel: "Describe/Specify:",
      },
    },
    capex: {
      name: "capex",
      amortize: {
        name: "amortize",
        header: "Amortize equipment CAPEX per unit:",
        fields: EstimateUtils.stages.map((stage) => ({...stage, placeholder: "Please fill in value…"})),
      },
    },
  };

  public static anyOtherInformationYouWouldLikeToInclude: IFormText = {
    label: "Any other information you would like to include:",
    name: "anyOtherInformationYouWouldLikeToInclude",
    placeholder: "Please Fill In Additional Information if any…",
  };

  public static getValue(value: any): any {
    return value ? value : "Not Added";
  }

  // tslint:disable-next-line:max-line-length
  public static calculateNumberOfPackages({stores, usd, days}: { stores: string, usd: string, days: string }, noMessage: string = "Not Added") {
    if (!isInteger(stores) || !isInteger(usd) || !isInteger(days)) {
      return noMessage;
    }
    return (Number(stores) * Number(usd) * Number(days)).toLocaleString();
  }
}

export default EstimateUtils;
