import {IUserEntity} from "../../../../interface/entity/IUserEntity";
import {ICollectionResource} from "../../../../interface/rest/ICollectionResource";

export interface IAdminUserListState {
  collectionResource: ICollectionResource<IUserEntity>;
  isRemoving: boolean;
}

export const ADMIN_USER_COLLECTION_INIT = "ADMIN_USER_COLLECTION_INIT";

export const ADMIN_USER_COLLECTION_FETCH_START = "ADMIN_USER_COLLECTION_FETCH_START";
export const ADMIN_USER_COLLECTION_FETCH_SUCCESS = "ADMIN_USER_COLLECTION_FETCH_SUCCESS";
export const ADMIN_USER_COLLECTION_FETCH_ERROR = "ADMIN_USER_COLLECTION_FETCH_ERROR";

export const ADMIN_USER_COLLECTION_REMOVE_START = "ADMIN_USER_COLLECTION_REMOVE_START";
export const ADMIN_USER_COLLECTION_REMOVE_SUCCESS = "ADMIN_USER_COLLECTION_REMOVE_SUCCESS";
export const ADMIN_USER_COLLECTION_REMOVE_ERROR = "ADMIN_USER_COLLECTION_REMOVE_ERROR";

interface IAdminUserCollectionRemoveStartAction {
  type: typeof ADMIN_USER_COLLECTION_REMOVE_START;
}

interface IAdminUserCollectionRemoveSuccessAction {
  type: typeof ADMIN_USER_COLLECTION_REMOVE_SUCCESS;
}

interface IAdminUserCollectionRemoveErrorAction {
  type: typeof ADMIN_USER_COLLECTION_REMOVE_ERROR;
}

interface IAdminUserCollectionInitAction {
  type: typeof ADMIN_USER_COLLECTION_INIT;
}

interface IAdminUserCollectionFetchStartAction {
  type: typeof ADMIN_USER_COLLECTION_FETCH_START;
}

interface IAdminUserCollectionFetchSuccessAction {
  type: typeof ADMIN_USER_COLLECTION_FETCH_SUCCESS;
  collectionResource: ICollectionResource<IUserEntity>;
}

interface IAdminUserCollectionFetchErrorAction {
  type: typeof ADMIN_USER_COLLECTION_FETCH_ERROR;
}

export type IAdminUserListActionTypes =
  | IAdminUserCollectionRemoveStartAction
  | IAdminUserCollectionRemoveSuccessAction
  | IAdminUserCollectionRemoveErrorAction
  | IAdminUserCollectionInitAction
  | IAdminUserCollectionFetchStartAction
  | IAdminUserCollectionFetchSuccessAction
  | IAdminUserCollectionFetchErrorAction
  ;
