import {InnovationStageKey} from "../../../../interface/entity/IInnovationEntity";

export interface IInnovationItemStageCommonState {
  stage: InnovationStageKey;
}

export const INNOVATION_ITEM_STAGE_COMMON_SET_STAGE = "INNOVATION_ITEM_STAGE_COMMON_SET_STAGE";

interface IInnovationItemStageCommonSetStageAction {
  type: typeof INNOVATION_ITEM_STAGE_COMMON_SET_STAGE;
  stage: InnovationStageKey;
}

export type IInnovationItemStageCommonActionTypes =
  | IInnovationItemStageCommonSetStageAction
  ;
