import React, { ReactNode } from "react";
import {Container} from "../Grid";

interface IProps {
  children: ReactNode;
}

class AuthLayout extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { children } = this.props;
    return (
      <div className="page-wrap page-wrap-auth">
        <Container>
          <div className="block">
            {children}
          </div>
        </Container>
      </div>
    );
  }
}

export default AuthLayout;
