import jwtDecode from "jwt-decode";
import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Provider } from "react-redux";
import store from "store";
import App from "./components/App";
import { APP_SELECTOR } from "./constant/app";
import {
  LOCAL_STORAGE_AUTH_TOKEN,
  LOCAL_STORAGE_INNOVATION_STATS,
  LOCAL_STORAGE_USER,
} from "./constant/localStorage";
import { IUserEntity } from "./interface/entity/IUserEntity";
import "./markup/scss/app.scss";
import * as serviceWorker from "./serviceWorker";
import getReduxStore from "./store";
import { initialState } from "./store/system/reducer";

Modal.setAppElement(`#${APP_SELECTOR}`);

const authToken: string = store.get(LOCAL_STORAGE_AUTH_TOKEN);
const user: IUserEntity = store.get(LOCAL_STORAGE_USER);
const innovationStats = store.get(LOCAL_STORAGE_INNOVATION_STATS);

const system = initialState;

system.innovationStats = innovationStats;

export const clearLocalStorage = () => {
  store.remove(LOCAL_STORAGE_AUTH_TOKEN);
  store.remove(LOCAL_STORAGE_USER);
  store.remove(LOCAL_STORAGE_INNOVATION_STATS);
};

if (authToken && user) {
  const jwt = jwtDecode<{
    foo: string;
    exp: number;
    iat: number;
  }>(authToken);

  if (new Date().getTime() / 1000 > jwt.exp) {
    clearLocalStorage();
  } else {
    system.authToken = authToken;
    system.user = user;
    system.isGuest = false;
  }
} else {
  clearLocalStorage();
}

export const reduxStore = getReduxStore({ system });

ReactDOM.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
  document.getElementById(APP_SELECTOR)
);

serviceWorker.unregister();
