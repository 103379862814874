import {DateTime} from "luxon";
import React, {Fragment} from "react";
import {Col, Row} from "../../../../../../components/Grid";
import {reduxStore} from "../../../../../../index";
import {IAttachmentEntity} from "../../../../../../interface/entity/IAttachmentEntity";
import {IInnovationDepartmentComment} from "../../../../../../interface/entity/IInnovationDepartmentComment";
import {innovationDepartmentStatusKeyToMetaValue, innovationDepartmentStatusKeyToValue} from "../../../../../../interface/entity/IInnovationDepartmentEntity";

interface IProps {
  comment: IInnovationDepartmentComment;
  editModal: (departmentId: number, comment: any) => void;
  departmentId: number;
  onDelete: (commentId: string) => void;
}

class CommentRow extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {comment, editModal, departmentId, onDelete} = this.props;
    const {system} = reduxStore.getState();

    return (
      <div className="comment-item" key={`${comment.id}`}>
        <Row>
          <Col val="3">
            {comment.innovationDepartmentStatus !== null &&
            <Fragment>
                <p className="text-medium mb-2">Resolution Status:</p>
                <p className={`text-medium text-${innovationDepartmentStatusKeyToValue[comment.innovationDepartmentStatus]} mb-0`}>
                  {innovationDepartmentStatusKeyToMetaValue[comment.innovationDepartmentStatus]}
                </p>
            </Fragment>}
          </Col>
          <Col val="6">
            <div className="media">
              <div className="media-aside comment-item-avatar">
                <div className="user-avatar">
                  {comment.user.firstName.toUpperCase().charAt(0)}
                  {comment.user.lastName.toUpperCase().charAt(0)}
                </div>
              </div>
              <div className="media-body pl-2 clearfix">
                <div className="clearfix">
                  <div className="pull-left">
                    <p className="text-medium mb-2">{comment.user.fullName}</p>
                  </div>
                  <div className="pull-right">
                    <p className="text-primary-light mb-2">
                      Added: {DateTime.fromISO(comment.createdAt).toFormat("MM/dd/y")}
                    </p>
                  </div>
                </div>
                <div className="comment-item-content">
                  <p>{comment.message}</p>
                </div>
                {comment.attachments && comment.attachments.length > 0 && (
                  <Fragment>
                    {comment.attachments.map((attachment: IAttachmentEntity) => {
                      return (
                        <div key={attachment.id} className="form-control-wrap">
                          <span className="form-label sr-only"/>
                          <div className="form-control-static text-primary text-iconed my-2 py-0">
                            <a target={"_blank"} rel="noopener noreferrer" href={attachment.cdnUrl}>
                              <i className="ico ico-doc "/>
                              &nbsp;{attachment.fileName}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </Fragment>
                )}
              </div>
            </div>
          </Col>
          <Col val="3">
            <div className="comment-item-action">
              {system.user.id === comment.user.id &&
              <Fragment>
                  <button
                      className="btn btn-ghost btn-sq mr-3"
                      type="button"
                      onClick={() => editModal(departmentId, comment)}
                  >
                      <i className="ico ico-edit "/>
                  </button>
                  <button
                      className="btn btn-ghost btn-sq mr-3"
                      type="button"
                      onClick={() => onDelete(comment.id)}
                  >
                      <i className="ico ico-delete "/>
                  </button>
              </Fragment>}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CommentRow;
