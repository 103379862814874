import {FormikProps} from "formik";
import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import SubmitInnovationForm from "../components/form/SubmitInnovationForm";
import AuthLayout from "../components/Layout/AuthLayout";
import {AUTO_LOGOUT_DELAY} from "../constant/app";
import {clearLocalStorage} from "../index";
import {IInnovationEntity} from "../interface/entity/IInnovationEntity";
import formSuccess from "../markup/images/form-success.svg";
import mainLogo from "../markup/images/main-logo.png";
import {AppState} from "../store";
import {deleteAuthTokenAndUser} from "../store/system/action";
import SubmitFormWrapper, {IFormValues} from "./SubmitFormWrapper";

interface IState {
  isSubmitted: boolean;
}

interface IProps {
  onDeleteAuthTokenAndUser: () => void;
}

class Submit extends React.Component<IProps, IState> {
  private logoutTimer: number | undefined;

  constructor(props: IProps) {
    super(props);

    this.state = {
      isSubmitted: false,
    };
  }

  public componentWillUnmount() {
    if (this.logoutTimer) {
      window.clearTimeout(this.logoutTimer);
    }
  }

  public render(): React.ReactNode {
    const submittedSection = (
      <Fragment>
        <div className="media mb-2">
          <div className="media-body">
            <p><span className="text-medium">Thank you for your submission.</span>
              <br/>
              Someone from the Innovation Department will reach out to you shortly to follow-up.
            </p>
          </div>
          <div className="media-aside">
            <img className="mx-auto mb-3 d-block" src={formSuccess} alt="Form Success"/>
          </div>
        </div>
        <button
          className="btn btn-primary btn-lg btn-block"
          onClick={() => {
            this.onLogout();
          }}
        >
          got it
        </button>
      </Fragment>
    );

    return (
      <AuthLayout>
        <header className="block-header">
          <div className="block-logo">
            <img src={mainLogo} alt="Duda Farms"/>
          </div>
          <h1 className="block-title h2">
            Innovation Submission Form
          </h1>
        </header>
        <div className="block-body">
          {
            this.state.isSubmitted ? (
              submittedSection
            ) : (
              <SubmitFormWrapper onSuccess={this.onSuccess}>
                {(props: FormikProps<IFormValues>) =>
                  <Fragment>
                    <SubmitInnovationForm/>
                    <button type="submit" disabled={!props.isValid} className="btn btn-primary btn-lg btn-block">Submit My Innovation Request
                    </button>
                  </Fragment>
                }
              </SubmitFormWrapper>
            )
          }
        </div>
      </AuthLayout>
    );
  }

  private onSuccess = (innovation: IInnovationEntity) => {
    this.setState({isSubmitted: true}, () => {
      this.logoutTimer = window.setTimeout(
        () => {
          this.onLogout();
        },
        AUTO_LOGOUT_DELAY * 1000,
      );
    });
  }

  private onLogout = () => {
    const {onDeleteAuthTokenAndUser} = this.props;

    clearLocalStorage();
    onDeleteAuthTokenAndUser();
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDeleteAuthTokenAndUser: () => dispatch(deleteAuthTokenAndUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Submit);
