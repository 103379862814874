import {InnovationStatusKey} from "../../interface/entity/IInnovationEntity";
import {IInnovationCollectionSort} from "../../services/innovation";
import {
  IInnovationCollectionActionTypes,
  IInnovationCollectionState,
  INNOVATION_COLLECTION_CLOSE_FILTER_MODAL,
  INNOVATION_COLLECTION_FETCH_ERROR,
  INNOVATION_COLLECTION_FETCH_START,
  INNOVATION_COLLECTION_FETCH_SUCCESS,
  INNOVATION_COLLECTION_INIT,
  INNOVATION_COLLECTION_OPEN_FILTER_MODAL,
  INNOVATION_COLLECTION_SET_FILTERS,
  INNOVATION_COLLECTION_SET_SORT,
  INNOVATION_COLLECTION_SET_STATUS,
} from "./type";

const initialState: IInnovationCollectionState = {
  collectionResource: {
    isLoading: false,
    items: [],
  },
  filters: {},
  sort: IInnovationCollectionSort.createAtDesc,
  isFiltersModalOpen: false,
  status: InnovationStatusKey.Active,
};

export const innovationCollectionReducer = (
  state = initialState,
  action: IInnovationCollectionActionTypes,
): IInnovationCollectionState => {
  switch (action.type) {
    case INNOVATION_COLLECTION_FETCH_START: {
      return {
        ...state,
        collectionResource: {
          isLoading: true,
          items: [],
        },
      };
    }
    case INNOVATION_COLLECTION_FETCH_SUCCESS: {
      return {
        ...state,
        collectionResource: {
          ...action.collectionResource,
          isLoading: false,
        },
      };
    }
    case INNOVATION_COLLECTION_FETCH_ERROR: {
      return {
        ...state,
        collectionResource: {
          isLoading: false,
          items: [],
        },
      };
    }
    case INNOVATION_COLLECTION_OPEN_FILTER_MODAL: {
      return {
        ...state,
        isFiltersModalOpen: true,
      };
    }
    case INNOVATION_COLLECTION_CLOSE_FILTER_MODAL: {
      return {
        ...state,
        isFiltersModalOpen: false,
      };
    }
    case INNOVATION_COLLECTION_SET_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    case INNOVATION_COLLECTION_SET_SORT: {
      return {
        ...state,
        sort: action.sort,
      };
    }
    case INNOVATION_COLLECTION_SET_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }
    case INNOVATION_COLLECTION_INIT: {
      return initialState;
    }
    default:
      return state;
  }
};
