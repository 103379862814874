import {combineReducers} from "redux";
import {innovationItemActivityReducer} from "./activity/reducer";
import {innovationItemCommonReducer} from "./common/reducer";
import {innovationMilestoneReducer} from "./milestone/reducer";
import {innovationItemStageReducer} from "./stage/reducer";

export const innovationItemReducer = combineReducers({
  activity: innovationItemActivityReducer,
  common: innovationItemCommonReducer,
  stage: innovationItemStageReducer,
  milestone: innovationMilestoneReducer,
});
