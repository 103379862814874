import {combineReducers} from "redux";
import {innovationItemStageCommonReducer} from "./common/reducer";
import {innovationItemStageGateReducer} from "./gate/reducer";
import {innovationItemStageOversightReducer} from "./oversight/reducer";

export const innovationItemStageReducer = combineReducers({
  common: innovationItemStageCommonReducer,
  gate: innovationItemStageGateReducer,
  oversight: innovationItemStageOversightReducer,
});
