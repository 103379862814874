import {IInformationGateEntity, IInformationGateMilestone} from "../../../interface/entity/IInformationGateEntity";
import {IUserEntity} from "../../../interface/entity/IUserEntity";
import {
  INNOVATION_MILESTONE_ADMINS_FETCH_START, INNOVATION_MILESTONE_ADMINS_FETCH_SUCCESS,
  INNOVATION_MILESTONE_GATE_FETCH_START,
  INNOVATION_MILESTONE_GATE_FETCH_SUCCESS,
  INNOVATION_MILESTONE_MANAGERS_FETCH_START,
  INNOVATION_MILESTONE_MANAGERS_FETCH_SUCCESS,
  INNOVATION_MILESTONE_REORDER,
  INNOVATION_MILESTONE_VIEW,
  MilestoneView,
} from "./type";

export const innovationMilestoneChangeView = (view: MilestoneView) => {
  return {
    view,
    type: INNOVATION_MILESTONE_VIEW,
  };
};

export const informationGateFetchStart = () => {
  return {
    type: INNOVATION_MILESTONE_GATE_FETCH_START,
  };
};

export const informationGateFetchSuccess = (gate: IInformationGateEntity) => {
  return {
    type: INNOVATION_MILESTONE_GATE_FETCH_SUCCESS,
    gate,
  };
};

export const innovationMilestoneReorder = (milestones: IInformationGateMilestone[]) => {
  return {
    milestones,
    type: INNOVATION_MILESTONE_REORDER,
  };
};

export const innovationMilestoneManagersFetchStart = () => {
  return {
    type: INNOVATION_MILESTONE_MANAGERS_FETCH_START,
  };
};

export const innovationMilestoneManagersFetchSuccess = (managers: IUserEntity[]) => {
  return {
    type: INNOVATION_MILESTONE_MANAGERS_FETCH_SUCCESS,
    managers,
  };
};

export const innovationMilestoneAdminsFetchStart = () => {
  return {
    type: INNOVATION_MILESTONE_ADMINS_FETCH_START,
  };
};

export const innovationMilestoneAdminsFetchSuccess = (admins: IUserEntity[]) => {
  return {
    type: INNOVATION_MILESTONE_ADMINS_FETCH_SUCCESS,
    admins,
  };
};
