import {Field, FormikProps} from "formik";
import React, {Fragment} from "react";
import {FormText} from "../../../../../../components/form";
import FormRadioCustomList from "../../../../../../components/form/FormRadioCustomList";
import ImmediatelyUploadMultipleField from "../../../../../../components/form/upload/ImmediatelyUploadMultipleField";
import {Col, Row} from "../../../../../../components/Grid";
import {IEstimateInformationGate, YesNo} from "../../../../../../interface/entity/IInnovationEntity";
import EstimateUtils from "../utils/EstimateUtils";

interface IProps {
  form: FormikProps<IEstimateInformationGate>;
}

class Estimate extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {form} = this.props;
    return (
      <div>
        <Row>
          <Col val="10">
            {EstimateUtils.fields.stagedFeilds.map((param) =>
              <Fragment key={param.name}>
                <div className="mb-2">
                  <span className="form-label mb-4">{param.header}</span>
                </div>
                {param.stages.map((stage) =>
                  <Row key={`${param.name}.${stage.name}`}>
                    <Col val="2">
                      <div className="pt-label">
                        <div className="form-control-static text-primary-light">{stage.header}:</div>
                      </div>
                    </Col>
                    <Col val="10">
                      <Row>
                        {stage.fields.map((field) =>
                          <Col val="3" key={`${param.name}.${stage.name}.${field.name}`}>
                            <div className="form-group">
                              <Field
                                name={`${param.name}.${stage.name}.${field.name}`}
                                placeholder={field.placeholder}
                                label={field.label}
                                labelClass="text-regular text-primary-dark"
                                component={FormText}
                              />
                            </div>
                          </Col>)}
                        {param.name === EstimateUtils.needUnits.name && <Col val="3" key={`${param.name}.${stage.name}.total`}>
                            <label className="form-control-wrap">
                            <span className="form-label text-regular text-primary-dark">
                              Total:
                            </span>
                                <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    value={EstimateUtils.calculateNumberOfPackages({
                                      stores: (form.values as any)[param.name][stage.name].stores,
                                      days: (form.values as any)[param.name][stage.name].days,
                                      usd: (form.values as any)[param.name][stage.name].usd,
                                    })}
                                />
                            </label>
                        </Col>}
                      </Row>
                    </Col>
                  </Row>)}
              </Fragment>)}
            <Row>
              <Col val="10" offset="2">
                <div className="form-group">
                  <Field
                    name={EstimateUtils.fields.margin.targetRetailPrice.name}
                    placeholder={EstimateUtils.fields.margin.targetRetailPrice.placeholder}
                    label={EstimateUtils.fields.margin.targetRetailPrice.label}
                    labelClass="text-regular text-primary-dark"
                    component={FormText}
                  />
                </div>
              </Col>
            </Row>
            <div className="form-group">
              <Field
                name={`${EstimateUtils.fields.additionalEquipment.name}.${EstimateUtils.fields.additionalEquipment.answer.name}`}
                label={EstimateUtils.fields.additionalEquipment.answer.label}
                component={FormRadioCustomList}
                options={EstimateUtils.fields.additionalEquipment.answer.options}
              />
            </div>
            {form.values.additionalEquipment && form.values.additionalEquipment.answer === YesNo.Yes && (
              <Row>
                <Col val="6">
                  <div className="form-group">
                    <Field
                      name={`${EstimateUtils.fields.additionalEquipment.name}.${EstimateUtils.fields.additionalEquipment.description.name}`}
                      placeholder={EstimateUtils.fields.additionalEquipment.description.placeholder}
                      label={EstimateUtils.fields.additionalEquipment.description.label}
                      labelClass="text-regular text-primary-dark"
                      component={FormText}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <div className="mb-2">
              <span className="form-label">{EstimateUtils.fields.capex.amortize.header}</span>
            </div>
            <div className="mb-2">
              <Row>
                {EstimateUtils.fields.capex.amortize.fields.map((field) =>
                  <Col val="3" key={`${EstimateUtils.fields.capex.name}.${EstimateUtils.fields.capex.amortize.name}.${field.name}`}>
                    <div className="form-group">
                      <Field
                        name={`${EstimateUtils.fields.capex.name}.${EstimateUtils.fields.capex.amortize.name}.${field.name}`}
                        placeholder={field.placeholder}
                        label={field.header}
                        labelClass="text-regular text-primary-dark"
                        component={FormText}
                      />
                    </div>
                  </Col>)}
              </Row>
            </div>
          </Col>
        </Row>
        <div className="form-group">
          <Field
            name="generalAttachments"
            component={ImmediatelyUploadMultipleField}
            emptyMessage="No files Attached Yet..."
            title={"General documents (such as costing sheets, food safety reports, sales forecasts, etc.):"}
          />
        </div>
        <div className="form-group">
          <Field
            name={EstimateUtils.anyOtherInformationYouWouldLikeToInclude.name}
            label={EstimateUtils.anyOtherInformationYouWouldLikeToInclude.label}
            placeholder={EstimateUtils.anyOtherInformationYouWouldLikeToInclude.placeholder}
            component={FormText}
          />
        </div>
      </div>
    );
  }
}

export default Estimate;
