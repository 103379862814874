import { Field, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import store from "store";
import { FormPassword, FormText } from "../components/form";
import AuthLayout from "../components/Layout/AuthLayout";
import {
  ILoginFormValuesInterface,
  loginFormValidationSchema,
  onLoginFormSubmit,
} from "../components/login";
import { addSuccessNotification } from "../components/Notification";
import {
  FORGOT_PASSWORD,
  RECOVERY_PASSWORD_PREFIX,
} from "../components/routes";
import {LOCAL_STORAGE_INNOVATION_STATS} from "../constant/localStorage";
import { InnovationStatusKey } from "../interface/entity/IInnovationEntity";
import { IUserEntity } from "../interface/entity/IUserEntity";
import mainLogo from "../markup/images/main-logo.png";
import {getStats} from "../services/innovation";
import {
  setAuthTokenAndUser,
  setInnovationStats,
} from "../store/system/action";

interface IProps extends RouteComponentProps {
  onSetAuthTokenAndUser: (authToken: string, user: IUserEntity) => void;
  onSetInnovationStats: (
    innovationStats: {
      status: InnovationStatusKey;
      statusValue: string;
      statusLabel: string;
      amount: number;
    }[]
  ) => void;
}

const initialValues = {
  email: "",
  password: "",
};

class CommonLogin extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <AuthLayout>
        <header className="block-header">
          <div className="block-logo">
            <img src={mainLogo} alt="Duda Farms" />
          </div>
          <h1 className="block-title h3">Sign In</h1>
        </header>
        <div className="block-body">
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            initialValues={initialValues}
            onSubmit={async (
              values: ILoginFormValuesInterface,
              actions: FormikActions<ILoginFormValuesInterface>
            ) => {
              await onLoginFormSubmit(
                values,
                actions,
                async (authToken, user) => {
                  const { onSetAuthTokenAndUser } = this.props;
                  onSetAuthTokenAndUser(authToken, user);

                  const {data: innovationStats} = await getStats();
                  this.props.onSetInnovationStats(innovationStats);
                  store.set(LOCAL_STORAGE_INNOVATION_STATS, innovationStats);
                },
                (passwordResetToken: string) => {
                  this.props.history.push(
                    RECOVERY_PASSWORD_PREFIX + "/" + passwordResetToken
                  );
                  addSuccessNotification(
                    "Your password is obsolete. Please, set a new password."
                  );
                }
              );
            }}
            validationSchema={loginFormValidationSchema}
            render={(props: FormikProps<ILoginFormValuesInterface>) => (
              <form onSubmit={props.handleSubmit}>
                <div className="form-group">
                  <Field
                    label="Email:"
                    type="email"
                    name="email"
                    placeholder="john.doe@gmail.com"
                    component={FormText}
                  />
                </div>
                <div className="form-group">
                  <Field
                    label="Password:"
                    name="password"
                    placeholder="Password"
                    component={FormPassword}
                  />
                  <div className="">
                    <Link to={FORGOT_PASSWORD}>Forgot password?</Link>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block"
                >
                  Submit
                </button>
              </form>
            )}
          />
        </div>
      </AuthLayout>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetAuthTokenAndUser: (authToken: string, userEntity: IUserEntity) => {
    dispatch(setAuthTokenAndUser(authToken, userEntity));
  },
  onSetInnovationStats: (
    innovationStats: {
      status: InnovationStatusKey;
      statusValue: string;
      statusLabel: string;
      amount: number;
    }[]
  ) => {
    dispatch(setInnovationStats(innovationStats));
  },
});

export default connect(null, mapDispatchToProps)(CommonLogin);
