import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Switch} from "react-router";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {RouteAccessLevels} from "../constant/app";
import NotFound from "../route/NotFound";
import {AppState} from "../store";
import {ISystemState} from "../store/system/type";
import Notification from "./Notification";
import routes, {GuestRoute, IRoute, PrivateRoute} from "./routes";

interface IProps {
  system: ISystemState;
}

class App extends PureComponent<IProps> {
  public render(): React.ReactNode {
    const {isGuest, user} = this.props.system;
    return (
      <Router>
        <Notification/>
        <Switch>
          {routes.map((route: IRoute) => {
            switch (route.accessLevel) {
              case RouteAccessLevels.Guest:
                return <GuestRoute key={"route-" + route.path} {...route} isGuest={isGuest} user={user}/>;
              case RouteAccessLevels.Private:
                return <PrivateRoute key={"route-" + route.path} {...route} isGuest={isGuest} user={user}/>;
              default:
                return <Route key={"route-" + route.path} {...route} />;
            }
          })}
          <Route component={NotFound} status={404}/>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

export default connect(
  mapStateToProps,
  null,
)(App);
