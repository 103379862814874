import {FieldProps} from "formik";
import React from "react";
import Select from "react-select";
// tslint:disable-next-line
import {Props} from "react-select/base";
// tslint:disable-next-line
import {ValueType} from "react-select/src/types";

interface IAnotherFormSelect extends FieldProps {
  onChange?: (value: ValueType<{ label: string; value: string }>) => void;
  selectProps: Props;
  customProps: {
    label?: string;
  };
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    "border": "2px solid #d8dfe9",
    "minHeight": "48px",
    "box-shadow": "none",

    ":hover": {
      border: "2px solid #d8dfe9",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  placeholder: () => ({
    color: "#7b889a",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    "background": state.isSelected ? "#f0f8ff" : "#fff",
    "color": "#032139",

    ":hover": {
      background: "#004b86",
      color: "#fff",
    },
  }),
};

const AnotherFormSelect = ({selectProps, customProps, ...props}: IAnotherFormSelect) => (
  <label className="form-control-wrap">
    {customProps.label && (
      <span className={`form-label ${customProps.label === props.field.name ? "sr-only" : ""}`}>
        {customProps.label}
      </span>
    )}
    <Select
      {...selectProps}
      value={props.field.value}
      styles={customStyles}
      onChange={(value: ValueType<{ label: string; value: string }>) => {
        if (props.onChange) {
          props.onChange(value);
        }
        props.form.setFieldValue(props.field.name, value);
      }}
    />
  </label>
);

export default AnotherFormSelect;
