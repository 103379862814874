import {IDepartmentEntity} from "../../../../interface/entity/IDepartmentEntity";

export interface IAdminUserCreateState {
  department: {
    items: IDepartmentEntity[];
    isLoading: boolean;
  };
}

export const ADMIN_USER_CREATE_FETCH_DEPARTMENTS_START = "ADMIN_USER_CREATE_FETCH_DEPARTMENTS_START";
export const ADMIN_USER_CREATE_FETCH_DEPARTMENTS_SUCCESS = "ADMIN_USER_CREATE_FETCH_DEPARTMENTS_SUCCESS";
export const ADMIN_USER_CREATE_FETCH_DEPARTMENTS_ERROR = "ADMIN_USER_CREATE_FETCH_DEPARTMENTS_ERROR";

interface IAdminUserCreateFetchDepartmentsStartAction {
  type: typeof ADMIN_USER_CREATE_FETCH_DEPARTMENTS_START;
}

interface IAdminUserCreateFetchDepartmentsSuccessAction {
  type: typeof ADMIN_USER_CREATE_FETCH_DEPARTMENTS_SUCCESS;
  departments: IDepartmentEntity[];
}

interface IAdminUserCreateFetchDepartmentsErrorAction {
  type: typeof ADMIN_USER_CREATE_FETCH_DEPARTMENTS_ERROR;
}

export type IAdminUserCreateActionTypes =
  | IAdminUserCreateFetchDepartmentsStartAction
  | IAdminUserCreateFetchDepartmentsSuccessAction
  | IAdminUserCreateFetchDepartmentsErrorAction
  ;
