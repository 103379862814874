import React, {Fragment} from "react";
import GateViewAttachmentsSection from "../../../../../../components/GateViewAttachmentsSection";
import {Col, Row} from "../../../../../../components/Grid";
import {IRefineInformationGate, YesNo, YesNoDescribe} from "../../../../../../interface/entity/IInnovationEntity";
import RefineUtils from "../utils/RefineUtils";
import {defaultCheckboxValue, defaultRadioValue} from "./utils";

interface IProps {
  data: IRefineInformationGate;
}

class Refine extends React.Component<IProps> {
  public render(): React.ReactNode {
    const data: IRefineInformationGate = this.props.data;
    let profitPerUnit = data.profitAbsolute ? `$${data.profitAbsolute}` : "Not Added";
    profitPerUnit = data.profitPercent ? `${data.profitPercent}%` : profitPerUnit;

    return (
      <Fragment>
        <hr className="hr-dashed"/>
        <Row>
          <Col val={6}>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{RefineUtils.isConsumerResearchAvailable.label}</span>
              {defaultRadioValue(
                data.isConsumerResearchAvailable,
                RefineUtils.isConsumerResearchAvailable.options,
              )}
            </div>
            <div className="mb-4">
              <GateViewAttachmentsSection title={""} attachments={data.consumerResearchAttachments}/>
            </div>
          </Col>
          {data.isConsumerResearchAvailable === YesNo.No && (
            <Col val={6}>
              <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">
                What <span className="text-medium">consumer research</span> is needed to support:
              </span>
                <Row>
                  <Col val={6}>
                    <p className="text-regular text-primary-dark">{RefineUtils.transactionData.label}</p>
                  </Col>
                  <Col val={6}>
                    <p className="text-medium">
                      {defaultCheckboxValue(
                        data.transactionData,
                        RefineUtils.transactionData.options,
                        data.transactionDataOther,
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col val={6}>
                    <p className="text-regular text-primary-dark">{RefineUtils.qualitative.label}</p>
                  </Col>
                  <Col val={6}>
                    <p className="text-medium">{
                      defaultCheckboxValue(
                        data.qualitative,
                        RefineUtils.qualitative.options,
                        data.qualitativeOther,
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col val={6}>
                    <p className="text-regular text-primary-dark">{RefineUtils.quantitative.label}</p>
                  </Col>
                  <Col val={6}>
                    <p className="text-medium">{
                      defaultCheckboxValue(
                        data.quantitative,
                        RefineUtils.quantitative.options,
                        data.quantitativeOther,
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col val={6}>
                    <p className="text-regular text-primary-dark">{RefineUtils.isFieldResearchTestingProtocolPlanned.answer}</p>
                  </Col>
                  <Col val={6}>
                    <p className="text-medium">{
                      defaultCheckboxValue(
                        data.isFieldResearchTestingProtocolPlanned,
                        RefineUtils.isFieldResearchTestingProtocolPlanned.options,
                        data.isFieldResearchTestingProtocolPlannedOther,
                      )}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          )}
        </Row>
        <hr className="hr-dashed"/>
        <Row>
          <Col val={5}>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">{RefineUtils.isThisAnExistingPackageStructure.label}</span>
              {defaultRadioValue(
                data.isThisAnExistingPackageStructure,
                RefineUtils.isThisAnExistingPackageStructure.options,
              )}
            </div>
          </Col>
          <Col val={5}>
            <div className="mb-4">
              <span className="form-label text-regular text-primary-dark">Images or examples attached:</span>
              <GateViewAttachmentsSection title={""} attachments={data.isThisAnExistingPackageStructureAttachments}/>
            </div>
          </Col>
        </Row>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">{RefineUtils.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType.label}</span>
          {defaultRadioValue(
            data.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType,
            RefineUtils.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType.options,
          )}
        </div>
        {data.doesDudaFarmFreshFoodsCurrentlyOfferThePackageStructureOrType === YesNo.No && (
          <div className="mb-4">
            <span className="form-label text-regular text-primary-dark">{RefineUtils.whereIsThisPackageAvailable.label}</span>
            <p className="text-medium">{data.whereIsThisPackageAvailable || "Not Added"}</p>
          </div>
        )}
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">Please estimate the potential number of packages needed:</span>
          <Row>
            <Col val={3}>
              <span className="form-label text-regular text-primary-dark">{RefineUtils.numberOfStoreLocations.label}</span>
              <p className="text-medium mb-0">{data.numberOfStoreLocations || "Not Added"}</p>
            </Col>
            <Col val={3}>
              <span className="form-label text-regular text-primary-dark">{RefineUtils.unitPerStorePerDay.label}</span>
              <p className="text-medium mb-0">{data.unitPerStorePerDay || "Not Added"}</p>
            </Col>
            <Col val={3}>
              <span className="form-label text-regular text-primary-dark">{RefineUtils.numberOfDaysInMarket.label}</span>
              <p className="text-medium mb-0">{data.numberOfDaysInMarket || "Not Added"}</p>
            </Col>
            <Col val={3}>
              <span className="form-label text-regular text-primary-dark">
                Total:
              </span>
              <p className="text-medium mb-0">
                {RefineUtils.calculateNumberOfPackages({
                  numberOfStoreLocations: data.numberOfStoreLocations,
                  unitPerStorePerDay: data.unitPerStorePerDay,
                  numberOfDaysInMarket: data.numberOfDaysInMarket,
                })}
              </p>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">
            Please estimate the <span className="text-medium">acres</span> needed to meet the volume needed:
          </span>
          <Row>
            <Col val={3}>
              <span className="form-label text-regular text-primary-dark">{RefineUtils.totalUnits.label}</span>
              <p className="text-medium mb-0">{data.totalUnits || "Not Added"}</p>
            </Col>
            <Col val={2}>
              <span className="form-label text-regular text-primary-dark">{RefineUtils.yieldPerAcre.label}</span>
              <p className="text-medium mb-0">{data.yieldPerAcre || "Not Added"}</p>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <Row>
            <Col val={6}>
              <span className="form-label text-regular">{RefineUtils.isThisACropDudaFarmFreshFoodsCurrentlyGrows.label}</span>
              <p className="text-medium">{defaultRadioValue(
                data.isThisACropDudaFarmFreshFoodsCurrentlyGrows,
                RefineUtils.isThisACropDudaFarmFreshFoodsCurrentlyGrows.options,
              )}</p>
            </Col>
            {data.isThisACropDudaFarmFreshFoodsCurrentlyGrows === YesNo.No && (
              <Col val={6}>
                <span className="form-label text-regular">{RefineUtils.pleaseEstimateTheContractGrownPricePerUnit.answer}</span>
                <p className="text-medium">{data.pleaseEstimateTheContractGrownPricePerUnit || "Not Added"}</p>
              </Col>
            )}
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <div className="mb-4">
            <span className="form-label text-regular">
              {RefineUtils.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation.label}
            </span>
            <p className="text-medium">{defaultRadioValue(
              data.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation,
              RefineUtils.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation.options,
            )}</p>
          </div>
          {data.doesDudaFarmFreshFoodsCurrentlyHaveTheProcessingCapabilityToProduceThisInnovation === YesNo.No && (
            <div className="mb-4">
              <Row>
                <Col val={6}>
                  <span className="form-label text-regular">{RefineUtils.whatPartnerIsBeingConsideredToProcess.label}</span>
                  <p className="text-medium mb-0">{data.whatPartnerIsBeingConsideredToProcess || "Not Added"}</p>
                </Col>
                <Col val={6}>
                  <span className="form-label text-regular">{RefineUtils.coPackCosts.label}</span>
                  <p className="text-medium mb-0">{data.coPackCosts || "Not Added"}</p>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <Row>
            <Col val={5}>
              <span className="form-label text-regular">{RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProduct.answer}</span>
              <p className="text-medium">{defaultRadioValue(
                data.isAnySpecialFormulationNecessaryForTheFinalProduct,
                RefineUtils.isAnySpecialFormulationNecessaryForTheFinalProduct.options
                  .map(
                    (v) => v.value === YesNoDescribe.Describe ?
                      {value: v.value, label: data.isAnySpecialFormulationNecessaryForTheFinalProductDescription} :
                      v,
                  ),
              )}</p>
            </Col>
            <Col val={6}>
              <span className="form-label text-regular">Has a prototype or final formulation been reviewed by:</span>
              <Row>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.rndApprove.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.rndApprove,
                      RefineUtils.rndApprove.options,
                    )}
                  </p>
                </Col>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.legalApprove.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.legalApprove,
                      RefineUtils.legalApprove.options,
                    )}
                  </p>
                </Col>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.foodSafetyApprove.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.foodSafetyApprove,
                      RefineUtils.foodSafetyApprove.options,
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.operationsApprove.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.operationsApprove,
                      RefineUtils.operationsApprove.options,
                    )}
                  </p>
                </Col>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.financeApprove.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.financeApprove,
                      RefineUtils.financeApprove.options,
                    )}
                  </p>
                </Col>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.salesAndOpsApprove.label}</span>
                  <p className="text-medium">
                    {defaultRadioValue(
                      data.salesAndOpsApprove,
                      RefineUtils.salesAndOpsApprove.options,
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular">{RefineUtils.willThisInnovationIncludeAnAdditionalManufacturedItem.label}</span>
          <p className="text-medium">
            {defaultRadioValue(
              data.willThisInnovationIncludeAnAdditionalManufacturedItem,
              RefineUtils.willThisInnovationIncludeAnAdditionalManufacturedItem.options,
            )}
          </p>
        </div>
        <div className="mb-4">
          {data.willThisInnovationIncludeAnAdditionalManufacturedItem === YesNo.Yes && (
            <Fragment>
              <div className="mb-4">
                <span className="form-label text-regular">{RefineUtils.additionalItemDescription.label}</span>
                <p className="text-medium mb-0">{data.additionalItemDescription || "Not Added"}</p>
              </div>
              <Row>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.additionalItemCost.label}</span>
                  <p className="text-medium mb-0">{data.additionalItemCost || "Not Added"}</p>
                </Col>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.additionalItemPartner.label}</span>
                  <p className="text-medium mb-0">{data.additionalItemPartner || "Not Added"}</p>
                </Col>
                <Col val={4}>
                  <span className="form-label text-regular">{RefineUtils.additionalItemAudit.label}</span>
                  <p className="text-medium mb-0">{data.additionalItemAudit || "Not Added"}</p>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <Row>
            <Col val={4}>
              <span className="form-label">{RefineUtils.budgetProductSamples.label} <span className="text-regular">Budgeted?</span></span>
              <Row>
                <Col val={6}>
                  <p className="text-medium">{defaultRadioValue(
                    data.budgetProductSamples,
                    RefineUtils.budgetProductSamples.options,
                  )}</p>
                </Col>
                {data.budgetProductSamples === YesNo.Yes && (
                  <Col val={6}>{data.budgetProductSamplesValue ? `$${data.budgetProductSamplesValue}` : "Not Added"}</Col>
                )}
              </Row>
            </Col>
            <Col val={4}>
              <span className="form-label">{RefineUtils.budgetArtwork.label} <span className="text-regular">Budgeted?</span></span>
              <Row>
                <Col val={6}>
                  <p className="text-medium">{defaultRadioValue(
                    data.budgetArtwork,
                    RefineUtils.budgetArtwork.options,
                  )}</p>
                </Col>
                {data.budgetArtwork === YesNo.Yes && (<Col val={6}>{data.budgetArtworkValues ? `$${data.budgetArtworkValues}` : "Not Added"}</Col>)}
              </Row>
            </Col>
            <Col val={4}>
              <span className="form-label">{RefineUtils.budgetMerchandising.label} <span className="text-regular">Budgeted?</span></span>
              <Row>
                <Col val={6}>
                  <p className="text-medium">{defaultRadioValue(
                    data.budgetMerchandising,
                    RefineUtils.budgetMerchandising.options,
                  )}</p>
                </Col>
                {data.budgetMerchandising === YesNo.Yes && (
                  <Col val={6}>{data.budgetMerchandisingValue ? `$${data.budgetMerchandisingValue}` : "Not Added"}</Col>
                )}
              </Row>
            </Col>
            <Col val={4}>
              <span className="form-label">{RefineUtils.budgetPhoto.label} <span className="text-regular">Budgeted?</span></span>
              <Row>
                <Col val={6}>
                  <p className="text-medium">{defaultRadioValue(
                    data.budgetPhoto,
                    RefineUtils.budgetPhoto.options,
                  )}</p>
                </Col>
                {data.budgetPhoto === YesNo.Yes && (<Col val={6}>{data.budgetPhotoValue ? `$${data.budgetPhotoValue}` : "Not Added"}</Col>)}
              </Row>
            </Col>
            <Col val={4}>
              <span className="form-label">{RefineUtils.budgetNutrition.label} <span className="text-regular">Budgeted?</span></span>
              <Row>
                <Col val={6}>
                  <p className="text-medium">{defaultRadioValue(
                    data.budgetNutrition,
                    RefineUtils.budgetNutrition.options,
                  )}</p>
                </Col>
                {data.budgetNutrition === YesNo.Yes && (<Col val={6}>{data.budgetNutritionValue ? `$${data.budgetNutritionValue}` : "Not Added"}</Col>)}
              </Row>
            </Col>
            <Col val={4}>
              <span className="form-label">{RefineUtils.budgetTrademark.label} <span className="text-regular">Budgeted?</span></span>
              <Row>
                <Col val={6}>
                  <p className="text-medium">{defaultRadioValue(
                    data.budgetTrademark,
                    RefineUtils.budgetTrademark.options,
                  )}</p>
                </Col>
                {data.budgetTrademark === YesNo.Yes && (<Col val={6}>{data.budgetTrademarkValue ? `$${data.budgetTrademarkValue}` : "Not Added"}</Col>)}
              </Row>
            </Col>
            <Col val={4}>
              <span className="form-label">{RefineUtils.budgetSales.label} <span className="text-regular">Budgeted?</span></span>
              <Row>
                <Col val={6}>
                  <p className="text-medium">{defaultRadioValue(
                    data.budgetSales,
                    RefineUtils.budgetSales.options,
                  )}</p>
                </Col>
                {data.budgetSales === YesNo.Yes && (<Col val={6}>{data.budgetSalesValue ? `$${data.budgetSalesValue}` : "Not Added"}</Col>)}
              </Row>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <Row>
          <Col val={6}>
            <span className="form-label">{RefineUtils.budgetMinOrder.label}</span>
            <Row>
              <Col val={8}>
                <p className="text-medium">{data.budgetMinOrder || "Not Added"}</p>
              </Col>
            </Row>
          </Col>
          <Col val={6}>
            <span className="form-label">{RefineUtils.budgetPackaging.label}</span>
            <Row>
              <Col val={8}>
                <p className="text-medium">{data.budgetPackaging || "Not Added"}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <Row>
            <Col val={3}>
              <span className="form-label text-regular">{RefineUtils.isThereATargetRetailPrice.label}</span>
              <p className="text-medium">{data.isThereATargetRetailPrice ? `${data.isThereATargetRetailPrice}` : "Not Added"}</p>
            </Col>
            <Col val={3}>
              <span className="form-label text-regular">{RefineUtils.whatIsTheTargetedDudaWhloesalePrice.label}</span>
              <p className="text-medium">{data.whatIsTheTargetedDudaWholesalePrice ? `${data.whatIsTheTargetedDudaWholesalePrice}` : "Not Added"}</p>
            </Col>
            <Col val={6}>
              <span className="form-label text-regular">Is there a target profit margin per unit:</span>
              <p className="text-medium">{profitPerUnit}</p>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular">{RefineUtils.preliminaryCogsIfKnown.label}</span>
          <p className="text-medium">{data.preliminaryCogsIfKnown || "Not Added"}</p>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular">{RefineUtils.pleaseConfirmEstimatedNumberOfUnitsNeeded.label}</span>
          <p className="text-medium">{data.pleaseConfirmEstimatedNumberOfUnitsNeeded || "Not Added"}</p>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular">{RefineUtils.calculatePreliminaryTotalRevenue.label}</span>
          <p className="text-medium">{data.calculatePreliminaryTotalRevenue || "Not Added"}</p>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label text-regular">
            What food safety protocol is needed (please describe):
          </span>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular">{RefineUtils.safetyField.label}</span>
          <p className="text-medium">{data.safetyField || "Not Added"}</p>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular">{RefineUtils.safetyPlant.label}</span>
          <p className="text-medium">{data.safetyPlant || "Not Added"}</p>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular">{RefineUtils.safetyMarket.label}</span>
          <p className="text-medium">{data.safetyMarket || "Not Added"}</p>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <GateViewAttachmentsSection
            title={"General documents (such as costing sheets, food safety reports, sales forecasts, etc.):"}
            attachments={data.generalAttachments}
          />
        </div>
        <div className="mb-4">
          <span className="form-label">
            {RefineUtils.anyOtherInformationYouWouldLikeToInclude.label}
          </span>
          <p className="text-medium">{data.anyOtherInformationYouWouldLikeToInclude || "Not Added"}</p>
        </div>
      </Fragment>
    );
  }
}

export default Refine;
