import React, {Fragment} from "react";
import GateViewAttachmentsSection from "../../../../../../components/GateViewAttachmentsSection";
import {Col, Row} from "../../../../../../components/Grid";
import {IExecuteInformationGate, YesNo} from "../../../../../../interface/entity/IInnovationEntity";
import ExecuteUtils from "../utils/ExecuteUtils";
import {defaultFormValue, defaultRadioValue} from "./utils";

interface IProps {
  data: IExecuteInformationGate;
}

class Execute extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {data} = this.props;

    return (
      <Fragment>
        <div className="mb-2">
          <Row>
            <Col val="2">
              <div className="pt-label">
                <div className="form-control-static-sm pt-0 text-primary-light">{ExecuteUtils.salesPlan.title}</div>
              </div>
            </Col>
            <Col val="10">
              {ExecuteUtils.salesPlan.fields.map((field) =>
                <div key={field.name}>
                  <span className="form-label text-regular text-primary-dark">{field.label}</span>
                  <p className="text-medium">{defaultFormValue(data, field.name)}</p>
                </div>,
              )}
              <GateViewAttachmentsSection title={"Sales PLan:"} attachments={data.salesPlanAttachments}/>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div>
          <Row>
            <Col val="2">
              <div className="pt-label">
                <div className="form-control-static-sm pt-0 text-primary-light">{ExecuteUtils.marketingPlan.title}</div>
              </div>
            </Col>
            <Col val="10">
              {ExecuteUtils.marketingPlan.fields.map((field) =>
                <div key={field.name}>
                  <span className="form-label text-regular text-primary-dark">{field.label}</span>
                  <p className="text-medium">{defaultFormValue(data.marketingPlan, field.name)}</p>
                </div>,
              )}
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div>
          <Row>
            <Col val="2">
              <div className="pt-label">
                <div className="form-control-static-sm pt-0 text-primary-light">
                  {ExecuteUtils.promoPricingPlan.title}
                </div>
              </div>
            </Col>
            <Col val="10">
              {ExecuteUtils.promoPricingPlan.fields.map((field) =>
                <div key={field.name}>
                  <span className="form-label text-regular text-primary-dark">{field.label}</span>
                  <p className="text-medium">{defaultFormValue(data.promoPricingPlan, field.name)}</p>
                </div>,
              )}
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-5">
          <span className="form-label">{ExecuteUtils.harvestDeliveryDate.label}</span>
          <div className="form-datepicker-wrap">
            <div className="text-primary text-medium text-iconed">
              <i className="ico ico-calendar mr-1"/>&nbsp;{defaultFormValue(data, ExecuteUtils.harvestDeliveryDate.name)}
            </div>
          </div>
        </div>
        <hr className="hr-dashed"/>
        <div>
          <span className="form-label mb-3">{ExecuteUtils.capacityProductivityForecastOnTrack.title}</span>
          <Row>
            <Col val="11">
              <Row>
                {ExecuteUtils.capacityProductivityForecastOnTrack.fields.map((field, index) =>
                  <Col val={4} key={field.name}>
                    <span className="form-label text-primary-light">{field.label}</span>
                    <Row>
                      <Col val={4}>
                        <p className="text-regular text-primary-dark">Capacity:</p>
                      </Col>
                      <Col val={8}>
                        <p className="text-medium">{defaultFormValue(data.capacityProductivityForecastOnTrack, field.name)}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col val={4}>
                        <p className="text-regular text-primary-dark">On Track:</p>
                      </Col>
                      <Col val={8}>
                        <p className="text-medium">{defaultRadioValue(
                          defaultFormValue(data.capacityProductivityForecastOnTrack, `${field.name}Selector`),
                          ExecuteUtils.capacityProductivityForecastOnTrackSelector.fields[index].options,
                        )}
                        </p>
                      </Col>
                    </Row>
                  </Col>,
                )}
              </Row>
            </Col>
          </Row>
        </div>
        <hr className="hr-dashed"/>
        <div>
          <span className="form-label text-regular text-primary-dark">
            {ExecuteUtils.areSalesOrderForecastOnTrack.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              (data as any)[ExecuteUtils.areSalesOrderForecastOnTrack.name],
              ExecuteUtils.areSalesOrderForecastOnTrack.options,
            )}
          </p>
        </div>
        {data && (data as any)[ExecuteUtils.areSalesOrderForecastOnTrack.name] === YesNo.No && (
          <div className="mb-4">
          <span className="form-label text-regular">
            {ExecuteUtils.areSalesOrderForecastOnTrackAdjustment.label}
          </span>
            <p className="text-medium text-regular">
              {(data as any)[ExecuteUtils.areSalesOrderForecastOnTrackAdjustment.name] || "Not Added"}
            </p>
          </div>
        )}
        <hr className="hr-dashed"/>
        <GateViewAttachmentsSection title={"Sales Order Forecast:"} attachments={data.salesOrderForecastAttachments}/>
        <hr className="hr-dashed"/>
        <div>
          <span className="form-label text-regular text-primary-dark">
            {ExecuteUtils.areFoodSafetyMetricsAcceptable.label}
          </span>
          <p className="text-medium">
            {defaultRadioValue(
              (data as any)[ExecuteUtils.areFoodSafetyMetricsAcceptable.name],
              ExecuteUtils.areSalesOrderForecastOnTrack.options,
            )}
          </p>
        </div>
        {data && (data as any)[ExecuteUtils.areFoodSafetyMetricsAcceptable.name] === YesNo.No && (
          <div className="mb-4">
          <span className="form-label text-regular">
            {ExecuteUtils.areFoodSafetyMetricsAcceptableAdjustment.label}
          </span>
            <p className="text-medium text-regular">
              {(data as any)[ExecuteUtils.areFoodSafetyMetricsAcceptableAdjustment.name] || "Not Added"}
            </p>
          </div>
        )}
        <hr className="hr-dashed"/>
        <GateViewAttachmentsSection title={"Monitor Food Safety Attachments:"} attachments={data.adjustmentsAttachments}/>
        <hr className="hr-dashed"/>
        <GateViewAttachmentsSection title={"General Documents:"} attachments={data.generalAttachments}/>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">
            {ExecuteUtils.anyOtherInformationYouWouldLikeToInclude.label}
          </span>
          <p className="text-medium">{data.anyOtherInformationYouWouldLikeToInclude || "Not Added"}</p>
        </div>
      </Fragment>
    );
  }
}

export default Execute;
