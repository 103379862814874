import {
  IInnovationMilestone, INNOVATION_MILESTONE_ADMINS_FETCH_START, INNOVATION_MILESTONE_ADMINS_FETCH_SUCCESS,
  INNOVATION_MILESTONE_GATE_FETCH_START,
  INNOVATION_MILESTONE_GATE_FETCH_SUCCESS,
  INNOVATION_MILESTONE_MANAGERS_FETCH_START,
  INNOVATION_MILESTONE_MANAGERS_FETCH_SUCCESS,
  INNOVATION_MILESTONE_REORDER,
  INNOVATION_MILESTONE_VIEW,
  MilestoneView,
} from "./type";

const initialState: IInnovationMilestone = {
  isLoading: false,
  view: MilestoneView.View,
  gate: undefined,
  managers: [],
  managersLoading: false,
  admins: [],
  adminsLoading: false,
};

export const innovationMilestoneReducer = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case INNOVATION_MILESTONE_VIEW: {
      return {
        ...state,
        view: action.view,
      };
    }
    case INNOVATION_MILESTONE_GATE_FETCH_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case INNOVATION_MILESTONE_GATE_FETCH_SUCCESS: {
      return {
        ...state,
        gate: action.gate,
        isLoading: false,
      };
    }
    case INNOVATION_MILESTONE_REORDER: {
      return {
        ...state,
        isLoading: false,
        gate: {
          ...state.gate,
          milestone: {
            milestones: action.milestones,
          },
        },
      };
    }
    case INNOVATION_MILESTONE_MANAGERS_FETCH_START: {
      return {
        ...state,
        managers: [],
        managersIsLoading: true,
      };
    }
    case INNOVATION_MILESTONE_MANAGERS_FETCH_SUCCESS: {
      return {
        ...state,
        managers: action.managers,
        managersIsLoading: false,
      };
    }
    case INNOVATION_MILESTONE_ADMINS_FETCH_START: {
      return {
        ...state,
        managers: [],
        managersIsLoading: true,
      };
    }
    case INNOVATION_MILESTONE_ADMINS_FETCH_SUCCESS: {
      return {
        ...state,
        admins: action.admins,
        adminsIsLoading: false,
      };
    }
    default:
      return state;
  }
};
