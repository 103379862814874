import {ErrorMessage} from "formik";
import React, {Fragment, useState} from "react";
import Ico from "../Ico";
import {IForm} from "./IForm";

const FormPassword = ({field, form, label, placeholder = "Enter", className = "form-control", labelClass}: IForm) => {
  const [visible, setVisible] = useState(false);

  return (
    <Fragment>
      <label className={`form-control-wrap ${form.errors[field.name] ? "form-error-wrap" : ""}`}>
        <div className="clearfix">
          {label &&
          <div className="pull-left">
              <span className={`form-label ${label === field.name ? "sr-only" : ""} ${labelClass ? labelClass : ""}`}>
                {label}
              </span>
          </div>
          }
          <div className="pull-right">
            <span className="form-count">
              {visible ? "Visible" : "Hidden"}
            </span>
          </div>
        </div>
        <div className="input-group">
          <input
            {...field}
            type={visible ? "text" : "password"}
            placeholder={placeholder}
            className={className}
          />
          <div className="input-group-append">
            <button type="button" className="btn" onClick={() => setVisible(!visible)}>
              <Ico name={visible ? "visible" : "hidden"} />
            </button>
          </div>
        </div>
      </label>
      <ErrorMessage component="span" className="form-error" name={field.name}/>
    </Fragment>
  );
};

export default FormPassword;
