import {
  IInnovationItemActionTypes,
  IInnovationItemActivityState, INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_ERROR,
  INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_START,
  INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_SUCCESS,
} from "./type";

const initialState: IInnovationItemActivityState = {
  activityCollectionResource: {
    isLoading: false,
    items: [],
  },
};

export const innovationItemActivityReducer = (
  state = initialState,
  action: IInnovationItemActionTypes,
): IInnovationItemActivityState => {
  switch (action.type) {
    case INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_START: {
      return {
        ...state,
        activityCollectionResource: {
          isLoading: true,
          items: [],
        },
      };
    }
    case INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_SUCCESS: {
      return {
        ...state,
        activityCollectionResource: {
          ...action.activityCollectionResource,
          isLoading: false,
        },
      };
    }
    case INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_ERROR: {
      return {
        ...state,
        activityCollectionResource: {
          isLoading: false,
          items: [],
        },
      };
    }
    default:
      return state;
  }
};
