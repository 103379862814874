import {Field, Formik, FormikProps} from "formik";
import React, {Fragment} from "react";
import Modal from "react-modal";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import * as Yup from "yup";
import {FormTextArea} from "../../../../../../components/form";
import FormSelectMultiple from "../../../../../../components/form/FormSelectMultiple";
import PostponedUploadMultipleField from "../../../../../../components/form/upload/PostponedUploadMultipleField";
import WrapperLoader from "../../../../../../components/loader/WrapperLoader";
import axiosInstance from "../../../../../../instance/axios";
import {IAttachmentEntity} from "../../../../../../interface/entity/IAttachmentEntity";
import {IInnovationDepartmentComment as IDCEntity} from "../../../../../../interface/entity/IInnovationDepartmentComment";
import {IUserEntity} from "../../../../../../interface/entity/IUserEntity";
import {ICollectionResource} from "../../../../../../interface/rest/ICollectionResource";
import {AppState} from "../../../../../../store";
import {
  innovationItemCommentsDepartmentCloseModal,
  innovationItemCommentsDepartmentFetchUsers,
  innovationItemCommentsDepartmentOpenModal,
} from "../../../../../../store/innovationItem/stage/oversight/comments/department/action";

interface IProps {
  isCommentLoading: boolean;
  isModalOpen: boolean;
  innovationDepartmentId: number;
  onInnovationItemCommentsDepartmentCloseModal: () => void;
  onSubmit: (values: IInnovationDepartmentComment, deletedAttachmentIds: string[], cb?: () => void) => void;
  onInnovationItemCommentsDepartmentFetchUsers: (users: ICollectionResource<IUserEntity>) => void;
  users: ICollectionResource<IUserEntity>;
  comment?: IDCEntity;
}

interface IState {
  deletedAttachmentIds: string[];
}

export interface IInnovationDepartmentComment {
  users?: string[];
  message: string;
  attachments: File[];
}

class InnovationItemDepartmentAddCommentModal extends React.Component<IProps, IState> {
  public state: IState = {
    deletedAttachmentIds: [],
  };

  public async componentDidMount() {
    await this.fetchUsers();
  }

  public render(): React.ReactNode {
    const {comment, users} = this.props;
    const {deletedAttachmentIds} = this.state;
    const existingFiles = (comment && comment.attachments) ? comment.attachments : [];
    const filteredExistingFiles = existingFiles.filter((attachment: IAttachmentEntity) => !deletedAttachmentIds.includes(attachment.id));

    const initialValues: IInnovationDepartmentComment = {
      attachments: [],
      message: comment ? comment.message : "",
      users: comment ? comment.users : undefined,
    };

    const validationSchema = Yup.object().shape({
      message: Yup.string()
        .max(300)
        .trim()
        .required("Required"),
    });

    return (
      <Fragment>
        <Modal
          isOpen={this.props.isModalOpen}
          contentLabel="TEST"
          className="modal modal-sm"
          onRequestClose={this.close}
        >
          <header className="modal-header clearfix">
            <h3 className="modal-title pull-left">{comment ? "Edit Comment" : "Add Comment"}</h3>
            <button
              className="modal-close"
              type="button"
              onClick={this.close}
            >
              <i className="ico ico-close-thin"/>
            </button>
          </header>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            render={({handleSubmit, values}: FormikProps<IInnovationDepartmentComment>) =>
              <form onSubmit={handleSubmit}>
                <div className="mobal-body px-5 py-6">
                  <WrapperLoader isLoading={this.props.isCommentLoading}>
                    <div className="form-group">
                      <Field
                        component={FormSelectMultiple}
                        customProps={{
                          label: "Select Recipient(s) for your comment:",
                        }}
                        name="users"
                        selectProps={{
                          defaultValue: initialValues.users && initialValues.users.map((userId: string) => {
                            const userEntity = users.items.find((u: IUserEntity) => u.id === userId);
                            if (userEntity) {
                              return {value: userEntity.id, label: userEntity.fullName};
                            } else {
                              return {value: userId, label: "DELETED"};
                            }
                          }),
                          options: this.props.users.items.map((user) => ({value: user.id, label: user.fullName})),
                          placeholder: "Select Recipient(s) for your comment...",
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        label="Add your comment here:"
                        name="message"
                        placeholder="Fill in text here…"
                        rows="3"
                        showCounter={true}
                        maxLength={300}
                        component={FormTextArea}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        name="attachments"
                        component={PostponedUploadMultipleField}
                        emptyMessage={"No files Attached Yet..."}
                        existingFiles={filteredExistingFiles}
                        onRemoveExistingFile={(attachmentId: string) => this.setState({deletedAttachmentIds: [...deletedAttachmentIds, attachmentId]})}
                      />
                    </div>
                  </WrapperLoader>
                </div>
                <footer className="modal-footer text-right">
                  <button
                    className="btn btn-shade btn-primary ml-4"
                    type="submit"
                    disabled={values.message.length === 0}
                  >
                    {comment ? "Edit Comment" : "Add Comment"}
                  </button>
                </footer>
              </form>}
          />
        </Modal>
      </Fragment>
    );
  }

  private close = () => {
    if (this.props.isCommentLoading) {
      return;
    }
    this.setState({deletedAttachmentIds: []});
    this.props.onInnovationItemCommentsDepartmentCloseModal();
  }

  private onSubmit = (values: IInnovationDepartmentComment) => {
    if (this.props.isCommentLoading) {
      return;
    }
    const {deletedAttachmentIds} = this.state;
    this.props.onSubmit(
      values,
      deletedAttachmentIds,
      () => this.setState({deletedAttachmentIds: []}),
    );
  }

  private fetchUsers = async () => {
    await axiosInstance().get("/user", {
      params: {
        perPage: 1000,
      },
    })
      .then((response: { data: ICollectionResource<IUserEntity> }) => {
        this.props.onInnovationItemCommentsDepartmentFetchUsers(response.data);
      })
      .catch((error) => {
        // this.props.onAdminUserCollectionFetchError(error);
      });
  }
}

const mapStateToProps = (state: AppState) => ({
  isCommentLoading: state.innovationItem.stage.oversight.comments.department.isCommentLoading,
  isModalOpen: state.innovationItem.stage.oversight.comments.department.isAddCommentModalOpen,
  comment: state.innovationItem.stage.oversight.comments.department.comment,
  innovationDepartmentId: state.innovationItem.stage.oversight.comments.department.departmentId,
  users: state.innovationItem.stage.oversight.comments.department.users,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onInnovationItemCommentsDepartmentOpenModal: () => {
    dispatch(innovationItemCommentsDepartmentOpenModal());
  },
  onInnovationItemCommentsDepartmentCloseModal: () => {
    dispatch(innovationItemCommentsDepartmentCloseModal());
  },
  onInnovationItemCommentsDepartmentFetchUsers: (users: ICollectionResource<IUserEntity>) => {
    dispatch(innovationItemCommentsDepartmentFetchUsers(users));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemDepartmentAddCommentModal);
