import {
  ISystemActionTypes,
  ISystemState,
  SYSTEM_DELETE_AUTH_TOKEN,
  SYSTEM_DELETE_AUTH_TOKEN_AND_USER,
  SYSTEM_DELETE_USER,
  SYSTEM_SET_AUTH_TOKEN,
  SYSTEM_SET_AUTH_TOKEN_AND_USER,
  SYSTEM_SET_INNOVATION_STATS,
  SYSTEM_SET_USER,
} from "./type";

export const initialState: ISystemState = {
  isGuest: true,
  innovationStats: [],
};

export const systemReducer = (
  state = initialState,
  action: ISystemActionTypes
): ISystemState => {
  switch (action.type) {
    case SYSTEM_SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.authToken,
        isGuest: false,
      };
    }
    case SYSTEM_SET_INNOVATION_STATS: {
      return {
        ...state,
        innovationStats: action.innovationStats,
      };
    }
    case SYSTEM_SET_AUTH_TOKEN_AND_USER: {
      return {
        ...state,
        authToken: action.authToken,
        isGuest: false,
        user: action.user,
      };
    }
    case SYSTEM_SET_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    case SYSTEM_DELETE_USER: {
      return {
        ...state,
        isGuest: true,
        user: undefined,
      };
    }
    case SYSTEM_DELETE_AUTH_TOKEN: {
      return {
        ...state,
        authToken: undefined,
        isGuest: true,
      };
    }
    case SYSTEM_DELETE_AUTH_TOKEN_AND_USER: {
      return {
        ...state,
        authToken: undefined,
        isGuest: true,
        user: undefined,
      };
    }
    default:
      return state;
  }
};
