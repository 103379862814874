import React, {Fragment} from "react";
import {IFormText} from "../../../../../../components/form/IForm";
import GateViewAttachmentsSection from "../../../../../../components/GateViewAttachmentsSection";
import {Col, Row} from "../../../../../../components/Grid";
import {ITestInformationGate} from "../../../../../../interface/entity/IInnovationEntity";
import TestUtils from "../utils/TestUtils";
import {defaultCheckboxValue, defaultRadioValue} from "./utils";

interface IProps {
  data: ITestInformationGate;
}

class Test extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {data} = this.props;
    return (
      <Fragment>
        <div className="mb-4">
          <span className="form-label">{TestUtils.testMetricsConsumer}</span>
        </div>
        <div className="mb-4">
          <span className="form-label text-primary-light">Consumer Research Protocol - Final Prototype Validation</span>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">{TestUtils.testMetricsConsumerQualitative.label}</span>
          <p className="text-medium">
            {defaultCheckboxValue(
              (data as any)[TestUtils.testMetricsConsumerQualitative.name],
              TestUtils.testMetricsConsumerQualitative.options,
            )}
          </p>
        </div>
        <div className="mb-4">
          <span className="form-label text-regular text-primary-dark">{TestUtils.testMetricsConsumerQuantitative.label}</span>
          <p className="text-medium">
            {defaultCheckboxValue(
              (data as any)[TestUtils.testMetricsConsumerQuantitative.name],
              TestUtils.testMetricsConsumerQuantitative.options,
            )}
          </p>
        </div>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">{TestUtils.testMetricsCustomer}</span>
        </div>
        <div className="mb-4">
          <span className="form-label text-primary-light">{TestUtils.testMetricsCustomerCustomerSuccess.title}</span>
        </div>
        <Row>
          <Col val={9}>
            <Row>
              {TestUtils.testMetricsCustomerCustomerSuccess.fields.map((field: IFormText, index) => (
                <Col val={4} key={index}>
                  <span className="form-label text-regular text-primary-dark">{field.label}</span>
                  <p className="text-medium">
                    {(data as any)[field.name] || "Not Added"}
                  </p>
                </Col>
              ))}
              <Col val={4}>
                <span className="form-label text-regular text-primary-dark">{TestUtils.buyerAlignment.label}</span>
                <p className="text-medium">
                  {defaultRadioValue(
                    (data as any)[TestUtils.buyerAlignment.name],
                    TestUtils.buyerAlignment.options,
                  )}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">{TestUtils.testMetricsOperations}</span>
        </div>
        <div className="mb-4">
          <span className="form-label text-primary-light">{TestUtils.testMetricsOperationsCapacityAndYield.title}</span>
        </div>
        <Row>
          {TestUtils.testMetricsOperationsCapacityAndYield.fields.map((field: IFormText, index) => (
            <Col val={2} key={index}>
              <span className="form-label text-regular text-primary-dark">{field.label}</span>
              <p className="text-medium">
                {(data as any)[field.name] || "Not Added"}
              </p>
            </Col>
          ))}
        </Row>
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">{TestUtils.testMetricsQcFoodSafety.title}</span>
        </div>
        {TestUtils.testMetricsQcFoodSafety.fields.map((field: IFormText, index) => (
          <div className="mb-4" key={index}>
            <span className="form-label text-regular text-primary-dark">{field.label}</span>
            <p className="text-medium">
              {(data as any)[field.name] || "Not Added"}
            </p>
          </div>
        ))}
        <hr className="hr-dashed"/>
        <div className="mb-4">
          <span className="form-label">{TestUtils.testMetricsFinancialMetrics.title}</span>
        </div>
        <Row>
          {TestUtils.testMetricsFinancialMetrics.fields.map((field: IFormText, index) => (
            <Col val={4} key={index}>
              <div className="mb-4">
                <span className="form-label text-regular text-primary-dark">{field.label}</span>
                <Row>
                  <Col val={6}>
                    <p className="text-medium">
                      {defaultRadioValue(
                        (data as any)[field.name],
                        TestUtils.testMetricsFinancialMetrics.fields[index].options,
                      )}
                    </p>
                  </Col>
                  <Col val={6}>
                    {(data as any)[TestUtils.testMetricsFinancialMetricsValues.fields[index].name] || "Not Added"}
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
        <hr className="hr-dashed"/>
        <GateViewAttachmentsSection
          title={"General documents (such as costing sheets, food safety reports, sales forecasts, etc.):"}
          attachments={data.generalAttachments}
        />
        <div className="mb-4">
          <span className="form-label">
            {TestUtils.anyOtherInformationYouWouldLikeToInclude.label}
          </span>
          <p className="text-medium">{data.anyOtherInformationYouWouldLikeToInclude || "Not Added"}</p>
        </div>
      </Fragment>
    );
  }
}

export default Test;
