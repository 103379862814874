import {IActivityEntity} from "../../../interface/entity/IActivityEntity";
import {ICollectionResource} from "../../../interface/rest/ICollectionResource";
import {
  INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_ERROR,
  INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_START,
  INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_SUCCESS,
} from "./type";

export const innovationItemActivityCollectionFetchStart = () => {
  return {
    type: INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_START,
  };
};

export const innovationItemActivityCollectionFetchSuccess = (activityCollectionResource: ICollectionResource<IActivityEntity>) => {
  return {
    activityCollectionResource,
    type: INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_SUCCESS,
  };
};

export const innovationItemActivityCollectionFetchError = (error: unknown) => {
  return {
    error,
    type: INNOVATION_ITEM_ACTIVITY_COLLECTION_FETCH_ERROR,
  };
};
