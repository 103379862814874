import {IAttachmentEntity} from "../interface/entity/IAttachmentEntity";
import {InnovationStageKey} from "../interface/entity/IInnovationEntity";

const prepareFormWithAttachments = (
  data: any,
  stage: InnovationStageKey,
) => {
  switch (stage) {
    case InnovationStageKey.Concept:
      return {
        ...data,
        proposedInnovationAttachments: data.proposedInnovationAttachments.map((item: IAttachmentEntity) => item.id),
        consumerResearchAttachments: data.consumerResearchAttachments.map((item: IAttachmentEntity) => item.id),
        generalAttachments: data.generalAttachments.map((item: IAttachmentEntity) => item.id),
      };
    case InnovationStageKey.Refine:
      return {
        ...data,
        consumerResearchAttachments: data.consumerResearchAttachments.map((item: IAttachmentEntity) => item.id),
        isThisAnExistingPackageStructureAttachments: data.isThisAnExistingPackageStructureAttachments.map((item: IAttachmentEntity) => item.id),
        generalAttachments: data.generalAttachments.map((item: IAttachmentEntity) => item.id),
      };
    case InnovationStageKey.Estimate:
      return {
        ...data,
        generalAttachments: data.generalAttachments.map((item: IAttachmentEntity) => item.id),
      };
    case InnovationStageKey.Action:
      return {
        ...data,
        uploadSalesForecastAttachments: data.uploadSalesForecastAttachments.map((item: IAttachmentEntity) => item.id),
        generalAttachments: data.generalAttachments.map((item: IAttachmentEntity) => item.id),
      };
    case InnovationStageKey.Test:
      return {
        ...data,
        generalAttachments: data.generalAttachments.map((item: IAttachmentEntity) => item.id),
      };
    case InnovationStageKey.Execute:
      return {
        ...data,
        salesPlanAttachments: data.salesPlanAttachments.map((item: IAttachmentEntity) => item.id),
        salesOrderForecastAttachments: data.salesOrderForecastAttachments.map((item: IAttachmentEntity) => item.id),
        adjustmentsAttachments: data.adjustmentsAttachments.map((item: IAttachmentEntity) => item.id),
        generalAttachments: data.generalAttachments.map((item: IAttachmentEntity) => item.id),
      };
    default:
      return data;
  }
};

export default prepareFormWithAttachments;
