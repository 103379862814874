import React from "react";
import {IPagination} from "../interface/rest/IPagination";

interface IProps extends IPagination {
  onChange: (page: number) => void;
}

interface IPaginationBtn {
  onClick: any;
  ico: string;
  disabled: boolean;
}

const PaginationBtn = (props: IPaginationBtn) => {
  return (
    <li>
      <button
        className="pagination-btn"
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <i className={`ico ico-${props.ico}`} />
      </button>
    </li>
  );
};

class Pagination extends React.Component<IProps> {
  public render(): React.ReactNode {
    return (
      <ul className="pagination">
        <PaginationBtn
          onClick={this.pageFirst}
          ico="arrow-double-left"
          disabled={this.props.page === 1}
        />
        <PaginationBtn
          onClick={this.pagePrev}
          ico="arrow-left"
          disabled={this.props.page === 1}
        />
        {
          this.pages()
        }
        <PaginationBtn
          onClick={this.pageNext}
          ico="arrow-right"
          disabled={this.props.page === this.props.totalPages}
        />
        <PaginationBtn
          onClick={this.pageLast}
          ico="arrow-double-right"
          disabled={this.props.page === this.props.totalPages}
        />
      </ul>
    );
  }
  private pageFirst = () => this.props.onChange(1);
  private pagePrev = () => this.props.onChange(this.props.page - 1);
  private pageNext = () => this.props.onChange(this.props.page + 1);
  private pageLast = () => this.props.onChange(this.props.totalPages);

  private pages = () => {
    const [total, current] = [this.props.totalPages, this.props.page];
    const range = [];
    const rangeWithDots = [];
    let l;
    for (let index = 1; index <= total; index++) {
      if ((index === 1 || index === total || index >= current - 2) && index < current + 3) {
        range.push(index);
      }
    }

    for (const index of range) {
      if (l) {
        if (index - l === 2 || index - l !== 1) {
          rangeWithDots.push(<li key={index}><span>...</span></li>);
        }
      }
      rangeWithDots.push(
        (
          <li key={index} className={index === current ? "active" : ""}>
            <button onClick={() => this.props.onChange(index)}>{index}</button>
          </li>
        ),
      );
      l = index;
    }

    return rangeWithDots.map((item) => item);
  }
}

export default Pagination;
