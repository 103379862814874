import {Field} from "formik";
import {DateTime} from "luxon";
import React, {Fragment} from "react";
import {FormText, FormTextArea} from "../form";
import {Col, Row} from "../Grid";

class SubmitInnovationForm extends React.Component {
  public render(): React.ReactNode {
    return (
      <Fragment>
        <Row>
          <Col val="6">
            <div className="form-datepicker-wrap mb-10">
              <span className="form-label">Submission Date:</span>
              <div className="form-control-static text-primary text-medium text-iconed">
                <i className="ico ico-calendar "/>
                &nbsp;{DateTime.local().toFormat("MM/dd/y")}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col val="6">
            <div className="form-group">
              <Field
                label="Submitter First Name*:"
                name="submitterFirstName"
                placeholder="John"
                component={FormText}
              />
            </div>
          </Col>
          <Col val="6">
            <div className="form-group">
              <Field
                label="Submitter Last Name*:"
                name="submitterLastName"
                placeholder="Doe"
                component={FormText}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col val="6">
            <div className="form-group">
              <Field
                label="Email*:"
                type="email"
                name="submitterEmail"
                placeholder="e.g. john.doe@gmail.com"
                component={FormText}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col val="6">
            <div className="form-group">
              <Field
                label="Job Title/Function*:"
                name="submitterJobTitle"
                placeholder="e.g. Sales Manager"
                component={FormText}
              />
            </div>
          </Col>
          <Col val="6">
            <div className="form-group">
              <Field
                label="Location*:"
                name="submitterLocation"
                placeholder="e.g. Location 01"
                component={FormText}
              />
            </div>
          </Col>
        </Row>
        <div className="form-group">
          <Field
            label="Project Name*:"
            name="name"
            placeholder="e.g. Colorful Food Grade Dye for Desserts and Confectionary"
            showCounter={true}
            maxLength={75}
            component={FormText}
          />
        </div>
        <div className="form-group">
          <Field
            label="Description*:"
            name="description"
            placeholder="Provide a brief description of the innovation you are proposing"
            showCounter={true}
            maxLength={500}
            component={FormTextArea}
          />
        </div>
      </Fragment>
    );
  }
}

export default SubmitInnovationForm;
