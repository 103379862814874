import { UserRole } from "../interface/entity/IUserEntity";

export const APP_LOGIN_URL = "/";
export const APP_GENERAL_USER_EMAIL = "employee@dudafarms.com";
export const APP_HOME_URLS = {
  [UserRole.Employee]: "/submit",
  [UserRole.Manager]: "/innovation",
  [UserRole.Admin]: "/innovation",
};

export enum RouteAccessLevels {
  // anonymous user
  Guest = 0,
  // logged in user
  Private = 2,
}

export const APP_SELECTOR = "root";
export const AUTO_LOGOUT_DELAY = 6000;

export const ACTIVITY_SORT_CREATED_AT_ASC = 0;
export const ACTIVITY_SORT_CREATED_AT_DESC = 1;
export const ACTIVITY_SORT_USER_ASC = 2;
export const ACTIVITY_SORT_USER_DESC = 3;
