import {IInnovationItemStageCommonActionTypes, IInnovationItemStageCommonState, INNOVATION_ITEM_STAGE_COMMON_SET_STAGE} from "./type";

const initialState: IInnovationItemStageCommonState = {
  stage: 0,
};

export const innovationItemStageCommonReducer = (
  state = initialState,
  action: IInnovationItemStageCommonActionTypes,
): IInnovationItemStageCommonState => {
  switch (action.type) {
    case INNOVATION_ITEM_STAGE_COMMON_SET_STAGE: {
      return {
        ...state,
        stage: action.stage,
      };
    }
    default:
      return state;
  }
};
