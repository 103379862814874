// because 'history' is a dependency of the react-router package
// tslint:disable-next-line no-implicit-dependencies
import * as H from "history";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { clearLocalStorage } from "../index";
import { InnovationStatusKey } from "../interface/entity/IInnovationEntity";
import {
  UserRole,
  UserValidationPermission,
} from "../interface/entity/IUserEntity";
import mainLogo from "../markup/images/main-logo.png";
import { AppState } from "../store";
import { deleteAuthTokenAndUser } from "../store/system/action";
import { ISystemState } from "../store/system/type";
import { Col, Container, Row } from "./Grid";
import { ADMIN_USER_LIST, INNOVATION_LIST, SUBMISSION_LIST } from "./routes";

interface IProps {
  system: ISystemState;
  onDeleteAuthTokenAndUser: () => void;
  location: H.Location;
}

class Header extends React.Component<IProps> {
  public render(): React.ReactNode {
    const {
      system: { isGuest, user, innovationStats },
    } = this.props;

    let hasUnseenSubmission = false;

    if (innovationStats) {
      const submitted = innovationStats.find(
        (item) => item.status === InnovationStatusKey.Submitted
      );

      if (submitted && submitted.amount > 0) {
        hasUnseenSubmission = true;
      }
    }

    const menuItems: Array<{
      to: string;
      title: string;
      isActive: () => boolean;
      isVisible: () => boolean;
      hasDot: boolean;
    }> = [
      {
        to: SUBMISSION_LIST,
        title: "Submissions",
        isActive: () => this.props.location.pathname.startsWith("/submission"),
        isVisible: () =>
          user !== undefined &&
          user.isValidator === UserValidationPermission.Allow,
        hasDot: hasUnseenSubmission,
      },
      {
        to: INNOVATION_LIST,
        title: "Innovations",
        isActive: () => this.props.location.pathname.startsWith("/innovation"),
        isVisible: () => true,
        hasDot: false,
      },
      {
        to: ADMIN_USER_LIST,
        title: "Settings",
        isActive: () => this.props.location.pathname.startsWith("/admin"),
        isVisible: () => user !== undefined && user.role === UserRole.Admin,
        hasDot: false,
      },
    ];

    return (
      <header className="main-header">
        <Container>
          <Row>
            <Col val="3">
              <div className="main-header-logo">
                <Link to="/">
                  <img src={mainLogo} alt="Duda Farms" />
                </Link>
              </div>
            </Col>
            <Col val="6">
              <nav className="nav-main-wrap">
                <ul className="nav-main">
                  {menuItems
                    .filter((item) => item.isVisible())
                    .map((item) => (
                      <li
                        key={item.to}
                        className={`${item.isActive() ? "active" : ""} ${item.hasDot ? "nav-main-li-dot" : ""}`}
                      >
                        <Link to={item.to}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </nav>
            </Col>
            <Col val="3">
              {!isGuest && (
                <div className="text-right py-1">
                  <button className="btn btn-link" onClick={this.onLogout}>
                    <i className="ico ico-logout mr-1">
                      <span className="sr-only">Sign Out</span>
                    </i>
                    Sign Out
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </header>
    );
  }

  private onLogout = () => {
    const { onDeleteAuthTokenAndUser } = this.props;

    clearLocalStorage();
    onDeleteAuthTokenAndUser();
  };
}

const mapStateToProps = (state: AppState) => ({
  system: state.system,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDeleteAuthTokenAndUser: () => dispatch(deleteAuthTokenAndUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
