import {IFormText, IFormTextFields, IFormToggle} from "../../../../../../components/form/IForm";
import {YesNo} from "../../../../../../interface/entity/IInnovationEntity";

class ActionUtils {
  public static finalProductFormAlignmentBasic: IFormTextFields = {
    title: "Final Product Form Alignment:",
    fields: [
      {
        label: "Size",
        name: "size",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Weight",
        name: "weight",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Formula",
        name: "formula",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Yield",
        name: "yield",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Cost/Unit",
        name: "costUntil",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
    ],
  };

  public static salesTargetAggregated: IFormToggle = {
    label: "Sales Target Aggregated:",
    name: "salesTargetAggregated",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static salesObjectivesByCustomerAccountOwner: IFormToggle = {
    label: "Sales Objectives by Customer/Account Owner:",
    name: "salesObjectivesByCustomerAccountOwner",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static finalProductFormAlignmentExtra: IFormTextFields = {
    title: "Production Pilot Metrics Confirmed:",
    fields: [
      {
        label: "Yield/Acre",
        name: "yieldAcre",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Plant Productivity Metrics",
        name: "plantProductivityMetrics",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Food/Plant Safety Metrics",
        name: "foodPlantSafetyMetrics",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
      {
        label: "Legal Review",
        name: "legalReview",
        placeholder: "Please fill in value…",
        labelClass: "text-regular",
      },
    ],
  };

  public static ifContractManufacturedAreCogsConfirmed: IFormToggle = {
    label: "If Contract Manufactured, are COGS confirmed:",
    name: "ifContractManufacturedAreCogsConfirmed",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static ifContractManufacturedAreCogsConfirmedAdjustment: IFormText = {
    name: "ifContractManufacturedAreCogsConfirmedAdjustment",
    placeholder: "Please fill in details…",
    label: "Variance From Target:",
    labelClass: "text-regular",
  };

  public static doesPilotScaleToFullLandPlan: IFormToggle = {
    label: "Does Pilot Scale to full land plan",
    name: "doesPilotScaleToFullLandPlan",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static doesPilotScaleToFullLandPlanAdjustment: IFormText = {
    name: "doesPilotScaleToFullLandPlanAdjustment",
    placeholder: "Please fill in details…",
    label: "Variance/Adjustments:",
    labelClass: "text-regular",
  };

  public static doFinalPilotCogsMeetOrExceedHurdles: IFormToggle = {
    label: "Do Final Pilot COGS meet or exceed hurdles",
    name: "doFinalPilotCogsMeetOrExceedHurdles",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static doFinalPilotCogsMeetOrExceedHurdlesAdjustment: IFormText = {
    name: "doFinalPilotCogsMeetOrExceedHurdlesAdjustment",
    placeholder: "Please fill in details…",
    label: "Details:",
    labelClass: "text-regular",
  };

  public static doSalesCommitmentsMatchProductForecast: IFormToggle = {
    label: "Do Sales Commitments match product forecast",
    name: "doSalesCommitmentsMatchProductForecast",
    options: [
      {value: YesNo.Yes, label: "Yes"},
      {value: YesNo.No, label: "No"},
    ],
  };

  public static doSalesCommitmentsMatchProductForecastAdjustment: IFormText = {
    name: "doSalesCommitmentsMatchProductForecastAdjustment",
    placeholder: "Please fill in details…",
    label: "Variance/Adjustments:",
    labelClass: "text-regular",
  };

  public static finalValues: IFormTextFields = {
    fields: [
      {
        label: "Final Volume Forecast:",
        name: "finalVolumeForecast",
        placeholder: "Please fill in details…",
      },
      {
        label: "Final Price/Unit:",
        name: "finalPriceUnit",
        placeholder: "Please fill in details…",
      },
      {
        label: "Final Cost per Unit:",
        name: "finalCostPerUnit",
        placeholder: "Please fill in details…",
      },
      {
        label: "Final Margin/Unit:",
        name: "finalMarginUnit",
        placeholder: "Please fill in details…",
      },
      {
        label: "Total Revenue Forecast:",
        name: "totalRevenueForecast",
        placeholder: "Please fill in details…",
      },
    ],
  };

  public static anyOtherInformationYouWouldLikeToInclude: IFormText = {
    label: "Any other information you would like to include:",
    name: "anyOtherInformationYouWouldLikeToInclude",
    placeholder: "Please Fill In Additional Information if any…",
  };
}

export default ActionUtils;
