import {Field, Formik, FormikProps} from "formik";
import {DateTime} from "luxon";
import React from "react";
import Modal from "react-modal";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Dispatch} from "redux";
import * as Yup from "yup";
import {FormTextArea} from "../../../../../../components/form";
import PostponedUploadMultipleField from "../../../../../../components/form/upload/PostponedUploadMultipleField";
import WrapperLoader from "../../../../../../components/loader/WrapperLoader";
import {
  IInnovationDepartmentEntity,
  InnovationDepartmentStatusKey,
  innovationDepartmentStatusKeyToMetaValue,
  innovationDepartmentStatusKeyToValue,
} from "../../../../../../interface/entity/IInnovationDepartmentEntity";
import {IInnovationEntity, InnovationStatusKey} from "../../../../../../interface/entity/IInnovationEntity";
import {AppState} from "../../../../../../store";
import {IInnovationItemStageOversightResolutionsState} from "../../../../../../store/innovationItem/stage/oversight/resolutions/type";

export interface IInnovationDepartmentResolution {
  status: InnovationDepartmentStatusKey;
  comment: string;
  attachments: File[];
}

interface IProps {
  onSubmit: (values: IInnovationDepartmentResolution) => void;
  innovationDepartmentItem: IInnovationDepartmentEntity;
  commentsLink: string;
  innovationEntity?: IInnovationEntity;
  innovationItemStageOversightResolutionsState: IInnovationItemStageOversightResolutionsState;
}

interface IState {
  isModalOpened: boolean;
  isLastCommentTooltipOpen: boolean;
  currentStatus?: InnovationDepartmentStatusKey;
}

class InnovationItemOversightDashboardDepartmentRow extends React.Component<IProps, IState> {
  constructor(props: Readonly<IProps>) {
    super(props);

    this.state = {
      isModalOpened: false,
      isLastCommentTooltipOpen: false,
    };
  }

  public render(): React.ReactNode {
    const {innovationDepartmentItem: {department, status, comments, id},
           onSubmit,
           commentsLink,
           innovationEntity,
           innovationItemStageOversightResolutionsState: {isItemLoading},
    } = this.props;

    const initialValues: IInnovationDepartmentResolution = {
      status,
      comment: "",
      attachments: [],
    };

    const validationSchema = Yup.object().shape({
      comment: Yup.string()
        .max(300)
        .trim()
        .required("Required"),
    });

    const buttons = Object.values(InnovationDepartmentStatusKey)
        .filter((iterationStatus: InnovationDepartmentStatusKey) => !isNaN(iterationStatus))
        .filter((iterationStatus: InnovationDepartmentStatusKey) => [InnovationDepartmentStatusKey.Approval, InnovationDepartmentStatusKey.Go].includes(iterationStatus))
    ;
    const lastComment = comments && comments.sort((a, b) => DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? 0 : -1)[comments.length - 1];

    return (
      <tr>
        <td className="text-medium">
          {department && department.name}
        </td>
        <td>
          <div className="form-radio-custom-wrap">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values: IInnovationDepartmentResolution) => {
                if (this.props.innovationItemStageOversightResolutionsState.isItemLoading) {
                  return;
                }
                onSubmit(values);
              }}
              render={({handleSubmit, submitForm, values, setValues}: FormikProps<IInnovationDepartmentResolution>) => (
                <form onSubmit={handleSubmit} className={"row w-100"}>
                  <Field
                    name={"status"}
                    render={() => {
                      return buttons.map((iterableStatus: InnovationDepartmentStatusKey) => (
                        <button
                          className={`col btn btn-radio btn-${innovationDepartmentStatusKeyToValue[iterableStatus]} ${status === iterableStatus ? "active" : ""}`}
                          type={"button"}
                          key={iterableStatus}
                          disabled={innovationEntity && (innovationEntity.status !== InnovationStatusKey.Active)}
                          onClick={() => {
                            if (status === iterableStatus) {
                              return;
                            }
                            setValues({
                              status: iterableStatus,
                              comment: "",
                              attachments: [],
                            });
                            this.setState({
                              isModalOpened: true,
                              currentStatus: status,
                            });
                          }}
                        >
                          {innovationDepartmentStatusKeyToMetaValue[iterableStatus]}
                        </button>
                      ));
                    }}
                  />
                  <Modal
                    isOpen={this.state.isModalOpened}
                    onRequestClose={this.onCloseModal}
                    contentLabel="Change Resolution Comment"
                    className="modal modal-sm"
                  >
                    <header className="modal-header clearfix">
                      <h3 className="modal-title pull-left">
                        Change Resolution Comment
                      </h3>
                      <button
                        className="modal-close"
                        type="button"
                        onClick={this.onCloseModal}
                      >
                        <i className="ico ico-close-thin"/>
                      </button>
                    </header>
                    <div className="mobal-body px-5 py-6">
                      <WrapperLoader isLoading={isItemLoading}>
                        <div className="form-group mb-6">
                          <span className="form-label">You are making following change to resolution status:</span>
                          <div className="activity-status">
                            {/*tslint:disable-next-line*/}
                            <div
                              className={"activity-status-prev status-"
                              + (this.state.currentStatus !== undefined && innovationDepartmentStatusKeyToValue[this.state.currentStatus])}
                            >
                              {this.state.currentStatus !== undefined && innovationDepartmentStatusKeyToMetaValue[this.state.currentStatus]}
                            </div>
                            <div className={"activity-status-new status-" + innovationDepartmentStatusKeyToValue[values.status]}>
                              {innovationDepartmentStatusKeyToMetaValue[values.status]}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <Field
                            label="Comment*:"
                            name="comment"
                            placeholder="Comment text..."
                            showCounter={true}
                            maxLength={300}
                            component={FormTextArea}
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            name="attachments"
                            component={PostponedUploadMultipleField}
                            emptyMessage="No files Attached Yet..."
                          />
                        </div>
                      </WrapperLoader>
                    </div>
                    <footer className="modal-footer text-right">
                      <button
                        className="btn btn-shade ml-4"
                        type="button"
                        onClick={this.onCloseModal}
                      >
                        Close
                      </button>
                      <button
                        className="btn btn-shade ml-4"
                        type="button"
                        onClick={() => {
                          submitForm();
                        }}
                      >
                        Add Comment
                      </button>
                    </footer>
                  </Modal>
                </form>
              )}
            />
          </div>
        </td>
        <td>
          <div className="clearfix">
            <div className="pull-left">
              <div className="form-control-static-sm">
                <Link to={commentsLink + "/" + id}>{comments ? comments.length : 0} comment</Link>
              </div>
            </div>
            <div className="pull-right relative">
              <button
                className="btn btn-default"
                onClick={() => (comments && comments.length > 0) && this.setState({isLastCommentTooltipOpen: true})}
                onBlur={() => this.setState({isLastCommentTooltipOpen: false})}
              >
                <i className="ico ico-comment "/>
              </button>
              <div className={`tooltip-comment ${this.state.isLastCommentTooltipOpen ? "d-block" : ""}`}>
                <div className="tooltip-arrow"/>
                <div className="tooltip-body">
                  <div className="tooltip-date">Added: {lastComment && DateTime.fromISO(lastComment.createdAt).toFormat("MM/dd/y")}</div>
                  <div className="tooltip-title">{lastComment && lastComment.user && lastComment.user.fullName}</div>
                  <div className="tooltip-text">{lastComment && lastComment.message}</div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }

  private onCloseModal = () => {
    const {innovationItemStageOversightResolutionsState: {isItemLoading}} = this.props;
    if (isItemLoading) {
      return;
    }
    this.setState({isModalOpened: false, currentStatus: undefined});
  }
}

const mapStateToProps = (state: AppState) => ({
  innovationItemStageOversightResolutionsState: state.innovationItem.stage.oversight.resolutions,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnovationItemOversightDashboardDepartmentRow);
