import {
  INotification,
  INotificationActionTypes,
  INotificationState,
  NOTIFICATION_ADD_NOTIFICATION,
  NOTIFICATION_REMOVE_NOTIFICATION,
} from "./type";

const initialState: INotificationState = {
  items: [],
};

export const notificationReducer = (
  state = initialState,
  action: INotificationActionTypes,
): INotificationState => {
  switch (action.type) {
    case NOTIFICATION_ADD_NOTIFICATION: {
      return {
        ...state,
        items: [
          action.notification,
          ...state.items,
        ],
      };
    }
    case NOTIFICATION_REMOVE_NOTIFICATION: {
      return {
        ...state,
        items: state.items.filter((item: INotification) => item.id !== action.id),
      };
    }
    default:
      return state;
  }
};
