import {ErrorMessage} from "formik";
import React from "react";
import {Col, Row} from "../Grid";
import {IForm} from "./IForm";

const FormText = (
  {
    field,
    form,
    type = "text",
    label,
    placeholder = "",
    className = "form-control",
    labelClass,
    append = "",
    onChange = null,
    showCounter = false,
    maxLength,
    disabled = false,
    col,
    labelHtml,
  }: IForm) => {

  const input = append ? (
    <div className="input-group">
      <div className="input-group-append input-group-val">{append}</div>
      <input
        {...field}
        type={type}
        placeholder={placeholder}
        className={className}
        onChange={onChange || field.onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
    </div>
  ) : (
    <input
      {...field}
      type={type}
      placeholder={placeholder}
      className={className}
      onChange={onChange || field.onChange}
      maxLength={maxLength}
      disabled={disabled}
    />
  );

  const content = col ? <Row><Col val={col}>{input}</Col></Row> : input;

  return (
    <label className={`form-control-wrap ${(form.errors[field.name] && form.touched[field.name]) ? "form-error-wrap" : ""}`}>
      {(label && showCounter) && (
        <div className={"row"}>
          {label && <div className={"col-8"}>
          <span className={`form-label${label === field.name ? " sr-only" : ""}${labelClass ? (" " + labelClass) : ""}`}>
          {label}
        </span>
          </div>}
          {showCounter && maxLength && <div className={"col-4 text-right text-primary-light"}>{field.value.length}/{maxLength}</div>}
        </div>
      )}
      {(label && !showCounter) && (
        <span className={`form-label${label === field.name ? " sr-only" : ""}${labelClass ? (" " + labelClass) : ""}`}>
          {label} {labelHtml}
        </span>
      )}
      {content}
      <ErrorMessage component="span" className="form-error" name={field.name}/>
    </label>
  );
};

export default FormText;
