import {
  ADMIN_USER_COLLECTION_FETCH_ERROR,
  ADMIN_USER_COLLECTION_FETCH_START,
  ADMIN_USER_COLLECTION_FETCH_SUCCESS, ADMIN_USER_COLLECTION_REMOVE_ERROR,
  ADMIN_USER_COLLECTION_REMOVE_START,
  ADMIN_USER_COLLECTION_REMOVE_SUCCESS,
  IAdminUserListActionTypes,
  IAdminUserListState,
} from "./type";

const initialState: IAdminUserListState = {
  collectionResource: {
    isLoading: false,
    items: [],
  },
  isRemoving: false,
};

export const adminUserListReducer = (
  state = initialState,
  action: IAdminUserListActionTypes,
): IAdminUserListState => {
  switch (action.type) {
    case ADMIN_USER_COLLECTION_REMOVE_START: {
      return {
        ...state,
        isRemoving: true,
      };
    }
    case ADMIN_USER_COLLECTION_REMOVE_SUCCESS: {
      return {
        ...state,
        isRemoving: false,
      };
    }
    case ADMIN_USER_COLLECTION_REMOVE_ERROR: {
      return {
        ...state,
        isRemoving: false,
      };
    }
    case ADMIN_USER_COLLECTION_FETCH_START: {
      return {
        ...state,
        collectionResource: {
          isLoading: true,
          items: [],
        },
      };
    }
    case ADMIN_USER_COLLECTION_FETCH_SUCCESS: {
      return {
        ...state,
        collectionResource: {
          ...action.collectionResource,
          isLoading: false,
        },
      };
    }
    case ADMIN_USER_COLLECTION_FETCH_ERROR: {
      return {
        ...state,
        collectionResource: {
          isLoading: false,
          items: [],
        },
      };
    }
    default:
      return state;
  }
};
